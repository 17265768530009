<template>
  <table style="width: 100%; background-color:#F0F1DC; height: 40px; font-size: 20px;">
    <tr >
      <td>
        <B>&nbsp;&nbsp;INVENTARIO DE RECURSOS TURÍSTICOS</B>
      </td>
    </tr>
  </table>
  <br>
  <div class="grid p-fluid">
  <div class="col-12 md:col-4">
    <div class="card">
      <table cellpadding="3" style="width: 100%; background-color:#F8F8F8; height: 35px;">
        <tr >
          <td>
            <B>&nbsp;&nbsp;INFORMACIÓN TURÍSTICA</B>
          </td>
          <td style="width: 55px;">
            <div>
              <router-link to="/turistico">
                <Button label="Atrás" icon="pi pi-arrow-left" class="p-button-info" @click="goBack" />
              </router-link>
            </div>
          </td>
          <td style="width: 150px;">
            <div>
              <Button label="Actualizar datos" icon="pi pi-pencil" @click="editarDialogModal" />
            </div>
          </td>
     
        </tr>
      </table>

      <!--Formulario de registro de informacion-->
      <div class="floatlabel-demo col-12 md:col-12">
        <Dialog header="Actualizar datos de recursos turísticos"
            v-model:visible="displayModal"
            :style="{ width: '60%' }"
            :modal="true"
            class="p-fluid top-modal">
          <br />
          
          <!--Tabla de displiegue de la informacion-->
          <div class="grid formgrid">
            <div class="field col-12">
              
              <Accordion class="accordion-custom" :activeIndex="0">
                <AccordionTab>
                    <template #header>
                      <i class="pi pi-map-marker"></i>
                      <span> GEOLOCALIZACIÓN</span>
                    </template>
                    <div class="p-fluid">
                      <div class="grid formgrid">
                        <div class="field col-12 mb-3 lg:col-1 lg:col-12">
                          <Prueba2View
                            id="mapx"
                            title="Ubique a su empresa haciendo doble click sobre la ubicación exacta *"
                            :zoom="campos.zoom"
                            :lati="ppirta.g_x"
                            :long="ppirta.g_y"
                            @newPosition="updatePosition($event, campos)">
                          </Prueba2View>
                        </div>
                      <div class="field col-12 mb-3 lg:col-1 lg:col-2">
                          <label for="password">X</label>
                          <InputText type="text" id="g_x" v-model="ppirta.g_x" readonly/>
                        </div>
                        <div class="field col-12 mb-3 lg:col-1 lg:col-2">
                          <label for="inputtext">Y</label>
                          <InputText type="text" id="g_y" v-model="ppirta.g_y" readonly/>
                        </div>
                        <div class="field col-12 mb-3 lg:col-2 lg:col-2">
                          <label for="inputtext">Z</label>
                          <InputText
                            type="number"
                            id="g_z"
                            v-model.trim="ppirta.g_z"
                            autofocus
                            @input="validateInput"
                          />
                          <p v-if="error" style="color:rgb(169, 7, 7)">El valor Z debe tener entre mínimo 1 y máximo 4 dígitos.</p>              
                        </div>
                        <!-- <div class="field col-12 mb-3 lg:col-2 lg:col-3">
                          <label for="inputtext">Proyeccion</label>
                          <InputText type="text" id="g_proyeccion" v-model.trim="ppirta.g_proyeccion" autofocus/>
                        
                        </div> -->
                        <div class="field col-12 mb-3 lg:col-2 lg:col-3">
                          <label for="proyeccion">Proyección</label>
                          <Dropdown
                            id="proyeccion"
                            :options="proyecciones"
                            v-model="ppirta.id_proyeccion"
                            optionLabel="sigla"
                            optionValue="id"
                            placeholder="Elegir..."
                          ></Dropdown>
                        </div>
                        <!-- <div class="field col-12 mb-3 lg:col-2 lg:col-3">
                          <label for="inputtext">Datum</label>
                          <InputText type="text" id="g_datum" v-model.trim="ppirta.g_datum" autofocus/>
                        </div> -->
                        
                        <div class="field col-12 mb-3 lg:col-2 lg:col-3">
                          <label for="g_datum">Datum</label>
                          <Dropdown
                            id="g_datum"
                            :options="datums"
                            v-model="ppirta.id_datum"
                            optionLabel="sigla"
                            optionValue="id"
                            placeholder="Elegir..."
                          ></Dropdown>
                        </div>

                      </div>
                    </div>
                </AccordionTab>
                <AccordionTab>
                    <template #header>
                        <i class="pi pi-user"></i>
                        <span> ATRACTIVO TURÍSTICO</span>
                    </template>
                    <div class="p-fluid">
                      <div class="grid formgrid" >
                        <div class="field col-12 mb-3 lg:col-2 lg:col-8 ">
                          <label for="username">Nombre de Atractivo</label>
                          <InputText id="username" autofocus  v-model="ppirta.nombre_atractivo"/>
                        </div>

                        <div class="field col-12 mb-3 lg:col-1 lg:col-2">
                          <label for="jerarquia">Jerarquía</label>
                          <InputText id="jerarquia"  placeholder="" v-model="ppirta.jerarquia" />
                        </div>

                        <div class="field col-12 mb-3 lg:col-1 lg:col-2">
                          <label for="codigo">Código</label>
                          <InputText id="codigo" placeholder="00.00.00" v-model="ppirta.codigo" readonly/>
                        </div>
                        <div class="field col-12 mb-3 lg:col-2 lg:col-4">
                          <label for="username">Categoría</label>
                          <span>
                            <Dropdown id="id_categoria" v-model="ppirta.id_categoria" :options="categorias" @update:model-value="val => tipo(val)"
                              optionLabel="nombre" optionValue="id" placeholder="Elegir..." />
                          </span>
                        </div>
                        <div class="field col-12 mb-3 lg:col-2 lg:col-4">
                          <label for="username">Tipo</label>
                          <span>
                            <Dropdown id="id_tipo" v-model="ppirta.id_tipo" :options="tipos" @update:model-value="val => subTipo(val)"
                              optionLabel="nombre" optionValue="id" placeholder="Elegir..." />
                          </span>
                        </div>
                        <div class="field col-12 mb-3 lg:col-2 lg:col-4">
                          <label for="username">Subtipo</label>
                          <span>
                            <Dropdown id="id_subtipo" :options="subtipos" v-model="ppirta.id_subtipo" @update:model-value="val => codigoGenerado(val)"
                            optionLabel="nombre" optionValue="id" placeholder="Elegir..." />
                          </span>
                        </div>
            
                      </div>
                    </div>
                </AccordionTab>
                
                <AccordionTab>
                  <template #header>
                      <i class="pi pi-directions"></i>
                      <span> UBICACIÓN TERRITORIAL</span>
                  </template>
                  <div class="p-fluid">
                    <div class="grid formgrid">
                    <div class="field col-12 mb-3 lg:col-1 lg:col-3">
                        <label for="macroregion">Macro región</label>
                        <Dropdown id="macroregion" :options="macroRegion" v-model="ppirta.id_macro_region" @update:model-value="val => getRegion(val)"
                          optionLabel="nombre" optionValue="id" placeholder="Elegir..."></Dropdown>
                      </div>
                      <div class="field col-12 mb-3 lg:col-1 lg:col-3">
                        <label for="region">Región</label>
                        <Dropdown id="region" :options="regiones" v-model="ppirta.id_region"
                          optionLabel="nombre" optionValue="id" placeholder="Elegir..."></Dropdown>
                      </div>
                      <div class="field col-12 mb-3 lg:col-2 lg:col-3">
                        <label for="departamneto">Departamento</label>
                        <Dropdown id="departamneto" :options="departamentos" v-model="ppirta.id_departamento" @update:model-value="val => getProvincia(val)"
                          optionLabel="nombre" optionValue="id" placeholder="Elegir..."
                          ></Dropdown>
                      </div>
                      <div class="field col-12 mb-3 lg:col-2 lg:col-3">
                        <label for="provincia">Provincia</label>
                        <Dropdown id="provincia" :options="provincias" v-model="ppirta.id_provincia" @update:model-value="val => getMunicipio(val)"
                          optionLabel="nombre" optionValue="id" placeholder="Elegir..."></Dropdown>
                      </div>
                      <div class="field col-12 mb-3 lg:col-2 lg:col-3">
                        <label for="municipio">Municipio</label>
                        <Dropdown id="municipio" :options="municipios" v-model="ppirta.id_municipio" @update:model-value="val => getComunidad(val)"
                          optionLabel="nombre" optionValue="id" placeholder="Elegir..."></Dropdown>
                      </div>
                      <div class="field col-12 mb-3 lg:col-2 lg:col-3">
                        <label for="comunidad">Comunidad</label>
                        <InputText type="text" id="g_datum" v-model.trim="ppirta.comunidad" autofocus/>
                      </div>
                      <div class="field col-12 mb-3 lg:col-2 lg:col-3">
                        <label for="inputtext">Distrito/Sección</label>
                        <InputText type="text" id="g_datum" v-model.trim="ppirta.distrito" autofocus/>
                      </div>
                      <div class="field col-12 mb-3 lg:col-2 lg:col-3">
                        <label for="inputtext">GAIOC/TIOC</label>
                        <InputText type="text" id="g_datum" v-model.trim="ppirta.gaioc" autofocus/>
                      </div>
                    </div>
                  </div>
                </AccordionTab>
              </Accordion>
            </div>
          </div>
          <Toast />

          <!-- {{ ppirta }} -->
          <template #footer>
            <Button label="Cancelar" icon="pi pi-times" @click="closeModal" class="p-button-text" />
            <Button label="Guardar cambios" icon="pi pi-check" @click="guardarTuristico()" />
          </template>
        </Dialog>

      </div>
      <br>
      <div>
        <!-- {{ ppirta }} -->
        <ul class="widget-list">
          <li class="flex py-2">
              <div class="flex align-items-center">
                  <i class="pi pi-star-fill p-2 lightblue-bgcolor white-color widget-list-item-radius"></i>
                  <div :class="{'ml-2': !isRTL, 'mr-2': isRTL}">
                      <div><b>RECURSO ATRACTIVO</b> | {{ppirta.nombre_atractivo}}</div>
                      <small class="muted-text"><b>CÓDIGO</b> | {{ppirta.codigo}}</small>                            
                  </div>
              </div>
          </li>

          <li class="flex py-2">
              <div class="flex align-items-center">
                  <i class="pi pi-file-excel p-2 yellow-bgcolor text-color widget-list-item-radius"></i>
                  <div :class="{'ml-2': !isRTL, 'mr-2': isRTL}">
                    <div><b>CATEGORÍA</b> | {{ppirta.categoria}}</div>
                      <small class="muted-text"><b>CÓDIGO</b> | {{ppirta.codigo_categoria}}</small>                            
                  </div>
              </div>
          </li>

          <li class="flex py-2">
              <div class="flex align-items-center">
                  <i class="pi pi-image p-2 teal-bgcolor white-color widget-list-item-radius"></i>
                  <div :class="{'ml-2': !isRTL, 'mr-2': isRTL}">
                    <div><b>TIPO CATEGORÍA</b> | {{ppirta.tipo}}</div>
                      <small class="muted-text"><b>CÓDIGO</b> | {{ppirta.codigo_tipo}}</small>                            
                  </div>
              </div>
          </li>

          <li class="flex py-2">
              <div class="flex align-items-center">
                  <i class="pi pi-file p-2 bluegrey-bgcolor white-color widget-list-item-radius"></i>
                  <div :class="{'ml-2': !isRTL, 'mr-2': isRTL}">
                    <div><b>SUB TIPO CATEGORÍA</b> | {{ppirta.subtipo}}</div>
                      <small class="muted-text"><b>CÓDIGO</b> | {{ppirta.codigo_subtipo}}</small>                            
                  </div>
              </div>
          </li>
          <li class="flex py-2">
              <div class="flex align-items-center">
                  <i class="pi pi-file p-2 bluegrey-bgcolor white-color widget-list-item-radius"></i>
                  <div :class="{'ml-2': !isRTL, 'mr-2': isRTL}">
                    <div><b>JERARQUÍA</b> | {{ppirta.jerarquia}}</div>
                      <!-- <small class="muted-text"><b>CODIGO</b> | 1.1.1</small>                             -->
                  </div>
              </div>
          </li>
          <!-- <li class="flex py-2">
              <div class="flex align-items-center">
                  <i class="pi pi-file p-2 bluegrey-bgcolor white-color widget-list-item-radius"></i>
                  <div :class="{'ml-2': !isRTL, 'mr-2': isRTL}">
                    <div><b>CODIGO</b> | {{ppirta.codigo}}</div>
                      <small class="muted-text"><b>CODIGO</b> | 1.1.1</small>                            
                  </div>
              </div>
          </li> -->
      </ul> 
    </div>
  
     <br>
     <table style="width: 100%; background-color:#F8F8F8; height: 35px;">
        <tr >
          <td>
            <B>&nbsp;&nbsp;GEOLOCALIZACIÓN</B>
          </td>
        </tr>
      </table>
      <br>
      <table style="width: 100%;">
        <tr style="text-align: center;background-color:#D7ECEE; height: 35px;" >
          <td><B>X</B></td>
          <td><B>Y</B></td>
          <td><B>Z</B></td>
          <td><B>PROYECCIÓN</B></td>
          <td><B>DATUM</B></td>
        </tr>
        <tr style="text-align: center;" >
          <td>{{ppirta.g_x}}</td>
          <td>{{ppirta.g_y}}</td>
          <td>{{ppirta.g_z}}</td>
          <td>{{ppirta.sigla_proyeccion}}</td>
          <td>{{ppirta.sigla_datum}}</td>
        </tr>
      </table>
      <br>

     <div class="overlay-header" style=" height: 20rem">
      <Prueba1View
        id="mapx"
        title="Ubique a su empresa haciendo doble click sobre la ubicación exacta *"
        :zoom="campos.zoom"
        :lat="campos.lat"
        :long="campos.long"
        @newPosition="updatePosition($event, campos)">
      </Prueba1View>
    </div>
    <br><br><br><br><br><br><br><br><br><br><br>
     <table style="width: 100%; background-color:#F8F8F8; height: 35px;">
        <tr >
          <td>
            <B>&nbsp;&nbsp;UBICACIÓN TERRITORIAL</B>
          </td>
        </tr>
      </table>
      <br>
      <div>
        <ul class="widget-list">
            <li class="flex py-2">
                <div class="flex align-items-center">
                    <i class="pi pi-star-fill p-2 lightblue-bgcolor white-color widget-list-item-radius"></i>
                    <div :class="{'ml-2': !isRTL, 'mr-2': isRTL}">
                        <div><b>MACRO REGIÓN</b> | {{ppirta.macroregion}} <b>CÓDIGO</b> | {{ppirta.codigo_macroregion}}</div>
                        <div><b>REGIÓN</b> | {{ppirta.region}} <b>CÓDIGO</b> | {{ppirta.codigo_region}}</div>
                    </div>
                </div>
            </li>

            <li class="flex py-2">
                <div class="flex align-items-center">
                    <i class="pi pi-file-excel p-2 yellow-bgcolor text-color widget-list-item-radius"></i>
                    <div :class="{'ml-2': !isRTL, 'mr-2': isRTL}">
                      <div><b>DEPARTAMENTO</b> | {{ppirta.departamento}} <b>- CÓDIGO</b> | {{ppirta.codigo_departamento}}</div>
                      <div><b>PROVINCIA</b> | {{ppirta.provincia}} <b>CÓDIGO</b> | {{ppirta.codigo_provincia}}</div>
                    </div>
                </div>
            </li>

            <li class="flex py-2">
                <div class="flex align-items-center">
                    <i class="pi pi-image p-2 teal-bgcolor white-color widget-list-item-radius"></i>
                    <div :class="{'ml-2': !isRTL, 'mr-2': isRTL}">
                      <div><b>MUNICIPIO</b> | {{ppirta.municipio}} <b>- CÓDIGO</b> | {{ppirta.codigo_municipio}}</div>
                      <!-- <div><b>CAPITAL</b> |  <b>CÓDIGO</b> | 021101 </div> -->
                      <div><b>COMUNIDAD</b> | {{ppirta.comunidad}}</div>
                    </div>
                </div>
            </li>
            <!-- <li class="flex py-2">
                <div class="flex align-items-center">
                    <i class="pi pi-file p-2 bluegrey-bgcolor white-color widget-list-item-radius"></i>
                    <div :class="{'ml-2': !isRTL, 'mr-2': isRTL}">
                      <div><b>COMUNIDAD</b> | {{ppirta.comunidad}}</div>
                        <small class="muted-text"><b>CÓDIGO</b> | 1.1.1</small>                            
                    </div>
                </div>
            </li> -->
        </ul> 
     </div>
    </div>
  </div>
  
  <div class="col-12 md:col-8">
        <div class="card card-w-title">
          <table style="width: 100%; background-color:#F8F8F8; height: 35px;">
        <tr >
          <td>
            <B>&nbsp;&nbsp;DETALLE DE RECURSOS TURÍSTICOS ATRACTIVOS</B>
          </td>
        </tr>
      </table>
      <br>
          <TabMenu :model="nestedRouteItems" />
          <router-view/>
        </div>
      </div>
  </div>
    
  <TabView>
    <TabPanel v-for="tab in tabs" :key="tab.title" :header="tab.title">
      <p>{{tab.content}}</p>
    </TabPanel>
  </TabView>
  
  </template>
  
  <script>
  import { onMounted, ref } from 'vue';
  import Accordion from 'primevue/accordion';
  import Dropdown from 'primevue/dropdown';
  import * as ppirtaService from '@/service/PlantillaService.js'
  import * as departamentoService from '@/service/DepartamentoService.js'
  import * as categoriaService from '@/service/CategoriaService.js'
  import * as tipoService from '@/service/TipoService.js'
  import * as provinciaService from '@/service/ProvinciaService.js'
  import * as municipioService from '@/service/MunicipioService.js'
  import * as comunidadSevice from '@/service/ComunidadService.js'
  import * as macroregionService from '@/service/MacroregionService.js'
  import * as regionService from '@/service/RegionService.js'
  import * as proyeccionService from '@/service/ProyeccionService.js'
  import * as datumService from '@/service/DatumService.js'
  import { useToast } from "primevue/usetoast";
  import InputMask from "primevue/inputmask";
  
  import { FilterMatchMode } from "primevue/api";
  
  // Importacones para realizar las validaciones
  import { email, helpers, required } from "@vuelidate/validators";
  import { useVuelidate } from "@vuelidate/core";

  import { useRoute } from 'vue-router'
  import Prueba1View from '@/pages/admin/turistico/Prueva1View.vue'
  import Prueba2View from '@/pages/admin/turistico/Prueba2View.vue'
  
  export default {
    data() {
    return {
      ppirta: {
        g_z: ""
      },
      error: false
    };
  },
    methods: {
      goBack() {
        localStorage.removeItem("savedLatitude");
        localStorage.removeItem("savedLongitude");
        this.$router.push("/turistico");
      },
      validateInput() {
        const inputValue = this.ppirta.g_z;
        const isValid = /^\d{1,4}$/.test(inputValue);

        if (isValid) {
          this.error = false;
        } else {
          this.error = true;
          this.ppirta.g_z = '';
        }
      }
    },
    props:{
      src: {
        type: Array,
        required: true
      }
    },
    components: {
      "Prueba1View": Prueba1View,
      "Prueba2View": Prueba2View,
      Dropdown,
    },
    setup(){
      const campos = ref({ });
      const categorias = ref([]);
      const categoria = ref({});
      const tipos = ref([]);
      const subtipos = ref([]);
      const provincias = ref([]);
      const municipios = ref([]);
      const comunidades = ref([]);
      const macroRegion = ref([]);
      const regiones = ref([]);
      const departamentos = ref([]);
      const ppirta = ref({});
      const displayModal = ref(false);
      const submitted = ref(false);
      const toast = useToast();
      const errores = ref({});
      const route = useRoute();
      const proyecciones = ref([]);
      const datums = ref([]);
      onMounted(async () => {
        getCategorias()
        one_ppirtas()
        getMacroregion()
        getDepartamentos()
        await cargarProyecciones();
        await cargarDatums();
      })

      const cargarProyecciones = async () => {
        try {
          const { data } = await proyeccionService.list();
          proyecciones.value = data.proyeccion;
        } catch (error) {
          console.error('Error al cargar las proyecciones:', error);
        }
      };

    const cargarDatums = async () => {
      try {
        const { data } = await datumService.list();
        datums.value = data.datum;
      } catch (error) {
        console.error('Error al cargar los datums:', error);
      }
    };
  
      // METODOS
      const one_ppirtas =  async () => {
       const {data} = await ppirtaService.show(route.params.id)
       ppirta.value = data.ppirta
       //console.log(ppirta.value)
      }
      
      const getCategorias = async () => {
        const {data} = await categoriaService.index('','','');
        categorias.value=data.categorias.data;
      }

      const tipo = async (val) => {
        const {data} = await tipoService.indexcat(val);
        tipos.value=data.tipos;
      }
      const subTipo = async (val) => {
        const {data} = await tipoService.indexsub(val);
        subtipos.value=data.subtipos;
      }

      const codigoGenerado = async (val) => {
        const {data} = await tipoService.detallesub(val);
        usuario.value.codigo = data.subtipo.codigo
      }

      const getMacroregion = async (val) => {
        const {data} = await macroregionService.list(val);
        macroRegion.value=data.macroRegion;
      }

      const getRegion = async (val) => {
        const {data} = await regionService.showmacro(val);
        regiones.value=data.regiones;
      }

      const getDepartamentos = async () => {
        const {data} = await departamentoService.list();
        departamentos.value=data.departamentos;
      }

      const getProvincia = async (val) => {
        const {data} = await provinciaService.buscar(val);
        provincias.value=data.provincias;
      }

      const getMunicipio = async (val) => {
        const {data} = await municipioService.showPromun(val);
        municipios.value=data.municipios;
      }

      const guardarCategoria = async () => {
        try {
          if(categoria.value.id){
            await categoriaService.update(categoria.value, categoria.value.id)
            listarCategorias()
            closeModal() 
            toast.add({severity:'success', summary: 'REGISTRO', detail:'Macro region Actualizada', life: 3000});
          }
          else{
          await categoriaService.store(categoria.value)
          listarCategorias()
          //categorias.value.push(categoria.value)
          closeModal()     
          toast.add({severity:'success', summary: 'REGISTRO', detail:'Macro region Registrada', life: 3000}); 
          }
        } catch (error) {
          console.log(error.response.data)
          errores.value = error.response.data.errores
          toast.add({severity:'error', summary: 'ERROR DE REGISTRO', detail:'Error de registro de Macro region', life: 3000});
        }
  
  
  
      
      }
  
      // METODOS DE LA PLANTILLA
  
      const selectButtonValues1= ([
            {name: 'SI', code: 'O1'},
            {name: 'NO', code: 'O2'},
          ]);
  
       const selectButtonValue1 = null;
      
      const  nestedRouteItems=([
        {
          label : 'INFORMACIÓN',
          to :'/informacion/'+ route.params.id
        },
        {
          label: 'RUTAS',
          to: '/rutasacceso/'+ route.params.id
        },
        {
          label: 'TEMPORALIDAD',
          to: '/temporalidad/'+ route.params.id
        },
        {
          label: 'DECLARATORIA',
          to: '/visitas/'+ route.params.id
        },
        {
          label: 'INTEGRIDAD',
          to: '/integridad/'+ route.params.id
        },
        {
          label: 'AUTENTICIDAD',
          to: '/capacidad/'+ route.params.id
        },
        {
          label: 'ACTIVIDAD',
          to: '/detalle/'+ route.params.id
        },
        {
          label: 'FOTOGRAMA',
          to: '/fotograma/'+ route.params.id
        }
      ]);
  
      // Guardar la entidad 
      const guardarTuristico = async () => {

        if(ppirta.value.g_z)
        {
  
          if(!ppirta.value.id || !ppirta.value.nombre){
            submitted.value = true;
            // if (!isFormValid) { 
            //   return;
            // }
          }

          try {
            if (ppirta.value.id) {
              await ppirtaService.update(ppirta.value, ppirta.value.id);
              closeModal();
              toast.add({
                severity: "success",
                summary: "REGISTRO",
                detail: "Datos de atractivo turístico actualizados con éxito",
                life: 5000,
              });

            }
          } catch (error) {
            errores.value = error.response.data.errores;
            toast.add({
              severity: "error",
              summary: "ERROR DE REGISTRO",
              detail: "Error al actualizar el registro",
              life: 5000,
            });
          }
        } else {
          toast.add({
            severity: "error",
            summary: "Valor Z es requerida mínimo 1 y máximo 4 dígitos",
            detail: "Error de registro del formulario",
            life: 5000,
          });
        }

      };

      // Abrir un modal
      const openModal = () => {
        ppirta.value = datos;
        categoria.value = {};
        displayModal.value = true;
        submitted.value = false;
      };
  
      // Abrir un modal
      const openDetalle = () => {
          categoria.value={}
          displayModal.value= true
        }
  
      // Abrir un modal actualizar
      const editarDialogModal = (datos) => {
        displayModal.value= true
      }
  
      // Cerrar un modal
      const closeModal = () => {
        displayModal.value= false
        // ppirta.value={}
      }
      function updatePosition(position, campo) {
        campo.valor = `${position.position.lat},${position.position.lng}`;
        let arr = campo.valor.split(',');
        ppirta.value.g_x = arr[0]
        ppirta.value.g_y = arr[1]

      }
      // DEVOLVER REGISTROS
        
      return {
        updatePosition, 
        displayModal: false, 
        nestedRouteItems, 
        campos,
        selectButtonValues1, 
        selectButtonValue1,  
        submitted, 
        displayModal, 
        openModal, 
        closeModal, 
        guardarCategoria,
        codigoGenerado,
        getMacroregion,
        getRegion,
        getProvincia,
        getMunicipio,
        subTipo,
        categorias,
        tipo,
        tipos,
        subtipos,
        macroRegion,
        regiones,
        departamentos,
        provincias,
        municipios,
        guardarTuristico, 
        editarDialogModal,
        proyecciones,
        datums,
        errores, 
        ppirta      
      }
  
    }
  
  }
  </script>
  
  <style scoped>
  .top-modal .p-dialog {
    top: 0px;
  }
  </style>