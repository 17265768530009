<template>
    <div class="card">
      <table cellpadding="5px" style="width: 100%; background-color: #f8f8f8; height: 45px">
        <tr>
          <td style="font-size: 20px">
            <B>SERVICIO DE MÉDICO</B>
          </td>
        </tr>
      </table>
      <br />
      <!--Cabecera de menu principal-->

     
      <!--Tabla de displiegue de la informacion-->
      <DataTable ref="dt" :value="medicos" v-model:selection="selectedProducts" dataKey="id"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros" responsiveLayout="scroll">
  
        <!-- <Column field="id" header="ID" :sortable="true" style="min-width: 2rem"></Column> -->
        <Column field="tipo" header="SERVICIO" :sortable="false" style="min-width: 8rem"></Column>
        <Column field='subtipo' header="TIPO" :sortable="false" style="min-width: 8rem"></Column>
        <Column field='nombre' header="NOMBRE" :sortable="false" style="min-width: 8rem"></Column>
        <Column field='direccion' header="DIRECCIÓN" :sortable="false" style="min-width: 8rem"></Column>
        <Column field='hora_inicio' header="HORA-INICIO" :sortable="false" style="min-width: 8rem"></Column>
        <Column field='hora_fin' header="HORA-FIN" :sortable="false" style="min-width: 8rem"></Column>
      </DataTable>
  
      <!--Para el despliegue del mensaje de salida-->
      <Toast />
    </div>
  </template>
  
  <script>
  import { reactive,onMounted, ref } from "vue";
  
  import * as subtipoService from '@/service/SubTipoService.js'
  import * as medicoService from '@/service/MedicoService.js'
  
  import { useToast } from "primevue/usetoast";
  import InputMask from "primevue/inputmask";
  
  import { FilterMatchMode } from "primevue/api";
  
  // Importacones para realizar las validaciones
  import { email, helpers, required } from "@vuelidate/validators";
  import { useVuelidate } from "@vuelidate/core";
  import { useRoute } from 'vue-router'
  import { assertLiteral } from "@babel/types";
  import CryptoJS from 'crypto-js';
  
  
  export default {
  
    setup() {
  
      const encryptionKey = 'M1n1st3r10d3d3s4rr0ll0pr0duct1v0y3c0n0m14plur4l';
  
      const rolss = JSON.parse(localStorage.getItem("role")) || [];
      const primerRol = ref(rolss[0] || {});
      const idPrimerRol = ref(primerRol.value.id || null);
  
      const route = useRoute();
      const medicoId = route.params.id;
  
      const medico = ref({});
      const medicos = ref();
      const subtipo = ref({});
      const subtipos = ref([]);
      const subtipocats = ref([]);
      const displayModal = ref(false);
      const submitted = ref(false);
      const toast = useToast();
      const errores = ref({});
  
      onMounted(() => {
        listarSubTipos();
        listarMedicos();
        
       });
  
      const listarSubTipos = async () => {
        const {data} = await subtipoService.showSubTipo(59);
        subtipos.value=data.subtipos;
  
      }
  
      const dt = ref();
      const deleteProductDialog = ref(false);
      const deleteProductsDialog = ref(false);
      const loading = ref(false);
      const totalRecords = ref(0);
      const selectedProducts = ref();
      const lazyParams = ref({});
     
      const filters = ref({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      });
  
      // METODOS
      const onPage = (event) => {
        lazyParams.value = event;
        listarMedicos();
      };
  
      const onFilter = () => {
        lazyParams.value.filters = filters.value;
        listarMedicos();
      };
  
      const buscar = () => {
        console.log(filters.value.global.value);
        listarMedicos();
      };
  
      const state = reactive( {
        medico: {
          id_subtipo:"",
        }
      });
  
      // Tipo de validacion a realizar del dato
      const rules = {
        medico: {
          id_subtipo: { required },
          
      }
      };
      // Seccion de la validacion
      const v$ = useVuelidate(rules, state);
  
      // Listado de las entidades creadas
      const listarMedicos = async () => {
        try {
          loading.value = true;
          let ppirtf = route.params.id
          let rows = lazyParams.value.rows;
          let q = filters.value.global.value == null?'':filters.value.global.value;
          let page = (lazyParams.value.page == null)?0:lazyParams.value.page;
  
          const encryptedId = route.params.id.match(/.{1,2}/g).map(byte => String.fromCharCode(parseInt(byte, 16))).join('');
          const sanitizedId = CryptoJS.AES.decrypt(encryptedId, encryptionKey).toString(CryptoJS.enc.Utf8);
          const originalId = sanitizedId.replace(/_/g, '/');
  
          const { data } = await medicoService.index(originalId, page + 1, rows, q);
          console.log(data)
          medicos.value = data.medico.data;
          totalRecords.value = data.medico.total;
          loading.value = false;
  
        } catch (error) {
          console.log(error.response.data);
        }
      };
  
      //Guardar Datos
      const guardarMedico = async () => {
        if(medico.value.id_subtipo) {
          if(medico.value.nombre) {
            if(medico.value.hora_inicio) {
              if(medico.value.hora_fin) {
                try {
                  if (medico.value.id) {
                    await medicoService.update(medico.value, medico.value.id);
                    listarMedicos();
                    closeModal();
                    toast.add({
                      severity: "success",
                      summary: "REGISTRO",
                      detail: "Servicio Médico actualizada con éxito",
                      life: 5000,
                    });
                  } else {
  
                    const encryptedId = route.params.id.match(/.{1,2}/g).map(byte => String.fromCharCode(parseInt(byte, 16))).join('');
                    const sanitizedId = CryptoJS.AES.decrypt(encryptedId, encryptionKey).toString(CryptoJS.enc.Utf8);
                    const originalId = sanitizedId.replace(/_/g, '/');
  
                    medico.value.id_ppirtf = originalId;
  
                    await medicoService.store(medico.value);
                    listarMedicos();
                    //categorias.value.push(categoria.value)
                    closeModal();
                    toast.add({
                      severity: "success",
                      summary: "REGISTRO",
                      detail: " Servicio Médico Registrada con éxito",
                      life: 5000,
                    });
                  }
                } catch (error) {
                  closeModal();
                  console.log(error.response.data);
                  errores.value = error.response.data.errores;
                  toast.add({
                    severity: "error",
                    summary: "ERROR DE REGISTRO",
                    detail: "Error de registro de Servicio Médico",
                    life: 5000,
                  });
                }
              } else {
                toast.add({ severity: "error", summary: "Hora fin es requerida", detail: "Error de registro del formulario", life: 5000, });
              }
            } else {
              toast.add({ severity: "error", summary: "Hora inicio es requerida", detail: "Error de registro del formulario", life: 5000, });
            }
          } else {
            toast.add({ severity: "error", summary: "Nombre es requerida", detail: "Error de registro del formulario", life: 5000, });
          }
        } else {
          toast.add({ severity: "error", summary: "El tipo servicio es requerida", detail: "Error de registro del formulario", life: 5000, });
        }
      };
  
      // METODOS DE LA PLANTILLA
      // Abrir un modal
      const openModal = () => {
  
        if (medicos.value && medicos.value.length > 0) {
          const idPpirtf = medicos.value[0].id_ppirtf;
          const idTipo = medicos.value[0].id_tipo;
          
          medico.value = {
            id_ppirtf: idPpirtf,
            id_tipo: idTipo,
            // calidad: "", // El valor seleccionado se almacenará aquí
            
          };
          displayModal.value = true;
          submitted.value = false;
        }
        medico.value = {
            id_ppirtf: medicoId,
            id_tipo: '59',
            id_subtipo: 0,
  
          };
          displayModal.value = true;
          submitted.value = false;
      };
  
      // Abrir un modal
      const editarDialogModal = (datos) => {
        medico.value = datos;
        displayModal.value = true;
      };
  
      // Eliminar un producto
      const confirmDeleteProduct = (datos) => {
        medico.value = datos;
        deleteProductDialog.value = true;
      };
  
      const deleteProduct = async () => {
        try {
            await medicoService.destroy(medico.value.id);
            listarMedicos();
            toast.add({
              severity: "error",
              summary: "REGISTRO",
              detail: "Servicio Médico Eliminado",
              life: 5000,
            });
            deleteProductDialog.value = false;
            medico.value = {}; 
        } catch (error) {
              //console.log(error.response.data);
              errores.value = error.response.data.errores;
              toast.add({
                severity: "error",
                summary: "REGISTRO",
                detail: "No se elimino el registro",
                life: 5000,
              });
        }
                
      };
  
      const confirmDeleteSelected = () => {
        deleteProductsDialog.value = true;
      }; 
      const deleteSelectedProducts = () => {
        products.value = products.value.filter(val => !selectedProducts.value.includes(val));
        deleteProductsDialog.value = false;
        selectedProducts.value = null;
        toast.add({severity:'success', summary: 'Successful', detail: 'Registros eliminados', life: 3000});
      }; 
          
      // Cerrar un modal
      const closeModal = () => {
        displayModal.value = false;
        medico.value = {};
        submitted.value = false;
        listarMedicos();
        state.sigla = '';
        state.nombre = '';
      };
  
      const formatCurrency = (value) => {
        if (value)
          return value.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          });
        return;
      };
  
      // DEVOLVER REGISTROS
      return {
        rolss,
        idPrimerRol,
        v$,
        state,
        rules,
        buscar,
        onFilter,
        onPage,
        loading,
        totalRecords,
        formatCurrency,
        dt,
        deleteProductDialog,
        deleteProductsDialog,
        confirmDeleteProduct,
        deleteProduct,
        confirmDeleteSelected,
        deleteSelectedProducts,
        selectedProducts,
        filters,
        medicos,
        medico,
        subtipo,
        subtipos,
        subtipocats,
        submitted,
        displayModal,
        openModal,
        closeModal,
        guardarMedico,
        editarDialogModal,
        errores,
      };
    },
    components: { InputMask },
  };
  </script>
  
  <style></style>
  