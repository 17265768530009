
<template>
  <div class="card">
    <table cellpadding="5px" style="width: 100%; background-color: #f8f8f8; height: 45px">
      <tr>
        <td style="font-size: 20px">
          <B>FOTOGRAMAS</B>
        </td>
      </tr>
    </table>
    <br />
    <!--Cabecera de menu principal-->
    <Toolbar class="mb-4">
      <template #start>
        <Button label="Nuevo" icon="pi pi-plus" class="p-button-success mr-2" @click="openAddModal" />
      </template>
    </Toolbar>

    <!--Tabla de displiegue de la informacion-->
    <DataTable ref="dt" :value="fotogramas" v-model:selection="selectedProducts" dataKey="id" responsiveLayout="scroll">
      <Column field="ruta_fotograma" header="FOTOGRAMAS">
        <template #body="slotProps">
          <div class="centered-column"> <!-- Agregamos una clase CSS personalizada -->
            <Image :src="'data:image/jpeg;base64,' + slotProps.data.ruta_fotograma" alt="Image" width="150" preview />
            <div class="button-container">
              <Button v-if="slotProps.data.favorito === '1'" icon="pi pi-heart-fill"
                class="p-button-rounded p-button-primary mr-2" @click="openFavoritoModal(slotProps.data)"
                title="Elegido como favorito" />
              <Button v-else icon="pi pi-heart" class="p-button-rounded p-button-primary mr-2"
                @click="openFavoritoModal(slotProps.data)" title="Elegir como Favorito?" />
              <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                @click="openEditModal(slotProps.data)" />
              <Button v-if="idPrimerRol === 1" icon="pi pi-trash"
                class="p-button-rounded p-button-warning" @click="confirmDeleteProduct(slotProps.data)" />
            </div>
          </div>
        </template>
      </Column>
    </DataTable>

    <!--Formulario de registro de informacion-->
    <div class="floatlabel-demo col-12 md:col-12">
      <Dialog header="DATOS DE FOTOGRAMA" v-model:visible="displayAddModal" :breakpoints="{ '800px': '450px' }"
        class="p-fluid" :style="{ width: '40%' }" :modal="true">
        <div>
          <div class="p-fluid">

            <InlineMessage :style="{ border: 'solid #696cff', borderWidth: '0 0 0 6px', color: '#000000' }"
              severity="info" class="border-primary w-full justify-content-start">
              <div class="flex align-items-center">
                <div class="ml-2">Debe verificar que las imagenes seleccionados sean correctos para subir.<br>De lo
                  contrario puede <span style="color:#4D5EBA"> X Cancelar</span> el registro.</div>
              </div>
            </InlineMessage><br>

            <div class="grid formgrid">

              <div class="field col-12 mb-3 lg:col-1 lg:col-12">
                <div>
                  <h5>FOTOGRAMA</h5>
                  <FileUpload name="demo[]" @upload="onUpload" :customUpload="true" @uploader="guardarFotograma"
                    :multiple="true" accept=".jpg,.jpeg,.png" :minFiles="1" :maxFiles="4" :maxFileSize="5000000"
                    @select="onSelectedFiles" :chooseLabel="'Elegir imagen'" :uploadLabel="'Subir'"
                    :cancelLabel="'Cancelar'" :messages="{
                      invalidFileType: 'Tipo de archivo no válido, tipos de archivo permitidos: JPG, JPEG o PNG',
                      invalidFileSize: 'Tamaño de archivo no válido, el tamaño del archivo debe ser menor a 5 MB',
                      maxFiles: 'Máximo 4 archivos permitidos',
                      minFiles: 'Mínimo 1 archivo requerido'
                    }"
                    :invalidFileTypeMessage="'Tipo de archivo no válido, tipos de archivo permitidos: JPG, JPEG o PNG'"
                    :invalidFileSizeMessage="'Tamaño de archivo no válido, el tamaño del archivo debe ser menor a 5 MB'">
                    <template #header="{ chooseCallback, uploadCallback, clearCallback, files }">
                      <div class="flex flex-wrap justify-content-between align-items-center flex-1 gap-2">
                        <div class="flex gap-2">
                          <Button @click="chooseCallback()" icon="pi pi-images" rounded outlined></Button>
                          <Button @click="uploadEvent(uploadCallback)" icon="pi pi-cloud-upload" rounded outlined
                            severity="success" :disabled="!files || files.length === 0"></Button>
                          <Button @click="clearCallback()" icon="pi pi-times" rounded outlined severity="danger"
                            :disabled="!files || files.length === 0"></Button>
                        </div>
                        <ProgressBar :value="totalSizePercent" :showValue="false"
                          :class="['md:w-20rem h-1rem w-full md:ml-auto', { 'exceeded-progress-bar': totalSizePercent > 100 }]">
                          <span class="white-space-nowrap">{{ totalSize }}B / 2Mb</span>
                        </ProgressBar>
                      </div>
                    </template>
                    <template #content="{ files, uploadedFiles, removeUploadedFileCallback, removeFileCallback }">
                      <div v-if="files.length > 0">
                        <h5>Pendiente</h5>
                        <div class="flex flex-wrap p-0 sm:p-5 gap-5">
                          <div v-for="(file, index) of files" :key="file.name + file.type + file.size"
                            class="card m-0 px-6 flex flex-column border-1 surface-border align-items-center gap-3">
                            <div>
                              <img role="presentation" :alt="file.name" :src="file.objectURL" width="100" height="50"
                                class="shadow-2" />
                            </div>
                            <span class="font-semibold">{{ file.name }}</span>
                            <div>{{ formatSize(file.size) }}</div>
                            <Badge value="Pendiente" severity="warning" />
                            <Button icon="pi pi-times" @click="onRemoveTemplatingFile(file, removeFileCallback, index)"
                              outlined rounded severity="danger" />
                          </div>
                        </div>
                      </div>

                      <div v-if="uploadedFiles.length > 0">
                        <h5>Completado</h5>
                        <div class="flex flex-wrap p-0 sm:p-5 gap-5">
                          <div v-for="(file, index) of uploadedFiles" :key="file.name + file.type + file.size"
                            class="card m-0 px-6 flex flex-column border-1 surface-border align-items-center gap-3">
                            <div>
                              <img role="presentation" :alt="file.name" :src="file.objectURL" width="100" height="50"
                                class="shadow-2" />
                            </div>
                            <span class="font-semibold">{{ file.name }}</span>
                            <div>{{ formatSize(file.size) }}</div>
                            <Badge value="Completado" class="mt-3" severity="success" />
                            <Button icon="pi pi-times" @click="removeUploadedFileCallback(index)" outlined rounded
                              severity="danger" />
                          </div>
                        </div>
                      </div>
                    </template>
                    <template #empty>
                      <div class="flex align-items-center justify-content-center flex-column">
                        <i class="pi pi-cloud-upload border-2 border-circle p-5 text-8xl text-400 border-400" />
                        <p class="mt-4 mb-0">Arrastre y suelte los archivos aquí para cargarlos | <b>máximo 4
                            archivos</b>.</p>
                      </div>
                    </template>
                  </FileUpload>

                </div>
              </div>
            </div>
          </div>
        </div>
        <!--{{ fotograma }}-->

      </Dialog>

      <!-- Eliminar un registro-->
      <Dialog v-model:visible="deleteProductDialog" :style="{ width: '450px' }" header="Confirmar" :modal="true">
        <div class="confirmation-content">
          <h4><i class="pi pi-exclamation-triangle mr-2" style="font-size: 2rem" /><b>¿Está seguro de borrar este
              fotograma?</b></h4><br>
          <span v-if="fotograma">
            <Image :src="'data:image/jpeg;base64,' + fotograma.ruta_fotograma" alt="Image" width="100" />
          </span><br>
          <span>¡Si no lo está puede cancelar la accíón!</span>
        </div>
        <template #footer>
          <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="deleteProductDialog = false" />
          <Button label="Si, borrar fotograma" icon="pi pi-check" @click="deleteProduct" />
        </template>
      </Dialog>
    </div>

    <!-- Formulario de editar registro modal -->
    <div class="floatlabel-demo col-12 md:col-12">
      <Dialog header="EDITAR FOTOGRAMA" v-model:visible="displayEditModal" :breakpoints="{ '800px': '450px' }"
        class="p-fluid" :style="{ width: '40%' }" :modal="true">
        <div>
          <div class="p-fluid">

            <InlineMessage :style="{ border: 'solid #696cff', borderWidth: '0 0 0 6px', color: '#000000' }"
              severity="info" class="border-primary w-full justify-content-start">
              <div class="flex align-items-center">
                <div class="ml-2">Debe verificar que la imagen seleccionada sea correcto para subir.<br>De lo contrario
                  puede <span style="color:#4D5EBA"> X Cancelar</span> el registro.</div>
              </div>
            </InlineMessage><br>

            <div class="grid formgrid">

              <div class="field col-12 mb-3 lg:col-1 lg:col-12">
                <div>
                  <h5>FOTOGRAMA</h5>
                  <FileUpload name="demo[]" @upload="onUpload" :customUpload="true" @uploader="editarFotograma"
                    :multiple="false" accept=".jpg,.jpeg,.png" :minFiles="1" :maxFiles="4" :maxFileSize="5000000"
                    @select="onSelectedFiles" :chooseLabel="'Elegir imagen'" :uploadLabel="'Subir'"
                    :cancelLabel="'Cancelar'" :messages="{
                      invalidFileType: 'Tipo de archivo no válido, tipos de archivo permitidos: JPG, JPEG o PNG',
                      invalidFileSize: 'Tamaño de archivo no válido, el tamaño del archivo debe ser menor a 5 MB',
                    }"
                    :invalidFileTypeMessage="'Tipo de archivo no válido, tipos de archivo permitidos: JPG, JPEG o PNG'"
                    :invalidFileSizeMessage="'Tamaño de archivo no válido, el tamaño del archivo debe ser menor a 5 MB'">

                    <template #empty>
                      <div v-if="showImageInfo" role="progressbar"
                        class="p-progressbar p-component p-progressbar-determinate" aria-valuemin="0" aria-valuemax="100">
                        <div class="p-progressbar-value p-progressbar-value-animate" style="display: flex;"></div>
                      </div>
                      <div v-if="showImageInfo" class="p-fileupload-files">
                        <div class="p-fileupload-row">
                          <div>
                            <img role="presentation" :src="'data:image/jpeg;base64,' + fotograma.ruta_fotograma"
                              width="50">
                          </div>
                          <div class="p-fileupload-filename">cfilqrspdso.png</div>
                          <div>178.566 KB</div>
                          <div>
                            <button class="p-button p-component p-button-icon-only" disabled>
                              <span class="pi pi-times p-button-icon"></span>
                              <span class="p-button-label">&nbsp;</span>
                              <span class="p-ink"></span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </template>

                  </FileUpload>
                </div>
              </div>

            </div>
          </div>
        </div>
        <!-- {{ fotograma }} -->

      </Dialog>
    </div>

    <!-- Formulario de favorito modal -->
    <div class="floatlabel-demo col-12 md:col-12">
      <Dialog header="ELEGIR COMO FAVORITO ?" v-model:visible="displayFavoritoModal" :breakpoints="{ '800px': '450px' }"
        class="p-fluid" :style="{ width: '20%' }" :modal="true">
        <div>
          <div class="p-fluid">

            <Image :src="'data:image/jpeg;base64,' + fotograma.ruta_fotograma" alt="Image Fotograma" width="350"
              class="responsive-image" preview />

            <!--div>
                <div class="pt-2 mt-2">
                  Elegir como favorito? &nbsp;
                  <InputSwitch :id="fotograma.id" v-model="favoritoF[fotograma.id]"/>
                </div>
              </div-->

          </div>
        </div>

        <br>

        <div>
          <Button label="Guardar como favorito" icon="pi pi-save" @click="guardarFavorito" />
        </div>

      </Dialog>
    </div>

    <!--Para el despliegue del mensaje de salida-->
    <Toast />
  </div>
</template>

<script>
import InputSwitch from 'primevue/inputswitch';
import { onMounted, ref } from "vue";
import { useToast } from "primevue/usetoast";
import * as fotogramaService from '@/service/PlantillaService.js'

// Importaciones
import { useVuelidate } from "@vuelidate/core";
import { useRoute } from 'vue-router';

import CryptoJS from 'crypto-js';
const checked = ref(false);
export default {
  mostrarFavoritoModal(fotograma) {
    this.fotograma = fotograma;
    this.favorito = fotograma.esFavorito;
    this.displayFavoritoModal = true;
  },

  data() {
    return {
      showImageInfo: true,
      value: '',
      displayFavoritoModal: false,
      favorito: false,

    };
  },

  methods: {
    hideImageInfo() {
      this.showImageInfo = false;
    },
  },
  setup() {

    const rolss = JSON.parse(localStorage.getItem("role")) || [];
    const primerRol = ref(rolss[0] || {});
    const idPrimerRol = ref(primerRol.value.id || null);

    const route = useRoute();
    const fotogramaId = route.params.id;

    const fotogramas = ref();
    const fotograma = ref({});
    const favoritoF = ref({});
    const displayAddModal = ref(false);
    const displayEditModal = ref(false);
    const displayFavoritoModal = ref(false);
    const submitted = ref(false);
    const toast = useToast();
    const errores = ref({});

    onMounted(() => {
      listarFotogramas();

    });

    const dt = ref();
    const deleteProductDialog = ref(false);
    const deleteProductsDialog = ref(false);
    const loading = ref(false);
    const selectedProducts = ref();
    const lazyParams = ref({});

    // METODOS
    const onPage = (event) => {
      lazyParams.value = event;
      listarFotogramas();
    };

    // Listado de las entidades creadas
    const listarFotogramas = async () => {
      try {
        const encryptionKey = 'M1n1st3r10d3d3s4rr0ll0pr0duct1v0y3c0n0m14plur4l';
        const encryptedId = route.params.id.match(/.{1,2}/g).map(byte => String.fromCharCode(parseInt(byte, 16))).join('');
        const sanitizedId = CryptoJS.AES.decrypt(encryptedId, encryptionKey).toString(CryptoJS.enc.Utf8);
        const originalId = sanitizedId.replace(/_/g, '/');

        const { data } = await fotogramaService.listF(originalId);
        //console.log(data);

        fotogramas.value = data.fotograma;
        loading.value = false;

        // Mostrar los datos de los fotogramas
        data.Fotograma.data.forEach((fotograma, index) => {
          // console.log(`Fotograma ${index + 1}:`, fotograma);
        });

      } catch (error) {
        // console.log(error.response);
      }
    };

    // Guardar fotograma 
    const guardarFotograma = async (event) => {

      const promesas = [];
      for (const imageFile of event.files) {
        const promesa = new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(imageFile);

          reader.onload = async () => {
            const base64Image = reader.result.split(',')[1];

            // Asignar el base64 a la propiedad ruta_fotograma del objeto fotograma
            fotograma.value.ruta_fotograma = base64Image;

            try {
              await fotogramaService.subirFotogramas(fotograma.value);
              resolve(true);
            } catch (error) {
              console.error(error);
              resolve(false);
            }
          };
        });

        promesas.push(promesa);
      }

      // Espere a que se resuelvan todas las promesas.
      const results = await Promise.all(promesas);
      // Compruebe si alguna promesa tenía errores.
      const hasError = results.some((result) => result === false);

      if (!hasError) {
        listarFotogramas();
        closeModal();
        toast.add({
          severity: "success",
          summary: "REGISTRO",
          detail: "Fotogramas Registrado con éxito",
          life: 5000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Hubo un problema al subir una o más imágenes',
          life: 5000,
        });
      }
    };

    // editar fotograma
    const editarFotograma = async (event) => {

      const promesas = [];
      for (const imageFile of event.files) {
        const promesa = new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(imageFile);

          reader.onload = async () => {
            const base64Image = reader.result.split(',')[1];

            // Asignar el base64 a la propiedad ruta_fotograma del objeto fotograma
            fotograma.value.ruta_fotograma = base64Image;

            try {
              await fotogramaService.updateF(fotograma.value, fotograma.value.id);
              resolve(true);
            } catch (error) {
              resolve(false);
            }
          };
        });

        promesas.push(promesa);
      }

      // Espere a que se resuelvan todas las promesas.
      const results = await Promise.all(promesas);
      // Compruebe si alguna promesa tenía errores.
      const hasError = results.some((result) => result === false);

      if (!hasError) {
        listarFotogramas();
        closeModalEdit();
        toast.add({
          severity: "success",
          summary: "REGISTRO",
          detail: "Fotograma Actualizada con éxito",
          life: 5000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Hubo un problema al subir una o más imágenes',
          life: 5000,
        });
      }

    };
    // METODOS DE LA PLANTILLA
    // Abrir modal
    const openAddModal = () => {

      if (fotogramas.value && fotogramas.value.length > 0) {

        const idPpirta = fotogramas.value[0].id_ppirta;

        fotograma.value = {

          id_ppirta: idPpirta,

        };
        displayAddModal.value = true;
        submitted.value = false;
      }

      const encryptionKey = 'M1n1st3r10d3d3s4rr0ll0pr0duct1v0y3c0n0m14plur4l';
      const encryptedId = route.params.id.match(/.{1,2}/g).map(byte => String.fromCharCode(parseInt(byte, 16))).join('');
      const sanitizedId = CryptoJS.AES.decrypt(encryptedId, encryptionKey).toString(CryptoJS.enc.Utf8);
      const originalId = sanitizedId.replace(/_/g, '/');

      fotograma.value = {
        id_ppirta: originalId,
        ruta_fotograma: '',

      };
      displayAddModal.value = true;
      submitted.value = false;
    };

    // Editar modal
    const openEditModal = (datos) => {
      fotograma.value = datos;
      displayEditModal.value = true;
    };

    // Favorito modal
    const openFavoritoModal = (datos) => {

      fotograma.value = datos;
      displayFavoritoModal.value = true;
    };

    const guardarFavorito = async () => {
      try {

        const valorFavorito = favoritoF.value[fotograma.value.id] ? 1 : 0;
        //console.log(fotograma.value.id_ppirta, fotograma.value.id, valorFavorito);

        await fotogramaService.favorito(fotograma.value.id_ppirta, fotograma.value.id);
        listarFotogramas();
        toast.add({
          severity: "success",
          summary: "CORRECTO",
          detail: "Fotograma elegido como favorito",
          life: 5000,
        });
        displayFavoritoModal.value = false;
      } catch (error) {
        toast.add({
          severity: "error",
          summary: "REGISTRO",
          detail: "No se guardó como favorito",
          life: 5000,
        });
      }
    };



    // Eliminar un producto
    const confirmDeleteProduct = (datos) => {
      fotograma.value = datos;
      deleteProductDialog.value = true;
    };

    const deleteProduct = async () => {
      try {
        await fotogramaService.destroyF(fotograma.value.id);
        listarFotogramas();
        toast.add({
          severity: "error",
          summary: "REGISTRO",
          detail: "Fotograma Eliminado",
          life: 5000,
        });
        deleteProductDialog.value = false;
        fotograma.value = {};
      } catch (error) {
        //console.log(error.response.data);
        errores.value = error.response.data.errores;
        toast.add({
          severity: "error",
          summary: "REGISTRO",
          detail: "No se eliminó el registro",
          life: 5000,
        });
      }

    };

    const confirmDeleteSelected = () => {
      deleteProductsDialog.value = true;
    };

    // Cerrar un modal
    const closeModal = () => {
      displayAddModal.value = false;
      fotograma.value = {};
      submitted.value = false;
      listarFotogramas();
    };

    const closeModalEdit = () => {
      displayEditModal.value = false;
      fotograma.value = {};
      submitted.value = false;
      listarFotogramas();
    };

    const closeModalFavorito = () => {
      displayFavoritoModal.value = false;
      fotograma.value = {};
      submitted.value = false;
      listarFotogramas();
    };

    const onUpload = () => {
      toast.add({ severity: 'info', summary: 'Success', detail: 'File Uploaded', life: 3000 });
    }

    // DEVOLVER REGISTROS
    return {
      rolss,
      idPrimerRol,
      onUpload,
      guardarFotograma,
      onPage,
      loading,
      dt,
      deleteProductDialog,
      deleteProductsDialog,
      confirmDeleteProduct,
      deleteProduct,
      confirmDeleteSelected,
      selectedProducts,
      fotogramas,
      fotograma,
      favoritoF,
      submitted,
      openAddModal,
      openEditModal,
      openFavoritoModal,
      displayAddModal,
      displayEditModal,
      displayFavoritoModal,
      closeModal,
      closeModalEdit,
      editarFotograma,
      errores,
      guardarFavorito,
    };
  },
};
</script>
<style>
.responsive-image {
  width: 100%;
  height: auto;
}

.image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 260px;
}

.button-container {
  display: row;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  
}
.centered-column {
  text-align: center; /* Centra el contenido de la columna */
}
</style>