<template>
  <div class="card ">
    <table cellpadding="5px" style="width: 100%; background-color:hsl(187deg 68% 86%); height: 40px; font-size: 20px;">
      <tr>
        <td style="font-size: 20px">
          <B>REGISTRO DE INVENTARIO TURÍSTICO</B>
        </td>
      </tr>
    </table>
    <br />
    <div>
      <Fieldset legend="Recomendación">
        <p style="font-size: 15px;; color:darkslategray; text-align: justify; ">Para el registro de información en el
          <b>Inventario de Recursos Turísticos Atractivos</b>, es esencial que
          el personal tome todas las precauciones necesarias, esto implica
          asegurarse de contar con acceso a internet y registrar minuciosamente
          toda la información requerida a través del formulario correspondiente
          <span style="color: red">(*)</span>. Esta labor de registro nos proporcionara una vision completa y
          precisa de las facilidades turísticas que han sido registradas.
        </p>
      </Fieldset>
    </div>
    <br>

    <!--Tabla de displiegue de la informacion-->
    <div class="grid formgrid">
      <div class="field col-12">
        <!-- {{ usuario }} -->
        <Accordion class="accordion-custom" :activeIndex="0">
          <AccordionTab>
            <template #header>
              <i class="pi pi-map-marker"></i>
              <span>GEOLOCALIZACIÓN</span>
            </template>
            <div class="p-fluid">
              <div class="grid formgrid">
                <div class="field col-12 mb-3 lg:col-1 lg:col-12">
                  <PruebaView id="mapx" title="Ubique a su empresa haciendo doble click sobre la ubicación exacta *"
                    :zoom="campos.zoom" :lat="campos.lat" :long="campos.long"
                    @newPosition="updatePosition($event, campos)">
                  </PruebaView>
                </div>
                <div class="field col-12 mb-3 lg:col-1 lg:col-2">
                  <label for="password">X<span style="color: red">(*)</span></label>
                  <InputText type="text" id="g_x" v-model="usuario.g_x" readonly />
                </div>
                <div class="field col-12 mb-3 lg:col-1 lg:col-2">
                  <label for="inputtext">Y<span style="color: red">(*)</span></label>
                  <InputText type="text" id="g_y" v-model="usuario.g_y" readonly />
                </div>
                <div class="field col-12 mb-3 lg:col-2 lg:col-2">
                  <label for="inputtext">Z<span style="color: red">(*)</span></label>
                  <InputText type="number" id="g_z" v-model.trim="usuario.g_z" autofocus @input="validateInput" />
                  <p v-if="error" style="color:rgb(169, 7, 7)">El valor Z debe tener entre mínimo 1 y máximo 4 dígitos.
                  </p>
                </div>

                <!-- {{ proyecciones }} -->
                <div class="field col-12 mb-3 lg:col-2 lg:col-3">
                  <label for="proyeccion">Proyección<span style="color: red">(*)</span></label>
                  <Dropdown id="proyeccion" :options="proyecciones" v-model="usuario.id_proyeccion"
                    optionLabel="displayLabel" optionValue="id" placeholder="Elegir..."></Dropdown>
                </div>

                <div class="field col-12 mb-3 lg:col-2 lg:col-3">
                  <label for="datum">Datum<span style="color: red">(*)</span></label>
                  <Dropdown id="datum" :options="datums" v-model="usuario.id_datum" optionLabel="displayLabelDatum"
                    optionValue="id" placeholder="Elegir..."></Dropdown>
                </div>

              </div>
            </div>
          </AccordionTab>

          <AccordionTab>
            <template #header>
              <i class="pi pi-user"></i>
              <span>ATRACTIVO TURÍSTICO</span>
            </template>
            <div class="p-fluid">
              <div class="grid formgrid">
                <div class="field col-12 mb-3 lg:col-2 lg:col-8 ">
                  <label for="username">Nombre del atractivo turístico<span style="color: red">(*)</span></label>
                  <InputText id="username" autofocus v-model="usuario.nombre_atractivo" />
                </div>
                <div class="field col-12 mb-3 lg:col-1 lg:col-2">
                  <label for="jerarquia">Jerarquía</label>
                  <InputText id="jerarquia" placeholder="" v-model="usuario.jerarquia" />
                </div>
                <div class="field col-12 mb-3 lg:col-1 lg:col-2">
                  <label for="codigo">Código</label>
                  <InputText id="codigo" placeholder="00.00.00" v-model="usuario.codigo" readonly />
                </div>
                <div class="field col-12 mb-3 lg:col-2 lg:col-4">
                  <label for="username">Categoría</label>
                  <span>
                    <Dropdown id="id_categoria" v-model="usuario.id_categoria" :options="categorias"
                      @update:model-value="val => tipo(val)" optionLabel="nombre" optionValue="id"
                      placeholder="Elegir..." />
                  </span>
                </div>
                <div class="field col-12 mb-3 lg:col-2 lg:col-4">
                  <label for="username">Tipo</label>
                  <span>
                    <Dropdown id="id_tipo" v-model="usuario.id_tipo" :options="tipos"
                      @update:model-value="val => subTipo(val)" optionLabel="nombre" optionValue="id"
                      placeholder="Elegir..." />
                  </span>
                </div>
                <div class="field col-12 mb-3 lg:col-2 lg:col-4">
                  <label for="username">Subtipo</label>
                  <span>
                    <Dropdown id="id_subtipo" :options="subtipos" v-model="usuario.id_subtipo"
                      @update:model-value="val => codigoGenerado(val)" optionLabel="nombre" optionValue="id"
                      placeholder="Elegir..." />
                  </span>
                </div>

              </div>
            </div>
          </AccordionTab>
          <AccordionTab>
            <template #header>
              <i class="pi pi-directions"></i>
              <span>UBICACIÓN TERRITORIAL</span>
            </template>
            <div class="p-fluid">
              <div class="grid formgrid">
                <div class="field col-12 mb-3 lg:col-1 lg:col-3">
                  <label for="macroregion">Macro región</label>
                  <Dropdown id="macroregion" :options="macroRegion" v-model="usuario.id_macro_region"
                    @update:model-value="val => getRegion(val)" optionLabel="nombre" optionValue="id"
                    placeholder="Elegir...">
                  </Dropdown>
                </div>
                <div class="field col-12 mb-3 lg:col-1 lg:col-3">
                  <label for="region">Región</label>
                  <Dropdown id="region" :options="regiones" v-model="usuario.id_region" optionLabel="nombre"
                    optionValue="id" placeholder="Elegir..."></Dropdown>
                </div>
                <div class="field col-12 mb-3 lg:col-2 lg:col-3">
                  <label for="departamneto">Departamento</label>
                  <Dropdown id="departamneto" :options="departamentos" v-model="usuario.id_departamento"
                    @update:model-value="val => getProvincia(val)" optionLabel="nombre" optionValue="id"
                    placeholder="Elegir...">
                  </Dropdown>
                </div>
                <div class="field col-12 mb-3 lg:col-2 lg:col-3">
                  <label for="provincia">Provincia</label>
                  <Dropdown id="provincia" :options="provincias" v-model="usuario.id_provincia"
                    @update:model-value="val => getMunicipio(val)" optionLabel="nombre" optionValue="id"
                    placeholder="Elegir...">
                  </Dropdown>
                </div>
                <div class="field col-12 mb-3 lg:col-2 lg:col-3">
                  <label for="municipio">Municipio</label>
                  <Dropdown id="municipio" :options="municipios" v-model="usuario.id_municipio"
                    @update:model-value="val => getComunidad(val)" optionLabel="nombre" optionValue="id"
                    placeholder="Elegir...">
                  </Dropdown>
                </div>
                <div class="field col-12 mb-3 lg:col-2 lg:col-3">
                  <label for="comunidad">Comunidad</label>
                  <InputText type="text" id="g_datum" v-model.trim="usuario.comunidad" autofocus />
                </div>
                <div class="field col-12 mb-3 lg:col-2 lg:col-3">
                  <label for="inputtext">Distrito/Sección</label>
                  <InputText type="text" id="g_datum" v-model.trim="usuario.distrito" autofocus />
                </div>
                <div class="field col-12 mb-3 lg:col-2 lg:col-3">
                  <label for="inputtext">GAIOC/TIOC</label>
                  <InputText type="text" id="g_datum" v-model.trim="usuario.gaioc" autofocus />
                </div>
              </div>
            </div>
          </AccordionTab>
        </Accordion>
      </div>
    </div>

    <Toolbar class="mb-1">
      <template #end>
        <router-link to="/turistico">
          <Button label="Cancelar" icon="pi pi-times" class="p-button-danger" /></router-link>&nbsp;
        <Button label="Guardar registro" icon="pi pi-save" @click="guardarDatosAtractivo" autofocus />
      </template>
    </Toolbar>
    <Toast />
  </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue';
import Accordion from 'primevue/accordion';
import Dropdown from 'primevue/dropdown';
import * as departamentoSevice from '@/service/DepartamentoService.js'
import PruebaView from '@/pages/admin/turistico/PruevaView.vue'
import * as categoriaSevice from '@/service/CategoriaService.js'
import * as tipoSevice from '@/service/TipoService.js'
import * as provinciaSevice from '@/service/ProvinciaService.js'
import * as municipioSevice from '@/service/MunicipioService.js'
import * as comunidadSevice from '@/service/ComunidadService.js'
import * as macroregionSevice from '@/service/MacroregionService.js'
import * as regionSevice from '@/service/RegionService.js'
import * as proyeccionService from '@/service/ProyeccionService.js'
import * as datumService from '@/service/DatumService.js'
import * as plantillaSevice from '@/service/PlantillaService.js'
import { useToast } from "primevue/usetoast";

import CryptoJS from 'crypto-js';

export default {
  data() {
    return {
      usuario: {
        g_z: ""
      },
      error: false
    };
  },
  methods: {
    validateInput() {
      const inputValue = this.usuario.g_z;
      const isValid = /^\d{1,4}$/.test(inputValue);

      if (isValid) {
        this.error = false;
      } else {
        this.error = true;
        this.usuario.g_z = '';
      }
    }
  },
  components: {
    "PruebaView": PruebaView,
    Dropdown,
  },
  setup() {

    const toast = useToast();
    const errores = ref({});
    const checked = ref(false);
    const cities = ref([]);
    const active = ref(0);
    const usuario = ref({
      g_x: -16.5021332,
      g_y: -68.1312058
    });
    const campos = ref({});
    const tabs = ref([
      {
        title: "Header I",
        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
      },
      {
        title: "Header II",
        content: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Consectetur, adipisci velit, sed quia non numquam eius modi."
      },
      {
        title: "Header III",
        content: "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus."
      }
    ]);
    const departamentos = ref([]);
    const categorias = ref([]);
    const tipos = ref([]);
    const subtipos = ref([]);
    const provincias = ref([]);
    const municipios = ref([]);
    const comunidades = ref([]);
    const macroRegion = ref([]);
    const regiones = ref([]);
    const proyecciones = ref([]);
    const datums = ref([]);
    onMounted(async () => {
      getDepartamentos()
      getCategorias()
      getMacroregion()
      await cargarProyecciones();
      await cargarDatums();
    });

    const cargarProyecciones = async () => {
      try {
        const { data } = await proyeccionService.list();
        proyecciones.value = data.proyeccion.map(proyeccion => {
          return {
            ...proyeccion,
            displayLabel: `${proyeccion.sigla} - ${proyeccion.nombre}`
          };
        });
      } catch (error) {
        console.error("Error al cargar las proyecciones:", error);
      }
    };
    const cargarDatums = async () => {
      try {
        const { data } = await datumService.list();
        datums.value = data.datum.map(datum => {
          return {
            ...datum,
            displayLabelDatum: `${datum.sigla} - ${datum.nombre}`
          }
        });
      } catch (error) {
        console.error("Error al cargar los datums:", error);
      }
    };

    function updatePosition(position, campo) {

      campo['valor'] = `${position.position.lat},${position.position.lng}`;
      let arr = campo['valor'].split(',');
      usuario.value.g_x = arr[0]
      usuario.value.g_y = arr[1]

    }
    const getDepartamentos = async () => {
      const { data } = await departamentoSevice.list();
      departamentos.value = data.departamentos;
    }
    const getCategorias = async () => {
      const { data } = await categoriaSevice.index('', '', '');
      const categoriasFiltradas = data.categorias.data.filter(
        (categoria) => categoria.id_seccion === 1
      );
      categorias.value = categoriasFiltradas;
    }

    const tipo = async (val) => {
      const { data } = await tipoSevice.indexcat(val);
      tipos.value = data.tipos;
    }
    const subTipo = async (val) => {
      const { data } = await tipoSevice.indexsub(val);
      subtipos.value = data.subtipos;
    }
    const codigoGenerado = async (val) => {
      const { data } = await tipoSevice.detallesub(val);
      usuario.value.codigo = data.subtipo.codigo
    }
    const getProvincia = async (val) => {
      const { data } = await provinciaSevice.buscar(val);
      provincias.value = data.provincias;
    }
    const getMunicipio = async (val) => {
      const { data } = await municipioSevice.showPromun(val);
      municipios.value = data.municipios;
    }
    const getComunidad = async (val) => {
      const { data } = await comunidadSevice.showcomunmun(val);
      comunidades.value = data.comunidades;
    }
    const getMacroregion = async (val) => {
      const { data } = await macroregionSevice.list(val);
      macroRegion.value = data.macroRegion;
    }
    const getRegion = async (val) => {
      const { data } = await regionSevice.showmacro(val);
      regiones.value = data.regiones;
    }

    // Guardar la entidad
    const guardarDatosAtractivo = async () => {
      if (usuario.value.nombre_atractivo) {
        if (usuario.value.jerarquia) {
          if (usuario.value.id_categoria) {
            if (usuario.value.id_tipo) {
              if (usuario.value.id_subtipo) {
                if (usuario.value.g_z) {
                  if (usuario.value.id_proyeccion) {
                    if (usuario.value.id_datum) {
                      if (usuario.value.id_macro_region) {
                        if (usuario.value.id_region) {
                          if (usuario.value.id_departamento) {
                            if (usuario.value.id_provincia) {
                              if (usuario.value.id_municipio) {
                                if (usuario.value.comunidad) {
                                  if (usuario.value.distrito) {

                                    try {
                                      const datosAtractivo = await plantillaSevice.store(usuario.value);

                                      toast.add({
                                        severity: "success",
                                        summary: "REGISTRO",
                                        detail: "Formulario Registrado con éxito",
                                        life: 5000,
                                      });

                                      const encryptionKey = 'M1n1st3r10d3d3s4rr0ll0pr0duct1v0y3c0n0m14plur4l';
                                      const sanitizedId = datosAtractivo.data.data.id.toString().replace(/\//g, '_');
                                      const encryptedId = CryptoJS.AES.encrypt(sanitizedId, encryptionKey).toString();
                                      const hexEncodedId = Array.from(encryptedId, (char) => char.charCodeAt(0).toString(16)).join('');

                                      window.location.replace('/informacion/' + hexEncodedId);

                                    } catch (error) {
                                      errores.value = error.response.data.errores;
                                      toast.add({
                                        severity: "error",
                                        summary: "ERROR DE REGISTRO",
                                        detail: "Error de registro del formulario",
                                        life: 5000,
                                      });
                                    }

                                  } else {
                                    toast.add({
                                      severity: "error",
                                      summary: "El distrito es requerido",
                                      detail: "Error de registro del formulario",
                                      life: 5000,
                                    });
                                  }
                                } else {
                                  toast.add({
                                    severity: "error",
                                    summary: "La comunidad es requerida",
                                    detail: "Error de registro del formulario",
                                    life: 5000,
                                  });
                                }
                              } else {
                                toast.add({
                                  severity: "error",
                                  summary: "El municipio es requerido",
                                  detail: "Error de registro del formulario",
                                  life: 5000,
                                });
                              }
                            } else {
                              toast.add({
                                severity: "error",
                                summary: "La provincia es requerida",
                                detail: "Error de registro del formulario",
                                life: 5000,
                              });
                            }
                          } else {
                            toast.add({
                              severity: "error",
                              summary: "El departamento es requerido",
                              detail: "Error de registro del formulario",
                              life: 5000,
                            });
                          }
                        } else {
                          toast.add({
                            severity: "error",
                            summary: "La región es requerida",
                            detail: "Error de registro del formulario",
                            life: 5000,
                          });
                        }
                      } else {
                        toast.add({
                          severity: "error",
                          summary: "La macro región es requerida",
                          detail: "Error de registro del formulario",
                          life: 5000,
                        });
                      }
                    } else {
                      toast.add({
                        severity: "error",
                        summary: "El datum es requerido",
                        detail: "Error de registro del formulario",
                        life: 5000,
                      });
                    }
                  } else {
                    toast.add({
                      severity: "error",
                      summary: "La proyección es requerida",
                      detail: "Error de registro del formulario",
                      life: 5000,
                    });
                  }
                } else {
                  toast.add({
                    severity: "error",
                    summary: "Valor Z es requerida mínimo 1 y máximo 4 dígitos",
                    detail: "Error de registro del formulario",
                    life: 5000,
                  });
                }
              } else {
                toast.add({
                  severity: "error",
                  summary: "El subtipo es requerido",
                  detail: "Error de registro del formulario",
                  life: 5000,
                });

              }
            } else {
              toast.add({
                severity: "error",
                summary: "El tipo es requerido",
                detail: "Error de registro del formulario",
                life: 5000,
              });
            }
          } else {
            toast.add({
              severity: "error",
              summary: "La categoría es requerida",
              detail: "Error de registro del formulario",
              life: 5000,
            });
          }
        } else {
          toast.add({
            severity: "error",
            summary: "La jerarquía es requerida",
            detail: "Error de registro del formulario",
            life: 5000,
          });
        }
      } else {
        toast.add({
          severity: "error",
          summary: "El nombre del atractivo turístico es requerido",
          detail: "Error de registro del formulario",
          life: 5000,
        });
      }
    };
    return {
      active,
      tipo,
      getRegion,
      codigoGenerado,
      subTipo,
      getProvincia,
      getMacroregion,
      getMunicipio,
      getComunidad,
      updatePosition,
      tabs,
      regiones,
      macroRegion,
      comunidades,
      provincias,
      municipios,
      tipos,
      subtipos,
      cities,
      checked,
      departamentos,
      guardarDatosAtractivo,
      categorias,
      proyecciones,
      datums,
      campos,
      usuario
    }
  }
}
</script>

<style lang="scss" scoped>
.accordion-custom {

  i,
  span {
    vertical-align: middle;
  }

  span {
    margin: 0 .5rem;
  }
}

.p-accordion p {
  line-height: 1.5;
  margin: 0;
}

.custom-form-field {
  display: block;
  margin-bottom: 1rem;
}

.custom-label {
  font-weight: bold;
  margin-bottom: 0.5rem;
  display: block;
}

.custom-select {
  width: 100%;
  height: 63%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  background: #ECECEC;
}
</style>