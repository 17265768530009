import {http, urlBase} from "./Http"

// Listar integridad
export const index = (ppirta = ppirta, page=1, rows=10, q=null) => {
  return http().get(`${urlBase}/intppirta?ppirta=${ppirta}&page=${page}&rows=${rows}&q=${q}`);
}

// Guardar integridad
export const save = (datos) => {
  return http().post(`${urlBase}/intppirta`, datos);
}

// Editar integridad

export const update = (datos, id) => {
  return http().put(`${urlBase}/intppirta/${id}`, datos);
}
// eliminar integridad
export const destroy = (id) => {
  return http().delete(`${urlBase}/intppirta/${id}`);
}
// MOSTRAR
export const show = () => {
  return http().get(`${urlBase}/listvia`);
}
// Estado integridad
export const listest = () => {
  return http().get(`${urlBase}/listestado`);
}
// Listado integridad
export const listintegridad = () => {
  return http().get(`${urlBase}/listintegridad`);
}