//import * as httpInter from "./Http" 

import {http} from "./Http" 

/**
 * Para conectar con laravel
 * @param {email, paswword} datosUsaurio 
 */
export function loginConLaravel(datosUsaurio){
  //return http().post("/v1/auth/login", datosUsaurio);
  return http().post("/login", datosUsaurio);
}

export const getPerfil = function (){
  return http().get("/v1/auth/perfil");
}

export const logout = () => {
  return http().post("/v1/auth/logout");
}