<template>
    <div class="card">
      <table cellpadding="5px" style="width: 100%; background-color: #f8f8f8; height: 45px">
        <tr>
          <td style="font-size: 20px">
            <B>DATOS DE PENDIENTES</B>
          </td>
        </tr>
      </table>
      <br />

      <Toolbar class="mb-4">
        <template #start>
          <router-link to="/ppirtafinalizadonacional">
            <Button label="Ir a bandeja finalizado" icon="pi pi-eye" class="p-button-info" />
          </router-link>
          
        </template>
        
      </Toolbar>
      
      <DataTable ref="dt" :lazy="true" :value="pendientes" v-model:selection="selectedProducts" dataKey="id"
      :paginator="true" :rows="10" :filters="filters" :totalRecords="totalRecords" :loading="loading"
      @page="onPage($event)" @filter="onFilter($event)"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10]"
      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros" responsiveLayout="scroll">

        <template #header>
        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
          <h5 class="m-0">PENDIENTES</h5>
          <span class="block mt-2 md:mt-0 p-input-icon-left">
            <i class="pi pi-search" />
            <InputText v-model="filters['global'].value" @input="buscar()" placeholder="Buscar..." />
          </span>
        </div>
      </template>
  
        <Column field="codigo" header="CÓDIGO" :sortable="false" style="min-width: 8rem"></Column>
        <Column field="nombre_emisor" header="NOMBRE DEL EMISOR" :sortable="false" style="min-width: 8rem"></Column>
        <Column field="de_rol" header="NOMBRE DE ROL" :sortable="false" style="min-width: 8rem"></Column>
        <Column field="fecha_emision" header="FECHA DE EMISIÓN" :sortable="false" style="min-width: 8rem"></Column>
        <Column :exportable="false" style="min-width: 12rem" header="ESTADO">
          <template #body="slotProps">
            <span style="background: rgb(5, 182, 129);color: white; padding: 6px;">
              {{ slotProps.data.seg_estado }}
            </span>
          </template>
        </Column>

        <Column :exportable="false" style="min-width: 12rem" header="ACCIONES">
    
            <template #body="slotProps">
            <Button v-if="idPrimerRol === 2" class="p-button-primary mr-1"
            @click="confirmRevisar(slotProps.data)" title="Revisar">
                Revisar
            </Button>

            <router-link :to="getEncryptedURLVer(slotProps.data.id_ppirta)">
            <Button class=" p-button-info mr-1" @click="crearLocalstorage(slotProps.data)"
              title="Ver información">
              Ver info
            </Button>
          </router-link>
            </template>
      </Column>
      </DataTable>

      <!--Formulario de revisar registro -->
    <div class="floatlabel-demo col-12 md:col-12">
  
      <Dialog v-model:visible="revisarDialog" :style="{ width: '40%' }" header="Aprobar el registro de Atractivos Turísticos" position="top" :modal="true" :closable="true">
        <Divider />
   
        <div class="p-fluid">

            <div class="grid formgrid">
            
              <div class="field col-12 mb-3 lg:col-1 lg:col-6">
                <label for="codigo">Código <span style="color: red;">*</span></label>
                <InputText type="text" v-model.trim="pendiente.codigo" style="pointer-events: none" readonly />
              </div>

              <div class="field col-12 mb-3 lg:col-1 lg:col-6">
                <label for="emisor">Nombre de Emisor <span style="color: red;">*</span></label>
                <InputText type="text" id="direccion" placeholder="" v-model.trim="pendiente.nombre_emisor" style="pointer-events: none" readonly/>
              </div>

              <div class="field col-12 mb-3 lg:col-1 lg:col-6">
                <label for="rol">Rol <span style="color: red;">*</span></label>
                <InputText type="text" placeholder="" v-model.trim="pendiente.de_rol" style="pointer-events: none" readonly />
              </div>

              <div class="field col-12 mb-3 lg:col-1 lg:col-6">
                <label for="departamento">Departamento <span style="color: red;">*</span></label>
                <InputText type="text" :value="getNombreDepartamento(pendiente.id_departamento)" style="pointer-events: none" readonly />
              </div>

              <div class="field col-12 mb-3 lg:col-1 lg:col-12">
                <label for="revision">Revisión <span style="color: red;">*</span></label>
                <Dropdown
                  id="revision"
                  :options="revisionOptions"
                  v-model="pendientee.aprobado"
                  optionLabel="nombre"
                  optionValue="valor"
                  placeholder="Elegir..."
                ></Dropdown>
              </div>

              <div class="field col-12 mb-3 lg:col-2 lg:col-12">
                  <label for="observacion">Observación </label>
                  <Textarea v-model="pendientee.observacion" rows="3" cols="20" style="pointer-events: block"  />
              </div>

            </div>
        </div>
        <!--{{ pendientee }}-->
        
        <template #footer>
          <Button label="Cancelar" icon="pi pi-times" class="p-button-danger" @click="revisarDialog = false" />
          <Button label="REVISAR" icon="pi pi-check" @click="revisarRegistro" />
        </template>
      </Dialog>

    </div>
      
      <!--Para el despliegue del mensaje de salida-->
      <Toast />
    </div>
  </template>
  
  <script>
  import { reactive,onMounted, ref } from "vue";

  import * as plantillaService from '@/service/PlantillaService.js'
  import * as pppendienteNService from '@/service/PpirtaPendienteService.js';
  
  import { useToast } from "primevue/usetoast";
  import InputMask from "primevue/inputmask";
  import Divider from 'primevue/divider';
  
  import { FilterMatchMode } from "primevue/api";
  
  // Importacones para realizar las validaciones
  import { required } from "@vuelidate/validators";
  import { useVuelidate } from "@vuelidate/core";
  import { useRoute } from 'vue-router';
  import CryptoJS from 'crypto-js';
  
  
  export default {
    data() {
        return {
        revisionOptions: [
            { nombre: "Aprobado", valor: 1 },
            { nombre: "No Aprobado", valor: 2 },
        ],
        };
    },
    setup() {
      const rolss = JSON.parse(localStorage.getItem("role")) || [];
      const primerRol = ref(rolss[0] || {});
      const idPrimerRol = ref(primerRol.value.id || null);
  
      const route = useRoute();
      const pendienteId = route.params.id;
      
      const pendientes = ref();

      const listarppirta = ref([]);
      const pendiente = ref({});
      const pendientee = ref({});
      const displayModal = ref(false);
      const submitted = ref(false);
      const toast = useToast();
      const errores = ref({});
      
      onMounted(() => {
        listarPendientes();
        
        
      });

      const dt = ref();
      const revisarDialog = ref(false);
      const loading = ref(false);
      const totalRecords = ref(0);
      const selectedProducts = ref();
      const lazyParams = ref({});
      
      const filters = ref({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      });
  
      // METODOS
      const onPage = (event) => {
        lazyParams.value = event;
        listarPendientes();
      };
  
      const onFilter = () => {
        lazyParams.value.filters = filters.value;
        listarPendientes();
      };
  
      const buscar = () => {
        listarPendientes();
      };
  
      // Validaion del formulario
      // Definimos los variables a validar
  
      const state = reactive( {
        pendiente: {
          id:"",
        }
      });
  
      // Tipo de validacion a realizar del dato
      const rules = {
        pendiente: {
          id: { required },
            
        }
      };
      // Seccion de la validacion
      const v$ = useVuelidate(rules, state);
  
      // Listado de las entidades creadas
      const listarPendientes = async () => {
        try {
          loading.value = true;
          let ppirta = route.params.id
          let rows = lazyParams.value.rows;
          let q = filters.value.global.value == null?'':filters.value.global.value;
          let page = (lazyParams.value.page == null)?0:lazyParams.value.page;
  
          const { data } = await pppendienteNService.indexN(page + 1, rows, q);
          //console.log(data)
          pendientes.value = data.pendientes.data;
          totalRecords.value = data.pendientes.total;
          loading.value = false;
  
        } catch (error) {
          console.log(error.response.data);
        }
      };
  
      // Recibir
      const confirmRevisar = (datos) => {
        pendiente.value = datos;
        revisarDialog.value = true;
        pendientee.value = {
          id_seguimiento: pendiente.value.id,
          aprobado: "",
          observacion: "",
          
        };
      };

      const revisarRegistro = async () => {
        // Verificar si el campo "Revisión" está lleno y no es nulo ni una cadena vacía
        if (pendientee.value.aprobado && pendientee.value.aprobado !== "") {
          try {
            const as = await pppendienteNService.revisarPpirtaN(pendientee.value);
          
            listarPendientes();
            toast.add({
              severity: "success",
              summary: "EL REGISTRO",
              detail: "Revisado con éxito, por favor vaya a bandeja de Finalizado",
              life: 5000,
            });
            revisarDialog.value = false;
            pendiente.value = {};
          } catch (error) {
            // console.log(error.response.data);
            errores.value = error.response.data.errores;
            toast.add({
              severity: "error",
              summary: "EL REGISTRO",
              detail: "No se pudo revisar el registro",
              life: 5000,
            });
          }
        } else {
          // Si el campo "Revisión" está vacío o es nulo, mostrar un mensaje de error
          toast.add({
            severity: "error",
            summary: "Corregir",
            detail: "El campo Revisión es requerido",
            life: 5000,
          });
        }
       
      };

   
      // Cerrar un modal
      const closeModal = () => {
        displayModal.value = false;
        pendiente.value = {};
        submitted.value = false;
        listarPendientes();
        state.id = '';
      };
  
      // DEVOLVER REGISTROS
      return {
        rolss,
        idPrimerRol,
        v$,
        state,
        rules,
        buscar,
        onFilter,
        onPage,
        loading,
        totalRecords,
        dt,
        revisarDialog,
        revisarRegistro,
        confirmRevisar,
        selectedProducts,
        filters,
        pendientes,
        pendiente,
        pendientee,
        submitted,
        displayModal,
        closeModal,
        errores,
        listarppirta,
      };
    },
    methods: {
      exportCSV() {
        this.$refs.dt.exportCSV();
      },
      getEncryptedURLVer(id) {
        const encryptionKey = 'M1n1st3r10d3d3s4rr0ll0pr0duct1v0y3c0n0m14plur4l';
        const sanitizedId = id.toString().replace(/\//g, '_');
        const encryptedId = CryptoJS.AES.encrypt(sanitizedId, encryptionKey).toString();
        const hexEncodedId = Array.from(encryptedId, (char) => char.charCodeAt(0).toString(16)).join('');

        return {
          name: 'ver',
          params: { 'id': hexEncodedId },
        };
      },
      crearLocalstorage(pendiente) {

        plantillaService.showU(pendiente.id_ppirta)
          .then(response => {
            
            // Verifica si la promesa se resolvió correctamente
            if (response.status === 200 && response.data && response.data.ppirta) {
              const ppirtaData = response.data.ppirta;

              localStorage.setItem("savedLatitude", ppirtaData.g_x);
              localStorage.setItem("savedLongitude", ppirtaData.g_y);
             
            } else {
              console.error("No se pudo obtener la información necesaria de la respuesta.");
            }
          })
          .catch(error => {
            console.error("Error al obtener los datos:", error);
          });
      },
      
    },
    computed:{
      getNombreDepartamento() {
        return (id) => {
          switch (id) {
            case 1:
              return 'CHUQUISACA';
            case 2:
              return 'LA PAZ';
            case 3:
              return 'COCHABAMBA';
            case 4:
              return 'ORURO';
            case 5:
              return 'POTOSÍ';
            case 6:
              return 'TARIJA';
            case 7:
              return 'SANTA CRUZ';
            case 8:
              return 'BENI';
            case 9:
              return 'PANDO';
            default:
              return 'N/A';
          }
        };
      }
    },
    components: { InputMask },
  };
  </script>
  
  <style></style>