import {http, urlBase} from "./Http"

// Listar Tramo
export const index = (ppirta = ppirta, page=1, rows=10, q=null) => {
  return http().get(`${urlBase}/temppirta?ppirta=${ppirta}&page=${page}&rows=${rows}&q=${q}`);
}

// Guardar Tramo
export const save = (datos) => {
  return http().post(`${urlBase}/temppirta`, datos);
}

// Editar Tramo

export const update = (datos, id) => {
  return http().put(`${urlBase}/temppirta/${id}`, datos);
}
// eliminar tramo
export const destroy = (id) => {
  return http().delete(`${urlBase}/temppirta/${id}`);
}
// Listado Mes Tramo
export const listmes = () => {
  return http().get(`${urlBase}/listmes`);
}