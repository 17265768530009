import {http, urlBase} from "./Http" 

//ppirta
// MOSTRAR
export const showGraficoG = () => {
  return http().get(`${urlBase}/graficobarrasgobernacion`);
}

// MOSTRAR
export const showGrafico2G = () => {
  return http().get(`${urlBase}/graficocirgobppirta`);
}


// MOSTRAR gobernacion
export const graficoGobernacion = () => {
  return http().get(`${urlBase}/graficogob`);
}



//ppirtf
// MOSTRAR
export const showGraficoCPG= () => {
  return http().get(`${urlBase}/graficobarrasgobernacionppirtf`);
}

// MOSTRAR
export const showGraficoCP2G = () => {
  return http().get(`${urlBase}/graficocirgobppirtf`);
}