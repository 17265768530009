<template>
    <div class="card">
      <table cellpadding="5px" style="width: 100%; background-color: #f8f8f8; height: 45px">
        <tr>
          <td style="font-size: 20px">
            <B>DATOS DE FINALIZADO</B>
          </td>
        </tr>
      </table>
      <br />

      <!-- {{ finalizados }} -->
      <DataTable ref="dt" :lazy="true" :value="finalizados" v-model:selection="selectedProducts" dataKey="id"
      :paginator="true" :rows="10" :filters="filters" :totalRecords="totalRecords" :loading="loading"
      @page="onPage($event)" @filter="onFilter($event)"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10]"
      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros" responsiveLayout="scroll">

        <template #header>
        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
          <h5 class="m-0">FINALIZADOS</h5>
          <span class="block mt-2 md:mt-0 p-input-icon-left">
            <i class="pi pi-search" />
            <InputText v-model="filters['global'].value" @input="buscar()" placeholder="Buscar..." />
          </span>
        </div>
      </template>

        <Column :exportable="false" style="min-width: 8rem" header="PDF">
          <template #body="slotProps">
                       <Button icon="pi pi-file-pdf" class="p-button-danger" @click="verPDF(slotProps.data)" :title="'Generar PDF:' + slotProps.data.cite" />
          </template>
        </Column>

        <Column field="cite" header="CÓDIGO" :sortable="false" style="min-width: 8rem"></Column>
        <Column field="nombre_emisor" header="NOMBRE DEL EMISOR" :sortable="false" style="min-width: 8rem"></Column>
        <Column field="de_rol" header="NOMBRE DE ROL" :sortable="false" style="min-width: 8rem"></Column>
        <Column field="fecha_emision" header="FECHA DE EMISIÓN" :sortable="false" style="min-width: 8rem"></Column>
        <Column :exportable="false" style="min-width: 12rem" header="ESTADO">
            <template #body="slotProps">
                <span style="background: green;color: white; padding: 6px;">
                    {{ slotProps.data.seg_estado }}
                </span>
            </template>
        </Column>

        <Column :exportable="false" style="min-width: 12rem" header="ACCIONES">
    
          <template #body="slotProps">

            <router-link :to="getEncryptedURLVer(slotProps.data.id_ppirta)">
              <Button class=" p-button-info mr-1" @click="crearLocalstorage(slotProps.data)"
                title="Ver información">
                Ver info
              </Button>
            </router-link>

          </template>
      </Column>
      </DataTable>

      <!--Formulario de revisar registro -->
    <div class="floatlabel-demo col-12 md:col-12">
  
      <Dialog v-model:visible="revisarDialog" :style="{ width: '40%' }" header="Derivar a la Nacional el registro de Atractivos Turísticos" position="top" :modal="true" :closable="true">
        <Divider />
   
        <div class="p-fluid">

            <div class="grid formgrid">
            
              <div class="field col-12 mb-3 lg:col-1 lg:col-6">
                <label for="codigo">Código <span style="color: red;">*</span></label>
                <InputText type="text" v-model.trim="finalizado.codigo" style="pointer-events: none" readonly />
              </div>

              <div class="field col-12 mb-3 lg:col-1 lg:col-6">
                <label for="emisor">Nombre de Emisor <span style="color: red;">*</span></label>
                <InputText type="text" id="direccion" placeholder="" v-model.trim="finalizado.nombre_emisor" style="pointer-events: none" readonly/>
              </div>

              <div class="field col-12 mb-3 lg:col-1 lg:col-6">
                <label for="rol">Rol <span style="color: red;">*</span></label>
                <InputText type="text" placeholder="" v-model.trim="finalizado.de_rol" style="pointer-events: none" readonly />
              </div>

              <div class="field col-12 mb-3 lg:col-1 lg:col-6">
                <label for="departamento">Departamento <span style="color: red;">*</span></label>
                <InputText type="text" :value="getNombreDepartamento(finalizado.id_departamento)" style="pointer-events: none" readonly />
              </div>

              

            </div>
        </div>
        <!--{{ pendientee }}-->
        <template #footer>
          <Button label="Cancelar" icon="pi pi-times" class="p-button-danger" @click="revisarDialog = false" />
          <Button label="DERIVAR A NACIONAL" icon="pi pi-check" @click="revisarRegistro" />
        </template>
      </Dialog>

    </div>

    <!-- Diálogo para mostrar el PDF -->
    <Dialog v-model:visible="pdfDialogVisible" :style="{ width: '90%' }" header="" position="top" :modal="true" :closable="false">
      <embed v-if="pdffs" :src="getPdfSource()" type="application/pdf" width="100%" height="650px" />
      <div style="height: 100px;text-align: center; padding-top: 50px;color: #4E5FBB;" v-else>Para ver el pdf, haga clic en Generar PDF</div>
    
      <template #footer>
        <Button label="Cerrar" icon="pi pi-times" class="p-button-text" @click="closeModalpdf" />
        <Button v-if="!pdffs" label="Generar PDF" icon="pi pi-check" :loading="loading" @click="verPDF" />
      </template>
    </Dialog>
      
      <!--Para el despliegue del mensaje de salida-->
      <Toast />
    </div>
  </template>
  
  <script>
  import { reactive,onMounted, ref } from "vue";
  
  import * as ppfinalizadoService from '@/service/PpirtfFinalizadoService.js';
  import * as pdfService from '@/service/PdfService.js'
  
  import { useToast } from "primevue/usetoast";
  import InputMask from "primevue/inputmask";
  import Divider from 'primevue/divider';
  
  import { FilterMatchMode } from "primevue/api";
  
  // Importacones para realizar las validaciones
  import { required } from "@vuelidate/validators";
  import { useVuelidate } from "@vuelidate/core";
  import { useRoute } from 'vue-router';
  import CryptoJS from 'crypto-js';
  
  
  export default {
    data() {
        return {
        revisionOptions: [
            { nombre: "Aprobado", valor: 1 },
            { nombre: "No Aprobado", valor: 2 },
        ],
        };
    },
    setup() {

      const pdff = ref({});
      const pdffs = ref();

      const pdfDialogVisible = ref(false);

      // Implementación de la función para obtener la fuente del PDF
      const getPdfSource = () => {
        // Asegúrate de que pdffs contenga los datos en formato base64 del PDF
        return `data:application/pdf;base64,${pdffs.value}`;
      };

      // Función para mostrar el diálogo del PDF
      const confirmarVerPDF = (datos) => {
        pdff.value = datos;
        pdfDialogVisible.value = true;
      };

      // Función para ver el PDF
      const verPDF = async (datos) => {
        pdff.value = datos;
        loading.value = true;
        const startTime = performance.now(); // Registro del tiempo de inicio

        try {
          const { data } = await pdfService.generarppirtacpPDF(pdff.value.id_ppirta);
          
          pdffs.value = data.pdf;

          pdfDialogVisible.value = true;
        } catch (error) {
          pdfDialogVisible.value = false;
          loading.value = false;
          toast.add({
            severity: "error",
            summary: "PDF",
            detail: "No se pudo generar el PDF",
            life: 5000,
          });
        } finally {
          const endTime = performance.now(); // Registro del tiempo de finalización
          const elapsedTime = endTime - startTime; // Cálculo del tiempo transcurrido en milisegundos

          // Establecer un límite de tiempo
          const timeoutDuration = 20000;
          const remainingTime = timeoutDuration - elapsedTime;

          if (remainingTime > 0) {

            setTimeout(() => {
              loading.value = false;
            }, remainingTime);
          } else {
            loading.value = false;
          }
        }
      };

      // Cerrar un modal
      const closeModalpdf = () => {
        loading.value = false;
        pdfDialogVisible.value = false;
        pdffs.value = null;
        
      };

      const rolss = JSON.parse(localStorage.getItem("role")) || [];
      const primerRol = ref(rolss[0] || {});
      const idPrimerRol = ref(primerRol.value.id || null);
  
      const route = useRoute();
      const finalizadoId = route.params.id;
  
      const finalizados = ref();
      const finalizado = ref({});
      const pendientee = ref({});
      const displayModal = ref(false);
      const submitted = ref(false);
      const toast = useToast();
      const errores = ref({});
      
      onMounted(() => {
        listarFinalizados();
        
      });
  
      const dt = ref();
      const revisarDialog = ref(false);
      const loading = ref(false);
      const totalRecords = ref(0);
      const selectedProducts = ref();
      const lazyParams = ref({});
      
      const filters = ref({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      });
  
      // METODOS
      const onPage = (event) => {
        lazyParams.value = event;
        listarFinalizados();
      };
  
      const onFilter = () => {
        lazyParams.value.filters = filters.value;
        listarFinalizados();
      };
  
      const buscar = () => {
        listarFinalizados();
      };
  
      // Validaion del formulario
      // Definimos los variables a validar
  
      const state = reactive( {
        finalizado: {
          id:"",
        }
      });
  
      // Tipo de validacion a realizar del dato
      const rules = {
        finalizado: {
          id: { required },
            
        }
      };
      // Seccion de la validacion
      const v$ = useVuelidate(rules, state);
  
      // Listado de las entidades creadas
      const listarFinalizados = async () => {
        try {
          loading.value = true;
          let ppirta = route.params.id
          let rows = lazyParams.value.rows;
          let q = filters.value.global.value == null?'':filters.value.global.value;
          let page = (lazyParams.value.page == null)?0:lazyParams.value.page;
  
          const { data } = await ppfinalizadoService.index(page + 1, rows, q);
      
          finalizados.value = data.pendientes.data;
          totalRecords.value = data.pendientes.total;
          loading.value = false;
  
        } catch (error) {
          console.log(error.response.data);
        }
      };
  
      // Recibir
      const confirmRevisar = (datos) => {
        finalizado.value = datos;
        revisarDialog.value = true;
        finalizadoe.value = {
          id_seguimiento: finalizado.value.id,
          aprobado: "",
          observacion: "",
          
        };
      };

      const revisarRegistro = async () => {
        // Verificar si el campo "Revisión" está lleno y no es nulo ni una cadena vacía
        if (pendientee.value.aprobado && pendientee.value.aprobado !== "") {
          try {
            const as = await pppendienteService.revisarPpirta(pendientee.value);
          
            listarFinalizados();
            toast.add({
              severity: "success",
              summary: "EL REGISTRO",
              detail: "Revisado con éxito",
              life: 5000,
            });
            revisarDialog.value = false;
            finalizado.value = {};
          } catch (error) {
            // console.log(error.response.data);
            errores.value = error.response.data.errores;
            toast.add({
              severity: "error",
              summary: "EL REGISTRO",
              detail: "No se pudo revisar el registro",
              life: 5000,
            });
          }
        } else {
          // Si el campo "Revisión" está vacío o es nulo, mostrar un mensaje de error
          toast.add({
            severity: "error",
            summary: "Corregir",
            detail: "El campo Revisión es requerido",
            life: 5000,
          });
        }
       
      };

   
      // Cerrar un modal
      const closeModal = () => {
        displayModal.value = false;
        finalizado.value = {};
        submitted.value = false;
        listarFinalizados();
        state.id = '';
      };
  
      // DEVOLVER REGISTROS
      return {
        getPdfSource,
        confirmarVerPDF,
        pdfDialogVisible,
        verPDF,
        pdff,
        pdffs,
        rolss,
        idPrimerRol,
        v$,
        state,
        rules,
        buscar,
        onFilter,
        onPage,
        loading,
        totalRecords,
        dt,
        revisarDialog,
        revisarRegistro,
        confirmRevisar,
        selectedProducts,
        filters,
        finalizados,
        finalizado,
        pendientee,
        submitted,
        displayModal,
        closeModal,
        closeModalpdf,
        errores,
      };
    },
    methods: {
      crearLocalstorage(poblado) {
        localStorage.setItem("savedLatitude", poblado.g_x);
        localStorage.setItem("savedLongitude", poblado.g_y);
      },
      exportCSV() {
        this.$refs.dt.exportCSV();
      },
      getEncryptedURL(id) {
      const encryptionKey = 'M1n1st3r10d3d3s4rr0ll0pr0duct1v0y3c0n0m14plur4l';
      const sanitizedId = id.toString().replace(/\//g, '_');
      const encryptedId = CryptoJS.AES.encrypt(sanitizedId, encryptionKey).toString();
      const hexEncodedId = Array.from(encryptedId, (char) => char.charCodeAt(0).toString(16)).join('');

      return {
        name: 'informacion',
        params: { 'id': hexEncodedId },
      };
    },

    getEncryptedURLVer(id) {
      const encryptionKey = 'M1n1st3r10d3d3s4rr0ll0pr0duct1v0y3c0n0m14plur4l';
      const sanitizedId = id.toString().replace(/\//g, '_');
      const encryptedId = CryptoJS.AES.encrypt(sanitizedId, encryptionKey).toString();
      const hexEncodedId = Array.from(encryptedId, (char) => char.charCodeAt(0).toString(16)).join('');

      return {
        name: 'verf',
        params: { 'id': hexEncodedId },
      };
    },
      
    },
    computed:{
      getNombreDepartamento() {
        return (id) => {
          switch (id) {
            case 1:
              return 'CHUQUISACA';
            case 2:
              return 'LA PAZ';
            case 3:
              return 'COCHABAMBA';
            case 4:
              return 'ORURO';
            case 5:
              return 'POTOSÍ';
            case 6:
              return 'TARIJA';
            case 7:
              return 'SANTA CRUZ';
            case 8:
              return 'BENI';
            case 9:
              return 'PANDO';
            default:
              return 'N/A';
          }
        };
      }
    },
    components: { InputMask },
  };
  </script>
  
  <style></style>