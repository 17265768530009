import {http, urlBase} from "./Http"
import CryptoJS from 'crypto-js';

const encryptionKey = 'M1n1st3r10d3d3s4rr0ll0pr0duct1v0y3c0n0m14plur4l';

// LISTAR
export const index = (page=1, rows=10, q=null) => {
  return http().get(`${urlBase}/ppirtf?page=${page}&rows=${rows}&q=${q}`);
}

// GUARDAR
export const store = (datos) => {
  return http().post(`${urlBase}/ppirtf`,datos);
}

// MOSTRAR
export const show = (id) => {

  const encryptedId = id.match(/.{1,2}/g).map(byte => String.fromCharCode(parseInt(byte, 16))).join('');
  const sanitizedId = CryptoJS.AES.decrypt(encryptedId, encryptionKey).toString(CryptoJS.enc.Utf8);
  const originalId = sanitizedId.replace(/_/g, '/');

  return http().get(`${urlBase}/ppirtf/${originalId}`);
}

export const showU = (id) => {
  return http().get(`${urlBase}/ppirtf/${id}`);
}

// // EDITAR
export const editar = (id, datos) => {
  return http().put(`${urlBase}/infppirtf/${id}`, datos);
}

// MODIFICAR
export const update = (datos, id) => {
  return http().put(`${urlBase}/ppirtf/${id}`, datos);
}

// ELIMINAR
export const destroy = (id) => {
  return http().delete(`${urlBase}/ppirtf/${id}`);
}

// DERIVAR
export const derivarPpirtf = (id) => {
  return http().post(`${urlBase}/derivarppirtf/${id}`);
}

// APROBAR
export const aprobarPpirta = (id) => {
  return http().post(`${urlBase}/derivarppirtf/${id}`);
}