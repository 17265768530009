<template>
    <div>
      <br>
      <h5>INFORMACIÓN DEL LUGAR TURÍSTICO</h5>
      <div v-if="isLoading" class="loading-spinner">
        <i class="pi pi-spinner pi-spin"></i> Cargando datos...
      </div>
        <div v-else class="p-fluid">
          <div class="grid formgrid">
            <div class="field col-12 mb-12 lg:col-12 lg:col-12">
              <label for="macroregion">Descripcion del recurso Turistico</label>
              <Textarea style="background-color: #FCFDFE;" inputId="textarea" rows="5" cols="15" v-model="ppirta.descripcion" readonly></Textarea>
            </div>
            <h5>CAPACIDAD DE CARGA </h5>
            <div class="p-fluid">
              <div class="grid formgrid">
                <div class="field col-12 mb-3 lg:col-1 lg:col-4">
                  <label for="macroregion">CCF</label>
                  <InputText  type="text" id="g_z" placeholder="00000" v-model="ppirta.cc_ccf" style="pointer-events: none;" readonly />
                </div>
                <div class="field col-12 mb-3 lg:col-1 lg:col-4">
                  <label for="macroregion">CCR</label>
                  <InputText type="text" id="g_z" placeholder="00000" v-model="ppirta.cc_ccr" style="pointer-events: none;" readonly />
                </div>
                <div class="field col-12 mb-3 lg:col-2 lg:col-4">
                  <label for="macroregion">CCE</label>
                  <InputText  type="text" id="g_z" placeholder="00000"  v-model="ppirta.cc_cce" style="pointer-events: none;" readonly />
                </div>
                <div class="field col-12 mb-12 lg:col-12 lg:col-12">
                  <h5> VINCULACIÓN DEL RECURSO</h5>
                </div>
                <div class="field mb-1 lg:col-1 lg:col-1">
                <label> <b>C</b></label>
                </div>
                <div class="field-checkbox mb-2 lg:col-2 lg:col-2">
                  <Dropdown id="municipio" :options="viavt" v-model="ppirta.v_c"
                        optionLabel="nombre" optionValue="id" placeholder="Elegir..."></Dropdown>
                </div>
                <div class="field mb-1 lg:col-1 lg:col-1">
                <label> <b>CI</b></label>
                </div>
                <div class="field-checkbox mb-2 lg:col-2 lg:col-2">
                  <Dropdown id="municipio" :options="viavt" v-model="ppirta.v_ci"
                        optionLabel="nombre" optionValue="id" placeholder="Elegir..."></Dropdown>
                </div>
                <div class="field mb-1 lg:col-1 lg:col-1">
                <label> <b>R</b></label>
                </div>
                <div class="field-checkbox mb-2 lg:col-2 lg:col-2">
                  <Dropdown id="municipio" :options="viavt" v-model="ppirta.v_r"
                        optionLabel="nombre" optionValue="id" placeholder="Elegir..."></Dropdown>
                </div>
                <div class="field mb-1 lg:col-1 lg:col-1">
                <label> <b>N</b></label>
                </div>
                <div class="field-checkbox mb-2 lg:col-2 lg:col-2">
                  <Dropdown id="municipio" :options="viavt" v-model="ppirta.v_b"
                        optionLabel="nombre" optionValue="id" placeholder="Elegir..."></Dropdown>
                </div>
              </div>
            </div>
            <div class="field col-12 mb-3 lg:col-2 lg:col-4">
              <label for="macroregion">Servicio de emergencia</label><br>
            </div>
            <div class="field-checkbox col-12 mb-3 lg:col-2 lg:col-3">
              <RadioButton  name="city" value="SI" v-model="ppirta.emergencia" />
              <label for="city1">SI</label>
            </div>
            <div class="field-checkbox col-12 mb-3 lg:col-2 lg:col-3">
              <RadioButton label="No" name="city" value="NO" v-model="ppirta.emergencia" />
              <label for="city1">NO</label>
            </div>
              <div class="field col-12 mb-3 lg:col-1 lg:col-4">
                <label for="macroregion">Población más cercana</label>
                <InputText type="text" id="g_z" placeholder="" v-model="ppirta.poblacion_cercana" style="pointer-events: none;" readonly />
              </div>
              <div class="field col-12 mb-3 lg:col-1 lg:col-4">
                <label for="macroregion">Distancía del atractivo en Km</label>
                <InputText type="text" id="g_z" placeholder="" v-model="ppirta.distancia_km" style="pointer-events: none;" readonly />
              </div>
              <div class="field col-12 mb-3 lg:col-1 lg:col-4">
                <label for="macroregion">Frecuencia de T</label>
                <InputText type="text" id="g_z" placeholder="" v-model="ppirta.frecuencia" style="pointer-events: none;" readonly />
              </div>
              <div class="field mb-3 lg:col-2 lg:col-3">
                      <label for="city2">Estado de Conservación</label>
                </div>
              <div class="field-checkbox mb-3 lg:col-2 lg:col-3">
                <RadioButton  name="city" value="B" v-model="ppirta.conservacion" />
                  <label for="city1">BUENO</label>
                    </div>
                <div class="field-checkbox mb-3 lg:col-2 lg:col-3">
                  <RadioButton  name="city" value="R" v-model="ppirta.conservacion" />
                  <label for="city1">REGULAR</label>
              
                </div>
                <div class="field-checkbox mb-3 lg:col-2 lg:col-3">
                  <RadioButton  name="city" value="M" v-model="ppirta.conservacion" />
                  <label for="city1">MALO</label>
                </div>
                <div class="field col-12 mb-12 lg:col-12 lg:col-12">
              <h5> CAUSAS DE DETERIORO</h5>
            </div>
    
            <div class="field-checkbox mb-3 lg:col-2 lg:col-3">
              <label for="city1">NATURALES</label>
            </div>
            <div class="field-checkbox mb-2 lg:col-2 lg:col-2">
              <Dropdown id="municipio" :options="viav" v-model="ppirta.deteoro_antopica"
                optionLabel="nombre" optionValue="id" placeholder="Elegir..."></Dropdown>
            </div>
            <div class="field-checkbox mb-3 lg:col-2 lg:col-4">
              <label for="city1">ANTRÓPICAS</label>
    
            </div>
            <div class="field-checkbox mb-2 lg:col-2 lg:col-2">
              <Dropdown id="municipio" :options="viav" v-model="ppirta.deteoro_natural"
                optionLabel="nombre" optionValue="id" placeholder="Elegir..."></Dropdown>
            </div>
            <div class="field col-12 mb-3 lg:col-2 lg:col-4">
              <label for="macroregion">Estado de protección</label>
            </div>
            <div class="field-checkbox mb-3 lg:col-2 lg:col-4">
              <RadioButton  name="city" value="A" v-model="ppirta.proteccion" />
              <label for="city1">PROTEGIDO</label>
    
            </div>
            <div class="field-checkbox mb-3 lg:col-2 lg:col-4">
              <RadioButton  name="city" value="I" v-model="ppirta.proteccion" />
              <label for="city1">NO PROTEGIDO</label>
            </div>
            <div class="field col-12 mb-12 lg:col-12 lg:col-12">
              <h5>CONDICIONES CLIMÁTICAS</h5>
            </div>
    
            <div class="field col-12 mb-3 lg:col-1 lg:col-4">
                <label for="macroregion">Bioclima</label>
                <InputText type="text" id="g_z" placeholder="Puna baja del altiplano sur árido" v-model="ppirta.bioclima" style="pointer-events: none;" readonly/>
            </div>
            <div class="field col-12 mb-3 lg:col-1 lg:col-4">
            <label for="macroregion">Temperatura °C</label>
            <InputText type="text" id="g_z" placeholder="Min 0 ºC – Max 20 ºC/12ºC%" v-model="ppirta.temperatura" style="pointer-events: none;" readonly/>
            </div>
            <div class="field col-12 mb-3 lg:col-2 lg:col-4">
            <label for="macroregion">Humedad Relativa %</label>
            <InputText  type="text" id="g_z" placeholder="25 %  – 55 % todo el año"  v-model="ppirta.humedad" style="pointer-events: none;" readonly/>
            </div>
            <div class="field col-12 mb-12 lg:col-12 lg:col-12">
              <label for="macroregion">Restricciones Turísticas</label>
              <Textarea inputId="textarea" rows="2" cols="15" v-model="ppirta.restriccion" style="background-color: #FCFDFE;pointer-events: none;" readonly></Textarea>
            </div>
          </div>
        </div>
    </div>
    <Toast />
    </template>
    
    <script>
    import { onMounted, ref } from 'vue'
    import * as ppirtaService from '@/service/PlantillaService.js'
    import * as categoriaService from '@/service/CategoriaService.js'
    import { useToast } from "primevue/usetoast";
    import { useRoute } from 'vue-router'
    
    export default {
      props:{
        src: {
          type: Array,
          required: true
        }
      },
    
      setup(){

        const isLoading = ref(true);
        const simulateDataLoading = async () => {
          await new Promise((resolve) => setTimeout(resolve, 1000)); 
          isLoading.value = false;
        };
    
        const categorias = ref([]);
        const option = ref({});
        const categoria = ref({});
        const displayModal = ref(false);
        const submitted = ref(false);
        const toast = useToast();
        const errores = ref({});
        const informacion = ref({ });
        const ppirta = ref({});
        const route = useRoute()
        const viavt = ref([
          {
            'id' : 't',
            'nombre' : 'Si'
          },
          {
            'id' : 'f',
            'nombre' : 'No'
          }
        ]);
        const viav = ref([
          {
            'id' : 'A',
            'nombre' : 'Si'
          },
          {
            'id' : 'I',
            'nombre' : 'No'
          }
        ]);
        const descripcion = "asdsa"
        onMounted( () => {
          one_ppirtas()
          listarCategorias()
          simulateDataLoading();
        })
    
        // METODOS
        const one_ppirtas =  async () => {
         const {data} = await ppirtaService.show(route.params.id)
         ppirta.value = data.ppirta
        //  console.log('hgkjgkjh',ppirta.value)
        }
        const listarCategorias =  async () => {
         const {data} = await categoriaService.index()
          categorias.value = data
        }


        // DEVOLVER REGISTROS
        return {option, informacion, viavt, viav, categoria, submitted, displayModal, errores, ppirta, descripcion,isLoading,}
    
      }
    
    }
    </script>
  <style>
    .loading-spinner {
  text-align: center;
  font-size: 20px;
  margin-top: 20px;
}
  </style>