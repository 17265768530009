<template>
  <div class="card as bg-image">
    <div class="container">
      <div class="card as">
        <div class="profile-container">
          <div class="avatar-container">
            <div class="clearfix">
              <img v-if="genero === 'M'" src="@/../public/layout/images/mdpyep/avatar/avatar-hombre.jpg" class="avatar float-right" />
              <img v-else-if="genero === 'F'" src="@/../public/layout/images/mdpyep/avatar/avatar-mujer.jpg" class="avatar float-right" />
              <span>avatar</span>
            </div>
          </div>
          <div class="user-data">
            <h3 class="tt"><span style="color:white">::::::::::</span>Información de mi Perfil<span style="color:white">::::::::::</span></h3>
            <Divider />
            <div>
              <b>Nombre del usuario: </b> {{ nombres }} {{ primerApellido }} {{ segundoApellido }}
            </div>
            <Divider />
            <div>
              <b>Correo electrónico: </b> {{ email }}
            </div>
            <Divider />
            <div>
              <b>N° documento de identidad: </b> {{ numeroDocumento || 'N/A' }}
            </div>
            <Divider />
            <div>
              <b>Dirección: </b> {{ direccion }}
            </div>
            <Divider />
            <div>
              <b>Cargo: </b> {{ cargo }}
            </div>
            <Divider />
            <div>
              <b>Género: </b> {{ genero === 'M' ? 'Masculino' : 'Femenino' }}
            </div>
            <Divider />
            <div>
              <b>Nacionalidad:</b> {{ nacionalidad === 'B' ? 'Boliviana' : 'Extranjero' }}
            </div>
            <Divider />
            <div>
              <b>Departamento:</b> {{ getNombreDepartamento(id_departamento) }}
            </div>
            <Divider />
            <div>
              <b>Rol:</b> {{ nombrePrimerRol }}
            </div>
            <Divider />
            <!-- <div>
              <b>Estado:</b> <span :class="{ 'activo-bg': estado, 'inactivo-bg': !estado }"> {{ estado ? 'Activo' : 'Inactivo' }}</span>
            </div> 
            <Divider />-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import * as departamentoService from "@/service/DepartamentoService.js";
import * as authService from "@/service/AuthService.js"
import { ref, onMounted } from "vue";
import Divider from 'primevue/divider';
import { computed } from 'vue';


export default {

  setup(){
      // Datos de usuario
			const usuario = JSON.parse(localStorage.getItem("user")) || {};

      const email = ref(usuario.email || "");
      const nombres = ref(usuario.nombres || "");
      const primerApellido = ref(usuario.primer_apellido || "");
      const segundoApellido = ref(usuario.segundo_apellido || "");
      const numeroDocumento = ref(usuario.numero_documento || "");
      const direccion = ref(usuario.direccion || "");
      const cargo = ref(usuario.cargo || "");
      const genero = ref(usuario.genero || "");
      const nacionalidad = ref(usuario.nacionalidad || "");
      const estado = ref(usuario.estado || "");
      const id_departamento = ref(usuario.id_departamento || "");


      // Datos de usuario y rol
      const roles = JSON.parse(localStorage.getItem("role")) || [];

      const primerRol = ref(roles[0] || {});
      const idPrimerRol = ref(primerRol.value.id || null);
      const nombrePrimerRol = ref(primerRol.value.nombre || "");
      const descripcionPrimerRol = ref(primerRol.value.descripcion || "");

      // Datos de usuario sus menus
      const menus = JSON.parse(localStorage.getItem("menu")) || [];
     
      const primerMenu = ref(menus[0] || {});
      const nombreMenu = ref(primerMenu.value.nombre || null);

      return {
				usuario,
				roles,
        menus,

				email,
				nombres,
				primerApellido,
				segundoApellido,
        numeroDocumento,
        direccion,
        cargo,
        genero,
        nacionalidad,
        estado,
        id_departamento,
				
				primerRol,
				idPrimerRol,
				nombrePrimerRol,
				descripcionPrimerRol,

        primerMenu,
        nombreMenu,
			};

  },
  methods: {
    getNombreMenuPadre(padreId) {
      const menuPadre = this.menus.find(menu => menu.id === padreId);
      return menuPadre ? menuPadre.nombre : "N/A";
    }
  },

  computed: {
    avatarSrc() {
      return this.genero === 'M' ? "layout/images/mdpyep/avatar/avatar-hombre.jpg" : "layout/images/mdpyep/avatar/avatar-mujer.jpg";
    },
    getNombreDepartamento() {
      return (id) => {
        switch (id) {
          case 1:
            return 'CHUQUISACA';
          case 2:
            return 'LA PAZ';
          case 3:
            return 'COCHABAMBA';
          case 4:
            return 'ORURO';
          case 5:
            return 'POTOSÍ';
          case 6:
            return 'TARIJA';
          case 7:
            return 'SANTA CRUZ';
          case 8:
            return 'BENI';
          case 9:
            return 'PANDO';
          default:
            return 'N/A';
        }
      };
    }
  }
}
</script>

<style scoped>
.bg-image {
  background-image: url('@/../public/layout/images/mdpyep/img/11.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
}
.card .as{
  border-radius: 10px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 35%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}
.activo-bg {
  background-color: green; 
  color: white; 
  padding:4px;
}
.inactivo-bg {
  background-color: red;
  color: white;
  padding:4px;
}
.user-data {
  flex: 1;
  padding: 10px;
}
.avatar-container {
  padding: 10px;
}
.profile-container {
  display: block;
  align-items: center;
}
.avatar-center {
  display: block;
  flex-direction: column;
  align-items: center;
}
.avatar-label {
  margin-top: 8px;
  font-weight: bold;
  color: #333;
}

.avatar-container {
  margin-right: 20px;
  text-align: center;
  color: blueviolet;
  margin-bottom: 5px;
}
.avatar {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border: 2px solid #fff;
  background-color: #f5f5f5;
  display: block;
  margin: 0 auto; 
}
@media (max-width: 768px) { 
  .card .as {
    border-radius: 10px;
    padding: 200px;
    padding-top: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; 
    margin: 0 auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }
  .avatar{
    width: 50px !important;
    height: 50px !important;
  }
  
}
</style>