<template>
	<div class="layout-footer flex align-items-center p-2 shadow-2">
	  <img id="footer-logo" :src="require('@/../public/layout/images/mdpyep/logo_viceministerio_turismoo.jpg')" alt="ultima-footer-logo">
	  
	  <Button
		icon="pi pi-youtube fs-large"
		class="p-button-rounded p-button-text p-button-plain"
		:class="{'ml-auto mr-2': !isRTL, 'ml-2 mr-auto': isRTL}"
		@click="redirectToYouTube"
	  >
	  </Button>
	  <Button 
		icon="pi pi-facebook fs-large" 
		class="p-button-rounded p-button-text p-button-plain" 
		:class="{'mr-2': !isRTL, 'ml-2': isRTL}" 
		@click="redirectToFb"
	  >
	  </Button>
	  <Button 
		icon="pi pi-twitter fs-large" 
		class="p-button-rounded p-button-text p-button-plain" 
		:class="{'mr-2': !isRTL, 'ml-2': isRTL}"
		@click="redirectToTw"
	  >
	  </Button>
	</div>
  </template>
  
  <script>
  export default {
	data() {
	  return {
		isRTL: false, // ejemplo de valor
	  };
	},
	methods: {
	  redirectToYouTube() {
		const url = "https://www.youtube.com/@viceministeriodeturismodeb9636";
		window.open(url, '_blank');
	  },
	  redirectToFb(){
		const url = "https://www.facebook.com/vmtbolivia/?locale=es_LA";
		window.open(url, '_blank');
	  },
	  redirectToTw(){
		const url = "https://twitter.com/vmtBolivia";
		window.open(url, '_blank');
	  }
	},
	name: "AppFooter",
	props: {
	  layoutMode: {
		type: String,
		default: 'light'
	  }
	},
	computed: {
	  isRTL() {
		return this.$appState.RTL;
	  }
	}
  }
  </script>
  
  <style scoped>
  </style>
  