import {http, urlBase} from "./Http" 

//Gobernacion
// MOSTRAR
export const index = (page=1, rows=10, q=null) => {
  return http().get(`${urlBase}/ppirtapendiente?page=${page}&rows=${rows}&q=${q}`);
}

// RECIBIR
export const recibirPpirta = (id) => {
  return http().post(`${urlBase}/ppirtarecibir/${id}`);
}

// REVISAR
export const revisarPpirta = (datos) => {
  return http().post(`${urlBase}/ppirtarevisargobernacion`,datos);
}


//Nacional
// MOSTRAR
export const indexN = (page=1, rows=10, q=null) => {
  return http().get(`${urlBase}/ppirtanacionalpendiente?page=${page}&rows=${rows}&q=${q}`);
}

// RECIBIR
export const recibirPpirtaN = (id) => {
  return http().post(`${urlBase}/ppirtanacionalrecibir/${id}`);
}

// REVISAR
export const revisarPpirtaN = (datos) => {
  return http().post(`${urlBase}/ppirtarevisarnacional`,datos);
}

