<template>
  <div class="card">
    <table cellpadding="5px" style="width: 100%; background-color: #f8f8f8; height: 45px">
      <tr>
        <td style="font-size: 20px">
          <B>GESTIÓN DE RUTAS</B>
        </td>
      </tr>
    </table>
    <br />
    <!--Cabecera de menu principal-->
    <Toolbar class="mb-4">
      <template #start>
        <Button label="Nuevo" icon="pi pi-plus" class="p-button-success mr-2" @click="openModal" />
      </template>
    </Toolbar>

    <!--Tabla de displiegue de la informacion-->
    <DataTable ref="dt" :value="tramos" v-model:selection="selectedProducts" dataKey="id"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros" responsiveLayout="scroll">

      <!-- <Column field="id" header="ID" :sortable="true" style="min-width: 2rem"></Column> -->
      <Column field="tramo" header="TRAMO" :sortable="false" style="min-width: 8rem"></Column>
      <Column field='tipo' header="TIPO" :sortable="false" style="min-width: 8rem"></Column>
      <Column field='distancia_km' header="DISTANCIA KM" :sortable="false" style="min-width: 8rem"></Column>
      <Column field='distancia_hr' header="DISTANCIA HRS" :sortable="false" style="min-width: 8rem"></Column>
     <Column field="s_si" header="SEÑALIZACIÓN V/T SI" :sortable="false" style="min-width: 8rem"></Column>
      <Column field="s_no" header="SEÑALIZACION V/T NO" :sortable="false" style="min-width: 8rem"></Column>
      <Column field="estado" header="ESTADO" :sortable="false" style="min-width: 6rem" :body="customStateColumn" />
      <Column :exportable="false" style="min-width: 8rem" header="ACCIONES">

        <template #body="slotProps">
          <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
            @click="editarDialogModal(slotProps.data)" />
          <Button v-if="idPrimerRol === 1" icon="pi pi-trash" class="p-button-rounded p-button-warning"
            @click="confirmDeleteProduct(slotProps.data)" />
        </template>
      </Column>
    </DataTable>

    <!--Formulario de registro de informacion-->
    <div class="floatlabel-demo col-12 md:col-12">
      <Dialog header="ACCECIBILIDAD AL ATRACTIVO POR TRAMOS" v-model:visible="displayModal" :breakpoints="{'800px': '450px'}"
        class="p-fluid" :style="{width: '800px'}" :modal="true">
        <div>
          <div class="p-fluid">
            <div class="grid formgrid">
              <div class="field col-12 mb-3 lg:col-1 lg:col-8">
                <label for="macroregion">Tramo</label>
                <InputText type="text" id="g_z"  v-model.trim="tramo.tramo" autofocus/>
              </div>
              <div class="field col-12 mb-3 lg:col-2 lg:col-4">
              <label for="municipio">Tipo de Vía</label>
              <Dropdown id="municipio" :options="via" v-model="tramo.id_tipo_via"
                optionLabel="nombre" optionValue="id" placeholder="Elegir..."></Dropdown>
            </div>
            <div class="field col-12 mb-3 lg:col-2 lg:col-5">
              <label for="municipio">Estado de la vía</label>
              <Dropdown id="municipio" :options="estado" v-model="tramo.id_estado"
                optionLabel="nombre" optionValue="id" placeholder="Elegir..."></Dropdown>
            </div>
              <div class="field col-12 mb-3 lg:col-1 lg:col-5">
                <label for="macroregion">Dístancia en Km</label>
                <InputText type="number" id="g_z" placeholder="" v-model.trim="tramo.distancia_km" autofocus/>
              </div>
              <div class="field col-12 mb-3 lg:col-1 lg:col-2">
                <label for="macroregion">Dístancia Hr</label>
                <InputText type="number" id="g_z" placeholder="" v-model.trim="tramo.distancia_hr" autofocus/>
              </div>
              <div class="field col-12 mb-3 lg:col-2 lg:col-4">
                <label for="macroregion">Señalización v/t</label><br>
              </div>
              
              <div class="field col-12 mb-3 lg:col-2 lg:col-8">
                <Dropdown id="municipio" :options="viavt" v-model="tramo.s_si"
                optionLabel="nombre" optionValue="id" placeholder="Elegir..."></Dropdown>
            </div>
              
            </div>
          </div>
        </div>
        <!-- {{ tramo }} -->
        <template #footer>
          <Button label="Cancelar" icon="pi pi-times" @click="closeModal" class="p-button-text" />
          <Button label="Guardar" icon="pi pi-check" @click="guardarTramo" />
        </template>
      </Dialog>

      <!-- Eliminar un registro-->
      <Dialog v-model:visible="deleteProductDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
        <div class="confirmation-content">
          <h4><i class="pi pi-exclamation-triangle mr-2" style="font-size: 2rem" /><b>¿Está seguro de borrar el registro?</b></h4><br>
            <span v-if="tramo"><b style="color: #4E5FBB;">{{tramo.tramo}}</b></span><br>
            <span>¡Si no lo está puede cancelar la accíón!</span>
        </div>
        <template #footer>
          <Button label="Cancelar" icon="pi pi-times" class="p-button-text"   @click="deleteProductDialog = false" />
          <Button label="Si, borrar registro" icon="pi pi-check" @click="deleteProduct"/>
        </template>
      </Dialog>
      <!-- Eliminar varios registro-->
      <Dialog v-model:visible="deleteProductsDialog" :style="{ width: '450px' }" header="Confirm" :modal="true">
        <div class="confirmation-content">
          <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
          <span v-if="product">Are you sure you want to delete the selected products?</span>
        </div>
        <template #footer>
          <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductsDialog = false" />
          <Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteSelectedProducts" />
        </template>
      </Dialog>

    </div>

    <!--Para el despliegue del mensaje de salida-->
    <Toast />
  </div>
</template>

<script>
import { reactive,onMounted, ref } from "vue";
import * as usuarioService from '@/service/UsuarioService.js'
import * as tramoService from '@/service/TramoService.js'
import * as departamentoSevice from '@/service/DepartamentoService.js'
import * as entidadSevice from '@/service/EntidadService.js'
import * as rolSevice from '@/service/RolService.js'

import { useToast } from "primevue/usetoast";
import InputMask from "primevue/inputmask";

import { FilterMatchMode } from "primevue/api";

// Importacones para realizar las validaciones
import { email, helpers, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { useRoute } from 'vue-router';
import CryptoJS from 'crypto-js';


export default {
  data() {
    return {
      data: [
        { estado: "Regular" },
        { estado: "Bueno" },
        { estado: "Malo" },
      ],
    };
  },
  methods: {
    customStateColumn(rowData) {
      let stateClass = "";
      switch (rowData.estado) {
        case "Regular":
          stateClass = "regular-state";
          break;
        case "Bueno":
          stateClass = "good-state";
          break;
        case "Malo":
          stateClass = "bad-state";
          break;
      }

      return {
        class: stateClass,
        render: (h) => {
          return h('span', rowData.estado);
        },
      };
    },
  },
  setup() {

    const encryptionKey = 'M1n1st3r10d3d3s4rr0ll0pr0duct1v0y3c0n0m14plur4l';

    const rolss = JSON.parse(localStorage.getItem("role")) || [];
    const primerRol = ref(rolss[0] || {});
    const idPrimerRol = ref(primerRol.value.id || null);

    const tramo_editar = ref({});
    const tramo = ref({});
    const tramos = ref();
    const route = useRoute();
    const usuarios = ref();
    const usuario = ref({});
    const displayModal = ref(false);
    const submitted = ref(false);
    const toast = useToast();
    const errores = ref({});
    const departamentos = ref([]);
    const entidades = ref([]);
    const roles = ref([]);
    const categorias = ref([]);
    const via = ref([]);
    const estado = ref([]);
    const viavt = ref([
      {
        'id' : 'V',
        'nombre' : 'V'
      },
      {
        'id' : 'T',
        'nombre' : 'T'
      },
      {
        'id' : 'V/T',
        'nombre' : 'V/T'
      },
      {
        'id' : 'Ninguna',
        'nombre' : 'Ninguna'
      }
    ]);

    onMounted(() => {
      listarUsuarios();
      listarTramo();
      getDepartamentos();
      getEntidad();
      getRol();
      listarVia();
      listarEstado();
     });

     const getDepartamentos = async () => {
      const {data} = await departamentoSevice.index('','','');
      departamentos.value=data.departamentos.data;
    }

    const getEntidad = async () => {
     const {data} = await entidadSevice.index('','','');
     entidades.value=data.entidades.data;
    }

    const getRol = async () => {
       const {data} = await rolSevice.index();
       roles.value=data.rol.data;
    }
 
    const listaSexos = ref([
          {sexo: 'MASCULINO', code_s: 'M'},
					{sexo: 'FEMENINO', code_s: 'F'}
        ]);

    const listaNacionalidades = ref([
          {nacionalidad: 'BOLIVIANA', code_n: '0'},
					{nacionalidad: 'EXTRANJERO', code_n: '1'}
        ]);

   // Validaion del formulario
    // Definimos los variables a validar
    const state = reactive( {
      email: "",
    });

    // Tipo de validacion a realizar del dato
    const rules = {
      email: { required, email },
    };
    // Seccion de la validacion
    const v$ = useVuelidate(rules, state);


    const dt = ref();
    const deleteProductDialog = ref(false);
    const deleteProductsDialog = ref(false);
    const loading = ref(false);
    const totalRecords = ref(0);
    const selectedProducts = ref();
    const lazyParams = ref({});
   

    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    // METODOS
    const onPage = (event) => {
      lazyParams.value = event;
      listarUsuarios();
    };

    const onFilter = () => {
      lazyParams.value.filters = filters.value;
      listarUsuarios();
    };

    const buscar = () => {
      console.log(filters.value.global.value);
      listarUsuarios();
    };



    // Listado de las entidades creadas
    const listarUsuarios = async () => {
      try {
        loading.value = true;
        let rows = lazyParams.value.rows;
        let q = filters.value.global.value == null?'':filters.value.global.value;
        let page = (lazyParams.value.page == null)?0:lazyParams.value.page;

        const { data } = await usuarioService.index(page + 1, rows, q);
        usuarios.value = data.users.data;
        totalRecords.value = data.users.total;
        loading.value = false;

      } catch (error) {
        console.log(error.response.data);
      }
    };

    const listarTramo = async () => {
      loading.value = true;
     
      let rows = lazyParams.value.rows;
      let q = filters.value.global.value == null?'':filters.value.global.value;
      let page = (lazyParams.value.page == null)?0:lazyParams.value.page;

      const encryptedId = route.params.id.match(/.{1,2}/g).map(byte => String.fromCharCode(parseInt(byte, 16))).join('');
      const sanitizedId = CryptoJS.AES.decrypt(encryptedId, encryptionKey).toString(CryptoJS.enc.Utf8);
      const originalId = sanitizedId.replace(/_/g, '/');

      const { data } = await tramoService.index(originalId, page + 1, rows, q);
      // console.log('este es el data',data)
      tramos.value = data.rutas.data;
      totalRecords.value = data.rutas.total;
      loading.value = false;
    };

    //Guardar tramo
    const guardarTramo = async () => {
      try {
        if(tramo.value.tramo) {
          if(tramo.value.id_tipo_via) {
          if(tramo.value.id_estado) {
            if(tramo.value.distancia_km) {
              if(tramo.value.distancia_hr) {
                if(tramo.value.s_si) {
                  if (tramo.value.id) {
                    tramo_editar.value.id = tramo.value.id
                    tramo_editar.value.id_ppirta = tramo.value.id_ppirta
                    tramo_editar.value.tramo = tramo.value.tramo
                    tramo_editar.value.id_tipo_via = tramo.value.id_tipo_via
                    tramo_editar.value.id_estado = tramo.value.id_estado
                    tramo_editar.value.distancia_km = tramo.value.distancia_km
                    tramo_editar.value.distancia_hr = tramo.value.distancia_hr
                    tramo_editar.value.s_si = tramo.value.s_si

                    await tramoService.update(tramo_editar.value, tramo_editar.value.id);
                      closeModal();
                      listarTramo();
                    toast.add({
                      severity: "success",
                      summary: "REGISTRO",
                      detail: "Tramo actualizada",
                      life: 5000,
                    });
                  } else {

                    const encryptedId = route.params.id.match(/.{1,2}/g).map(byte => String.fromCharCode(parseInt(byte, 16))).join('');
                    const sanitizedId = CryptoJS.AES.decrypt(encryptedId, encryptionKey).toString(CryptoJS.enc.Utf8);
                    const originalId = sanitizedId.replace(/_/g, '/');

                    tramo.value.id_ppirta = originalId;

                    await tramoService.save(tramo.value);
                    listarTramo();
                   
                    closeModal();
                    toast.add({
                      severity: "success",
                      summary: "REGISTRO",
                      detail: "Tramo Registrado",
                      life: 5000,
                    });
                  }
                  
                } else {
                  toast.add({ severity: "error", summary: "La señalizacion V/T es requerida", detail: "Error de registro del formulario", life: 5000, });
                }
              } else {
                toast.add({ severity: "error", summary: "La distancia hr es requerida", detail: "Error de registro del formulario", life: 5000, });
              }
            } else {
              toast.add({ severity: "error", summary: "La distancia km es requerida", detail: "Error de registro del formulario", life: 5000, });
            }
          } else {
            toast.add({ severity: "error", summary: "Es estado es requerido", detail: "Error de registro del formulario", life: 5000, });
          }
        } else {
          toast.add({ severity: "error", summary: "El tipo vía es requerido", detail: "Error de registro del formulario", life: 5000, });
        }

        } else {
          toast.add({ severity: "error", summary: "El tramo vía es requerido", detail: "Error de registro del formulario", life: 5000, });
        }
      
          } catch (error) {
            closeModal();
            console.log(error.response.data);
            errores.value = error.response.data.errores;
            toast.add({
              severity: "error",
              summary: "ERROR DE REGISTRO",
              detail: "Error de registro del tramo",
              life: 5000,
            });
          }

    };

    // Guardar la entidad 
    const guardarUsuario = async (isFormValid) => {
      submitted.value = true;
      if(!usuario.value.numero_documento || 
      !usuario.value.nombres ||
      !usuario.value.primer_apellido ||
      !usuario.value.genero ||
      !usuario.value.nacionalidad ||
      !usuario.value.id_departamento ||
      !usuario.value.celular ||
      !usuario.value.direccion ||
      !usuario.value.cargo ||
      !usuario.value.id_rol){ 
        alert(usuario.value.numero_documento);
        alert(usuario.value.nombres);
        alert(usuario.value.primer_apellido);
        alert(usuario.value.genero);
        alert(usuario.value.nacionalidad);
        alert(usuario.value.id_departamento);
        alert(usuario.value.celular);
        alert(usuario.value.direccion);
        alert(usuario.value.cargo);
        alert(usuario.value.rol);


        return;
      }

    
        if (!isFormValid) { 
          return;
        }
      

          try {
            if (usuario.value.id) {
              await usuarioService.update(usuario.value, usuario.value.id);
              listarUsuarios();
              closeModal();
              toast.add({
                severity: "success",
                summary: "REGISTRO",
                detail: "Ruta actualizada",
                life: 5000,
              });
            } else {
              await usuarioService.store(usuario.value);
              listarUsuarios();
              //categorias.value.push(categoria.value)
              closeModal();
              toast.add({
                severity: "success",
                summary: "REGISTRO",
                detail: "Ruta Registrada",
                life: 5000,
              });
            }
          } catch (error) {
            console.log(error.response.data);
            errores.value = error.response.data.errores;
            toast.add({
              severity: "error",
              summary: "ERROR DE REGISTRO",
              detail: "Error de registro de la Ruta",
              life: 5000,
            });
          }
    };
    // METODOS DE LA PLANTILLA
    // Abrir un modal
    const openModal = () => {
      usuario.value = {};
      displayModal.value = true;
      submitted.value = false;
    };
    // Abrir un modal
    const editarDialogModal = (datos) => {
      tramo.value = datos;
      displayModal.value = true;
    };

    // Eliminar un producto
    const confirmDeleteProduct = (datos) => {
      tramo.value = datos;
          deleteProductDialog.value = true;
        };

    const deleteProduct = async () => {
      try {
        await tramoService.destroy(tramo.value.id);
        listarTramo();
        toast.add({
          severity: "error",
          summary: "REGISTRO",
          detail: "Ruta Eliminado",
          life: 5000,
        });
        deleteProductDialog.value = false;
        tramo.value = {}; 
      } catch (error) {
        //console.log(error.response.data);
        errores.value = error.response.data.errores;
        toast.add({
          severity: "error",
          summary: "REGISTRO",
          detail: "No se elimino el registro",
          life: 5000,
        });
      }
              
        };

    const confirmDeleteSelected = () => {
            deleteProductsDialog.value = true;
        }; 
    const deleteSelectedProducts = () => {
            products.value = products.value.filter(val => !selectedProducts.value.includes(val));
            deleteProductsDialog.value = false;
            selectedProducts.value = null;
            toast.add({severity:'success', summary: 'Successful', detail: 'Categorias Eliminadas', life: 3000});
        };  
        
    // Cerrar un modal
    const closeModal = () => {
      displayModal.value = false;
      tramo.value = {};
      submitted.value = false;
      listarTramo();
      state.codigo = '';
      state.nombre = '';
      state.id_macro_region = '';
    };

    const listarVia =  async () => {
     const {data} = await tramoService.show()
      via.value = data.tipovia
    }

    const listarEstado =  async () => {
     const {data} = await tramoService.listest()
      estado.value = data.estados
    }
    const formatCurrency = (value) => {
      if (value)
        return value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      return;
    };

    // DEVOLVER REGISTROS
    return {
      rolss,
      idPrimerRol,
      categorias,
      via,
      estado,
      viavt,
      listarVia,
      listarEstado,
      v$,
      listaSexos, 
      listaNacionalidades,
      tramo_editar,
      departamentos,
      entidades,
      roles,
      state,
      rules,
      buscar,
      onFilter,
      onPage,
      loading,
      totalRecords,
      formatCurrency,
      dt,
      deleteProductDialog,
      deleteProductsDialog,
      confirmDeleteProduct,
      deleteProduct,
      confirmDeleteSelected,
      deleteSelectedProducts,
      selectedProducts,
      filters,
      tramo,
      tramos,
      usuarios,
      usuario,
      submitted,
      displayModal,
      openModal,
      closeModal,
      guardarUsuario,
      guardarTramo,
      editarDialogModal,
      errores,
    };
  },
  components: { InputMask },
};
</script>
<style scoped>
  .regular-state {
    background-color: lightgreen;
  }
  
  .good-state {
    background-color: green;
    color: white;
  }
  
  .bad-state {
    background-color: red;
    color: white;
  }
  </style>