<template>
  <div class="card">
    <table cellpadding="5px" style="width: 100%; background-color: #f8f8f8; height: 45px">
      <tr>
        <td style="font-size: 20px">
          <B>GESTIÓN DE USUARIOS</B>
        </td>
      </tr>
    </table>
    <br />
    <!--Cabecera de menu principal-->
    <Toolbar class="mb-4">
      <template #start>
      <Button v-if="idPrimerRol === 1" label="Nuevo" icon="pi pi-plus" class="p-button-success mr-2" @click="openModal1" />
      <Button v-if="idPrimerRol === 2" label="Nuevo" icon="pi pi-plus" class="p-button-success mr-2" @click="openModal2" />
      <Button v-if="idPrimerRol === 3" label="Nuevo" icon="pi pi-plus" class="p-button-success mr-2" @click="openModal3" />
      </template>
      <template #end>
        <Button label="PDF" icon="pi pi-file-pdf" class="p-button-help" @click="exportToPDF" />
      </template>
    </Toolbar>

    <!--Tabla de displiegue de la informacion-->
    <DataTable ref="dt" :lazy="true" :value="usuarios" v-model:selection="selectedProducts" dataKey="id"
      :paginator="true" :rows="10" :filters="filters" :totalRecords="totalRecords" :loading="loading"
      @page="onPage($event)" @filter="onFilter($event)"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros" responsiveLayout="scroll">
      <template #header>
        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
          <h5 class="m-0">USUARIOS</h5>
          <span class="block mt-2 md:mt-0 p-input-icon-left">
            <i class="pi pi-search" />
            <InputText v-model="filters['global'].value" @input="buscar()" placeholder="Buscar..." />
          </span>
        </div>
      </template>

      <!-- <Column selectionMode="multiple" style="width: 3rem" :exportable="false"></Column>
      <Column field="id" header="ID" :sortable="true" style="min-width: 2rem"></Column> -->
      <Column field="numero_documento" header="NRO DE CI" :sortable="false" style="min-width: 6rem"></Column>
      <Column field='nombres' header="NOMBRES" :sortable="false" style="min-width: 6rem"></Column>
      <Column field='primer_apellido' header="PATERNO" :sortable="false" style="min-width: 6rem"></Column>
      <Column field='segundo_apellido' header="MATERNO" :sortable="false" style="min-width: 6rem"></Column>
      <Column field="email" header="CORREO ELECTRÓNICO" :sortable="false" style="min-width: 16rem"></Column>
      <!-- <Column field="cargo" header="CARGO" :sortable="false" style="min-width: 8rem"></Column> -->
      <Column field="rol" header="TIPO USUARIO" :sortable="false" style="min-width: 8rem"></Column>
      <Column :exportable="false" style="min-width: 8rem" header="ACCIONES">
        
        <template #body="slotProps">
          
          <Button v-if="idPrimerRol === 1" icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
            @click="editarDialogModal1(slotProps.data)" />
            <Button v-if="idPrimerRol === 2" icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
            @click="editarDialogModal2(slotProps.data)" />
            <Button v-if="idPrimerRol === 3" icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
            @click="editarDialogModal3(slotProps.data)" />
          <Button v-if="idPrimerRol === 1 " icon="pi pi-trash" class="p-button-rounded p-button-warning"
            @click="confirmDeleteProduct(slotProps.data)" />
        </template>
      </Column>
    </DataTable>

    <!--Formulario de registro de informacion administrador-->
    <div class="floatlabel-demo col-12 md:col-12">
      <Dialog header="REGISTRO DE USUARIO" v-model:visible="displayModal1" :breakpoints="{'800px': '450px'}"
        class="p-fluid" :style="{width: '45%'}" maximizable :modal="true">
        <InlineMessage :style="{  border: 'solid #696cff', borderWidth: '0 0 0 6px', color: '#000000' }"
                    severity="info" class="border-primary w-full justify-content-start">
          <div class="flex align-items-center">
              <!-- <img alt="logo" src="https://primefaces.org/cdn/primevue/images/logo.svg" width="32" /> -->
              <div class="ml-2">Debe verificar que los datos seleccionados e ingresados sean correctos para registrar.<br>De lo contrario puede <span style="color:#4E5FBB"> X Cancelar</span> el registro.</div>
          </div>
        </InlineMessage>
        <br />
        <div class="grid formgrid">

          <div class="field col-12 mb-3 lg:col-2 lg:col-2">
            <label for="name">Documento</label>
            <InputText type="number" id="numero_documento" v-model.trim="usuario.numero_documento" mode="decimal"
              :useGrouping="false" required="true" autofocus
              :class="{'p-invalid': submitted && !usuario.numero_documento}" />
            <small class="p-error" v-if="submitted && !usuario.numero_documento">El Nro documento es requerido</small>
          </div>
          <div class="field col-12 mb-3 lg:col-1 lg:col-1">
            <label for="name">Compl.</label>
            <InputText id="complemento" placeholder="1B" v-model.trim="usuario.complemento" required="true" />
          </div>

          <div class="field col-12 mb-3 lg:col-2 lg:col-3">
            <label for="name">Nombres</label>
            <InputText id="nombres" v-model.trim="usuario.nombres" required
              :class="{'p-invalid': submitted && (!validarNombres() || !usuario.nombres)}" />
            <small class="p-error" v-if="submitted && (!validarNombres() || !usuario.nombres)">El nombre es requerido y solo debe tener letras</small>
          </div>

          
          <div class="field col-12 mb-3 lg:col-2 lg:col-3">
            <label for="name">Primer apellido</label>
            <InputText id="primer_apellido" v-model.trim="usuario.primer_apellido" required
              :class="{'p-invalid': submitted && (!validarApellidos() || !usuario.primer_apellido)}" />
            <small class="p-error" v-if="submitted && (!validarApellidos() || !usuario.primer_apellido)">El primer apellido es requerido y solo debe tener letras</small>
          </div>
          <div class="field col-12 mb-3 lg:col-2 lg:col-3">
            <label for="name">Segundo apellido</label>
            <InputText id="segundo_apellido" v-model.trim="usuario.segundo_apellido" required="true"
              :class="{'p-invalid': submitted && usuario.segundo_apellido && !validarApellidos()}" />
            <small class="p-error" v-if="submitted && usuario.segundo_apellido && !validarApellidos()">El apellido debe tener solo letras</small>
          </div>
          
        </div>
        <div class="grid formgrid">
          <div class="field col-12 mb-3 lg:col-2 lg:col-3">
            <label for="name">Género</label>
            <Dropdown id="sexos" v-model="usuario.genero" :options="listaSexos" optionLabel="sexo" optionValue="code_s"
              placeholder="Elegir..." :class="{'p-invalid': submitted && !usuario.genero}" />
            <small class="p-error" v-if="submitted && !usuario.genero">El género es requerido</small>
          </div>
          <div class="field col-12 mb-3 lg:col-2 lg:col-3">
            <label for="name">Nacionalidad</label>
            <Dropdown id="nacionalidad" v-model="usuario.nacionalidad" :options="listaNacionalidades"
              optionLabel="nacionalidad" optionValue="code_n" placeholder="Elegir..."
              :class="{'p-invalid': submitted && !usuario.nacionalidad}" />
            <small class="p-error" v-if="submitted && !usuario.nacionalidad">La nacionalidad es requerido</small>
          </div>
          <div class="field col-12 mb-3 lg:col-2 lg:col-6">
            <label for="name">Teléfono</label>
            <InputText type="number" id="celular" v-model.trim="usuario.celular" required="true"
              :class="{'p-invalid': submitted && !usuario.celular}" />
            <small class="p-error" v-if="submitted && !usuario.celular">El teléfono es requerido</small>
          </div>
        </div>

        <div class="grid formgrid">
          <div class="field col-12 mb-3 lg:col-2 lg:col-6">
            <label for="name">Correo electrónico</label>

            <InputText id="email" v-model.trim="usuario.email" v-model="v$.email.$model"
              :class="{'p-invalid':v$.email.$invalid && submitted}" :key="email" aria-describedby="email-error" />

            <span v-if="v$.email.$error && submitted">
              <span id="email-error" v-for="(error, index) of v$.email.$errors" :key="index">
                <small class="p-error">No es un correo válido</small>
              </span>
              <small class="p-error" v-if="submitted && !usuario.email">El correo electrónico es invalido</small>
            </span>
            <small v-else-if="(v$.email.$invalid && submitted) || v$.email.$pending.$response" class="p-error">El correo
              electrónico es requerido</small>

          </div>
          <div class="field col-12 mb-3 lg:col-2 lg:col-6">
            <label for="name">Dirección</label>
            <InputText id="direccion" v-model.trim="usuario.direccion" required="true"
              :class="{'p-invalid': submitted && !usuario.direccion}" />
            <small class="p-error" v-if="submitted && !usuario.direccion">La dirección es requerido</small>
          </div>
        </div>

        <table style="width: 100%; background-color:#F8F8F8; height: 35px;">
          <tr>
            <td>
              <B>&nbsp;&nbsp;DATOS COMPLEMENTARIOS</B>
            </td>
          </tr>
        </table>
        <br>
        <div class="grid formgrid">
          <div class="field col-12 mb-3 lg:col-2 lg:col-3">
            <label for="name">Departamento</label>
            <Dropdown id="id_departamento" v-model="usuario.id_departamento" :options="departamentos"
              optionLabel="nombre" optionValue="id" placeholder="Elegir..."
              :class="{'p-invalid': submitted && !usuario.id_departamento}" />
            <small class="p-error" v-if="submitted && !usuario.id_departamento">El departamento es requerido</small>
          </div>

          <div class="field col-12 mb-3 lg:col-2 lg:col-3">
            <label for="name">Entidad</label>
            <Dropdown id="id_entidad" v-model="usuario.id_entidad" :options="entidades" optionLabel="nombre"
              optionValue="id" placeholder="Elegir..." :class="{'p-invalid': submitted && !usuario.id_entidad}" />
            <small class="p-error" v-if="submitted && !usuario.id_entidad">El municipio es requerido</small>
          </div>

          <div class="field col-12 mb-3 lg:col-2 lg:col-3">
            <label for="name">Cargo</label>
            <InputText id="cargo" v-model.trim="usuario.cargo" required="true"
              :class="{'p-invalid': submitted && !usuario.cargo}" />
            <small class="p-error" v-if="submitted && !usuario.cargo">El cargo es requerido</small>
          </div>


          <div class="field col-12 mb-3 lg:col-2 lg:col-3">
            <label for="name">Perfil Usuario</label>
            <!-- <Dropdown
              id="rol"
              v-model.trim="usuario.id_rol"
              :options="filteredRoles"
              optionLabel="nombre"
              optionValue="id"
              placeholder="Elegir..."
              :class="{'p-invalid': submitted && !usuario.id_rol}"
            /> -->
            <Dropdown 
            id="rol" 
            v-model.trim="usuario.id_rol" 
            :options="roles" 
            optionLabel="nombre" 
            optionValue="id"
            placeholder="Elegir..."
            :class="{'p-invalid': submitted && !usuario.id_rol}" />
            <small class="p-error" v-if="submitted && !usuario.id_rol">El perfil es requerido</small>
          </div>

        </div>

        <table style="width: 100%; background-color:#F8F8F8; height: 35px;">
          <tr>
            <td>
              <B>&nbsp;&nbsp;DOCUMENTO DE RESPALDO</B>
            </td>
          </tr>
        </table>
        <br>
        <div class="file-upload-container">
          <label class="file-upload-label">
            <input
              type="file"
              @change="handleFileChange"
              accept="application/pdf"
              :class="{'p-invalid': submitted && !usuario.documento}"
              aria-label="Seleccionar archivo"
            />
          </label>
          <small class="p-error" v-if="submitted && !usuario.documento">El archivo PDF es requerido</small>
          <div v-if="pdfUrl" class="pdf-preview">
            <iframe :src="pdfUrl" frameborder="0"></iframe>
          </div>
        </div>
          
        <!-- {{ usuario }} -->
        <template #footer>
          <Button label="Cancelar" icon="pi pi-times" @click="closeModal" class="p-button-text" />
          <Button label="Guardar" icon="pi pi-check" @click="guardarUsuario" />
        </template>
      </Dialog>

      <!-- Eliminar un registro-->
      <Dialog v-model:visible="deleteProductDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
        <div class="confirmation-content">
          <h4><i class="pi pi-exclamation-triangle mr-2" style="font-size: 2rem" /><b>¿Está seguro de borrar el registro?</b></h4><br>
            <span v-if="usuario"><b style="color: #4E5FBB;">{{usuario.nombres+" "+usuario.primer_apellido}}</b></span><br>
            <span>¡Si no lo está puede cancelar la accíón!</span>
        </div>
        <template #footer>
          <Button label="Cancelar" icon="pi pi-times" class="p-button-text"   @click="deleteProductDialog = false" />
          <Button label="Si, borrar registro" icon="pi pi-check" @click="deleteProduct"/>
        </template>
      </Dialog>
      <!-- Eliminar varios registro-->
      <Dialog v-model:visible="deleteProductsDialog" :style="{ width: '450px' }" header="Confirm" :modal="true">
        <div class="confirmation-content">
          <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
          <span v-if="product">Are you sure you want to delete the selected products?</span>
        </div>
        <template #footer>
          <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductsDialog = false" />
          <Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteSelectedProducts" />
        </template>
      </Dialog>

    </div>

     <!--Formulario de registro de informacion nacional-->
     <div class="floatlabel-demo col-12 md:col-12">
      <Dialog header="REGISTRO DE USUARIO" v-model:visible="displayModal2" :breakpoints="{'800px': '450px'}"
        class="p-fluid" :style="{width: '45%'}" maximizable :modal="true">
        <InlineMessage :style="{  border: 'solid #696cff', borderWidth: '0 0 0 6px', color: '#000000' }"
                    severity="info" class="border-primary w-full justify-content-start">
          <div class="flex align-items-center">
              <!-- <img alt="logo" src="https://primefaces.org/cdn/primevue/images/logo.svg" width="32" /> -->
              <div class="ml-2">Debe verificar que los datos seleccionados e ingresados sean correctos para registrar.<br>De lo contrario puede <span style="color:#4E5FBB"> X Cancelar</span> el registro.</div>
          </div>
        </InlineMessage>
        <br />
        <div class="grid formgrid">

          <div class="field col-12 mb-3 lg:col-2 lg:col-2">
            <label for="name">Documento</label>
            <InputText type="number" id="numero_documento" v-model.trim="usuario.numero_documento" mode="decimal"
              :useGrouping="false" required="true" autofocus
              :class="{'p-invalid': submitted && !usuario.numero_documento}" />
            <small class="p-error" v-if="submitted && !usuario.numero_documento">El Nro documento es requerido</small>
          </div>
          <div class="field col-12 mb-3 lg:col-1 lg:col-1">
            <label for="name">Compl.</label>
            <InputText id="complemento" placeholder="1B" v-model.trim="usuario.complemento" required="true" />
          </div>
          <div class="field col-12 mb-3 lg:col-2 lg:col-3">
            <label for="name">Nombres</label>
            <InputText id="nombres" v-model.trim="usuario.nombres" required
              :class="{'p-invalid': submitted && (!validarNombres() || !usuario.nombres)}" />
            <small class="p-error" v-if="submitted && (!validarNombres() || !usuario.nombres)">El nombre es requerido y solo debe tener letras</small>
          </div>

          
          <div class="field col-12 mb-3 lg:col-2 lg:col-3">
            <label for="name">Primer apellido</label>
            <InputText id="primer_apellido" v-model.trim="usuario.primer_apellido" required
              :class="{'p-invalid': submitted && (!validarApellidos() || !usuario.primer_apellido)}" />
            <small class="p-error" v-if="submitted && (!validarApellidos() || !usuario.primer_apellido)">El primer apellido es requerido y solo debe tener letras</small>
          </div>
          <div class="field col-12 mb-3 lg:col-2 lg:col-3">
            <label for="name">Segundo apellido</label>
            <InputText id="segundo_apellido" v-model.trim="usuario.segundo_apellido" required="true"
              :class="{'p-invalid': submitted && usuario.segundo_apellido && !validarApellidos()}" />
            <small class="p-error" v-if="submitted && usuario.segundo_apellido && !validarApellidos()">El apellido debe tener solo letras</small>
          </div>

        </div>
        <div class="grid formgrid">
          <div class="field col-12 mb-3 lg:col-2 lg:col-3">
            <label for="name">Género</label>
            <Dropdown id="sexos" v-model="usuario.genero" :options="listaSexos" optionLabel="sexo" optionValue="code_s"
              placeholder="Elegir..." :class="{'p-invalid': submitted && !usuario.genero}" />
            <small class="p-error" v-if="submitted && !usuario.genero">El género es requerido</small>
          </div>
          <div class="field col-12 mb-3 lg:col-2 lg:col-3">
            <label for="name">Nacionalidad</label>
            <Dropdown id="nacionalidad" v-model="usuario.nacionalidad" :options="listaNacionalidades"
              optionLabel="nacionalidad" optionValue="code_n" placeholder="Elegir..."
              :class="{'p-invalid': submitted && !usuario.nacionalidad}" />
            <small class="p-error" v-if="submitted && !usuario.nacionalidad">La nacionalidad es requerido</small>
          </div>
          <div class="field col-12 mb-3 lg:col-2 lg:col-6">
            <label for="name">Teléfono</label>
            <InputText type="number" id="celular" v-model.trim="usuario.celular" required="true"
              :class="{'p-invalid': submitted && !usuario.celular}" />
            <small class="p-error" v-if="submitted && !usuario.celular">El teléfono es requerido</small>
          </div>
        </div>

        <div class="grid formgrid">
          <div class="field col-12 mb-3 lg:col-2 lg:col-6">
            <label for="name">Correo electrónico</label>

            <InputText id="email" v-model.trim="usuario.email" v-model="v$.email.$model"
              :class="{'p-invalid':v$.email.$invalid && submitted}" :key="email" aria-describedby="email-error" />

            <span v-if="v$.email.$error && submitted">
              <span id="email-error" v-for="(error, index) of v$.email.$errors" :key="index">
                <small class="p-error">No es un correo válido</small>
              </span>
              <small class="p-error" v-if="submitted && !usuario.email">El correo electrónico es invalido</small>
            </span>
            <small v-else-if="(v$.email.$invalid && submitted) || v$.email.$pending.$response" class="p-error">El correo
              electrónico es requerido</small>

          </div>
          <div class="field col-12 mb-3 lg:col-2 lg:col-6">
            <label for="name">Dirección</label>
            <InputText id="direccion" v-model.trim="usuario.direccion" required="true"
              :class="{'p-invalid': submitted && !usuario.direccion}" />
            <small class="p-error" v-if="submitted && !usuario.direccion">La dirección es requerido</small>
          </div>
        </div>

        <table style="width: 100%; background-color:#F8F8F8; height: 35px;">
          <tr>
            <td>
              <B>&nbsp;&nbsp;DATOS COMPLEMENTARIOS</B>
            </td>
          </tr>
        </table>
        <br>
        <div class="grid formgrid">
          <div class="field col-12 mb-3 lg:col-2 lg:col-3">
            <label for="name">Departamento</label>
            <Dropdown id="id_departamento" v-model="usuario.id_departamento" :options="departamentos"
              optionLabel="nombre" optionValue="id" placeholder="Elegir..."
              :class="{'p-invalid': submitted && !usuario.id_departamento}" />
            <small class="p-error" v-if="submitted && !usuario.id_departamento">El departamento es requerido</small>
          </div>

          <div class="field col-12 mb-3 lg:col-2 lg:col-3">
            <label for="name">Entidad</label>
            <Dropdown id="id_entidad" v-model="usuario.id_entidad" :options="entidades" optionLabel="nombre"
              optionValue="id" placeholder="Elegir..." :class="{'p-invalid': submitted && !usuario.id_entidad}" />
            <small class="p-error" v-if="submitted && !usuario.id_entidad">La entidad es requerido</small>
          </div>

          <div class="field col-12 mb-3 lg:col-2 lg:col-3">
            <label for="name">Cargo</label>
            <InputText id="cargo" v-model.trim="usuario.cargo" required="true"
              :class="{'p-invalid': submitted && !usuario.cargo}" />
            <small class="p-error" v-if="submitted && !usuario.cargo">El cargo es requerido</small>
          </div>


          <div class="field col-12 mb-3 lg:col-2 lg:col-3">
            <label for="name">Perfil Usuario</label>
            <Dropdown
              id="rol"
              v-model.trim="usuario.id_rol"
              :options="filteredRolesgt"
              optionLabel="nombre"
              optionValue="id"
              placeholder="Elegir..."
              :class="{'p-invalid': submitted && !usuario.id_rol}"
            />
            <small class="p-error" v-if="submitted && !usuario.id_rol">El perfil es requerido</small>
          </div>

        </div>

        <table style="width: 100%; background-color:#F8F8F8; height: 35px;">
          <tr>
            <td>
              <B>&nbsp;&nbsp;DOCUMENTO DE RESPALDO</B>
            </td>
          </tr>
        </table>
        <br>
        <div class="file-upload-container">
          <label class="file-upload-label">
            <input
              type="file"
              @change="handleFileChange"
              accept="application/pdf"
              :class="{'p-invalid': submitted && !usuario.documento}"
              aria-label="Seleccionar archivo"
            />
          </label>
          <small class="p-error" v-if="submitted && !usuario.documento">El archivo PDF es requerido</small>
          <div v-if="pdfUrl" class="pdf-preview">
            <iframe :src="pdfUrl" frameborder="0"></iframe>
          </div>
        </div>
          
        <!-- {{ usuario }} -->
        <template #footer>
          <Button label="Cancelar" icon="pi pi-times" @click="closeModal" class="p-button-text" />
          <Button label="Guardar" icon="pi pi-check" @click="guardarUsuario" />
        </template>
      </Dialog>

      <!-- Eliminar un registro-->
      <Dialog v-model:visible="deleteProductDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
        <div class="confirmation-content">
          <h4><i class="pi pi-exclamation-triangle mr-2" style="font-size: 2rem" /><b>¿Está seguro de borrar el registro?</b></h4><br>
            <span v-if="usuario"><b style="color: #4E5FBB;">{{usuario.nombres+" "+usuario.primer_apellido}}</b></span><br>
            <span>¡Si no lo está puede cancelar la accíón!</span>
        </div>
        <template #footer>
          <Button label="Cancelar" icon="pi pi-times" class="p-button-text"   @click="deleteProductDialog = false" />
          <Button label="Si, borrar registro" icon="pi pi-check" @click="deleteProduct"/>
        </template>
      </Dialog>
      <!-- Eliminar varios registro-->
      <Dialog v-model:visible="deleteProductsDialog" :style="{ width: '450px' }" header="Confirm" :modal="true">
        <div class="confirmation-content">
          <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
          <span v-if="product">Are you sure you want to delete the selected products?</span>
        </div>
        <template #footer>
          <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductsDialog = false" />
          <Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteSelectedProducts" />
        </template>
      </Dialog>

    </div>

    <!--Formulario de registro de informacion gobernacion-->
    <div class="floatlabel-demo col-12 md:col-12">
      <Dialog header="REGISTRO DE USUARIO TÉCNICO" v-model:visible="displayModal3" :breakpoints="{'800px': '450px'}"
        class="p-fluid" :style="{width: '45%'}" maximizable :modal="true">
        <InlineMessage :style="{  border: 'solid #696cff', borderWidth: '0 0 0 6px', color: '#000000' }"
                    severity="info" class="border-primary w-full justify-content-start">
          <div class="flex align-items-center">
              <!-- <img alt="logo" src="https://primefaces.org/cdn/primevue/images/logo.svg" width="32" /> -->
              <div class="ml-2">Debe verificar que los datos seleccionados e ingresados sean correctos para registrar.<br>De lo contrario puede <span style="color:#4E5FBB"> X Cancelar</span> el registro.</div>
          </div>
        </InlineMessage>
        <br />
        <div class="grid formgrid">

          <div class="field col-12 mb-3 lg:col-2 lg:col-2">
            <label for="name">Documento</label>
            <InputText type="number" id="numero_documento" v-model.trim="usuario.numero_documento" mode="decimal"
              :useGrouping="false" required="true" autofocus
              :class="{'p-invalid': submitted && !usuario.numero_documento}" />
            <small class="p-error" v-if="submitted && !usuario.numero_documento">El Nro documento es requerido</small>
          </div>
          <div class="field col-12 mb-3 lg:col-1 lg:col-1">
            <label for="name">Compl.</label>
            <InputText id="complemento" placeholder="1B" v-model.trim="usuario.complemento" required="true" />
          </div>

          <div class="field col-12 mb-3 lg:col-2 lg:col-3">
            <label for="name">Nombres</label>
            <InputText id="nombres" v-model.trim="usuario.nombres" required
              :class="{'p-invalid': submitted && (!validarNombres() || !usuario.nombres)}" />
            <small class="p-error" v-if="submitted && (!validarNombres() || !usuario.nombres)">El nombre es requerido y solo debe tener letras</small>
          </div>

          <div class="field col-12 mb-3 lg:col-2 lg:col-3">
            <label for="name">Primer apellido</label>
            <InputText id="primer_apellido" v-model.trim="usuario.primer_apellido" required
              :class="{'p-invalid': submitted && (!validarApellidos() || !usuario.primer_apellido)}" />
            <small class="p-error" v-if="submitted && (!validarApellidos() || !usuario.primer_apellido)">El primer apellido es requerido y solo debe tener letras</small>
          </div>
          <div class="field col-12 mb-3 lg:col-2 lg:col-3">
            <label for="name">Segundo apellido</label>
            <InputText id="segundo_apellido" v-model.trim="usuario.segundo_apellido" required="true"
              :class="{'p-invalid': submitted && usuario.segundo_apellido && !validarApellidos()}" />
            <small class="p-error" v-if="submitted && usuario.segundo_apellido && !validarApellidos()">El apellido debe tener solo letras</small>
          </div>

        </div>
        <div class="grid formgrid">
          <div class="field col-12 mb-3 lg:col-2 lg:col-3">
            <label for="name">Género</label>
            <Dropdown id="sexos" v-model="usuario.genero" :options="listaSexos" optionLabel="sexo" optionValue="code_s"
              placeholder="Elegir..." :class="{'p-invalid': submitted && !usuario.genero}" />
            <small class="p-error" v-if="submitted && !usuario.genero">El género es requerido</small>
          </div>
          <div class="field col-12 mb-3 lg:col-2 lg:col-3">
            <label for="name">Nacionalidad</label>
            <Dropdown id="nacionalidad" v-model="usuario.nacionalidad" :options="listaNacionalidades"
              optionLabel="nacionalidad" optionValue="code_n" placeholder="Elegir..."
              :class="{'p-invalid': submitted && !usuario.nacionalidad}" />
            <small class="p-error" v-if="submitted && !usuario.nacionalidad">La nacionalidad es requerido</small>
          </div>
          <div class="field col-12 mb-3 lg:col-2 lg:col-6">
            <label for="name">Teléfono</label>
            <InputText type="number" id="celular" v-model.trim="usuario.celular" required="true"
              :class="{'p-invalid': submitted && !usuario.celular}" />
            <small class="p-error" v-if="submitted && !usuario.celular">El teléfono es requerido</small>
          </div>
        </div>

        <div class="grid formgrid">
          <div class="field col-12 mb-3 lg:col-2 lg:col-6">
            <label for="name">Correo electrónico</label>

            <InputText id="email" v-model.trim="usuario.email" v-model="v$.email.$model"
              :class="{'p-invalid':v$.email.$invalid && submitted}" :key="email" aria-describedby="email-error" />

            <span v-if="v$.email.$error && submitted">
              <span id="email-error" v-for="(error, index) of v$.email.$errors" :key="index">
                <small class="p-error">No es un correo válido</small>
              </span>
              <small class="p-error" v-if="submitted && !usuario.email">El correo electrónico es invalido</small>
            </span>
            <small v-else-if="(v$.email.$invalid && submitted) || v$.email.$pending.$response" class="p-error">El correo
              electrónico es requerido</small>

          </div>
          <div class="field col-12 mb-3 lg:col-2 lg:col-6">
            <label for="name">Dirección</label>
            <InputText id="direccion" v-model.trim="usuario.direccion" required="true"
              :class="{'p-invalid': submitted && !usuario.direccion}" />
            <small class="p-error" v-if="submitted && !usuario.direccion">La dirección es requerido</small>
          </div>
        </div>

        <table style="width: 100%; background-color:#F8F8F8; height: 35px;">
          <tr>
            <td>
              <B>&nbsp;&nbsp;DATOS COMPLEMENTARIOS</B>
            </td>
          </tr>
        </table>
        <br>
        <div class="grid formgrid">
          <div class="field col-12 mb-3 lg:col-2 lg:col-3">
            <label for="name">Departamento</label>
            <InputText id="direccion" :value="getNombreDepartamento(id_departamento)"  readonly/>
          </div>

          <div class="field col-12 mb-3 lg:col-2 lg:col-3">
            <label for="name">Entidad</label>
            <Dropdown id="id_entidad" v-model="usuario.id_entidad" :options="entidades" optionLabel="nombre"
              optionValue="id" placeholder="Elegir..." :class="{'p-invalid': submitted && !usuario.id_entidad}" />
            <small class="p-error" v-if="submitted && !usuario.id_entidad">El municipio es requerido</small>
          </div>

          <div class="field col-12 mb-3 lg:col-2 lg:col-3">
            <label for="name">Cargo</label>
            <InputText id="cargo" v-model.trim="usuario.cargo" required="true"
              :class="{'p-invalid': submitted && !usuario.cargo}" />
            <small class="p-error" v-if="submitted && !usuario.cargo">El cargo es requerido</small>
          </div>

          
          <div class="field col-12 mb-3 lg:col-2 lg:col-3">
            <label for="name">Perfil Usuario</label>
            <Dropdown
              id="rol"
              v-model.trim="usuario.id_rol"
              :options="filteredRoles"
              optionLabel="nombre"
              optionValue="id"
              placeholder="Elegir..."
              :class="{'p-invalid': submitted && !usuario.id_rol}"
            />
            
            <small class="p-error" v-if="submitted && !usuario.id_rol">El perfil es requerido</small>
          </div>

        </div>

        <table style="width: 100%; background-color:#F8F8F8; height: 35px;">
          <tr>
            <td>
              <B>&nbsp;&nbsp;DOCUMENTO DE RESPALDO</B>
            </td>
          </tr>
        </table>
        <br>
        <div class="file-upload-container">
          <label class="file-upload-label">
            <input
              type="file"
              @change="handleFileChange"
              accept="application/pdf"
              :class="{'p-invalid': submitted && !usuario.documento}"
              aria-label="Seleccionar archivo"
            />
          </label>
          <small class="p-error" v-if="submitted && !usuario.documento">El archivo PDF es requerido</small>
          <div v-if="pdfUrl" class="pdf-preview">
            <iframe :src="pdfUrl" frameborder="0"></iframe>
          </div>
        </div>
          
        <!-- {{ usuario }} -->
        <template #footer>
          <Button label="Cancelar" icon="pi pi-times" @click="closeModal" class="p-button-text" />
          <Button label="Guardar" icon="pi pi-check" @click="guardarUsuario" />
        </template>
      </Dialog>

      <!-- Eliminar un registro-->
      <Dialog v-model:visible="deleteProductDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
        <div class="confirmation-content">
          <h4><i class="pi pi-exclamation-triangle mr-2" style="font-size: 2rem" /><b>¿Está seguro de borrar el registro?</b></h4><br>
            <span v-if="usuario"><b style="color: #4E5FBB;">{{usuario.nombres+" "+usuario.primer_apellido}}</b></span><br>
            <span>¡Si no lo está puede cancelar la accíón!</span>
        </div>
        <template #footer>
          <Button label="Cancelar" icon="pi pi-times" class="p-button-text"   @click="deleteProductDialog = false" />
          <Button label="Si, borrar registro" icon="pi pi-check" @click="deleteProduct"/>
        </template>
      </Dialog>
      <!-- Eliminar varios registro-->
      <Dialog v-model:visible="deleteProductsDialog" :style="{ width: '450px' }" header="Confirm" :modal="true">
        <div class="confirmation-content">
          <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
          <span v-if="product">Are you sure you want to delete the selected products?</span>
        </div>
        <template #footer>
          <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductsDialog = false" />
          <Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteSelectedProducts" />
        </template>
      </Dialog>

    </div>

    <!--Para el despliegue del mensaje de salida-->
    <Toast />
  </div>
</template>

<script>
import { reactive,onMounted, ref } from "vue";
import * as usuarioService from '@/service/UsuarioService.js'
import * as departamentoSevice from '@/service/DepartamentoService.js'
import * as entidadSevice from '@/service/EntidadService.js'
import * as rolSevice from '@/service/RolService.js'

import { useToast } from "primevue/usetoast";
import InputMask from "primevue/inputmask";

import { FilterMatchMode } from "primevue/api";

// Importacones para realizar las validaciones
import { email, helpers, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import jsPDF from 'jspdf';
import 'jspdf-autotable';

export default {
  data() {
    return {
      usuario: {
        id_rol: null, // Valor seleccionado del Dropdown
        pdfBase64: null,
        nombres: '',
        primer_apellido: '',
        segundo_apellido: '',
      },
      submitted: false,
      roles: [], // Todas las opciones de roles
      pdfUrl: null,
    };
  },
  computed: {
    filteredRoles() {
      return this.roles.filter(role => role.id === 4);
    },
    filteredRolesgt() {
      return this.roles.filter(role => role.id === 3 || role.id === 4);
    },
    getNombreDepartamento() {
      return (id) => {
        switch (id) {
          case 1:
            return 'CHUQUISACA';
          case 2:
            return 'LA PAZ';
          case 3:
            return 'COCHABAMBA';
          case 4:
            return 'ORURO';
          case 5:
            return 'POTOSÍ';
          case 6:
            return 'TARIJA';
          case 7:
            return 'SANTA CRUZ';
          case 8:
            return 'BENI';
          case 9:
            return 'PANDO';
          default:
            return 'N/A';
        }
      };
    }
  },

  mounted() {
    const rolss = JSON.parse(localStorage.getItem("role")) || [];
    this.roles = rolss;

    if (this.roles.length > 0) {
      this.usuario.id_rol = this.roles[0].id; // Establecer el valor predeterminado al primer rol
      this.submitted = false;
    } else {
      this.submitted = true;
    }
  },
  setup() {
    const user = JSON.parse(localStorage.getItem("user")) || {};
    const id_departamento = ref(user.id_departamento || "");

    const rolss = JSON.parse(localStorage.getItem("role")) || [];
    const primerRol = ref(rolss[0] || {});
    const idPrimerRol = ref(primerRol.value.id || null);
    const nombrePrimerRol = ref(primerRol.value.nombre || "");
    // console.log(idPrimerRol.value)

    const usuarios = ref();
    const usuario = ref({});
    const displayModal1 = ref(false);
    const displayModal2 = ref(false);
    const displayModal3 = ref(false);
    const submitted = ref(false);
    const toast = useToast();
    const errores = ref({});
    const departamentos = ref([]);
    const entidades = ref([]);
    const roles = ref([]);

    onMounted(() => {
      listarUsuarios();
      getDepartamentos();
      getEntidad();
      getRol();
     });

     const getDepartamentos = async () => {
      const {data} = await departamentoSevice.index('','','');
      departamentos.value=data.departamentos.data;
    }

    const getEntidad = async () => {
     const {data} = await entidadSevice.index('','','');
     entidades.value=data.entidades.data;
    }

    const getRol = async () => {
      const {data} = await rolSevice.index();
      roles.value=data.rol.data;
    }
 
    const listaSexos = ref([
      {sexo: 'MASCULINO', code_s: 'M'},
      {sexo: 'FEMENINO', code_s: 'F'}
    ]);

    const listaNacionalidades = ref([
      {nacionalidad: 'BOLIVIANA', code_n: 'B'},
      {nacionalidad: 'EXTRANJERO', code_n: 'E'}
    ]);

   // Validaion del formulario
    // Definimos los variables a validar
    const state = reactive( {
      email: "",
    });

    // Tipo de validacion a realizar del dato
    const rules = {
      email: { required, email },
    };
    // Seccion de la validacion
    const v$ = useVuelidate(rules, state);

    const dt = ref();
    const deleteProductDialog = ref(false);
    const deleteProductsDialog = ref(false);
    const loading = ref(false);
    const totalRecords = ref(0);
    const selectedProducts = ref();
    const lazyParams = ref({});
   
    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    // METODOS
    const onPage = (event) => {
      lazyParams.value = event;
      listarUsuarios();
    };

    const onFilter = () => {
      lazyParams.value.filters = filters.value;
      listarUsuarios();
    };

    const buscar = () => {
      console.log(filters.value.global.value);
      listarUsuarios();
    };

    // Listado de las entidades creadas
    const listarUsuarios = async () => {
      try {
        loading.value = true;
        let rows = lazyParams.value.rows;
        let q = filters.value.global.value == null?'':filters.value.global.value;
        let page = (lazyParams.value.page == null)?0:lazyParams.value.page;

        const { data } = await usuarioService.index(page + 1, rows, q);
        usuarios.value = data.users.data;
        totalRecords.value = data.users.total;
        loading.value = false;

      } catch (error) {
        console.log(error.response.data);
      }
    };

    // Guardar la entidad 
    const guardarUsuario = async (isFormValid) => {
      submitted.value = true;
      if(
      !usuario.value.numero_documento || 
      !usuario.value.documento ||
      !validarTexto(usuario.value.nombres) ||
      !validarTexto(usuario.value.primer_apellido) ||
      !usuario.value.genero ||
      !usuario.value.nacionalidad ||
      !usuario.value.email ||
      !usuario.value.id_departamento ||
      !usuario.value.celular ||
      !usuario.value.direccion ||
      !usuario.value.cargo ||
      !usuario.value.id_rol){ 
      
        return;

      }
    
      if (!isFormValid) { 
        return;
      }

      // Validar si hay números en nombres o apellidos
      if (contieneNumeros(usuario.value.nombres) || contieneNumeros(usuario.value.primer_apellido) || contieneNumeros(usuario.value.segundo_apellido)) {
        toast.add({
          severity: "error",
          summary: "ERROR DE VALIDACIÓN",
          detail: "El nombre o los apellidos no deben contener números.",
          life: 5000,
        });
        return;
      }
    
      try {
        if (usuario.value.id) {
          await usuarioService.update(usuario.value, usuario.value.id);
          listarUsuarios();
          closeModal();
          toast.add({
            severity: "success",
            summary: "REGISTRO",
            detail: "Usuario actualizado correctamente",
            life: 5000,
          });
        } else {
          await usuarioService.store(usuario.value);

          listarUsuarios();

          closeModal();
          toast.add({
            severity: "success",
            summary: "REGISTRO",
            detail: "Usuario Registrado correctamente",
            life: 5000,
          });
        }
      } catch (error) {
    
        errores.value = error.response.data.errores;
        toast.add({
          severity: "error",
          summary: "ERROR DE REGISTRO",
          detail: "Error de registro del usuario, el correo electrónico ya existe.",
          life: 5000,
        });
      }
      
    };
    function validarTexto(texto) {
      const regex = /^[A-Za-zñÑáéíóúÁÉÍÓÚ ]+$/; // Expresión regular que permite letras y espacios
      return regex.test(texto);
    }
    function contieneNumeros(texto) {
      const regex = /\d/; // Expresión regular que busca números
      return regex.test(texto);
    }
    // METODOS DE LA PLANTILLA
    // Abrir un modal
    const openModal1 = () => {
      usuario.value = {};
      displayModal1.value = true;
      submitted.value = false;
      usuario.value = {
        id_departamento: id_departamento,
      };
      displayModal1.value = true;
      submitted.value = false;
      
    };

    // Abrir un modal
    const openModal2 = () => {
      usuario.value = {};
      displayModal2.value = true;
      submitted.value = false;
      usuario.value = {
        id_departamento: id_departamento,
      };
      displayModal2.value = true;
      submitted.value = false;
      
    };

    // Abrir un modal
    const openModal3 = () => {
      usuario.value = {};
      displayModal3.value = true;
      submitted.value = false;
      usuario.value = {
        id_departamento: id_departamento,
      };
      displayModal3.value = true;
      submitted.value = false;
      
    };

    // Abrir un modal
    const editarDialogModal1 = (datos) => {
      usuario.value = datos;
      displayModal1.value = true;
    };

    // Abrir un modal
    const editarDialogModal2 = (datos) => {
      usuario.value = datos;
      displayModal2.value = true;
    };

    // Abrir un modal
    const editarDialogModal3 = (datos) => {
      usuario.value = datos;
      displayModal3.value = true;
    };

    // Eliminar un producto
    const confirmDeleteProduct = (datos) => {
      usuario.value = datos;
      deleteProductDialog.value = true;
    };

    const deleteProduct = async () => {
      try {
        await usuarioService.destroy(usuario.value.id);
        listarUsuarios();
        toast.add({
          severity: "error",
          summary: "REGISTRO",
          detail: "Usuario Eliminado con éxito",
          life: 5000,
        });
        deleteProductDialog.value = false;
        usuario.value = {}; 
      } catch (error) {
        //console.log(error.response.data);
        errores.value = error.response.data.errores;
        toast.add({
          severity: "error",
          summary: "REGISTRO",
          detail: "No se eliminó el registro",
          life: 5000,
        });
      }
              
    };

    const confirmDeleteSelected = () => {
      deleteProductsDialog.value = true;
    }; 
    const deleteSelectedProducts = () => {
      products.value = products.value.filter(val => !selectedProducts.value.includes(val));
      deleteProductsDialog.value = false;
      selectedProducts.value = null;
      toast.add({severity:'success', summary: 'Successful', detail: 'Usuarios Eliminadas', life: 3000});
    };  
        
    // Cerrar un modal
    const closeModal = () => {
      
      displayModal1.value = false;
      displayModal2.value = false;
      displayModal3.value = false;
      usuario.value = {
        documento: ''
      };
      submitted.value = false;
      listarUsuarios();
      state.codigo = '';
      state.nombre = '';
      state.id_macro_region = '';
      state.documento = '';
    };

    // DEVOLVER REGISTROS
    return {
      rolss,
      idPrimerRol,
      nombrePrimerRol,
      v$,
      listaSexos, 
      listaNacionalidades,
      departamentos,
      entidades,
      roles,
      state,
      rules,
      buscar,
      onFilter,
      onPage,
      loading,
      totalRecords,
      dt,
      deleteProductDialog,
      deleteProductsDialog,
      confirmDeleteProduct,
      deleteProduct,
      confirmDeleteSelected,
      deleteSelectedProducts,
      selectedProducts,
      filters,
      usuarios,
      usuario,
      submitted,
      displayModal1,
      displayModal2,
      displayModal3,
      openModal1,
      openModal2,
      openModal3,
      closeModal,
      guardarUsuario,
      editarDialogModal1,
      editarDialogModal2,
      editarDialogModal3,
      errores,
      id_departamento,
    };
  },
  methods: {
    validarApellidos() {
      const regex = /^[A-Za-zñÑáéíóúÁÉÍÓÚ ]+$/

      if (!regex.test(this.usuario.primer_apellido)) {
        return false; // La validación falla si el primer apellido contiene caracteres no permitidos.
      }

      if (this.usuario.segundo_apellido && !regex.test(this.usuario.segundo_apellido)) {
        return false; // La validación falla si el segundo apellido (si se proporciona) contiene caracteres no permitidos.
      }

      return true;
    },
    validarNombres() {
      const nombre = this.usuario.nombres;
      const regex = /^[A-Za-zñÑáéíóúÁÉÍÓÚ ]+$/;

      if (!regex.test(nombre)) {
        return false; // La validación falla si el campo de nombres contiene caracteres no permitidos.
      }

      return true;
    },
		exportCSV() {
			this.$refs.dt.exportCSV();
		},
    async exportToPDF() {
      const doc = new jsPDF();

      // Título del PDF
      doc.setFontSize(20);
      doc.text('Gestión de Usuarios', 10, 10);

      // Obtén los datos de la tabla
      const tableData = this.usuarios.map(item => [item.numero_documento, item.nombres, item.primer_apellido+' '+item.segundo_apellido, item.email, item.rol]);

      // Encabezados de la tabla
      const headers = [['Cédula', 'Nombres', 'Apellidos', 'E-mail', 'Tipo Usuario']];

      // Crea la tabla en el PDF
      doc.autoTable({
        head: headers,
        body: tableData,
        startY: 20,
      });

      // Guarda el PDF
      doc.save('usuarios.pdf');
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.loadPDF(file);
        this.convertToBase64(file);
      }
    },
    loadPDF(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.pdfUrl = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    async convertToBase64(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64Data = e.target.result.split(',')[1];
        this.usuario.documento = base64Data;
      };
      reader.readAsDataURL(file);
    },
	},
  components: { InputMask },
};
</script>

<style scoped>
file-upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.file-upload-label {
  display: inline-block;
  padding: 10px 20px;
  background-color: #4e5fbb;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}
.file-upload-label:hover {
  background-color: #324298;
}
.file-upload-text {
  display: inline-block;
}
.pdf-preview {
  width: 100%;
  height: 100%;
  padding-top: 1%;
}
</style>