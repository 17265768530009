import {http, urlBase} from "./Http" 

// LISTAR
export const index = (page=1, rows=10, q=null) => {
  return http().get(`${urlBase}/subtipo?page=${page}&rows=${rows}&q=${q}`);
}

// GUARDAR
export const store = (datos) => {
  return http().post(`${urlBase}/subtipo`,datos);
}

// MOSTRAR
export const show = (id) => {
  return http().get(`${urlBase}/subtipo/${id}`);
}

// MOSTRAR
export const showTipsubt = (id) => {
  return http().get(`${urlBase}/tipsubt/${id}`);
}

// MODIFICAR
export const update = (datos, id) => {
  return http().put(`${urlBase}/subtipo/${id}`, datos);
}

// ELIMINAR
export const destroy = (id) => {
  return http().delete(`${urlBase}/subtipo/${id}`);
}

// MOSTRAR SUBTIPO POR id_tipo=40
export const showSubTipo = (id) => {
  return http().get(`${urlBase}/tiposub/${id}`);
}
