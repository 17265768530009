<template>
	<div class="layout-topbar shadow-4" style="height: 80px;">
		<div class="layout-topbar-left">
			<router-link class="layout-topbar-logo" to="/">
				<img id="logo" :src="require('@/../public/layout/images/mdpyep/irts.png')"
					alt="ultima-layout" style="height: 80px;">
			</router-link>

			<a class="layout-menu-button shadow-6 p-ripple" @click="onMenuButtonClick($event)" v-ripple>
				<i class="pi pi-chevron-right"></i>
			</a>

			<a class="layout-topbar-mobile-button p-ripple" @click="onTopbarMobileButtonClick($event)" v-ripple>
				<i class="pi pi-ellipsis-v fs-large"></i>
			</a>
		</div>

		<div class="layout-topbar-right" :class="{ 'layout-topbar-mobile-active': mobileTopbarActive }">
			<div class="layout-topbar-actions-left">
				<MegaMenu :model="items" class="layout-megamenu"></MegaMenu>
			</div>

			<div class="layout-topbar-actions-right">
				<ul class="layout-topbar-items">

					<li class="layout-topbar-item app">
						<div class="depto-container">

							<div @mouseover="showPopup('CHUQUISACA')" @mouseout="hidePopup()"
								class="popup-trigger">
								<img v-if="depto === 1 && idPrimerRol!==2"
									alt="DEPARTAMENTO DE: CHUQUISACA"
									src="@/../public/layout/images/mdpyep/banderas/chuquisaca.jpg"
									class="bandera" />
								
								<img v-else-if="depto === 1 && idPrimerRol===1"
									alt="DEPARTAMENTO DE: CHUQUISACA"
									src="@/../public/layout/images/mdpyep/banderas/nacional.png"
									class="bandera" />
							</div>
							<div class="popup" v-if="popupVisible">DEPARTAMENTO DE : CHUQUISACA</div>

							<div @mouseover="showPopup('LA PAZ')" @mouseout="hidePopup()"
								class="popup-trigger">
								<img v-if="depto === 2 && idPrimerRol!==2"
									alt="DEPARTAMENTO DE: LA PAZ"
									src="@/../public/layout/images/mdpyep/banderas/la_paz.jpg"
									class="bandera" />
								
								<img v-else-if="depto === 2 && idPrimerRol===2"
									alt="DEPARTAMENTO DE: LA PAZ"
									src="@/../public/layout/images/mdpyep/banderas/nacional.png"
									class="bandera" />
							</div>
							<div class="popup" v-if="popupVisible">DEPARTAMENTO DE : LA PAZ</div>

							<div @mouseover="showPopup('COCHABAMBA')" @mouseout="hidePopup()"
								class="popup-trigger">
								<img v-if="depto === 3 && idPrimerRol !== 2"
									alt="DEPARTAMENTO DE: COCHABAMBA"
									src="@/../public/layout/images/mdpyep/banderas/cochabamba.jpg"
									class="bandera" />

								<img v-if="depto === 3 && idPrimerRol === 2"
									alt="DEPARTAMENTO DE: COCHABAMBA"
									src="@/../public/layout/images/mdpyep/banderas/nacional.png"
									class="bandera" />
							</div>
							<div class="popup" v-if="popupVisible">DEPARTAMENTO DE : COCHABAMBA</div>

							<div @mouseover="showPopup('ORURO')" @mouseout="hidePopup()"
								class="popup-trigger">
								<img v-if="depto === 4  && idPrimerRol !== 2"
									alt="DEPARTAMENTO DE: ORURO"
									src="@/../public/layout/images/mdpyep/banderas/oruro.jpg"
									class="bandera" />

									<img v-if="depto === 4  && idPrimerRol === 2"
									alt="DEPARTAMENTO DE: ORURO"
									src="@/../public/layout/images/mdpyep/banderas/nacional.png"
									class="bandera" />
							</div>
							<div class="popup" v-if="popupVisible">DEPARTAMENTO DE : ORURO</div>

							<div @mouseover="showPopup('POTOSÍ')" @mouseout="hidePopup()"
								class="popup-trigger">
								<img v-if="depto === 5  && idPrimerRol !== 2"
									alt="DEPARTAMENTO DE: POTOSÍ"
									src="@/../public/layout/images/mdpyep/banderas/potosi.jpg"
									class="bandera" />
								
									<img v-if="depto === 5  && idPrimerRol === 2"
									alt="DEPARTAMENTO DE: POTOSÍ"
									src="@/../public/layout/images/mdpyep/banderas/nacional.png"
									class="bandera" />
							</div>
							<div class="popup" v-if="popupVisible">DEPARTAMENTO DE : POTOSÍ</div>

							<div @mouseover="showPopup('TARIJA')" @mouseout="hidePopup()"
								class="popup-trigger">
								<img v-if="depto === 6 && idPrimerRol !== 2"
									alt="DEPARTAMENTO DE: TARIJA"
									src="@/../public/layout/images/mdpyep/banderas/tarija.jpg"
									class="bandera" />

									<img v-if="depto === 6 && idPrimerRol === 2"
									alt="DEPARTAMENTO DE: TARIJA"
									src="@/../public/layout/images/mdpyep/banderas/nacional.png"
									class="bandera" />
							</div>
							<div class="popup" v-if="popupVisible">DEPARTAMENTO DE : TARIJA</div>

							<div @mouseover="showPopup('SANTA CRUZ')" @mouseout="hidePopup()"
								class="popup-trigger">
								<img v-if="depto === 7 && idPrimerRol !== 2"
									alt="DEPARTAMENTO DE: SANTA CRUZ"
									src="@/../public/layout/images/mdpyep/banderas/santa_cruz.jpg"
									class="bandera" />

									<img v-if="depto === 7 && idPrimerRol === 2"
									alt="DEPARTAMENTO DE: SANTA CRUZ"
									src="@/../public/layout/images/mdpyep/banderas/nacional.png"
									class="bandera" />
							</div>
							<div class="popup" v-if="popupVisible">DEPARTAMENTO DE : SANTA CRUZ</div>

							<div @mouseover="showPopup('BENI')" @mouseout="hidePopup()"
								class="popup-trigger">
								<img v-if="depto === 8 && idPrimerRol !== 2"
									alt="DEPARTAMENTO DE: BENI"
									src="@/../public/layout/images/mdpyep/banderas/beni.jpg"
									class="bandera" />

									<img v-if="depto === 8 && idPrimerRol === 2"
									alt="DEPARTAMENTO DE: BENI"
									src="@/../public/layout/images/mdpyep/banderas/nacional.png"
									class="bandera" />
							</div>
							<div class="popup" v-if="popupVisible">DEPARTAMENTO DE : BENI</div>

							<div @mouseover="showPopup('PANDO')" @mouseout="hidePopup()"
								class="popup-trigger">
								<img v-if="depto === 9 && idPrimerRol !== 2"
									alt="DEPARTAMENTO DE: PANDO"
									src="@/../public/layout/images/mdpyep/banderas/pando.jpg"
									class="bandera" />

									<img v-if="depto === 9 && idPrimerRol === 2"
									alt="DEPARTAMENTO DE: PANDO"
									src="@/../public/layout/images/mdpyep/banderas/nacional.png"
									class="bandera" />

							</div>
							<div class="popup" v-if="popupVisible">DEPARTAMENTO DE : PANDO</div>

						</div>
					</li>

					<li v-if="!$route.meta.noLayout" class="layout-topbar-item">

						<div>
							&nbsp;<b>Usuario:</b> {{ nombres }} {{ primerApellido }} {{ segundoApellido }}<br>
							<!-- &nbsp;<b>Correo:</b> {{ email }}<br> -->
							&nbsp;<b>Rol:</b> {{ nombrePrimerRol }}
						</div>

						<a class="layout-topbar-action flex flex-row justify-content-center align-items-center px-2 rounded-circle p-ripple"
							@click="onTopbarItemClick($event, 'profile')" v-ripple>
							<img v-if="genero === 'M'" alt="avatar"
								src="@/../public/layout/images/mdpyep/avatar/avatar-hombre.jpg" class="avt" />
							<img v-else-if="genero === 'F'" alt="avatar"
								src="@/../public/layout/images/mdpyep/avatar/avatar-mujer.jpg" class="avt" />

						</a>

						<transition name="layout-topbar-action-panel">
							<ul class="layout-topbar-action-panel shadow-6" v-show="activeTopbarItem === 'profile'">

								<li class="layout-topbar-action-item">
									<a class="flex flex-row align-items-center p-ripple" v-ripple href="/perfil">
										<i class="pi pi-cog" :class="{ 'mr-2': !isRTL, 'ml-2': isRTL }"></i>
										<span>Información personal</span>
									</a>
								</li>

								<li class="layout-topbar-action-item">
									<a class="flex flex-row align-items-center p-ripple" @click="visible = true">
										<i class="pi pi-power-off" :class="{ 'mr-2': !isRTL, 'ml-2': isRTL }"></i>
										<span>Cerrar Sesión </span>
									</a>
								</li>

								<Dialog v-model:visible="visible" modal header="Confirmar para Cerrar su Sesión"
									:style="{ width: '30%' }" position="top">

									<p>Para cerrar sesión de forma segura, haga clic en <span style="color:#4f5daa">Cerrar
											Sesión</span> y proteja sus datos personales y su privacidad.<br>
										<br><b>¡Gracias por usar nuestro sistema de Inventariación de Recursos
											Turísticos!</b>
									</p>
									<template #footer>
										<Button label="Cancelar" class="p-button-danger" icon="pi pi-times"
											@click="visible = false" text />
										<Button label="Cerrar Sesión" icon="pi pi-sign-out" @click="performLogout" />
									</template>
								</Dialog>

							</ul>
						</transition>
					</li>
				</ul>

			</div>
		</div>

	</div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

import { logout } from "@/service/LogoutService.js";
import { ref, onMounted } from "vue";

export default {
	setup() {

		// Datos de usuario
		const usuario = JSON.parse(localStorage.getItem("user")) || {};

		const email = ref(usuario.email || "");
		const nombres = ref(usuario.nombres || "");
		const primerApellido = ref(usuario.primer_apellido || "");
		const segundoApellido = ref(usuario.segundo_apellido || "");
		const genero = ref(usuario.genero || "");
		const depto = ref(usuario.id_departamento || "");

		// Datos de usuario rol
		const roles = JSON.parse(localStorage.getItem("role")) || [];

		const primerRol = ref(roles[0] || {});
		const idPrimerRol = ref(primerRol.value.id || null);
		const nombrePrimerRol = ref(primerRol.value.nombre || "");
		const descripcionPrimerRol = ref(primerRol.value.descripcion || "");

		// const store = useStore();
		// const isAuthenticated = computed(() => store.getters.isAuthenticated);
		// const usuario = computed(() => store.state.auth.user);

		const visible = ref(false);

		return {
			// isAuthenticated,
			usuario,
			roles,

			email,
			nombres,
			primerApellido,
			segundoApellido,
			genero,
			depto,

			primerRol,
			idPrimerRol,
			nombrePrimerRol,
			descripcionPrimerRol,
			visible,
		};
	},
	emits: ['menubutton-click', 'topbar-menubutton-click', 'topbaritem-click', 'rightpanel-button-click', 'topbar-mobileactive', 'search-click', 'search-toggle'],
	data() {
		return {
			searchText: '',
			items: [],
			popupVisible: false,
			popupText: "",
			departamentos: [
				{ nombre: "CHUQUISACA", numero: 1 },
				{ nombre: "LA PAZ", numero: 2 },
				{ nombre: "COCHABAMBA", numero: 3 },
				{ nombre: "ORURO", numero: 4 },
				{ nombre: "POTOSI", numero: 5 },
				{ nombre: "TARIJA", numero: 6 },
				{ nombre: "SANTA CRUZ", numero: 7 },
				{ nombre: "BENI", numero: 8 },
				{ nombre: "PANDO", numero: 9 },
			],
		}
	},
	props: {
		horizontal: {
			type: Boolean,
			default: false
		},
		topbarMenuActive: {
			type: Boolean,
			default: false
		},
		activeTopbarItem: String,
		mobileTopbarActive: Boolean,
		searchActive: Boolean
	},
	methods: {
		showPopup(nombreDepartamento) {
			this.popupText = nombreDepartamento;
			this.popupVisible = true;

		},

		hidePopup() {
			this.popupVisible = false;
		},
		onSearchContainerClick(event) {
			this.$emit("search-click", event);
		},
		changeSearchActive(event) {
			this.$emit('search-toggle', event);
		},
		onMenuButtonClick(event) {
			this.$emit('menubutton-click', event);
		},
		onTopbarMenuButtonClick(event) {
			this.$emit('topbar-menubutton-click', event);
		},
		onTopbarItemClick(event, item) {
			if (item === 'search') {
				this.$emit('search-toggle', event);
			}

			this.$emit('topbaritem-click', { originalEvent: event, item: item });
		},
		onTopbarMobileButtonClick(event) {
			this.$emit('topbar-mobileactive', event);
		},
		onRightPanelButtonClick(event) {
			this.$emit('rightpanel-button-click', event);
		},
		onSearchKeydown(event) {
			if (event.keyCode == 13) {
				this.$emit('search-toggle', event);
			}
		},
		onEnter() {
			if (this.$refs.searchInput) {
				this.$refs.searchInput.$el.focus();
			}
		},
		async performLogout() {
			try {
				// console.log('Logout clicked');
				await logout(); // Llamar a la función de cierre de sesión desde el servicio
				this.$store.dispatch('cerrarSesion'); // Dispatch de la acción para limpiar el estado de sesión en Vuex

				// Borrar las variables del localStorage
				localStorage.removeItem("role");
				localStorage.removeItem("menu");
				localStorage.removeItem("user");
				localStorage.removeItem("token");
				localStorage.removeItem("savedLatitude");
				localStorage.removeItem("savedLongitude");

				this.$router.push('/login'); // Redirigir a la página de inicio de sesión
			} catch (error) {
				console.error('Error al cerrar sesión:', error);
			}
		},
	},
	computed: {
		topbarItemsClass() {
			return ['topbar-items animated fadeInDown', {
				'topbar-items-visible': this.topbarMenuActive
			}];
		},
		isRTL() {
			return this.$appState.RTL;
		}
	}
}
</script>
<style scoped>
.avt {
	border-radius: 20px;
	width: 35px;
	height: 35px;
}

.bandera {
	padding: 0px;
	margin: 0px;
	width: 60px !important;
	height: 50px !important;
	border: none;
}

depto-container {
	position: relative;
	display: inline-block;
}

.popup-trigger {
	cursor: pointer;
}

.popup {
	position: absolute;
	color: white;
	top: 100%;
	left: 0;
	display: none;
	background-color: #1565C0;
	border: 1px solid #ccc;
	padding: 10px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	width: 290px;
}

.popup-trigger:hover+.popup {
	display: block;
}</style>