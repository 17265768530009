import {http, urlBase} from "./Http"
import CryptoJS from 'crypto-js';

const encryptionKey = 'M1n1st3r10d3d3s4rr0ll0pr0duct1v0y3c0n0m14plur4l';

// LISTAR
export const index = (page=1, rows=10, q=null) => {
  return http().get(`${urlBase}/ppirta?page=${page}&rows=${rows}&q=${q}`);
}

// GUARDAR
export const store = (datos) => {
  return http().post(`${urlBase}/ppirta`,datos);
}

// MOSTRAR
export const show = (id) => {

  const encryptedId = id.match(/.{1,2}/g).map(byte => String.fromCharCode(parseInt(byte, 16))).join('');
  const sanitizedId = CryptoJS.AES.decrypt(encryptedId, encryptionKey).toString(CryptoJS.enc.Utf8);
  const originalId = sanitizedId.replace(/_/g, '/');

  return http().get(`${urlBase}/ppirta/${originalId}`);
}

export const showU = (id) => {
  return http().get(`${urlBase}/ppirta/${id}`);
}
// // EDITAR
export const editar = (id, datos) => {
  return http().put(`${urlBase}/infppirta/${id}`, datos);
}

// MODIFICAR
export const update = (datos, id) => {
  return http().put(`${urlBase}/ppirta/${id}`, datos);
}

// ELIMINAR
export const destroy = (id) => {
  return http().delete(`${urlBase}/ppirta/${id}`);
}


// Servicio de fotogramas
// SUBIR FOTOGRAMAS
export const subirFotogramas = (formData) => {
  return http().post(`${urlBase}/fotogramappirta`, formData);
}

// MOSTRAR FOTOGRAMAS
export const listF = (id) => {
  return http().get(`${urlBase}/listfotograma/${id}`);
}

// MODIFICAR
export const updateF = (datos, id) => {
  return http().put(`${urlBase}/fotogramappirta/${id}`, datos);
}

// ELIMINAR
export const destroyF = (id) => {
  return http().delete(`${urlBase}/fotogramappirta/${id}`);
}

// FAVORITO
export const favorito = (idp, idf) => {

  return http().put(`${urlBase}/storefavorito?id_ppirta=${idp}&id_fotograma=${idf}`);
}


// DERIVAR
export const derivarPpirta = (id) => {
  return http().post(`${urlBase}/derivarppirta/${id}`);
}

// APROBAR
export const aprobarPpirta = (id) => {
  return http().post(`${urlBase}/derivarppirta/${id}`);
}