<template>
    <div class="card">
      <table cellpadding="5px" style="width: 100%; background-color: #f8f8f8; height: 45px">
        <tr>
          <td style="font-size: 20px">
            <B>GESTIÓN DE RUTAS</B>
          </td>
        </tr>
      </table>
      <br />
  
  
      <!--Tabla de displiegue de la informacion-->
      <DataTable ref="dt" :value="tramos" v-model:selection="selectedProducts" dataKey="id"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros" responsiveLayout="scroll">
  
        <!-- <Column field="id" header="ID" :sortable="true" style="min-width: 2rem"></Column> -->
        <Column field="tramo" header="TRAMO" :sortable="false" style="min-width: 8rem"></Column>
        <Column field='tipo' header="TIPO" :sortable="false" style="min-width: 8rem"></Column>
        <Column field='distancia_km' header="DISTANCIA KM" :sortable="false" style="min-width: 8rem"></Column>
        <Column field='distancia_hr' header="DISTANCIA HRS" :sortable="false" style="min-width: 8rem"></Column>
       <Column field="s_si" header="SEÑALIZACIÓN V/T SI" :sortable="false" style="min-width: 8rem"></Column>
        <Column field="s_no" header="SEÑALIZACION V/T NO" :sortable="false" style="min-width: 8rem"></Column>
        <Column field="estado" header="ESTADO" :sortable="false" style="min-width: 6rem" :body="customStateColumn" />
        
      </DataTable>
  
      <Toast />
    </div>
  </template>
  
  <script>
  import { reactive,onMounted, ref } from "vue";
  import * as usuarioService from '@/service/UsuarioService.js'
  import * as tramoService from '@/service/TramoService.js'
  import * as departamentoSevice from '@/service/DepartamentoService.js'
  import * as entidadSevice from '@/service/EntidadService.js'
  import * as rolSevice from '@/service/RolService.js'
  
  import { useToast } from "primevue/usetoast";
  import InputMask from "primevue/inputmask";
  
  import { FilterMatchMode } from "primevue/api";
  
  // Importacones para realizar las validaciones
  import { email, helpers, required } from "@vuelidate/validators";
  import { useVuelidate } from "@vuelidate/core";
  import { useRoute } from 'vue-router';
  import CryptoJS from 'crypto-js';
  
  
  export default {
    data() {
      return {
        data: [
          { estado: "Regular" },
          { estado: "Bueno" },
          { estado: "Malo" },
        ],
      };
    },
    methods: {
      customStateColumn(rowData) {
        let stateClass = "";
        switch (rowData.estado) {
          case "Regular":
            stateClass = "regular-state";
            break;
          case "Bueno":
            stateClass = "good-state";
            break;
          case "Malo":
            stateClass = "bad-state";
            break;
        }
  
        return {
          class: stateClass,
          render: (h) => {
            return h('span', rowData.estado);
          },
        };
      },
    },
    setup() {

      const encryptionKey = 'M1n1st3r10d3d3s4rr0ll0pr0duct1v0y3c0n0m14plur4l';

      const rolss = JSON.parse(localStorage.getItem("role")) || [];
      const primerRol = ref(rolss[0] || {});
      const idPrimerRol = ref(primerRol.value.id || null);
  
      const tramo_editar = ref({});
      const tramo = ref({});
      const tramos = ref();
      const route = useRoute();
      const usuarios = ref();
      const usuario = ref({});
      const displayModal = ref(false);
      const submitted = ref(false);
      const toast = useToast();
      const errores = ref({});
      const departamentos = ref([]);
      const entidades = ref([]);
      const roles = ref([]);
      const categorias = ref([]);
      const via = ref([]);
      const estado = ref([]);
      const viavt = ref([
        {
          'id' : 'V',
          'nombre' : 'V'
        },
        {
          'id' : 'T',
          'nombre' : 'T'
        },
        {
          'id' : 'V/T',
          'nombre' : 'V/T'
        },
        {
          'id' : 'Ninguna',
          'nombre' : 'Ninguna'
        }
      ]);
  
      onMounted(() => {
        listarUsuarios();
        listarTramo();
        getDepartamentos();
        getEntidad();
        getRol();
        listarVia();
        listarEstado();
       });
  
       const getDepartamentos = async () => {
        const {data} = await departamentoSevice.index('','','');
        departamentos.value=data.departamentos.data;
      }
  
      const getEntidad = async () => {
       const {data} = await entidadSevice.index('','','');
       entidades.value=data.entidades.data;
      }
  
      const getRol = async () => {
         const {data} = await rolSevice.index();
         roles.value=data.rol.data;
      }
   
      const listaSexos = ref([
            {sexo: 'MASCULINO', code_s: 'M'},
                      {sexo: 'FEMENINO', code_s: 'F'}
          ]);
  
      const listaNacionalidades = ref([
            {nacionalidad: 'BOLIVIANA', code_n: '0'},
                      {nacionalidad: 'EXTRANJERO', code_n: '1'}
          ]);
  
     // Validaion del formulario
      // Definimos los variables a validar
      const state = reactive( {
        email: "",
      });
  
      // Tipo de validacion a realizar del dato
      const rules = {
        email: { required, email },
      };
      // Seccion de la validacion
      const v$ = useVuelidate(rules, state);
  
  
      const dt = ref();
      const deleteProductDialog = ref(false);
      const deleteProductsDialog = ref(false);
      const loading = ref(false);
      const totalRecords = ref(0);
      const selectedProducts = ref();
      const lazyParams = ref({});
  
      const filters = ref({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      });
  
      // METODOS
      const onPage = (event) => {
        lazyParams.value = event;
        listarUsuarios();
      };
  
      const onFilter = () => {
        lazyParams.value.filters = filters.value;
        listarUsuarios();
      };
  
      const buscar = () => {
        
        listarUsuarios();
      };
  
  
  
      // Listado de las entidades creadas
      const listarUsuarios = async () => {
        try {
          loading.value = true;
          let rows = lazyParams.value.rows;
          let q = filters.value.global.value == null?'':filters.value.global.value;
          let page = (lazyParams.value.page == null)?0:lazyParams.value.page;
  
          const { data } = await usuarioService.index(page + 1, rows, q);
          usuarios.value = data.users.data;
          totalRecords.value = data.users.total;
          loading.value = false;
  
        } catch (error) {
          
        }
      };
  
      const listarTramo = async () => {
        loading.value = true;
        let rows = lazyParams.value.rows;
        let q = filters.value.global.value == null?'':filters.value.global.value;
        let page = (lazyParams.value.page == null)?0:lazyParams.value.page;

        const encryptedId = route.params.id.match(/.{1,2}/g).map(byte => String.fromCharCode(parseInt(byte, 16))).join('');
        const sanitizedId = CryptoJS.AES.decrypt(encryptedId, encryptionKey).toString(CryptoJS.enc.Utf8);
        const originalId = sanitizedId.replace(/_/g, '/');
  
        const { data } = await tramoService.index(originalId, page + 1, rows, q);
     
        tramos.value = data.rutas.data;
        totalRecords.value = data.rutas.total;
        loading.value = false;
      };
  
  
      // METODOS DE LA PLANTILLA
      // Abrir un modal
      const openModal = () => {
        usuario.value = {};
        displayModal.value = true;
        submitted.value = false;
      };
      // Abrir un modal
      const editarDialogModal = (datos) => {
        tramo.value = datos;
        displayModal.value = true;
      };
  
      // Eliminar un producto
      const confirmDeleteProduct = (datos) => {
        tramo.value = datos;
            deleteProductDialog.value = true;
          };
  
      const deleteProduct = async () => {
        try {
                await tramoService.destroy(tramo.value.id);
                listarTramo();
                toast.add({
                  severity: "error",
                  summary: "REGISTRO",
                  detail: "Tramo Eliminado",
                  life: 5000,
                });
                deleteProductDialog.value = false;
                tramo.value = {}; 
        } catch (error) {
              
              errores.value = error.response.data.errores;
              toast.add({
                severity: "error",
                summary: "REGISTRO",
                detail: "No se elimino el registro",
                life: 5000,
              });
        }
                
          };
  
      const confirmDeleteSelected = () => {
              deleteProductsDialog.value = true;
          }; 
      const deleteSelectedProducts = () => {
              products.value = products.value.filter(val => !selectedProducts.value.includes(val));
              deleteProductsDialog.value = false;
              selectedProducts.value = null;
              toast.add({severity:'success', summary: 'Successful', detail: 'Categorias Eliminadas', life: 3000});
          };  
          
      // Cerrar un modal
      const closeModal = () => {
        displayModal.value = false;
        tramo.value = {};
        submitted.value = false;
        listarTramo();
        state.codigo = '';
        state.nombre = '';
        state.id_macro_region = '';
      };
  
      const listarVia =  async () => {
       const {data} = await tramoService.show()
        via.value = data.tipovia
      }
  
      const listarEstado =  async () => {
       const {data} = await tramoService.listest()
        estado.value = data.estados
      }
      const formatCurrency = (value) => {
        if (value)
          return value.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          });
        return;
      };
  
      // DEVOLVER REGISTROS
      return {
        rolss,
        idPrimerRol,
        categorias,
        via,
        estado,
        viavt,
        listarVia,
        listarEstado,
        v$,
        listaSexos, 
        listaNacionalidades,
        tramo_editar,
        departamentos,
        entidades,
        roles,
        state,
        rules,
        buscar,
        onFilter,
        onPage,
        loading,
        totalRecords,
        formatCurrency,
        dt,
        deleteProductDialog,
        deleteProductsDialog,
        confirmDeleteProduct,
        deleteProduct,
        confirmDeleteSelected,
        deleteSelectedProducts,
        selectedProducts,
        filters,
        tramo,
        tramos,
        usuarios,
        usuario,
        submitted,
        displayModal,
        openModal,
        closeModal,
        editarDialogModal,
        errores,
      };
    },
    components: { InputMask },
  };
  </script>
  <style scoped>
    .regular-state {
      background-color: lightgreen;
    }
    
    .good-state {
      background-color: green;
      color: white;
    }
    
    .bad-state {
      background-color: red;
      color: white;
    }
    </style>