<template>
  <div>
    <br>
    <h5>INFORMACIÓN DEL LUGAR TURÍSTICO</h5>
      <div class="p-fluid">
        <div class="grid formgrid">
          <div class="field col-12 mb-12 lg:col-12 lg:col-12">
            <label for="descripcion">Descripción del recurso Turístico</label>
            <Textarea style="background-color: #FCFDFE;" inputId="textarea" rows="5" cols="15" v-model="ppirta.descripcion"></Textarea>
          </div>
          <h5>CAPACIDAD DE CARGA </h5>
          <div class="p-fluid">
            <div class="grid formgrid">

              <div class="field col-12 mb-3 lg:col-1 lg:col-4">
                <label for="ccf">CCF</label>
                <InputText  type="text" id="ccf" placeholder="00000" v-model="ppirta.cc_ccf" autofocus/>
              </div>

              <div class="field col-12 mb-3 lg:col-1 lg:col-4">
                <label for="ccr">CCR</label>
                <InputText type="text" id="ccr" placeholder="00000" v-model="ppirta.cc_ccr" autofocus/>
              </div>

              <div class="field col-12 mb-3 lg:col-2 lg:col-4">
                <label for="cce">CCE</label>
                <InputText  type="text" id="cce" placeholder="00000"  v-model="ppirta.cc_cce" autofocus/>
              </div>

              <div class="field col-12 mb-12 lg:col-12 lg:col-12">
                <h5> VINCULACIÓN DEL RECURSO</h5>
              </div>

              <div class="field mb-1 lg:col-1 lg:col-1">
                <label> <b>C</b></label>
              </div>

              <div class="field-checkbox mb-2 lg:col-2 lg:col-2">
                <Dropdown id="municipio" :options="viavt" v-model="ppirta.v_c"
                      optionLabel="nombre" optionValue="id" placeholder="Elegir...">
                </Dropdown>
              </div>

              <div class="field mb-1 lg:col-1 lg:col-1">
                <label> <b>CI</b></label>
              </div>

              <div class="field-checkbox mb-2 lg:col-2 lg:col-2">
                <Dropdown id="municipio" :options="viavt" v-model="ppirta.v_ci"
                      optionLabel="nombre" optionValue="id" placeholder="Elegir...">
                </Dropdown>
              </div>

              <div class="field mb-1 lg:col-1 lg:col-1">
                <label> <b>R</b></label>
              </div>

              <div class="field-checkbox mb-2 lg:col-2 lg:col-2">
                <Dropdown id="municipio" :options="viavt" v-model="ppirta.v_r"
                      optionLabel="nombre" optionValue="id" placeholder="Elegir...">
                </Dropdown>
              </div>

              <div class="field mb-1 lg:col-1 lg:col-1">
                <label> <b>N</b></label>
              </div>

              <div class="field-checkbox mb-2 lg:col-2 lg:col-2">
                <Dropdown id="municipio" :options="viavt" v-model="ppirta.v_b"
                      optionLabel="nombre" optionValue="id" placeholder="Elegir...">
                </Dropdown>
              </div>

            </div>
          </div>
          <div class="field col-12 mb-3 lg:col-2 lg:col-4">
            <label for="macroregion">Servicio de emergencia</label><br>
          </div>

          <div class="field-checkbox col-12 mb-3 lg:col-2 lg:col-3">
            <RadioButton  name="city" value="SI" v-model="ppirta.emergencia" />
            <label for="city1">SI</label>
          </div>

          <div class="field-checkbox col-12 mb-3 lg:col-2 lg:col-3">
            <RadioButton label="No" name="city" value="NO" v-model="ppirta.emergencia" />
            <label for="city1">NO</label>
          </div>

          <div class="field col-12 mb-3 lg:col-1 lg:col-4">
            <label for="macroregion">Población más cercana</label>
            <InputText type="text" id="g_z" placeholder="" v-model="ppirta.poblacion_cercana"/>
          </div>

          <div class="field col-12 mb-3 lg:col-1 lg:col-4">
            <label for="macroregion">Distancía del atractivo en Km</label>
            <InputText type="text" id="g_z" placeholder="" v-model="ppirta.distancia_km"/>
          </div>

          <div class="field col-12 mb-3 lg:col-1 lg:col-4">
            <label for="macroregion">Frecuencia de T</label>
            <InputText type="text" id="g_z" placeholder="" v-model="ppirta.frecuencia"/>
          </div>

          <div class="field mb-3 lg:col-2 lg:col-3">
            <label for="city2">Estado de Conservación</label>
          </div>

          <div class="field-checkbox mb-3 lg:col-2 lg:col-3">
            <RadioButton  name="city" value="B" v-model="ppirta.conservacion" />
              <label for="city1">BUENO</label>
          </div>

          <div class="field-checkbox mb-3 lg:col-2 lg:col-3">
            <RadioButton  name="city" value="R" v-model="ppirta.conservacion" />
            <label for="city1">REGULAR</label>
          </div>

          <div class="field-checkbox mb-3 lg:col-2 lg:col-3">
            <RadioButton  name="city" value="M" v-model="ppirta.conservacion" />
            <label for="city1">MALO</label>
          </div>

          <div class="field col-12 mb-12 lg:col-12 lg:col-12">
            <h5> CAUSAS DE DETERIORO</h5>
          </div>

          <div class="field-checkbox mb-3 lg:col-2 lg:col-3">
            <label for="city1">NATURALES</label>
          </div>

          <div class="field-checkbox mb-2 lg:col-2 lg:col-2">
            <Dropdown id="municipio" :options="viav" v-model="ppirta.deteoro_antopica"
              optionLabel="nombre" optionValue="id" placeholder="Elegir..."></Dropdown>
          </div>

          <div class="field-checkbox mb-3 lg:col-2 lg:col-4">
            <label for="city1">ANTRÓPICAS</label>
          </div>

          <div class="field-checkbox mb-2 lg:col-2 lg:col-2">
            <Dropdown id="municipio" :options="viav" v-model="ppirta.deteoro_natural"
              optionLabel="nombre" optionValue="id" placeholder="Elegir...">
            </Dropdown>
          </div>

          <div class="field col-12 mb-3 lg:col-2 lg:col-4">
            <label for="macroregion">Estado de protección</label>
          </div>

          <div class="field-checkbox mb-3 lg:col-2 lg:col-4">
            <RadioButton  name="city" value="A" v-model="ppirta.proteccion" />
            <label for="city1">PROTEGIDO</label>
          </div>

          <div class="field-checkbox mb-3 lg:col-2 lg:col-4">
            <RadioButton  name="city" value="I" v-model="ppirta.proteccion" />
            <label for="city1">NO PROTEGIDO</label>
          </div>

          <div class="field col-12 mb-12 lg:col-12 lg:col-12">
            <h5>CONDICIONES CLIMÁTICAS</h5>
          </div>

          <div class="field col-12 mb-3 lg:col-1 lg:col-4">
            <label for="macroregion">Bioclima</label>
            <InputText type="text" id="g_z" placeholder="Puna baja del altiplano sur árido" v-model="ppirta.bioclima" autofocus/>
          </div>

          <div class="field col-12 mb-3 lg:col-1 lg:col-4">
            <label for="macroregion">Temperatura °C</label>
            <InputText type="text" id="g_z" placeholder="Min 0 ºC – Max 20 ºC/12ºC%" v-model="ppirta.temperatura" autofocus/>
          </div>

          <div class="field col-12 mb-3 lg:col-2 lg:col-4">
            <label for="macroregion">Humedad Relativa %</label>
            <InputText  type="text" id="g_z" placeholder="25 %  – 55 % todo el año"  v-model="ppirta.humedad" autofocus/>
          </div>

          <div class="field col-12 mb-12 lg:col-12 lg:col-12">
            <label for="macroregion">Restricciones Turísticas</label>
            <Textarea style="background-color: #FCFDFE;" inputId="textarea" rows="3" cols="15" v-model="ppirta.restriccion"></Textarea>
          </div>
          
          <div class="field col-12 mb-3 lg:col-4 lg:col-4">
            <Button label="Guardar datos" icon="pi pi-check" @click="guardarInformacion" />
          </div>
          
        </div>
      </div>
  </div>
  <Toast />
</template>

<script>
import { onMounted, ref } from 'vue'
import * as ppirtaService from '@/service/PlantillaService.js'
import * as categoriaService from '@/service/CategoriaService.js'
import { useToast } from "primevue/usetoast";
import { useRoute } from 'vue-router';
import CryptoJS from 'crypto-js';


export default {
  props:{
  src: {
    type: Array,
    required: true
  }
},

  setup(){

    const encryptionKey = 'M1n1st3r10d3d3s4rr0ll0pr0duct1v0y3c0n0m14plur4l';

    const categorias = ref([]);
    const option = ref({});
    const categoria = ref({});
    const displayModal = ref(false);
    const submitted = ref(false);
    const toast = useToast();
    const errores = ref({});
    const informacion = ref({ });
    const ppirta = ref({});
    const route = useRoute()
    const viavt = ref([
      {
        'id' : 't',
        'nombre' : 'Si'
      },
      {
        'id' : 'f',
        'nombre' : 'No'
      }
    ]);
    const viav = ref([
      {
        'id' : 'A',
        'nombre' : 'Si'
      },
      {
        'id' : 'I',
        'nombre' : 'No'
      }
    ]);
    const descripcion = "asdsa"
    onMounted( () => {
      one_ppirtas()
      listarCategorias()
    })

    // METODOS
    const one_ppirtas =  async () => {

      //console.log(route.params.id)
     const {data} = await ppirtaService.show(route.params.id)
     ppirta.value = data.ppirta
    //  console.log('hgkjgkjh',ppirta.value)
    }
    const listarCategorias =  async () => {
     const {data} = await categoriaService.index()
      categorias.value = data
    }

    const guardarInformacion = async() => {
      try {
            if(ppirta.value.descripcion){
              if(ppirta.value.cc_ccf) {
                if(ppirta.value.cc_ccr) {
                  if(ppirta.value.cc_cce) {
                    if(ppirta.value.poblacion_cercana) {
                      if(ppirta.value.distancia_km) {
                        if(ppirta.value.emergencia) {
                          if(ppirta.value.frecuencia) {
                            if(ppirta.value.bioclima) {
                              if(ppirta.value.temperatura) {
                                if(ppirta.value.humedad) {
                                  if(ppirta.value.conservacion) {
                                    if(ppirta.value.proteccion) {
                                      if(ppirta.value.restriccion) {
                                        
                                        const encryptedId = route.params.id.match(/.{1,2}/g).map(byte => String.fromCharCode(parseInt(byte, 16))).join('');
                                        const sanitizedId = CryptoJS.AES.decrypt(encryptedId, encryptionKey).toString(CryptoJS.enc.Utf8);
                                        const originalId = sanitizedId.replace(/_/g, '/');

                                        ppirta.value.cc_ccm=0;

                                        await ppirtaService.editar(originalId, ppirta.value)

                                        toast.add({severity:'success', summary: 'La Información fue actualizada exitosamente', detail:'Se registro correctamente', life: 3000});
                                      } else {
                                        toast.add({ severity: "error", summary: "La restricción es requerida", detail: "Error de registro del formulario", life: 5000, });
                                      }
                                    } else {
                                      toast.add({ severity: "error", summary: "La protección es requerida", detail: "Error de registro del formulario", life: 5000, });
                                    }
                                  } else {
                                    toast.add({ severity: "error", summary: "La conservación es requerida", detail: "Error de registro del formulario", life: 5000, });
                                  }
                                } else {
                                  toast.add({ severity: "error", summary: "La humedad es requerida", detail: "Error de registro del formulario", life: 5000, });
                                }
                              } else {
                                toast.add({ severity: "error", summary: "La temperatura es requerida", detail: "Error de registro del formulario", life: 5000, });
                              }
                            } else {
                              toast.add({ severity: "error", summary: "El bioclima es requerido", detail: "Error de registro del formulario", life: 5000, });
                            }
                          } else {
                            toast.add({ severity: "error", summary: "La frecuencia es requerida", detail: "Error de registro del formulario", life: 5000, });
                          }
                        } else {
                          toast.add({ severity: "error", summary: "La emergencia es requerida", detail: "Error de registro del formulario", life: 5000, });
                        }
                      } else {
                        toast.add({ severity: "error", summary: "La distancía en km es requerida", detail: "Error de registro del formulario", life: 5000, });
                      }
                    } else {
                      toast.add({ severity: "error", summary: "La población cercana es requerida", detail: "Error de registro del formulario", life: 5000, });
                    }
                  } else {
                    toast.add({ severity: "error", summary: "cce es requerida", detail: "Error de registro del formulario", life: 5000, });
                  }
                } else {
                  toast.add({ severity: "error", summary: "ccr es requerida", detail: "Error de registro del formulario", life: 5000, });
                }
              } else {
                toast.add({ severity: "error", summary: "ccf es requerida", detail: "Error de registro del formulario", life: 5000, });
              }
            } else {
              toast.add({ severity: "error", summary: "La descripción es requerida", detail: "Error de registro del formulario", life: 5000, });
            }
      } catch (error) {
        errores.value = error.response.data.errores
        toast.add({severity:'error', summary: 'ERROR DE REGISTRO', detail:'Error de registro', life: 3000});
      }

    }

    const guardarCategoria = async () => {
      try {
            await ppirtaService.editar(route.params.id, ppirta.value)
            toast.add({severity:'success', summary: 'REGISTRO', detail:'Se registro correctamente', life: 3000});

      } catch (error) {
        errores.value = error.response.data.errores
        toast.add({severity:'error', summary: 'ERROR DE REGISTRO', detail:'Error de registro', life: 3000});
      }
    }

    // METODOS DE LA PLANTILLA

     const multiselectValue = null;
     const multiselectValues = ([
					{name: 'Australia', code: 'AU'},
					{name: 'Brazil', code: 'BR'},
					{name: 'China', code: 'CN'},
					{name: 'Egypt', code: 'EG'},
					{name: 'France', code: 'FR'},
					{name: 'Germany', code: 'DE'},
					{name: 'India', code: 'IN'},
					{name: 'Japan', code: 'JP'},
					{name: 'Spain', code: 'ES'},
					{name: 'United States', code: 'US'}
				]);

    // Abrir un modal
      const openModal = () => {
        categoria.value={}
        displayModal.value= true
      }

    // Abrir un modal
    const editarDialogModal = (datos) => {
        categoria.value = datos
        displayModal.value= true
      }

    // Cerrar un modal
      const closeModal = () => {
        displayModal.value= false
        categoria.value={}
      }
      // DEVOLVER REGISTROS
      return {option, informacion, viavt, viav,   multiselectValue, multiselectValues, categoria, submitted, displayModal,guardarInformacion, openModal, closeModal, guardarCategoria, editarDialogModal, errores, ppirta, descripcion}

  },


}
</script>
