<template>
    <table style="width: 100%; background-color:#F0F1DC; height: 40px; font-size: 20px;">
      <tr >
        <td>
          <B>&nbsp;&nbsp;INVENTARIO DE RECURSOS TURÍSTICOS</B>
        </td>
      </tr>
    </table>
    <br>
    <div class="grid p-fluid">
    <div class="col-12 md:col-4">
      <div v-if="isLoading" class="loading-spinner card">
        <i class="pi pi-spinner pi-spin"></i> Cargando datos...
      </div>
      <div v-else class="card">
        <table  style="width: 100%; background-color:#F8F8F8; height: 35px;">
          <tr >
            <td>
              <B>&nbsp;&nbsp;INFORMACIÓN TURÍSTICA</B>
            </td>
            <td style="width: 140px;">
            <div>
              <Button label="Volver atrás" icon="pi pi-arrow-left" class="p-button-info" @click="goBack" />
            </div>
          </td>
          </tr>
        </table>

        <br>
        <div>
          <!-- {{ ppirta }} -->
          <ul class="widget-list">
            <li class="flex py-2">
                <div class="flex align-items-center">
                    <i class="pi pi-star-fill p-2 lightblue-bgcolor white-color widget-list-item-radius"></i>
                    <div :class="{'ml-2': !isRTL, 'mr-2': isRTL}">
                        <div><b>RECURSO ATRACTIVO</b> | {{ppirta.nombre_atractivo}}</div>
                        <small class="muted-text"><b>CÓDIGO</b> | {{ppirta.codigo}}</small>                            
                    </div>
                </div>
            </li>
  
            <li class="flex py-2">
                <div class="flex align-items-center">
                    <i class="pi pi-file-excel p-2 yellow-bgcolor text-color widget-list-item-radius"></i>
                    <div :class="{'ml-2': !isRTL, 'mr-2': isRTL}">
                      <div><b>CATEGORÍA</b> | {{ppirta.categoria}}</div>
                        <small class="muted-text"><b>CÓDIGO</b> | {{ppirta.codigo_categoria}}</small>                            
                    </div>
                </div>
            </li>
  
            <li class="flex py-2">
                <div class="flex align-items-center">
                    <i class="pi pi-image p-2 teal-bgcolor white-color widget-list-item-radius"></i>
                    <div :class="{'ml-2': !isRTL, 'mr-2': isRTL}">
                      <div><b>TIPO CATEGORÍA</b> | {{ppirta.tipo}}</div>
                        <small class="muted-text"><b>CÓDIGO</b> | {{ppirta.codigo_tipo}}</small>                            
                    </div>
                </div>
            </li>
  
            <li class="flex py-2">
                <div class="flex align-items-center">
                    <i class="pi pi-file p-2 bluegrey-bgcolor white-color widget-list-item-radius"></i>
                    <div :class="{'ml-2': !isRTL, 'mr-2': isRTL}">
                      <div><b>SUB TIPO CATEGORÍA</b> | {{ppirta.subtipo}}</div>
                        <small class="muted-text"><b>CÓDIGO</b> | {{ppirta.codigo_subtipo}}</small>                            
                    </div>
                </div>
            </li>
            <li class="flex py-2">
                <div class="flex align-items-center">
                    <i class="pi pi-file p-2 bluegrey-bgcolor white-color widget-list-item-radius"></i>
                    <div :class="{'ml-2': !isRTL, 'mr-2': isRTL}">
                      <div><b>JERARQUÍA</b> | {{ppirta.jerarquia}}</div>
                        <!-- <small class="muted-text"><b>CODIGO</b> | 1.1.1</small>                             -->
                    </div>
                </div>
            </li>

        </ul> 
      </div>
    
       <br>
       <table style="width: 100%; background-color:#F8F8F8; height: 35px;">
          <tr >
            <td>
              <B>&nbsp;&nbsp;GEOLOCALIZACIÓN</B>
            </td>
          </tr>
        </table>
        <br>
        <table style="width: 100%;">
          <tr style="text-align: center;background-color:#D7ECEE; height: 35px;" >
            <td><B>X</B></td>
            <td><B>Y</B></td>
            <td><B>Z</B></td>
            <td><B>PROYECCIÓN</B></td>
            <td><B>DATUM</B></td>
          </tr>
          <tr style="text-align: center;" >
            <td>{{ppirta.g_x}}</td>
            <td>{{ppirta.g_y}}</td>
            <td>{{ppirta.g_z}}</td>
            <td>{{ppirta.sigla_proyeccion}}</td>
            <td>{{ppirta.sigla_datum}}</td>
          </tr>
        </table>
        <br>
  
       <div class="overlay-header" style=" height: 20rem">
        <Prueba1View
          id="mapx"
          title="Ubicación"
          :zoom="campos.zoom"
          :lat="campos.lat"
          :long="campos.long"
          @newPosition="updatePosition($event, campos)">
        </Prueba1View>
      </div>
      <br><br><br><br><br><br><br><br><br><br><br>
       <table style="width: 100%; background-color:#F8F8F8; height: 35px;">
          <tr >
            <td>
              <B>&nbsp;&nbsp;UBICACIÓN TERRITORIAL</B>
            </td>
          </tr>
        </table>
        <br>
        <div>
          <ul class="widget-list">
              <li class="flex py-2">
                  <div class="flex align-items-center">
                      <i class="pi pi-star-fill p-2 lightblue-bgcolor white-color widget-list-item-radius"></i>
                      <div :class="{'ml-2': !isRTL, 'mr-2': isRTL}">
                          <div><b>MACRO REGIÓN</b> | {{ppirta.macroregion}} <b>CÓDIGO</b> | {{ppirta.codigo_macroregion}}</div>
                          <div><b>REGIÓN</b> | {{ppirta.region}} <b>CÓDIGO</b> | {{ppirta.codigo_region}}</div>
                      </div>
                  </div>
              </li>
  
              <li class="flex py-2">
                  <div class="flex align-items-center">
                      <i class="pi pi-file-excel p-2 yellow-bgcolor text-color widget-list-item-radius"></i>
                      <div :class="{'ml-2': !isRTL, 'mr-2': isRTL}">
                        <div><b>DEPARTAMENTO</b> | {{ppirta.departamento}} <b>- CÓDIGO</b> | {{ppirta.codigo_departamento}}</div>
                        <div><b>PROVINCIA</b> | {{ppirta.provincia}} <b>CÓDIGO</b> | {{ppirta.codigo_provincia}}</div>
                      </div>
                  </div>
              </li>
  
              <li class="flex py-2">
                  <div class="flex align-items-center">
                      <i class="pi pi-image p-2 teal-bgcolor white-color widget-list-item-radius"></i>
                      <div :class="{'ml-2': !isRTL, 'mr-2': isRTL}">
                        <div><b>MUNICIPIO</b> | {{ppirta.municipio}} <b>- CÓDIGO</b> | {{ppirta.codigo_municipio}}</div>
                        <!-- <div><b>CAPITAL</b> |  <b>CÓDIGO</b> | 021101 </div> -->
                        <div><b>COMUNIDAD</b> | {{ppirta.comunidad}}</div>
                      </div>
                  </div>
              </li>
              
          </ul> 
       </div>
      </div>
    </div>
    
    <div class="col-12 md:col-8">
          <div class="card card-w-title">
            <table style="width: 100%; background-color:#F8F8F8; height: 35px;">
          <tr >
            <td>
              <B>&nbsp;&nbsp;DETALLE DE RECURSOS TURÍSTICOS ATRACTIVOS</B>
            </td>
          </tr>
        </table>
        <br>
            <TabMenu :model="nestedRouteItems" />
            <router-view/>
          </div>
        </div>
    </div>
      
    <TabView>
      <TabPanel v-for="tab in tabs" :key="tab.title" :header="tab.title">
        <p>{{tab.content}}</p>
      </TabPanel>
    </TabView>
    
    </template>
    
    <script>
    import { onMounted, ref } from 'vue';
    import Dropdown from 'primevue/dropdown';
    import * as ppirtaService from '@/service/PlantillaService.js'
    import * as departamentoService from '@/service/DepartamentoService.js'
    import * as categoriaService from '@/service/CategoriaService.js'
    import * as tipoService from '@/service/TipoService.js'
    import * as provinciaService from '@/service/ProvinciaService.js'
    import * as municipioService from '@/service/MunicipioService.js'
    import * as macroregionService from '@/service/MacroregionService.js'
    import * as regionService from '@/service/RegionService.js'
    import * as proyeccionService from '@/service/ProyeccionService.js'
    import * as datumService from '@/service/DatumService.js'
    import { useToast } from "primevue/usetoast";
  
    
    // Importacones para realizar las validaciones
    import { email, helpers, required } from "@vuelidate/validators";
    import { useVuelidate } from "@vuelidate/core";
  
    import { useRoute } from 'vue-router'
    import Prueba1View from '@/pages/admin/turistico/Prueva1View.vue'
    import Prueba2View from '@/pages/admin/turistico/Prueba2View.vue'
    
    export default {
      data() {
      return {
        ppirta: {
          g_z: ""
        },
        error: false
      };
    },
      methods: {
        goBack() {
          localStorage.removeItem("savedLatitude");
          localStorage.removeItem("savedLongitude");
          this.$router.go(-1);
          
        },
        validateInput() {
          const inputValue = this.ppirta.g_z;
          const isValid = /^\d{1,4}$/.test(inputValue);
    
          if (isValid) {
            this.error = false;
          } else {
            this.error = true;
            this.ppirta.g_z = '';
          }
        }
      },
      props:{
        src: {
          type: Array,
          required: true
        }
      },
      components: {
        "Prueba1View": Prueba1View,
        "Prueba2View": Prueba2View,
        Dropdown,
      },
      setup(){

        const isLoading = ref(true);
        const simulateDataLoading = async () => {
          await new Promise((resolve) => setTimeout(resolve,8));
          isLoading.value = false;
        };

        const campos = ref({ });
        const categorias = ref([]);
        const categoria = ref({});
        const tipos = ref([]);
        const subtipos = ref([]);
        const provincias = ref([]);
        const municipios = ref([]);
        const comunidades = ref([]);
        const macroRegion = ref([]);
        const regiones = ref([]);
        const departamentos = ref([]);
        const ppirta = ref({});
        const displayModal = ref(false);
        const submitted = ref(false);
        const toast = useToast();
        const errores = ref({});
        const route = useRoute();
        const proyecciones = ref([]);
        const datums = ref([]);
        onMounted(async () => {
          getCategorias()
          one_ppirtas()
          getMacroregion()
          getDepartamentos()
          await cargarProyecciones();
          await cargarDatums();
          simulateDataLoading();
        })
  
        const cargarProyecciones = async () => {
          try {
            const { data } = await proyeccionService.list();
            proyecciones.value = data.proyeccion;
          } catch (error) {
            console.error('Error al cargar las proyecciones:', error);
          }
        };
  
      const cargarDatums = async () => {
        try {
          const { data } = await datumService.list();
          datums.value = data.datum;
        } catch (error) {
          console.error('Error al cargar los datums:', error);
        }
      };
    
        // METODOS
        const one_ppirtas =  async () => {
         const {data} = await ppirtaService.show(route.params.id)
         ppirta.value = data.ppirta
         //console.log(ppirta.value)
        }
        
        const getCategorias = async () => {
          const {data} = await categoriaService.index('','','');
          categorias.value=data.categorias.data;
        }
  
        const tipo = async (val) => {
          const {data} = await tipoService.indexcat(val);
          tipos.value=data.tipos;
        }
        const subTipo = async (val) => {
          const {data} = await tipoService.indexsub(val);
          subtipos.value=data.subtipos;
        }
  
        const codigoGenerado = async (val) => {
          const {data} = await tipoService.detallesub(val);
          usuario.value.codigo = data.subtipo.codigo
        }
  
        const getMacroregion = async (val) => {
          const {data} = await macroregionService.list(val);
          macroRegion.value=data.macroRegion;
        }
  
        const getRegion = async (val) => {
          const {data} = await regionService.showmacro(val);
          regiones.value=data.regiones;
        }
  
        const getDepartamentos = async () => {
          const {data} = await departamentoService.list();
          departamentos.value=data.departamentos;
        }
  
        const getProvincia = async (val) => {
          const {data} = await provinciaService.buscar(val);
          provincias.value=data.provincias;
        }
  
        const getMunicipio = async (val) => {
          const {data} = await municipioService.showPromun(val);
          municipios.value=data.municipios;
        }
  
        const guardarCategoria = async () => {
          try {
            if(categoria.value.id){
              await categoriaService.update(categoria.value, categoria.value.id)
              listarCategorias()
              closeModal() 
              toast.add({severity:'success', summary: 'REGISTRO', detail:'Macro region Actualizada', life: 3000});
            }
            else{
            await categoriaService.store(categoria.value)
            listarCategorias()
            //categorias.value.push(categoria.value)
            closeModal()     
            toast.add({severity:'success', summary: 'REGISTRO', detail:'Macro region Registrada', life: 3000}); 
            }
          } catch (error) {
            console.log(error.response.data)
            errores.value = error.response.data.errores
            toast.add({severity:'error', summary: 'ERROR DE REGISTRO', detail:'Error de registro de Macro region', life: 3000});
          }
    
        }
    
        // METODOS DE LA PLANTILLA
    
        const selectButtonValues1= ([
              {name: 'SI', code: 'O1'},
              {name: 'NO', code: 'O2'},
            ]);
    
         const selectButtonValue1 = null;
        
        const  nestedRouteItems=([
          {
            label : 'INFORMACIÓN',
            to :'/ver/'+ route.params.id
          },
          {
            label: 'RUTAS',
            to: '/verrutas/'+ route.params.id
          },
          {
            label: 'TEMPORALIDAD',
            to: '/vertemporalidad/'+ route.params.id
          },
          {
            label: 'DECLARATORIA',
            to: '/vervisitas/'+ route.params.id
          },
          {
            label: 'INTEGRIDAD',
            to: '/verintegridad/'+ route.params.id
          },
          {
            label: 'AUTENTICIDAD',
            to: '/vercapacidad/'+ route.params.id
          },
          {
            label: 'ACTIVIDAD',
            to: '/veractividad/'+ route.params.id
          },
          {
            label: 'FOTOGRAMA',
            to: '/verfotograma/'+ route.params.id
          }
        ]);
    
        // Guardar la entidad 
        const guardarTuristico = async () => {
  
          if(ppirta.value.g_z)
          {
    
            if(!ppirta.value.id || !ppirta.value.nombre){
              submitted.value = true;
              // if (!isFormValid) { 
              //   return;
              // }
            }
  
            try {
              if (ppirta.value.id) {
                await ppirtaService.update(ppirta.value, ppirta.value.id);
                closeModal();
                toast.add({
                  severity: "success",
                  summary: "REGISTRO",
                  detail: "Datos de atractivo turístico actualizados con éxito",
                  life: 5000,
                });
  
              }
            } catch (error) {
              errores.value = error.response.data.errores;
              toast.add({
                severity: "error",
                summary: "ERROR DE REGISTRO",
                detail: "Error al actualizar el registro",
                life: 5000,
              });
            }
          } else {
            toast.add({
              severity: "error",
              summary: "Valor Z es requerida mínimo 1 y máximo 4 dígitos",
              detail: "Error de registro del formulario",
              life: 5000,
            });
          }
  
        };
  
        // Abrir un modal
        const openModal = () => {
          ppirta.value = datos;
          categoria.value = {};
          displayModal.value = true;
          submitted.value = false;
        };
    
        // Abrir un modal
        const openDetalle = () => {
            categoria.value={}
            displayModal.value= true
          }
    
        // Abrir un modal actualizar
        const editarDialogModal = (datos) => {
          displayModal.value= true
        }
    
        // Cerrar un modal
        const closeModal = () => {
          displayModal.value= false
          // ppirta.value={}
        }
        function updatePosition(position, campo) {
          campo.valor = `${position.position.lat},${position.position.lng}`;
          let arr = campo.valor.split(',');
          ppirta.value.g_x = arr[0]
          ppirta.value.g_y = arr[1]
  
        }
        // DEVOLVER REGISTROS
          
        return {
          updatePosition, 
          displayModal: false, 
          nestedRouteItems, 
          campos,
          selectButtonValues1, 
          selectButtonValue1,  
          submitted, 
          displayModal, 
          openModal, 
          closeModal, 
          guardarCategoria,
          codigoGenerado,
          getMacroregion,
          getRegion,
          getProvincia,
          getMunicipio,
          subTipo,
          categorias,
          tipo,
          tipos,
          subtipos,
          macroRegion,
          regiones,
          departamentos,
          provincias,
          municipios,
          guardarTuristico, 
          editarDialogModal,
          proyecciones,
          datums,
          errores, 
          ppirta,
          isLoading,    
        }
    
      },
    
    }
    </script>
    
    <style scoped>
    .top-modal .p-dialog {
      top: 0px;
    }
    </style>