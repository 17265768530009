import {http, urlBase} from "./Http"

//PDF atractivo g n
export const generarppirtaPDF = (ppirta) => {
  return http().get(`${urlBase}/ppirtapdf/${ppirta}`);
}
//PDF centro poblado g n
export const generarppirtacpPDF = (ppirtf) => {
    return http().get(`${urlBase}/ppirtfpdf/${ppirtf}`);
}

//Seguimiento ppirta g n
export const ppirtaseguimientoPDF = (idppirta) => {
return http().get(`${urlBase}/ppirtaseg/${idppirta}`);
}

//Seguimiento ppirta g n
export const ppirtfseguimientoPDF = (idppirtf) => {
return http().get(`${urlBase}/ppirtfseg/${idppirtf}`);
}

//down
//PDF atractivo g n
export const generardownPDF = (ppirta) => {
  return http().get(`${urlBase}/ppirtapdfdown/${ppirta}`);
}