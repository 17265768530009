import {http, urlBase} from "./Http" 

//ppirta
// MOSTRAR
export const showGrafico = () => {
  return http().get(`${urlBase}/graficobarrasnacional`);
}

// MOSTRAR
export const showGrafico2 = () => {
  return http().get(`${urlBase}/graficocirnacionalppirta`);
}


// MOSTRAR nacional
export const graficoNacional = () => {
  return http().get(`${urlBase}/graficonacional`);
}



//ppirtf
// MOSTRAR
export const showGraficoCP = () => {
  return http().get(`${urlBase}/graficobarrasnacionalppirtf`);
}

// MOSTRAR
export const showGraficoCP2 = () => {
  return http().get(`${urlBase}/graficocirnacionalppirtf`);
}