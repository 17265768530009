import {http, urlBase} from "./Http" 

// LISTAR
export const index = (page=1, rows=10, q=null) => {
  return http().get(`${urlBase}/tipo?page=${page}&rows=${rows}&q=${q}`);
}

// LISTAR
export const buscar = (id) => {
  return http().get(`${urlBase}/cattip/${id}`);
}

// GUARDAR
export const store = (datos) => {
  return http().post(`${urlBase}/tipo`,datos);
}

// MOSTRAR
export const show = (id) => {
  return http().get(`${urlBase}/tipo/${id}`);
}

// MODIFICAR
export const update = (datos, id) => {
  return http().put(`${urlBase}/tipo/${id}`, datos);
}

// ELIMINAR
export const destroy = (id) => {
  return http().delete(`${urlBase}/tipo/${id}`);
}



// MOSTRAR TIPO CATEGORIA
export const indexcat= (id) => {
  return http().get(`${urlBase}/cattip/${id}`);
}

// MOSTRAR SUBTIPO CATEGORIA
export const indexsub= (id) => {
  return http().get(`${urlBase}/tiposub/${id}`);
}

// MOSTRAR DETALLE SUBTIPO
export const detallesub= (id) => {
  return http().get(`${urlBase}/subtipo/${id}`);
}
// MODIFICAR
// export const update = (datos, id) => {
//   return http().put(`${urlBase}/tipo/${id}`, datos);
// }

// // ELIMINAR
// export const destroy = (id) => {
//   return http().delete(`${urlBase}/tipo/${id}`);
// }

// // COMBO
export const get = () => {
  return http().get(`${urlBase}/listcat`);
}