<template>
  <div class="card">
    <table cellpadding="5px" style="width: 100%; background-color: #f8f8f8; height: 45px">
      <tr>
        <td style="font-size: 20px">
          <B>GESTIÓN DE CATEGORÍA SUB TIPO</B>
        </td>
      </tr>
    </table>
    <br />
    <!--Cabecera de menu principal-->
    <Toolbar class="mb-4">
      <template #start>
        <Button label="Nuevo" icon="pi pi-plus" class="p-button-success mr-2" @click="openModal" />
      </template>
      <template #end>
        <Button label="PDF" icon="pi pi-file-pdf" class="p-button-help" @click="exportToPDF" />
      </template>
    </Toolbar>
    
<!-- {{ catsubtipos }} -->
    <!--Tabla de displiegue de la informacion-->
    <DataTable ref="dt" :lazy="true" :value="catsubtipos" v-model:selection="selectedProducts" dataKey="id"
      :paginator="true" :rows="10" :filters="filters" :totalRecords="totalRecords" :loading="loading"
      @page="onPage($event)" @filter="onFilter($event)"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros" responsiveLayout="scroll">
      <template #header>
        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
          <h5 class="m-0">CATEGORÍA SUBTIPO</h5>
          <span class="block mt-2 md:mt-0 p-input-icon-left">
            <i class="pi pi-search" />
            <InputText v-model="filters['global'].value" @input="buscar()" placeholder="Buscar..." />
          </span>
        </div>
      </template>

      <!-- <Column field="id" header="ID" :sortable="true" style="min-width: 2rem"></Column> -->
      <Column field="subtipo" header="SUBTIPO" :sortable="false" style="min-width: 8rem"></Column>
      <Column field='nombre' header="CAT. SUBTIPO" :sortable="false" style="min-width: 8rem"></Column>
      <Column field="codigo" header="CÓDIGO" :sortable="false" style="min-width: 8rem"></Column>
      <Column field="orden" header="ORDEN" :sortable="false" style="min-width: 8rem"></Column>
      <Column :exportable="false" style="min-width: 8rem" header="ACCIONES">

        <template #body="slotProps">
          <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
            @click="editarDialogModal(slotProps.data)" />
          <Button v-if="idPrimerRol === 1 || idPrimerRol === 3" icon="pi pi-trash" class="p-button-rounded p-button-warning"
            @click="confirmDeleteProduct(slotProps.data)" />
        </template>
      </Column>
    </DataTable>

    <!--Formulario de registro de informacion-->
    <div class="floatlabel-demo col-12 md:col-12">
      <Dialog header="DATOS DE CATEGORÍA SUB TIPO" v-model:visible="displayModal" :breakpoints="{'800px': '450px'}"
        class="p-fluid" :style="{width: '30%'}" maximizable :modal="true">
        <div>
          <div class="p-fluid">

            <InlineMessage :style="{  border: 'solid #696cff', borderWidth: '0 0 0 6px', color: '#000000' }"
                    severity="info" class="border-primary w-full justify-content-start">
              <div class="flex align-items-center">
                  <!-- <img alt="logo" src="https://primefaces.org/cdn/primevue/images/logo.svg" width="32" /> -->
                  <div class="ml-2">Debe verificar que los datos seleccionados e ingresados sean correctos para registrar.<br>De lo contrario puede <span style="color:#4E5FBB"> X Cancelar</span> el registro.</div>
              </div>
            </InlineMessage><br>
   
            <div class="grid formgrid">

              <div class="field col-12 mb-3 lg:col-1 lg:col-6">
                <label for="name">Subtipo</label>
                <Dropdown id="id" v-model="catsubtipo.id_subtipo" :key="id_subtipo" :options="subtipos" optionLabel="nombre" optionValue="id"  placeholder="Elegir..." />
              </div>

              <div class="field col-12 mb-3 lg:col-1 lg:col-6">
                <label for="codigo">Código</label>
                <InputText type="text" id="codigo" placeholder="" v-model.trim="catsubtipo.codigo" autofocus />
              </div>

              <div class="field col-12 mb-3 lg:col-1 lg:col-8">
                <label for="nombre">Nombre de categoría subtipo</label>
                <InputText type="text" id="nombre" placeholder="" v-model.trim="catsubtipo.nombre" autofocus/>
              </div>

              <div class="field col-12 mb-3 lg:col-1 lg:col-4">
                <label for="orden">Orden</label>
                <InputText type="number" id="orden" :min="0" placeholder="" v-model.trim="catsubtipo.orden" autofocus/>
              </div>

            </div>
          </div>
        </div>
        <!--{{ catsubtipo }}-->
        <template #footer>
          <Button label="Cancelar" icon="pi pi-times" @click="closeModal" class="p-button-text" />
          <Button label="Guardar" icon="pi pi-check" @click="guardarCatSubTipo()" />
        </template>
      </Dialog>

      <!-- Eliminar un registro-->
      <Dialog v-model:visible="deleteProductDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
        <div class="confirmation-content">
          <h4><i class="pi pi-exclamation-triangle mr-2" style="font-size: 2rem" /><b>¿Está seguro de borrar el registro?</b></h4><br>
            <span v-if="catsubtipo"><b style="color: #4E5FBB;">{{catsubtipo.nombre}}</b></span><br>
            <span>¡Si no lo está puede cancelar la accíón!</span>
        </div>
        <template #footer>
          <Button label="Cancelar" icon="pi pi-times" class="p-button-text"   @click="deleteProductDialog = false" />
          <Button label="Si, borrar registro" icon="pi pi-check" @click="deleteProduct"/>
        </template>
      </Dialog>
      <!-- Eliminar varios registro-->
      <Dialog v-model:visible="deleteProductsDialog" :style="{ width: '450px' }" header="Confirm" :modal="true">
        <div class="confirmation-content">
          <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
          <span v-if="product">Are you sure you want to delete the selected products?</span>
        </div>
        
        <template #footer>
          <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductsDialog = false" />
          <Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteSelectedProducts" />
        </template>
      </Dialog>

    </div>

    <!--Para el despliegue del mensaje de salida-->
    <Toast />
  </div>
</template>

<script>
import { reactive,onMounted, ref } from "vue";

import * as subtipoService from '@/service/SubTipoService.js'
import * as catsubtipoService from '@/service/SubTipoCatService.js'

import { useToast } from "primevue/usetoast";
import InputMask from "primevue/inputmask";

import { FilterMatchMode } from "primevue/api";

// Importacones para realizar las validaciones
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { useRoute } from 'vue-router';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

export default {

  setup() {
    const rolss = JSON.parse(localStorage.getItem("role")) || [];
    const primerRol = ref(rolss[0] || {});
    const idPrimerRol = ref(primerRol.value.id || null);
    
    const route = useRoute();

    const catsubtipos = ref();
    const catsubtipo = ref({});
    const subtipo = ref({});
    const subtipos = ref([]);
   
    const subtipocats = ref([]);
    const displayModal = ref(false);
    const submitted = ref(false);
    const toast = useToast();
    const errores = ref({});
    
    onMounted(() => {
      listarSubTipos();
      listarCatSubTipos();
      
    });

    // const listarSubTipos = async () => {
    //   const {data} = await subtipoService.index('','','');
    //   subtipos.value=data.subtipos.data;
    // }
    const listarSubTipos = async () => {
      const {data} = await subtipoService.showSubTipo(40);
      console.log(data.subtipos)
      subtipos.value=data.subtipos;

    }

    const dt = ref();
    const deleteProductDialog = ref(false);
    const deleteProductsDialog = ref(false);
    const loading = ref(false);
    const totalRecords = ref(0);
    const selectedProducts = ref();
    const lazyParams = ref({});
    
    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    // METODOS
    const onPage = (event) => {
      lazyParams.value = event;
      listarCatSubTipos();
    };

    const onFilter = () => {
      lazyParams.value.filters = filters.value;
      listarCatSubTipos();
    };

    const buscar = () => {
      
      listarCatSubTipos();
    };

    // Validaion del formulario
    // Definimos los variables a validar

    const state = reactive( {
      catsubtipo: {
        id_subtipo:"",
        codigo:"",
      }
    });

    // Tipo de validacion a realizar del dato
    const rules = {
      catsubtipo: {
        id_subtipo: { required },
        codigo: { required },
        
    }
    };
    // Seccion de la validacion
    const v$ = useVuelidate(rules, state);

    // Listado de las entidades creadas
    const listarCatSubTipos = async () => {
      try {
        loading.value = true;
        let rows = lazyParams.value.rows;
        let q = filters.value.global.value == null?'':filters.value.global.value;
        let page = (lazyParams.value.page == null)?0:lazyParams.value.page;

        const { data } = await catsubtipoService.index(page + 1, rows, q);
        // console.log(data)
        catsubtipos.value = data.catsubtipo.data;
        totalRecords.value = data.catsubtipo.total;
        loading.value = false;

      } catch (error) {
        
      }
    };

    // Guardar catsubtipo 
    const guardarCatSubTipo = async () => {

      if(!catsubtipo.value.id_subtipo || !catsubtipo.value.codigo ||
        !catsubtipo.value.nombre || !catsubtipo.value.orden){
        submitted.value = true;
        // if (!isFormValid) { 
        //   return;
        // }
      }

      try {
        if (catsubtipo.value.id) {
          await catsubtipoService.updateCat(catsubtipo.value, catsubtipo.value.id);
          listarCatSubTipos();
          closeModal();
          toast.add({
            severity: "success",
            summary: "REGISTRO",
            detail: "La categoría sub típo fue actualizado con éxito",
            life: 5000,
          });
        } else {
          await catsubtipoService.storeCat(catsubtipo.value);
          listarCatSubTipos();
          //categorias.value.push(categoria.value)
          closeModal();
          toast.add({
            severity: "success",
            summary: "REGISTRO",
            detail: "Categoría sub típo Registrado con éxito",
            life: 5000,
          });
        }
      } catch (error) {
        console.log(error.response.data);
        errores.value = error.response.data.errores;
        toast.add({
          severity: "error",
          summary: "ERROR DE REGISTRO",
          detail: "Error de registro de la categoría sub típo",
          life: 5000,
        });
      }
    };
    // METODOS DE LA PLANTILLA
    // Abrir un modal
    const openModal = () => {

      if (catsubtipos.value && catsubtipos.value.length > 0) {
        const idPpirtf = catsubtipos.value[0].id_ppirtf;
        const idTipo = catsubtipos.value[0].id_tipo;
        
        catsubtipo.value = {
          // id_ppirtf: idPpirtf,
        };
        displayModal.value = true;
        submitted.value = false;
      }
      catsubtipo.value = {
        id_subtipo: 0,

      };
      displayModal.value = true;
      submitted.value = false;
    };

    // Abrir un modal
    const editarDialogModal = (datos) => {
      catsubtipo.value = datos;
      displayModal.value = true;
    };

    // Eliminar un producto
    const confirmDeleteProduct = (datos) => {
      catsubtipo.value = datos;
      deleteProductDialog.value = true;
    };

    const deleteProduct = async () => {
      try {
          await catsubtipoService.destroyCat(catsubtipo.value.id);
          listarCatSubTipos();
          toast.add({
            severity: "error",
            summary: "REGISTRO",
            detail: "La categoría sub típo a sido Eliminado",
            life: 5000,
          });
          deleteProductDialog.value = false;
          catsubtipo.value = {}; 
      } catch (error) {
            //console.log(error.response.data);
            errores.value = error.response.data.errores;
            toast.add({
              severity: "error",
              summary: "REGISTRO",
              detail: "No se elemino el registro",
              life: 5000,
            });
      }
              
    };

    const confirmDeleteSelected = () => {
            deleteProductsDialog.value = true;
        }; 
    const deleteSelectedProducts = () => {
            products.value = products.value.filter(val => !selectedProducts.value.includes(val));
            deleteProductsDialog.value = false;
            selectedProducts.value = null;
            toast.add({severity:'success', summary: 'Successful', detail: 'La categoría sub típo es Eliminadas', life: 3000});
        };  
        
    // Cerrar un modal
    const closeModal = () => {
      displayModal.value = false;
      catsubtipo.value = {};
      submitted.value = false;
      listarCatSubTipos();
      state.nombre = '';
    };

    // DEVOLVER REGISTROS
    return {
      rolss,
      idPrimerRol,
      v$,
      state,
      rules,
      buscar,
      onFilter,
      onPage,
      loading,
      totalRecords,
      dt,
      deleteProductDialog,
      deleteProductsDialog,
      confirmDeleteProduct,
      deleteProduct,
      confirmDeleteSelected,
      deleteSelectedProducts,
      selectedProducts,
      filters,
      catsubtipos,
      catsubtipo,
      subtipo,
      subtipos,
      subtipocats,
      submitted,
      displayModal,
      openModal,
      closeModal,
      guardarCatSubTipo,
      editarDialogModal,
      errores,
    };
  },
  methods: {
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    async exportToPDF() {
      const doc = new jsPDF();

      // Título del PDF
      doc.setFontSize(20);
      doc.text('Gestión de Categorías Sub Típo', 10, 10);

      // Obtén los datos de la tabla
      const tableData = this.catsubtipos.map(item => [item.subtipo, item.nombre , item.codigo]);

      // Encabezados de la tabla
      const headers = [['Categoría Subtípo', 'Nombre', 'Código']];

      // Crea la tabla en el PDF
      doc.autoTable({
        head: headers,
        body: tableData,
        startY: 20,
      });

      // Guarda el PDF
      doc.save('categoria-sub-tipo.pdf');
    },
    
  },
  
  components: { InputMask },
};
</script>

<style></style>