<template>
  <div class="card">
    <table cellpadding="5px" style="width: 100%; background-color: #f8f8f8; height: 45px">
      <tr>
        <td style="font-size: 20px">
          <B>RESTAURACIÓN</B>
        </td>
      </tr>
    </table>
    <br />
    <!--Cabecera de menu principal-->
    <Toolbar class="mb-4">
      <template #start>
        <Button label="Nuevo" icon="pi pi-plus" class="p-button-success mr-2" @click="openModal" />
      </template>
    </Toolbar>
    
<!-- {{ restauraciones }} -->
    <!--Tabla de displiegue de la informacion-->
    <DataTable ref="dt" :value="restauraciones" v-model:selection="selectedProducts" dataKey="id"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros" responsiveLayout="scroll">

      <!-- <Column field="id" header="ID" :sortable="true" style="min-width: 2rem"></Column> -->
      <Column field="tipo" header="TIPO" :sortable="false" style="min-width: 10rem"></Column>
      <!-- <Column field="subtipo" header="SUBTIPO" :sortable="false" style="min-width: 10rem"></Column> -->
      <!-- <Column :sortable="false" style="min-width: 8rem" header="CAT. SUBTIPO">
        <template #body="slotProps">
          {{ slotProps.data.catsubtipo !== null ? slotProps.data.catsubtipo : '---' }}
        </template>
      </Column> -->
      <Column field="establecimiento" header="ESTABLECIMIENTO" :sortable="false" style="min-width: 8rem"></Column>
      <Column field="propietario" header="PROPIETARIO" :sortable="false" style="min-width: 8rem"></Column>
      <Column field="numero" header="NÚMERO" :sortable="false" style="min-width: 8rem"></Column>
      <Column field="direccion" header="DIRECCIÓN" :sortable="false" style="min-width: 8rem"></Column>
      <Column field='capacidad' header="CAPACIDAD" :sortable="false" style="min-width: 8rem"></Column>
      <Column field='costo' header="COSTO %" :sortable="false" style="min-width: 8rem"></Column>
      <Column field="calidad" header="CALIDAD" :sortable="false" style="min-width: 8rem">
        <template #body="slotProps">
          <span :style="getCellStyle(slotProps.data.calidad)">
            {{ getCalidadLabel(slotProps.data.calidad) }}
          </span>
        </template>
      </Column>
      <Column :exportable="false" style="min-width: 8rem" header="ACCIONES">

        <template #body="slotProps">
          <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
            @click="editarDialogModal(slotProps.data)" />
          <Button v-if="idPrimerRol === 1" icon="pi pi-trash" class="p-button-rounded p-button-warning"
            @click="confirmDeleteProduct(slotProps.data)" />
        </template>
      </Column>
    </DataTable>

    <!--Formulario de registro de informacion-->
    <div class="floatlabel-demo col-12 md:col-12">
      <Dialog header="DATOS DE RESTAURACIÓN" v-model:visible="displayModal" :breakpoints="{'800px': '450px'}"
        class="p-fluid" :style="{width: '30%'}" :modal="true">
        <div>
          <div class="p-fluid">

            <InlineMessage :style="{  border: 'solid #696cff', borderWidth: '0 0 0 6px', color: '#000000' }"
                    severity="info" class="border-primary w-full justify-content-start">
              <div class="flex align-items-center">
                  <!-- <img alt="logo" src="https://primefaces.org/cdn/primevue/images/logo.svg" width="32" /> -->
                  <div class="ml-2">Debe verificar que los datos seleccionados e ingresados sean correctos para registrar.<br>De lo contrario puede <span style="color:#4E5FBB"> X Cancelar</span> el registro.</div>
              </div>
            </InlineMessage><br>
   
            <div class="grid formgrid">
              <!-- <div>ID_PPIRTF: {{ restauracion.id_ppirtf }}</div>
              <div>ID_TIPO: {{ restauracion.id_tipo }}</div> -->

              <div class="field col-12 mb-3 lg:col-1 lg:col-6">
                <label for="name">Tipo</label>
                <Dropdown id="id" v-model="restauracion.id_subtipo" :key="id_subtipo" @change="listarCatSubTipos()" :options="subtipos" optionLabel="nombre" optionValue="id"  placeholder="Elegir..." />
              </div>
              
              <!-- <div class="field col-12 mb-3 lg:col-1 lg:col-6">
                <label for="name">Subtipo</label>
                <Dropdown id="id_catsubtipo" v-model.trim="restauracion.id_catsubtipo" v-model="v$.id_catsubtipo.$model" :options="subtipocats" optionLabel="nombre" optionValue="id"  placeholder="Elegir..." v-if="subtipocats.length > 0" />
                <Dropdown placeholder="No existen datos..." v-if="subtipocats.length == 0" />
              </div> -->

              <div class="field col-12 mb-3 lg:col-1 lg:col-6">
                <label for="establecimiento">Establecimiento</label>
                <InputText type="text" id="establecimiento" placeholder="" v-model.trim="restauracion.establecimiento" autofocus/>
              </div>

              <div class="field col-12 mb-3 lg:col-1 lg:col-6">
                <label for="propietario">Propietario</label>
                <InputText type="text" id="propietario" :minlength="0" placeholder="" v-model.trim="restauracion.propietario" autofocus/>
              </div>

              <div class="field col-12 mb-3 lg:col-1 lg:col-3">
                <label for="numero">Número</label>
                <InputText type="text" id="numero" :minlength="0" placeholder="" v-model.trim="restauracion.numero" autofocus/>
              </div>

              <div class="field col-12 mb-3 lg:col-1 lg:col-6">
                <label for="direccion">Dirección</label>
                <InputText type="text" id="direccion" :minlength="0" placeholder="" v-model.trim="restauracion.direccion" autofocus/>
              </div>

              <div class="field col-12 mb-3 lg:col-1 lg:col-3">
                <label for="capacidad">Capacidad</label>
                <InputText type="number" id="capacidad" :minlength="0" placeholder="" v-model.trim="restauracion.capacidad" autofocus/>
              </div>

              <div class="field col-12 mb-3 lg:col-1 lg:col-6">
                <label for="costo">Costo %</label>
                <InputText type="number" id="costo" :minlength="0" placeholder="" v-model.trim="restauracion.costo" autofocus/>
              </div>

              <div class="field col-12 mb-3 lg:col-1 lg:col-6">
                <label for="calidad">Calidad</label>
                <Dropdown
                  id="calidad"
                  :options="calidadOptions"
                  v-model="restauracion.calidad"
                  optionLabel="nombre"
                  optionValue="valor"
                  placeholder="Elegir..."
                ></Dropdown>
              </div>

            </div>
          </div>
        </div>
        <!-- {{ restauracion }} -->
        <template #footer>
          <Button label="Cancelar" icon="pi pi-times" @click="closeModal" class="p-button-text" />
          <Button label="Guardar" icon="pi pi-check" @click="guardarRestauracion()" />
        </template>
      </Dialog>

      <!-- Eliminar un registro-->
      <Dialog v-model:visible="deleteProductDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
        <div class="confirmation-content">
          <h4><i class="pi pi-exclamation-triangle mr-2" style="font-size: 2rem" /><b>¿Está seguro de borrar el registro?</b></h4><br>
            <span v-if="restauracion"><b style="color: #4E5FBB;">{{restauracion.establecimiento}}</b></span><br>
            <span>¡Si no lo está puede cancelar la accíón!</span>
        </div>
        <template #footer>
          <Button label="Cancelar" icon="pi pi-times" class="p-button-text"   @click="deleteProductDialog = false" />
          <Button label="Si, borrar registro" icon="pi pi-check" @click="deleteProduct"/>
        </template>
      </Dialog>
      <!-- Eliminar varios registro-->
      <Dialog v-model:visible="deleteProductsDialog" :style="{ width: '450px' }" header="Confirm" :modal="true">
        <div class="confirmation-content">
          <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
          <span v-if="product">Are you sure you want to delete the selected products?</span>
        </div>
        
        <template #footer>
          <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductsDialog = false" />
          <Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteSelectedProducts" />
        </template>
      </Dialog>

    </div>

    <!--Para el despliegue del mensaje de salida-->
    <Toast />
  </div>
</template>

<script>
import { reactive,onMounted, ref } from "vue";

import * as subtipoService from '@/service/SubTipoService.js'
import * as subtipocatService from '@/service/SubTipoCatService.js'
import * as restauracionService from '@/service/RestauracionService.js'

import { useToast } from "primevue/usetoast";
import InputMask from "primevue/inputmask";

import { FilterMatchMode } from "primevue/api";

// Importacones para realizar las validaciones
import { email, helpers, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { useRoute } from 'vue-router'
import { assertLiteral } from "@babel/types";
import CryptoJS from 'crypto-js';


export default {
  data() {
    return {
      calidadOptions: [
        { nombre: "Bueno", valor: "B" },
        { nombre: "Regular", valor: "R" },
        { nombre: "Malo", valor: "M" },
      ],
    };
  },
  setup() {

    const encryptionKey = 'M1n1st3r10d3d3s4rr0ll0pr0duct1v0y3c0n0m14plur4l';

    const rolss = JSON.parse(localStorage.getItem("role")) || [];
    const primerRol = ref(rolss[0] || {});
    const idPrimerRol = ref(primerRol.value.id || null);

    const route = useRoute();
    const restauracionId = route.params.id;

    const restauraciones = ref();
    const restauracion = ref({});
    const subtipo = ref({});
    const subtipos = ref([]);
    const subtipocats = ref([]);
    const displayModal = ref(false);
    const submitted = ref(false);
    const toast = useToast();
    const errores = ref({});
    
    onMounted(() => {
      listarSubTipos();
      listarRestauraciones();
      
    });

    // const listarSubTipos = async () => {
    //   const {data} = await subtipoService.index('','','');
    //   subtipos.value=data.subtipos.data;
    // }
    const listarSubTipos = async () => {
      const {data} = await subtipoService.showSubTipo(41);
      subtipos.value=data.subtipos;

    }

    const listarCatSubTipos = async () => {
      const {data} = await subtipocatService.buscar(restauracion.value.id_subtipo);
      subtipocats.value=data.catsubtipos;

      // if (data.catsubtipos.length === 0) {
      //   alert("El array está vacío");
      // } else {
      //   alert("El array no está vacío");
      // } 
      
    }

    const dt = ref();
    const deleteProductDialog = ref(false);
    const deleteProductsDialog = ref(false);
    const loading = ref(false);
    const totalRecords = ref(0);
    const selectedProducts = ref();
    const lazyParams = ref({});
    
    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    // METODOS
    const onPage = (event) => {
      lazyParams.value = event;
      listarRestauraciones();
    };

    const onFilter = () => {
      lazyParams.value.filters = filters.value;
      listarRestauraciones();
    };

    const buscar = () => {
      console.log(filters.value.global.value);
      listarRestauraciones();
    };

    // Validaion del formulario
    // Definimos los variables a validar

    const state = reactive( {
      restauracion: {
        id_subtipo:"",
      }
    });

    // Tipo de validacion a realizar del dato
    const rules = {
      restauracion: {
        id_subtipo: { required },
        
    }
    };
    // Seccion de la validacion
    const v$ = useVuelidate(rules, state);


    // Listado de las entidades creadas
    const listarRestauraciones = async () => {
      try {
        loading.value = true;
        let ppirtf = route.params.id
        let rows = lazyParams.value.rows;
        let q = filters.value.global.value == null?'':filters.value.global.value;
        let page = (lazyParams.value.page == null)?0:lazyParams.value.page;

        const encryptedId = route.params.id.match(/.{1,2}/g).map(byte => String.fromCharCode(parseInt(byte, 16))).join('');
        const sanitizedId = CryptoJS.AES.decrypt(encryptedId, encryptionKey).toString(CryptoJS.enc.Utf8);
        const originalId = sanitizedId.replace(/_/g, '/');

        const { data } = await restauracionService.index(originalId, page + 1, rows, q);
        console.log(data)
        restauraciones.value = data.restauracion.data;
        totalRecords.value = data.restauracion.total;
        loading.value = false;

      } catch (error) {
        console.log(error.response.data);
      }
    };

    // Guardar restauracion 
    const guardarRestauracion = async () => {

      if(!restauracion.value.id_subtipo || !restauracion.value.id_catsubtipo ||
        !restauracion.value.establecimiento || !restauracion.value.propietario ||
        !restauracion.value.numero || !restauracion.value.direccion || !restauracion.value.capacidad ||
        !restauracion.value.capacidad || !restauracion.value.costo || !restauracion.value.calidad){
        submitted.value = true;
        // if (!isFormValid) { 
        //   return;
        // }
      }

      try {
        if (restauracion.value.id) {
          await restauracionService.update(restauracion.value, restauracion.value.id);
          listarRestauraciones();
          closeModal();
          toast.add({
            severity: "success",
            summary: "REGISTRO",
            detail: "Restauración fue actualizado con éxito",
            life: 5000,
          });
        } else {

          const encryptedId = route.params.id.match(/.{1,2}/g).map(byte => String.fromCharCode(parseInt(byte, 16))).join('');
          const sanitizedId = CryptoJS.AES.decrypt(encryptedId, encryptionKey).toString(CryptoJS.enc.Utf8);
          const originalId = sanitizedId.replace(/_/g, '/');

          restauracion.value.id_ppirtf = originalId;

          await restauracionService.store(restauracion.value);
          listarRestauraciones();
          //categorias.value.push(categoria.value)
          closeModal();
          toast.add({
            severity: "success",
            summary: "REGISTRO",
            detail: "Restauración Registrado con éxito",
            life: 5000,
          });
        }
      } catch (error) {
        console.log(error.response.data);
        errores.value = error.response.data.errores;
        toast.add({
          severity: "error",
          summary: "ERROR DE REGISTRO",
          detail: "Error de registro de la Restauración",
          life: 5000,
        });
      }
    };
    // METODOS DE LA PLANTILLA
    // Abrir un modal
    const openModal = () => {

      if (restauraciones.value && restauraciones.value.length > 0) {
        const idPpirtf = restauraciones.value[0].id_ppirtf;
        const idTipo = restauraciones.value[0].id_tipo;
        
        restauracion.value = {
          id_ppirtf: idPpirtf,
          id_tipo: idTipo,
          // calidad: "", // El valor seleccionado se almacenará aquí
          
        };
        displayModal.value = true;
        submitted.value = false;
      }
      restauracion.value = {
          id_ppirtf: restauracionId,
          id_tipo: '41',
          id_subtipo: 0,
          id_catsubtipo: 0,

        };
        displayModal.value = true;
        submitted.value = false;
    };


    // Abrir un modal
    const editarDialogModal = (datos) => {
      restauracion.value = datos;
      displayModal.value = true;
    };

    // Eliminar un producto
    const confirmDeleteProduct = (datos) => {
      restauracion.value = datos;
      deleteProductDialog.value = true;
    };

    const deleteProduct = async () => {
      try {
          await restauracionService.destroy(restauracion.value.id);
          listarRestauraciones();
          toast.add({
            severity: "error",
            summary: "REGISTRO",
            detail: "Restauración Eliminado",
            life: 5000,
          });
          deleteProductDialog.value = false;
          restauracion.value = {}; 
      } catch (error) {
            //console.log(error.response.data);
            errores.value = error.response.data.errores;
            toast.add({
              severity: "error",
              summary: "REGISTRO",
              detail: "No se elemino el registro",
              life: 5000,
            });
      }
              
    };

    const confirmDeleteSelected = () => {
            deleteProductsDialog.value = true;
        }; 
    const deleteSelectedProducts = () => {
            products.value = products.value.filter(val => !selectedProducts.value.includes(val));
            deleteProductsDialog.value = false;
            selectedProducts.value = null;
            toast.add({severity:'success', summary: 'Successful', detail: 'Restauración es Eliminadas', life: 3000});
        };  
        
    // Cerrar un modal
    const closeModal = () => {
      displayModal.value = false;
      restauracion.value = {};
      submitted.value = false;
      listarRestauraciones();
      state.sigla = '';
      state.nombre = '';
    };

    const formatCurrency = (value) => {
      if (value)
        return value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      return;
    };

    // DEVOLVER REGISTROS
    return {
      rolss,
      idPrimerRol,
      v$,
      state,
      rules,
      buscar,
      onFilter,
      onPage,
      loading,
      totalRecords,
      formatCurrency,
      dt,
      deleteProductDialog,
      deleteProductsDialog,
      confirmDeleteProduct,
      deleteProduct,
      confirmDeleteSelected,
      deleteSelectedProducts,
      selectedProducts,
      filters,
      restauraciones,
      restauracion,
      subtipo,
      subtipos,
      subtipocats,
      submitted,
      displayModal,
      openModal,
      closeModal,
      listarCatSubTipos,
      guardarRestauracion,
      editarDialogModal,
      errores,
    };
  },
  methods: {
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    getCalidadLabel(calidad) {
      switch (calidad) {
        case 'B':
          return 'Bueno';
        case 'M':
          return 'Malo';
        case 'R':
          return 'Regular';
        default:
          return calidad;
      }
    },
    getCellStyle(calidad) {
      let backgroundColor;
      switch (calidad) {
        case 'B':
          backgroundColor = '#4CAF50'; // Verde para Bueno
          break;
        case 'M':
          backgroundColor = '#FF5252'; // Rojo para Malo
          break;
        case 'R':
          backgroundColor = '#03A9F4'; // Celeste para Regular
          break;
        default:
          backgroundColor = '#FFFFFF'; // Color blanco para otros valores
      }
      return {
        backgroundColor,
        borderRadius: '0px', // Ajusta el radio de borde según tu preferencia
        padding: '5px 10px', // Añade espacio interno alrededor del texto
        color: '#FFFFFF', // Color del texto
        display: 'inline-block', // Para que el fondo coloreado se ajuste al texto
      };
    },
  },
  components: { InputMask },
};
</script>

<style></style>