<template>
  <div class="card">
    <table cellpadding="5px" style="width: 100%; background-color: #f8f8f8; height: 45px">
      <tr>
        <td style="font-size: 20px">
          <B>GESTIÓN DE TIPO DE CATEGORÍA</B>
        </td>
      </tr>
    </table>
    <br />
    <!--Cabecera de menu principal-->
    <Toolbar class="mb-4">
      <template #start>
        <Button label="Nuevo" icon="pi pi-plus" class="p-button-success mr-2" @click="openModal" />
        <!-- <Button label="Eliminar" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected"
          :disabled="!selectedProducts || !selectedProducts.length" /> -->
      </template>
      <template #end>
        <Button label="PDF" icon="pi pi-file-pdf" class="p-button-help" @click="exportToPDF" />
      </template>
    </Toolbar>

    <!--Tabla de displiegue de la informacion-->
    <DataTable ref="dt" :lazy="true" :value="tipos" v-model:selection="selectedProducts" dataKey="id"
      :paginator="true" :rows="10" :filters="filters" :totalRecords="totalRecords" :loading="loading"
      @page="onPage($event)" @filter="onFilter($event)"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros" responsiveLayout="scroll">
      <template #header>
        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
          <h5 class="m-0">TIPOS DE CATEGORÍAS</h5>
          <span class="block mt-2 md:mt-0 p-input-icon-left">
            <i class="pi pi-search" />
            <InputText v-model="filters['global'].value" @input="buscar()" placeholder="Buscar..." />
          </span>
        </div>
      </template>
      <!-- <Column selectionMode="multiple" style="width: 3rem" :exportable="false"></Column>
      <Column field="id" header="ID" :sortable="true" style="min-width: 6rem"></Column> -->
      <Column field="codigo" header="CÓDIGO" :sortable="false" style="min-width: 10rem"></Column>
      <Column field="nombre" header="TIPO DE CATEGORÍA" :sortable="false" style="min-width: 10rem"></Column>
      <Column field="categoria" header="NOMBRE CATEGORÍA" :sortable="false" style="min-width: 10rem;" class="cattipo"></Column>
      <!-- <Column field="estado" header="ESTADO" :sortable="true" style="min-width: 10rem"></Column> -->

      <Column :exportable="false" style="min-width: 8rem" header="ACCIONES">

        <template #body="slotProps">
          <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
            @click="editarDialogModal(slotProps.data)" />
          <Button v-if="idPrimerRol === 1 || idPrimerRol === 3" icon="pi pi-trash" class="p-button-rounded p-button-warning"
            @click="confirmDeleteProduct(slotProps.data)" />
        </template>
      </Column>
    </DataTable>

    <!--Formulario de registro de informacion-->
    <div class="floatlabel-demo col-12 md:col-12">
      <Dialog header="REGISTRO DE TIPOS DE CATEGORÍA" v-model:visible="displayModal" :style="{ width: '30%' }" maximizable :modal="true"
        class="p-fluid">
        <InlineMessage :style="{  border: 'solid #696cff', borderWidth: '0 0 0 6px', color: '#000000' }"
                    severity="info" class="border-primary w-full justify-content-start">
          <div class="flex align-items-center">
              <!-- <img alt="logo" src="https://primefaces.org/cdn/primevue/images/logo.svg" width="32" /> -->
              <div class="ml-2">Debe verificar que los datos seleccionados e ingresados sean correctos para registrar.<br>De lo contrario puede <span style="color:#4E5FBB"> X Cancelar</span> el registro.</div>
          </div>
        </InlineMessage>
        <br />
        <div class="grid formgrid">
          <div class="field col-12 mb-3 lg:col-2 lg:col-6">
            <label for="name">Código *</label>
            <InputText id="codigo" v-model.trim="tipo.codigo"  v-model="v$.codigo.$model"
              :class="{ 'p-invalid': v$.codigo.$invalid && submitted }" />
            <small v-if="(v$.codigo.$invalid && submitted) || v$.codigo.$pending.$response" class="p-error">
              El código es requerido</small>
          </div>
          <div class="field col-12 mb-3 lg:col-2 lg:col-6">
            <label for="name">Orden *</label>
            <InputNumber inputId="minmax-buttons" v-model="tipo.orden" mode="decimal" showButtons :min="1" :max="100" />
          </div>


        </div>
        <div class="grid formgrid">
          <div class="field col-12 mb-3 lg:col-2 lg:col-12">
            <label for="name">Tipo de categoría *</label>
            <InputText id="nombre" v-model.trim="tipo.nombre" v-model="v$.nombre.$model"
              :class="{ 'p-invalid': v$.nombre.$invalid && submitted }" />
            <small v-if="(v$.nombre.$invalid && submitted) || v$.nombre.$pending.$response" class="p-error">
              El tipo de categoría es requerido</small>
          </div>
        </div>
        <table style="width: 100%; background-color: #f8f8f8; height: 35px">
          <tr>
            <td>
              <B>&nbsp;&nbsp;DATOS DE LA CATEGORÍA</B>
            </td>
          </tr>
        </table>
        <br />

        <!-- <div class="grid formgrid">
          <div class="field col-12 mb-3 lg:col-2 lg:col-12">
            <label for="name">Seccion *</label>
            <Dropdown id="id" v-model="tipo.id_categoria" :key="id_categoria" @change="listarCategorias()" :options="departamentos" optionLabel="nombre" optionValue="id"  placeholder="Elegir..." />
          </div>
        </div> -->
        <div class="grid formgrid">
          <div class="field col-12 mb-3 lg:col-2 lg:col-12">
            <label for="name">Categoría *</label>
              <Dropdown id="id_categoria" v-model.trim="tipo.id_categoria" v-model="v$.id_categoria.$model" :options="categorias" optionLabel="nombre" optionValue="id"  placeholder="Elegir..." :class="{'p-invalid': v$.id_categoria.$invalid && submitted }"/>
  					<small class="p-error" v-if="(v$.id_categoria.$invalid && submitted) || v$.id_categoria.$pending.$response">La categoría es requerida</small>
          </div>
        </div>


        <small>(*) indica campo requerido 🙄</small><br><br>
        <!-- {{ tipo }} -->
        <template #footer>
          <Button label="Cancelar" icon="pi pi-times" @click="closeModal" class="p-button-text" />
          <Button label="Guardar" icon="pi pi-check" @click="guardarTipo(!v$.$invalid)" />
        </template>
      </Dialog>

      <!-- Eliminar un registro-->
      <Dialog v-model:visible="deleteProductDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
        <div class="confirmation-content">
          <h4><i class="pi pi-exclamation-triangle mr-2" style="font-size: 2rem" /><b>¿Está seguro de borrar el registro?</b></h4><br>
            <span v-if="tipo"><b style="color: #4E5FBB;">{{tipo.nombre}}</b></span><br>
            <span>¡Si no lo está puede cancelar la accíón!</span>
        </div>
        <template #footer>
          <Button label="Cancelar" icon="pi pi-times" class="p-button-text"   @click="deleteProductDialog = false" />
          <Button label="Si, borrar registro" icon="pi pi-check" @click="deleteProduct"/>
        </template>
      </Dialog>
      <!-- Eliminar varios registro-->
      <Dialog v-model:visible="deleteProductsDialog" :style="{ width: '450px' }" header="Confirm" :modal="true">
        <div class="confirmation-content">
          <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
          <span v-if="product">Are you sure you want to delete the selected products?</span>
        </div>
        <template #footer>
          <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductsDialog = false" />
          <Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteSelectedProducts" />
        </template>
      </Dialog>

    </div>

    <!--Para el despliegue del mensaje de salida-->
    <Toast />
  </div>
</template>
<script>
import { reactive,onMounted, ref } from "vue";
import * as tipoService from "@/service/TipoService.js";
import * as categoriaService from "@/service/CategoriaService.js";
import { useToast } from "primevue/usetoast";
import InputMask from "primevue/inputmask";

import { FilterMatchMode } from "primevue/api";

// Importacones para realizar las validaciones
import { email, helpers, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import jsPDF from 'jspdf';
import 'jspdf-autotable';

export default {
  setup() {
    const rolss = JSON.parse(localStorage.getItem("role")) || [];
    const primerRol = ref(rolss[0] || {});
    const idPrimerRol = ref(primerRol.value.id || null);

    const tipos = ref();
    const tipo = ref({});
    const categoria = ref({});
    const categorias = ref([]);
    const displayModal = ref(false);
    const submitted = ref(false);
    const toast = useToast();
    const errores = ref({});
    onMounted(() => {
      listarTipos();
      listarCategorias();
    });

    const listarCategorias = async () => {
      
      const {data} = await categoriaService.index('','','');
      categorias.value=data.categorias.data;
    }


    const dt = ref();
    const deleteProductDialog = ref(false);
    const deleteProductsDialog = ref(false);
    const loading = ref(false);
    const totalRecords = ref(0);
    const selectedProducts = ref();
    const lazyParams = ref({});
   

    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    // METODOS
    const onPage = (event) => {
      lazyParams.value = event;
      listarTipos();
    };

    const onFilter = () => {
      lazyParams.value.filters = filters.value;
      listarTipos();
    };

    const buscar = () => {
      console.log(filters.value.global.value);
      listarTipos();
    };

    // Validaion del formulario
    // Definimos los variables a validar

    const state = reactive( {

        codigo:"",
        nombre:"",
        latitud:"",
        longitud:"",
        id_categoria:"",

    });

    // Tipo de validacion a realizar del dato
    const rules = {

        codigo : { required },
        nombre:{ required },
        latitud:{ required },
        longitud:{ required },
        id_categoria:{ required },

 
    };
    // Seccion de la validacion
    const v$ = useVuelidate(rules, state);


    // Listado de las entidades creadas
    const listarTipos = async () => {
      try {
        loading.value = true;
        let rows = lazyParams.value.rows;
        let q = filters.value.global.value == null?'':filters.value.global.value;
        let page = (lazyParams.value.page == null)?0:lazyParams.value.page;

        const { data } = await tipoService.index(page + 1, rows, q);
        tipos.value = data.tipos.data;
        totalRecords.value = data.tipos.total;
        loading.value = false;

      } catch (error) {
        console.log(error.response.data);
      }
    };

    // Guardar la entidad 
    const guardarTipo = async (isFormValid) => {

     // if(!categoria.value.codigo || !categoria.value.abreviacion || !categoria.value.nombre){
      submitted.value = true;
        // if (!isFormValid) { 
        //   return;
        // }
      //}

          try {
            if (tipo.value.id) {
              
              const codigo_agregado = {codigo_completo: categoria.value.id+tipo.value.id_categoria+tipo.value.codigo};
              tipo.value = Object.assign(tipo.value, codigo_agregado);

              await tipoService.update(tipo.value, tipo.value.id);
              listarTipos();
              closeModal();
              toast.add({
                severity: "success",
                summary: "REGISTRO",
                detail: "El Tipo se actualizó con éxito",
                life: 5000,
              });
            } else {

              const codigo_agregado = {codigo_completo: categoria.value.id+tipo.value.id_categoria+tipo.value.codigo};
              tipo.value = Object.assign(tipo.value, codigo_agregado);

              await tipoService.store(tipo.value);
              listarTipos();
              // categorias.value.push(categoria.value)
              closeModal();
              toast.add({
                severity: "success",
                summary: "REGISTRO",
                detail: "El Tipo de categoría se registró con éxito",
                life: 5000,
              });
            }
          } catch (error) {
            console.log(error.response.data);
            errores.value = error.response.data.errores;
            toast.add({
              severity: "error",
              summary: "ERROR",
              detail: "Error de registro del tipo",
              life: 5000,
            });
          }
    };
    // METODOS DE LA PLANTILLA
    // Abrir un modal
    const openModal = () => {
      tipo.value = {};
      displayModal.value = true;
      submitted.value = false;
    };
    // Abrir un modal
    const editarDialogModal = (datos) => {
      tipo.value = datos;
      displayModal.value = true;
    };

    // Eliminar un producto
    const confirmDeleteProduct = (datos) => {
      tipo.value = datos;
      deleteProductDialog.value = true;
    };

    const deleteProduct = async () => {
      try {
              await tipoService.destroy(tipo.value.id);
              listarTipos();
              toast.add({
                severity: "error",
                summary: "REGISTRO",
                detail: "Tipo Eliminada",
                life: 5000,
              });
              deleteProductDialog.value = false;
              tipo.value = {}; 
      } catch (error) {
            //console.log(error.response.data);
            errores.value = error.response.data.errores;
            toast.add({
              severity: "error",
              summary: "REGISTRO",
              detail: "No se elimino el registro",
              life: 5000,
            });
      }
              
        };

    const confirmDeleteSelected = () => {
            deleteProductsDialog.value = true;
        }; 
    const deleteSelectedProducts = () => {
            products.value = products.value.filter(val => !selectedProducts.value.includes(val));
            deleteProductsDialog.value = false;
            selectedProducts.value = null;
            toast.add({severity:'success', summary: 'Successful', detail: 'Categorias Eliminadas', life: 3000});
        };  
        
    // Cerrar un modal
    const closeModal = () => {
      displayModal.value = false;
      tipo.value = {};
      submitted.value = false;
      listarTipos();
      state.codigo = '';
      state.nombre = '';
      state.id_categoria = '';
    };

    const formatCurrency = (value) => {
      if (value)
        return value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      return;
    };

    // DEVOLVER REGISTROS
    return {
      rolss,
      idPrimerRol,
      v$,
      state,
      rules,
      buscar,
      onFilter,
      onPage,
      loading,
      totalRecords,
      formatCurrency,
      dt,
      deleteProductDialog,
      deleteProductsDialog,
      confirmDeleteProduct,
      deleteProduct,
      confirmDeleteSelected,
      deleteSelectedProducts,
      selectedProducts,
      filters,
      tipos,
      tipo,
      categoria,
      categorias,
      submitted,
      displayModal,
      openModal,
      closeModal,
      listarCategorias,
      guardarTipo,
      editarDialogModal,
      errores,
    };
  },
  methods: {
		exportCSV() {
			this.$refs.dt.exportCSV();
		},
    async exportToPDF() {
      const doc = new jsPDF();

      // Título del PDF
      doc.setFontSize(20);
      doc.text('Gestión de Típos', 10, 10);

      // Obtén los datos de la tabla
      const tableData = this.tipos.map(item => [item.codigo, item.nombre , item.categoria]);

      // Encabezados de la tabla
      const headers = [['Código', 'Típo', 'categoria']];

      // Crea la tabla en el PDF
      doc.autoTable({
        head: headers,
        body: tableData,
        startY: 20,
      });

      // Guarda el PDF
      doc.save('tipos.pdf');
    },
	},
  components: { InputMask },
};


</script>
<style>
.cattipo{
  color:#1565C0;
}
.cattipo:hover{
  color:black;
}
</style>

