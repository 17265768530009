<template>
    <div class="card">
      <table cellpadding="5px" style="width: 100%; background-color: #f8f8f8; height: 45px">
        <tr>
          <td style="font-size: 20px">
            <B>INFRAESTRUCTURA TURÍSTICA</B>
          </td>
        </tr>
      </table>
      <br />

      
  <!-- {{ infraestructuras }} -->
      <!--Tabla de displiegue de la informacion-->
      <DataTable ref="dt" :value="infraestructuras" v-model:selection="selectedProducts" dataKey="id"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros" responsiveLayout="scroll">
  
        <!-- <Column field="id" header="ID" :sortable="true" style="min-width: 2rem"></Column> -->
        <Column field="tipo" header="TIPO" :sortable="false" style="min-width: 8rem"></Column>
        <Column field='subtipo' header="SUBTIPO" :sortable="false" style="min-width: 8rem"></Column>
        <Column field="nombre" header="NOMBRE" :sortable="false" style="min-width: 8rem"></Column>
        <Column field="direccion" header="DIRECCIÓN" :sortable="false" style="min-width: 8rem"></Column>
        <Column field='capacidad' header="CAPACIDAD" :sortable="false" style="min-width: 8rem"></Column>
        <Column field="calidad" header="CALIDAD" :sortable="false" style="min-width: 8rem">
          <template #body="slotProps">
            <span :style="getCellStyle(slotProps.data.calidad)">
              {{ getCalidadLabel(slotProps.data.calidad) }}
            </span>
          </template>
        </Column>
  
      </DataTable>

      <!--Para el despliegue del mensaje de salida-->
      <Toast />
    </div>
  </template>
  
  <script>
  import { reactive,onMounted, ref } from "vue";
  
  import * as subtipoService from '@/service/SubTipoService.js'
  import * as subtipocatService from '@/service/SubTipoCatService.js'
  import * as infraestructuraService from '@/service/InfraestructuraService.js'
  
  import { useToast } from "primevue/usetoast";
  import InputMask from "primevue/inputmask";
  
  import { FilterMatchMode } from "primevue/api";
  
  // Importacones para realizar las validaciones
  import { email, helpers, required } from "@vuelidate/validators";
  import { useVuelidate } from "@vuelidate/core";
  import { useRoute } from 'vue-router'
  import { assertLiteral } from "@babel/types";
  import CryptoJS from 'crypto-js';
  
  
  export default {
    data() {
      return {
        calidadOptions: [
          { nombre: "Bueno", valor: "B" },
          { nombre: "Regular", valor: "R" },
          { nombre: "Malo", valor: "M" },
        ],
      };
    },
    setup() {
  
      const encryptionKey = 'M1n1st3r10d3d3s4rr0ll0pr0duct1v0y3c0n0m14plur4l';
  
      const rolss = JSON.parse(localStorage.getItem("role")) || [];
      const primerRol = ref(rolss[0] || {});
      const idPrimerRol = ref(primerRol.value.id || null);
  
      const route = useRoute();
      const infraestructuraId = route.params.id;
  
      const infraestructuras = ref();
      const infraestructura = ref({});
      const subtipo = ref({});
      const subtipos = ref([]);
      const subtipocats = ref([]);
      const displayModal = ref(false);
      const submitted = ref(false);
      const toast = useToast();
      const errores = ref({});
      
      onMounted(() => {
        listarSubTipos();
        listarInfraestructuras();
        
      });
  
      // const listarSubTipos = async () => {
      //   const {data} = await subtipoService.index('','','');
      //   subtipos.value=data.subtipos.data;
      // }
      const listarSubTipos = async () => {
        const {data} = await subtipoService.showSubTipo(45);
        subtipos.value=data.subtipos;
  
      }
  
      const dt = ref();
      const deleteProductDialog = ref(false);
      const deleteProductsDialog = ref(false);
      const loading = ref(false);
      const totalRecords = ref(0);
      const selectedProducts = ref();
      const lazyParams = ref({});
      
      const filters = ref({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      });
  
      // METODOS
      const onPage = (event) => {
        lazyParams.value = event;
        listarInfraestructuras();
      };
  
      const onFilter = () => {
        lazyParams.value.filters = filters.value;
        listarInfraestructuras();
      };
  
      const buscar = () => {
        console.log(filters.value.global.value);
        listarInfraestructuras();
      };
  
      // Validaion del formulario
      // Definimos los variables a validar
  
      const state = reactive( {
        infraestructura: {
          id_subtipo:"",
        }
      });
  
      // Tipo de validacion a realizar del dato
      const rules = {
        infraestructura: {
          id_subtipo: { required },
          
      }
      };
      // Seccion de la validacion
      const v$ = useVuelidate(rules, state);
  
      // Listado de las entidades creadas
      const listarInfraestructuras = async () => {
        try {
          loading.value = true;
          let ppirtf = route.params.id
          let rows = lazyParams.value.rows;
          let q = filters.value.global.value == null?'':filters.value.global.value;
          let page = (lazyParams.value.page == null)?0:lazyParams.value.page;
  
          const encryptedId = route.params.id.match(/.{1,2}/g).map(byte => String.fromCharCode(parseInt(byte, 16))).join('');
          const sanitizedId = CryptoJS.AES.decrypt(encryptedId, encryptionKey).toString(CryptoJS.enc.Utf8);
          const originalId = sanitizedId.replace(/_/g, '/');
  
          const { data } = await infraestructuraService.index(originalId, page + 1, rows, q);
          // console.log(data)
          infraestructuras.value = data.infraestructura.data;
          totalRecords.value = data.infraestructura.total;
          loading.value = false;
  
        } catch (error) {
          console.log(error.response.data);
        }
      };
  
      // Guardar infraestructura 
      const guardarInfraestructura = async () => {
  
        if(!infraestructura.value.id_subtipo || !infraestructura.value.nombre ||
          !infraestructura.value.direccion || !infraestructura.value.capacidad ||
          !infraestructura.value.calidad){
          submitted.value = true;
          // if (!isFormValid) { 
          //   return;
          // }
        }
  
        try {
          if (infraestructura.value.id) {
            await infraestructuraService.update(infraestructura.value, infraestructura.value.id);
            listarInfraestructuras();
            closeModal();
            toast.add({
              severity: "success",
              summary: "REGISTRO",
              detail: "Infraestructura fue actualizado con éxito",
              life: 5000,
            });
          } else {
  
            const encryptedId = route.params.id.match(/.{1,2}/g).map(byte => String.fromCharCode(parseInt(byte, 16))).join('');
            const sanitizedId = CryptoJS.AES.decrypt(encryptedId, encryptionKey).toString(CryptoJS.enc.Utf8);
            const originalId = sanitizedId.replace(/_/g, '/');
  
            infraestructura.value.id_ppirtf = originalId;
  
            await infraestructuraService.store(infraestructura.value);
            listarInfraestructuras();
            //categorias.value.push(categoria.value)
            closeModal();
            toast.add({
              severity: "success",
              summary: "REGISTRO",
              detail: "Infraestructura Registrado con éxito",
              life: 5000,
            });
          }
        } catch (error) {
          console.log(error.response.data);
          errores.value = error.response.data.errores;
          toast.add({
            severity: "error",
            summary: "ERROR DE REGISTRO",
            detail: "Error de registro de Infraestructura",
            life: 5000,
          });
        }
      };
      // METODOS DE LA PLANTILLA
      // Abrir un modal
      const openModal = () => {
  
        if (infraestructuras.value && infraestructuras.value.length > 0) {
          const idPpirtf = infraestructuras.value[0].id_ppirtf;
          const idTipo = infraestructuras.value[0].id_tipo;
          
          infraestructura.value = {
            id_ppirtf: idPpirtf,
            id_tipo: idTipo,
            // calidad: "", // El valor seleccionado se almacenará aquí
            
          };
          displayModal.value = true;
          submitted.value = false;
        }
        infraestructura.value = {
            id_ppirtf: infraestructuraId,
            id_tipo: '45',
            id_subtipo: 0,
  
          };
          displayModal.value = true;
          submitted.value = false;
      };
  
  
      // Abrir un modal
      const editarDialogModal = (datos) => {
        infraestructura.value = datos;
        displayModal.value = true;
      };
  
      // Eliminar un producto
      const confirmDeleteProduct = (datos) => {
        infraestructura.value = datos;
        deleteProductDialog.value = true;
      };
  
      const deleteProduct = async () => {
        try {
            await infraestructuraService.destroy(infraestructura.value.id);
            listarInfraestructuras();
            toast.add({
              severity: "error",
              summary: "REGISTRO",
              detail: "Infraestructura Eliminado",
              life: 5000,
            });
            deleteProductDialog.value = false;
            infraestructura.value = {}; 
        } catch (error) {
              //console.log(error.response.data);
              errores.value = error.response.data.errores;
              toast.add({
                severity: "error",
                summary: "REGISTRO",
                detail: "No se eliminó el registro",
                life: 5000,
              });
        }
                
      };
  
      const confirmDeleteSelected = () => {
              deleteProductsDialog.value = true;
          }; 
      const deleteSelectedProducts = () => {
              products.value = products.value.filter(val => !selectedProducts.value.includes(val));
              deleteProductsDialog.value = false;
              selectedProducts.value = null;
              toast.add({severity:'success', summary: 'Successful', detail: 'Infraestructura Eliminadas', life: 3000});
          };  
          
      // Cerrar un modal
      const closeModal = () => {
        displayModal.value = false;
        infraestructura.value = {};
        submitted.value = false;
        listarInfraestructuras();
        state.sigla = '';
        state.nombre = '';
      };
  
      const formatCurrency = (value) => {
        if (value)
          return value.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          });
        return;
      };
  
      // DEVOLVER REGISTROS
      return {
        rolss,
        idPrimerRol,
        v$,
        state,
        rules,
        buscar,
        onFilter,
        onPage,
        loading,
        totalRecords,
        formatCurrency,
        dt,
        deleteProductDialog,
        deleteProductsDialog,
        confirmDeleteProduct,
        deleteProduct,
        confirmDeleteSelected,
        deleteSelectedProducts,
        selectedProducts,
        filters,
        infraestructuras,
        infraestructura,
        subtipo,
        subtipos,
        subtipocats,
        submitted,
        displayModal,
        openModal,
        closeModal,
        guardarInfraestructura,
        editarDialogModal,
        errores,
      };
    },
    methods: {
      exportCSV() {
        this.$refs.dt.exportCSV();
      },
      getCalidadLabel(calidad) {
        switch (calidad) {
          case 'B':
            return 'Bueno';
          case 'M':
            return 'Malo';
          case 'R':
            return 'Regular';
          default:
            return calidad;
        }
      },
      getCellStyle(calidad) {
        let backgroundColor;
        switch (calidad) {
          case 'B':
            backgroundColor = '#4CAF50'; // Verde para Bueno
            break;
          case 'M':
            backgroundColor = '#FF5252'; // Rojo para Malo
            break;
          case 'R':
            backgroundColor = '#03A9F4'; // Celeste para Regular
            break;
          default:
            backgroundColor = '#FFFFFF'; // Color blanco para otros valores
        }
        return {
          backgroundColor,
          borderRadius: '0px', // Ajusta el radio de borde según tu preferencia
          padding: '5px 10px', // Añade espacio interno alrededor del texto
          color: '#FFFFFF', // Color del texto
          display: 'inline-block', // Para que el fondo coloreado se ajuste al texto
        };
      },
    },
    components: { InputMask },
  };
  </script>
  
  <style></style>