// store/index.js
import { createStore } from 'vuex';

export default createStore({
  state: {
    contador: 0,
    auth: null, // Aquí se almacenará la información de autenticación del usuario
  },
  mutations: {
    actualizaAuth(state, datUser) {
      state.auth = datUser;
    },
    cerrarSesion(state) {
      state.auth = null;
    }
  },
  actions: {
    loginVuex(context, datos_usuario) {
      context.commit("actualizaAuth", datos_usuario);
    },
    cerrarSesion(context) {
      context.commit("cerrarSesion");
    }
  },
  getters: {
    isAuthenticated(state) {
      return state.auth !== null; // Un getter para verificar si el usuario está autenticado
    }
  },
  modules: {
    // Si tienes otros módulos
  }
});
