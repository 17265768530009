<template>
  <div v-if="isLoading" class="loading-spinner">
    <i class="pi pi-spinner pi-spin"></i> Cargando datos...
  </div>
    <div v-else class="card">
      <table cellpadding="5px" style="width: 100%; background-color: #f8f8f8; height: 45px">
        <tr>
          <td style="font-size: 20px">
            <B>SERVICIO DE COMUNICACIÓN</B>
          </td>
        </tr>
      </table>
      <br />
      <!--Cabecera de menu principal-->
  
      <!--Tabla de displiegue de la informacion-->
      <DataTable ref="dt" :value="comunicaciones" v-model:selection="selectedProducts" dataKey="id"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros" responsiveLayout="scroll">
  
        <!-- <Column field="id" header="ID" :sortable="true" style="min-width: 2rem"></Column> -->
        <Column field="tipo" header="SERVICIO" :sortable="false" style="min-width: 8rem"></Column>
        <Column field='subtipo' header="TIPO" :sortable="false" style="min-width: 8rem"></Column>
        <Column field="cobertura" header="COBERTURA" :sortable="false" style="min-width: 8rem">
          <template #body="slotProps">
            <span :style="getCellStyle(slotProps.data.cobertura)">
              {{ getCoberturaLabel(slotProps.data.cobertura) }}
            </span>
          </template>
        </Column>
        
      </DataTable>
  
      <!--Para el despliegue del mensaje de salida-->
      <Toast />
    </div>
  </template>
  
  <script>
  import { reactive,onMounted, ref } from "vue";
  
  import * as subtipoService from '@/service/SubTipoService.js'
  import * as comunicacionService from '@/service/ComunicacionService.js'
  
  import { useToast } from "primevue/usetoast";
  import InputMask from "primevue/inputmask";
  
  import { FilterMatchMode } from "primevue/api";
  
  // Importacones para realizar las validaciones
  import { email, helpers, required } from "@vuelidate/validators";
  import { useVuelidate } from "@vuelidate/core";
  import { useRoute } from 'vue-router'
  import { assertLiteral } from "@babel/types";
  import CryptoJS from 'crypto-js';
  
  
  export default {
    methods: {
      getCoberturaLabel(cobertura) {
        switch (cobertura) {
          case 'B':
            return 'Bueno';
          case 'M':
            return 'Malo';
          case 'R':
            return 'Regular';
          default:
            return cobertura;
        }
      },
      getCellStyle(cobertura) {
        let backgroundColor;
        switch (cobertura) {
          case 'B':
            backgroundColor = '#4CAF50'; // Verde para Bueno
            break;
          case 'M':
            backgroundColor = '#FF5252'; // Rojo para Malo
            break;
          case 'R':
            backgroundColor = '#03A9F4'; // Celeste para Regular
            break;
          default:
            backgroundColor = '#FFFFFF'; // Color blanco para otros valores
        }
        return {
          backgroundColor,
          borderRadius: '0px', // Ajusta el radio de borde según tu preferencia
          padding: '5px 10px', // Añade espacio interno alrededor del texto
          color: '#FFFFFF', // Color del texto
          display: 'inline-block', // Para que el fondo coloreado se ajuste al texto
        };
      },
    },
  
    data() {
      return {
        coberturaOptions: [
          { nombre: "Bueno", valor: "B" },
          { nombre: "Regular", valor: "R" },
          { nombre: "Malo", valor: "M" },
        ],
      };
    },
    
    setup() {

      const isLoading = ref(true);
      const simulateDataLoading = async () => {
        await new Promise((resolve) => setTimeout(resolve, 1000)); 
        isLoading.value = false;
      };
  
      const encryptionKey = 'M1n1st3r10d3d3s4rr0ll0pr0duct1v0y3c0n0m14plur4l';
  
      const rolss = JSON.parse(localStorage.getItem("role")) || [];
      const primerRol = ref(rolss[0] || {});
      const idPrimerRol = ref(primerRol.value.id || null);
  
      const route = useRoute();
      const comunicacionId = route.params.id;
  
      const comunicacion = ref({});
      const comunicaciones = ref();
      const subtipo = ref({});
      const subtipos = ref([]);
      const subtipocats = ref([]);
      const displayModal = ref(false);
      const submitted = ref(false);
      const toast = useToast();
      const errores = ref({});
  
      onMounted(() => {
        listarSubTipos();
        listarComunicaciones();
        simulateDataLoading();
        
       });
  
      const listarSubTipos = async () => {
        const {data} = await subtipoService.showSubTipo(46);
        subtipos.value=data.subtipos;
  
      }
  
      const dt = ref();
      const deleteProductDialog = ref(false);
      const deleteProductsDialog = ref(false);
      const loading = ref(false);
      const totalRecords = ref(0);
      const selectedProducts = ref();
      const lazyParams = ref({});
     
      const filters = ref({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      });
  
      // METODOS
      const onPage = (event) => {
        lazyParams.value = event;
        listarComunicaciones();
      };
  
      const onFilter = () => {
        lazyParams.value.filters = filters.value;
        listarComunicaciones();
      };
  
      const buscar = () => {
        console.log(filters.value.global.value);
        listarComunicaciones();
      };
  
      const state = reactive( {
        comunicacion: {
          id_subtipo:"",
        }
      });
  
      // Tipo de validacion a realizar del dato
      const rules = {
        comunicacion: {
          id_subtipo: { required },
          
      }
      };
      // Seccion de la validacion
      const v$ = useVuelidate(rules, state);
  
      // Listado de las entidades creadas
      const listarComunicaciones = async () => {
        try {
          loading.value = true;
          let ppirtf = route.params.id
          let rows = lazyParams.value.rows;
          let q = filters.value.global.value == null?'':filters.value.global.value;
          let page = (lazyParams.value.page == null)?0:lazyParams.value.page;
  
          const encryptedId = route.params.id.match(/.{1,2}/g).map(byte => String.fromCharCode(parseInt(byte, 16))).join('');
          const sanitizedId = CryptoJS.AES.decrypt(encryptedId, encryptionKey).toString(CryptoJS.enc.Utf8);
          const originalId = sanitizedId.replace(/_/g, '/');
  
          const { data } = await comunicacionService.index(originalId, page + 1, rows, q);
          console.log(data)
          comunicaciones.value = data.comunicacion.data;
          totalRecords.value = data.comunicacion.total;
          loading.value = false;
  
        } catch (error) {
          console.log(error.response.data);
        }
      };

  
      // METODOS DE LA PLANTILLA
  
      // DEVOLVER REGISTROS
      return {
        rolss,
        idPrimerRol,
        v$,
        state,
        rules,
        buscar,
        onFilter,
        onPage,
        loading,
        totalRecords,
        dt,
        deleteProductDialog,
        deleteProductsDialog,
        selectedProducts,
        filters,
        comunicaciones,
        comunicacion,
        subtipo,
        subtipos,
        subtipocats,
        submitted,
        displayModal,
        errores,
        isLoading,
      };
    },
    components: { InputMask },
  };
  </script>
  
  <style></style>
  