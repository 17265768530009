import {http, urlBase} from "./Http"

// Listar Declaratoria
export const index = (ppirta = ppirta, page=1, rows=10, q=null) => {
  return http().get(`${urlBase}/actppirta?ppirta=${ppirta}&page=${page}&rows=${rows}&q=${q}`);
}

// Guardar Declaratoria
export const save = (datos) => {
  return http().post(`${urlBase}/actppirta`, datos);
}

// Editar Declaratoria

export const update = (datos, id) => {
  return http().put(`${urlBase}/actppirta/${id}`, datos);
}
// eliminar Declaratoria
export const destroy = (id) => {
  return http().delete(`${urlBase}/actppirta/${id}`);
}
// MOSTRAR
export const show = () => {
  return http().get(`${urlBase}/listvia`);
}
// Estado Declaratoria
export const listest = () => {
  return http().get(`${urlBase}/listestado`);
}
// Listado Motivacion
export const listarMotivacion = () => {
  return http().get(`${urlBase}/motppirta`);
}

// Listado Actividad
export const listarActi= (id) => {
  return http().get(`${urlBase}/tiposub/${id}`);
}