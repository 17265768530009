<template>
    <div class="layer-buttons">
      <button @click="switchMapLayer('OpenStreetMap')" class="btn">OpenStreetMap</button>
      <button @click="switchMapLayer('Satellite')" class="btn">Satelital</button>
    </div><br>
  
    <div style="position: relative; height: 350px !important; width: 100% !important">
      <l-map ref="map" v-model:zoom="zoom" :center="initialCenter" @click="onMapClick">
        <l-tile-layer
          v-if="selectedLayer === 'OpenStreetMap'"
          :url="'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'"
          layer-type="base"
          name="OpenStreetMap"
        ></l-tile-layer>
        <l-tile-layer
          v-else-if="selectedLayer === 'Satellite'"
          :url="'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'"
          layer-type="base"
          name="Satellite"
        ></l-tile-layer>
        <l-marker
          visible
          :draggable="!disabled"
          :icon="icon"
          :lat-lng.sync="position"
          @dragstart="dragging = true"
          @dragend="dragStartHandler"
        ></l-marker>
      </l-map>
      <div class="search-container">
        <input
          type="text"
          v-model="searchQuery"
          @input="searchLocation"
          placeholder="Buscar ubicación..."
          class="search-input"
        />
        <ul v-if="searchResults.length" class="search-results">
          <li v-for="(result, index) in searchResults" :key="index" @click="selectLocation(result)">
            {{ result.label }}
          </li>
        </ul>
      </div>
    </div>
  </template>
  
  <script>
  import "leaflet/dist/leaflet.css";
  import { icon } from 'leaflet';
  import { LMap, LTileLayer, LMarker, LPolygon } from "@vue-leaflet/vue-leaflet";
  import { OpenStreetMapProvider } from "leaflet-geosearch";
  
  export default {
    props: {
        id: String,
        title: String,
        zoom: Number,
        lati: Number,
        long: Number
    },
    components: {
      LMap,
      LTileLayer,
      LMarker,
      LPolygon,
    },
  
    data() {
 
      return {
        initialCenter: [-16.5021332, -68.1312058],
        zoom: 15,
        disabled: false,
        icon: icon({
          iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
          iconUrl: require('leaflet/dist/images/marker-icon.png'),
          shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
          iconSize: [30, 50],
          iconAnchor: [15, 50],
        }),
        position: { lat: -16.5021332, lng: -68.1312058 },
        searchQuery: "",
        searchResults: [],
        selectedLayer: "OpenStreetMap",
      };
    },
  
    methods: {
      onMapClick(value) {
        if (!this.disabled) {
          this.position = value.latlng;
          this.$emit('newPosition', { position: this.position });
        }
      },
      dragStartHandler(e) {
        const latlng = e.target.getLatLng();
        if (!this.disabled) {
          this.position = latlng;
          this.$emit('newPosition', { position: this.position });
        }
      },
      cargarPosition() {
        if (this.lat && this.lng) {
          this.position = {
            lat: this.lat,
            lng: this.lng,
          };
        } else if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((pos) => {
            this.position = {
              lat: this.lat,
              lng: this.lng,
            };
          });
        }
      },
      searchLocation() {
        if (this.searchQuery.length > 2) {
          const provider = new OpenStreetMapProvider();
          provider.search({ query: this.searchQuery }).then((results) => {
            this.searchResults = results;
          });
        } else {
          this.searchResults = [];
        }
      },
      selectLocation(result) {
        this.position = {
          lat: result.y,
          lng: result.x,
        };
        this.searchQuery = result.label;
        this.searchResults = [];
  
        // Obtener la referencia al mapa y centrar en la ubicación seleccionada
        const map = this.$refs.map.mapObject;
        map.setView(this.position);
  
        this.$emit('newPosition', { position: this.position });
      },
      switchMapLayer(layerName) {
        this.selectedLayer = layerName;
      },
  
    },
    created() {
        this.cargarPosition();

        if (this.lati && this.long) {
            this.initialCenter = [this.lati, this.long];
            this.position = {
            lat: this.lati,
            lng: this.long
            };
            this.zoom = 16;
        }
    }

  };
  </script>
  
  <style>
  .search-container {
    position: absolute;
    top: 10px;
    left: 400px;
    z-index: 1000;
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    width:50%;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .search-input {
    width: 100%;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
  }
  
  .search-results {
    list-style: none;
    padding: 0;
    margin: 0;
    border-top: 1px solid #ccc;
    max-height: 150px;
    overflow-y: auto;
  }
  
  .search-results li {
    padding: 5px;
    cursor: pointer;
  }
  
  .search-results li:hover {
    background-color: #f0f0f0;
  }
  .btn{
    padding: 6px;
    margin-left: 4px;
    cursor: pointer;
    border-radius: 5px;
  }
  .btn:hover{
    background: #1565C0;
    color: white;
  }
  </style>