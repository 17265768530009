import {http, urlBase} from "./Http"

// Listar Tramo
export const index = (ppirta = ppirta, page=1, rows=10, q=null) => {
  return http().get(`${urlBase}/rutappirta?ppirta=${ppirta}&page=${page}&rows=${rows}&q=${q}`);
}

// Guardar Tramo
export const save = (datos) => {
  return http().post(`${urlBase}/rutappirta`, datos);
}

// Editar Tramo

export const update = (datos, id) => {
  return http().put(`${urlBase}/rutappirta/${id}`, datos);
}
// eliminar tramo
export const destroy = (id) => {
  return http().delete(`${urlBase}/rutappirta/${id}`);
}
// MOSTRAR
export const show = () => {
  return http().get(`${urlBase}/listvia`);
}
// Estado Tramo
export const listest = () => {
  return http().get(`${urlBase}/listestado`);
}
// Listado Mes Tramo
export const listmes = () => {
  return http().get(`${urlBase}/listmes`);
}