import {http, urlBase} from "./Http"

// Listar autenticidad
export const index = (ppirta = ppirta, page=1, rows=10, q=null) => {
  return http().get(`${urlBase}/autppirta?ppirta=${ppirta}&page=${page}&rows=${rows}&q=${q}`);
}

// Guardar autenticidad
export const save = (datos) => {
  return http().post(`${urlBase}/autppirta`, datos);
}

// Editar autenticidad

export const update = (datos, id) => {
  return http().put(`${urlBase}/autppirta/${id}`, datos);
}
// eliminar autenticidad
export const destroy = (id) => {
  return http().delete(`${urlBase}/autppirta/${id}`);
}
// MOSTRAR
export const show = () => {
  return http().get(`${urlBase}/listvia`);
}
// Estado autenticidad
export const listest = () => {
  return http().get(`${urlBase}/listestado`);
}
// Listado autenticidad
export const listautenticidad = () => {
  return http().get(`${urlBase}/listauten`);
}