<template>
  <div style="position: relative; height: 450px !important; width: 100% !important">
    <l-map ref="map" v-model:zoom="zoom" :center="initialCenter">
      <l-tile-layer
        v-if="selectedLayer === 'OpenStreetMap'"
        :url="'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'"
        layer-type="base"
        name="OpenStreetMap"
      ></l-tile-layer>
      <l-tile-layer
        v-else-if="selectedLayer === 'Satellite'"
        :url="'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'"
        layer-type="base"
        name="Satellite"
      ></l-tile-layer>
      <l-marker
        visible
        :icon="icon"
        :lat-lng.sync="position"
      ></l-marker>
    </l-map>
  </div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import { icon } from 'leaflet';
import { LMap, LTileLayer, LMarker } from "@vue-leaflet/vue-leaflet";

export default {
  props: {
    lati: Number,
    long: Number
  },
  components: {
    LMap,
    LTileLayer,
    LMarker,
  },

  data() {
    return {
      initialCenter: [-16.5021332, -68.1312058],
      zoom: 15,
      icon: icon({
        iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
        iconUrl: require('leaflet/dist/images/marker-icon.png'),
        shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
        iconSize: [30, 50],
        iconAnchor: [15, 50],
      }),
      position: { lat: -16.5021332, lng: -68.1312058 },
      selectedLayer: "OpenStreetMap",
    };
  },

  methods: {
    cargarPosition() {
      if (this.lati && this.long) {
        this.position = {
          lat: this.lati,
          lng: this.long,
        };
        this.initialCenter = [this.lati, this.long];
        this.zoom = 16;

        localStorage.setItem("savedLatitude", this.lati);
        localStorage.setItem("savedLongitude", this.long);
      } else if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((pos) => {
          this.position = {
            lat: pos.coords.latitude,
            lng: pos.coords.longitude,
          };
        });
      }
    },

    switchMapLayer(layerName) {
      this.selectedLayer = layerName;
    },
  },

  created() {
    const savedLatitude = localStorage.getItem("savedLatitude");
    const savedLongitude = localStorage.getItem("savedLongitude");

    if (savedLatitude && savedLongitude) {
      this.position = {
        lat: parseFloat(savedLatitude),
        lng: parseFloat(savedLongitude),
      };
      this.initialCenter = [parseFloat(savedLatitude), parseFloat(savedLongitude)];
      this.zoom = 16;
    } else {
      this.cargarPosition();
    }
  },

  beforeDestroy() {
    localStorage.removeItem("savedLatitude");
    localStorage.removeItem("savedLongitude");
  }
};
</script>

<style>
.btn {
  padding: 6px;
  margin-left: 4px;
  cursor: pointer;
  border-radius: 5px;
}
.btn:hover {
  background: #1565C0;
  color: white;
}
.layer-buttons {
  text-align: center;
}
</style>
