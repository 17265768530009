<template>
  <div class="card">
    <table cellpadding="5px" style="width: 100%; background-color: #f8f8f8; height: 45px">
      <tr>
        <td style="font-size: 20px">
          <B>GESTIÓN DE REGISTRO DE RECURSOS TURÍSTICOS ATRACTIVOS</B>
        </td>
      </tr>
    </table>
    <br />
    <!--Cabecera de menu principal-->
    <Toolbar class="mb-4">
      <template #start><router-link to="/nuevo">
          <Button label="Nuevo registro" icon="pi pi-plus" class="p-button-success mr-2" /></router-link>
      </template>
      <template #end>
        <!--Button label="CSV" icon="pi pi-file-excel" class="p-button-help" @click="exportCSV($event)" /-->&nbsp;
        <!-- <Button label="PDF" icon="pi pi-file-pdf" class="p-button-help" @click="exportToPDF" /> -->
      </template>
    </Toolbar>

    <DataTable ref="dt" :lazy="true" :value="atractivos" v-model:selection="selectedProducts" dataKey="id"
      :paginator="true" :rows="10" :filters="filters" :totalRecords="totalRecords" :loading="loading"
      @page="onPage($event)" @filter="onFilter($event)"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros" responsiveLayout="scroll">
      <template #header>
        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
          <h5 class="m-0">RECURSOS TURÍSTICOS ATRACTIVOS</h5>
          <span class="block mt-2 md:mt-0 p-input-icon-left">
            <i class="pi pi-search" />
            <InputText v-model="filters['global'].value" @input="buscar()" placeholder="Buscar..." />
          </span>
        </div>
      </template>


      <!-- <Column field="id" header="ID" :sortable="false" style="min-width: 2rem"></Column> -->
      <Column v-if="idPrimerRol === 1 || idPrimerRol === 2 || idPrimerRol === 3" :exportable="false" style="min-width: 4rem" header="SEGUIMIENTO">
        <template #body="slotProps">
          <!-- <Button icon="pi pi-file-pdf" class="p-button-danger" @click="confirmarVerPDF(slotProps.data)" :title="'PDF de seguimiento a : '+slotProps.data.cite" /> -->
          <Button icon="pi pi-file-pdf" class="p-button-danger" @click="verPDF(slotProps.data)" :title="'PDF de seguimiento a: ' + slotProps.data.cite" />
        </template>
      </Column>

      <Column field="cite" header="CITE" :sortable="false" style="min-width: 6rem"></Column>
      <Column field="nombre_atractivo" header="ATRACTIVO TURÍSTICO" :sortable="false" style="min-width: 6rem"></Column>
      <Column field='categoria' header="CATEGORÍA" :sortable="false" style="min-width: 6rem"></Column>
      <Column field='tipo' header="TIPO" :sortable="false" style="min-width: 6rem"></Column>
      <Column field='subtipo' header="SUBTIPO" :sortable="false" style="min-width: 6rem"></Column>
      <Column field="codigo" header="CÓDIGO" :sortable="false" style="min-width: 10rem"></Column>
      <Column field="jerarquia" header="JERARQUÍA" :sortable="false" style="min-width: 8rem"></Column>
      <Column :exportable="false" style="min-width: 12rem" header="ACCIONES">

        <template #body="slotProps">

          <!--NACIONAL-->
          <span v-if="idPrimerRol === 2" :style="getCellStyle(slotProps.data.derivado)" class="p-button-rounded mr-1 cursor-pointer" @click="mostrarMensajeAprobado" title="APROBADO">
            {{ getAprobadoLabel(slotProps.data.derivado) }}
          </span>

          <Button v-if="idPrimerRol === 2 && slotProps.data.derivado !== 1" class="p-button-primary mr-1" @click="confirmAprobar(slotProps.data)" title="Aprobar para el catálogo nacional" >
            Aprobar
          </Button>

          <router-link v-if="idPrimerRol === 2 && slotProps.data.derivado === 1" :to="getEncryptedURLVer(slotProps.data.id)">
            <Button class=" p-button-info mr-1" @click="crearLocalstorage(slotProps.data)"
              title="Ver información">
              Ver info
            </Button>
          </router-link>

          <router-link v-if="idPrimerRol === 2 && slotProps.data.derivado !== 1" :to="getEncryptedURL(slotProps.data.id)">
            <Button icon="pi pi-pencil" class="p-button-warning mr-2" @click="crearLocalstorage(slotProps.data)" title="Editar" />
          </router-link>
          <!--NACIONAL-->

          <!--GOBERNACION-->
          <span v-if="idPrimerRol === 3" :style="getCellStyle(slotProps.data.derivado)" class="p-button-rounded mr-1 cursor-pointer" @click="mostrarMensajeDerivado" title="DERIVADO">
            {{ getDerivadoLabel(slotProps.data.derivado) }}
          </span>

          <Button v-if="idPrimerRol === 3 && slotProps.data.derivado !== 1" class="p-button-primary mr-1"
            @click="confirmDerivar(slotProps.data)" title="Derivar">
            Derivar
          </Button>
          
          <router-link v-if="idPrimerRol === 3 && slotProps.data.derivado === 1" :to="getEncryptedURLVer(slotProps.data.id)">
            <Button class=" p-button-info mr-1" @click="crearLocalstorage(slotProps.data)"
              title="Ver información">
              Ver info
            </Button>
          </router-link>

          <router-link v-if="idPrimerRol === 1 || idPrimerRol === 3 && slotProps.data.derivado !== 1" :to="getEncryptedURL(slotProps.data.id)">
            <Button icon="pi pi-pencil" class="p-button-warning mr-2" @click="crearLocalstorage(slotProps.data)" title="Editar" />
          </router-link>
          <!--GOBERNACION-->
          
          <Button v-if="idPrimerRol === 1" icon="pi pi-trash" class="p-button-danger"
            @click="confirmDeleteProduct(slotProps.data)" title="Borrar registro" />

        </template>
      </Column>

    </DataTable>

    <!--Formulario de eliminacion -->
    <div class="floatlabel-demo col-12 md:col-12">
      <Dialog v-model:visible="deleteProductDialog" :style="{ width: '450px' }" header="Confirmar" :modal="true">
        <div class="confirmation-content">
          <h4><i class="pi pi-exclamation-triangle mr-2" style="font-size: 2rem" /><b>¿Está seguro de borrar el
              registro?</b></h4><br>
          <span v-if="atractivo"><b style="color: #4E5FBB;">{{ atractivo.nombre_atractivo }}</b></span><br>
          <span>¡Si no lo está puede cancelar la accíón!</span>
        </div>
        <template #footer>
          <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="deleteProductDialog = false" />
          <Button label="Si, borrar registro" icon="pi pi-check" @click="deleteProduct" />
        </template>
      </Dialog>
    </div>

    <!--Formulario de derivar registro gobernacion-->
    <div class="floatlabel-demo col-12 md:col-12">
      <Dialog v-model:visible="derivarDialog" :style="{ width: '30%' }" header="" :modal="true" :closable="false">
        <div class="confirmation-content">
          <h4><b>¿Está seguro de Derivar a la Gobernación de {{ getNombreDepartamento(atractivo.id_departamento) }}?</b></h4><br>
          <span v-if="atractivo">
            Cite : <b style="color: #4E5FBB;"> {{ atractivo.cite }}</b><br><br>
            Atractivo Turístico : <b style="color: #4E5FBB;"> {{ atractivo.nombre_atractivo }}</b>
          </span><br>

        </div>
        <template #footer>
          <Button label="Cancelar" icon="pi pi-times" class="p-button-danger" @click="derivarDialog = false" />
          <Button label="DERIVAR" icon="pi pi-check" @click="derivarRegistro" />
        </template>
      </Dialog>
    </div>


    <!--Formulario de aprobar nacional -->
    <div class="floatlabel-demo col-12 md:col-12">
      <Dialog v-model:visible="aprobarDialog" :style="{ width: '30%' }" header="" :modal="true" :closable="false">
        <div class="confirmation-content">
          <h4><b>¿Está seguro de Aprobar a la Nacional de {{ getNombreDepartamento(atractivo.id_departamento) }}?</b></h4><br>
          <span v-if="atractivo">
            Cite : <b style="color: #4E5FBB;"> {{ atractivo.cite }}</b><br><br>
            Atractivo Turístico : <b style="color: #4E5FBB;"> {{ atractivo.nombre_atractivo }}</b>
            
          </span><br>
          <!-- <span>¡Si no lo está puede cancelar la accíón!</span> -->
        </div>
        <template #footer>
          <Button label="Cancelar" icon="pi pi-times" class="p-button-danger" @click="aprobarDialog = false" />
          <Button label="APROBAR" icon="pi pi-check" @click="aprobarRegistro" />
        </template>
      </Dialog>

    </div>

     <!-- Diálogo para mostrar el PDF -->
     <Dialog v-model:visible="pdfDialogVisible" :style="{ width: '90%' }" header="" position="top" :modal="true" :closable="false">
      <embed v-if="pdffs" :src="getPdfSource()" type="application/pdf" width="100%" height="650px" />
      <div style="height: 100px;text-align: center; padding-top: 50px;color: #4E5FBB;" v-else>Para ver el seguimiento, haga clic en PDF seguimiento</div>
    
      <template #footer>
        <Button label="Cerrar" icon="pi pi-times" class="p-button-text" @click="closeModalpdf" />
        <!-- <Button v-if="!pddfs" label="PDF seguimiento" icon="pi pi-check" :loading="loading" @click="verPDF" /> -->
      </template>
    </Dialog>

    <!--Para el despliegue del mensaje de salida-->
    <Toast />
  </div>
</template>

<script>
import { reactive, onMounted, ref } from "vue";

import * as departamentoSevice from '@/service/DepartamentoService.js'
import * as entidadSevice from '@/service/EntidadService.js'
import * as rolSevice from '@/service/RolService.js'
import * as plantillaSevice from '@/service/PlantillaService.js'
import * as pdfService from '@/service/PdfService.js'

import { useToast } from "primevue/usetoast";
import InputMask from "primevue/inputmask";
import { FilterMatchMode } from "primevue/api";

// Importacones para realizar las validaciones
import { email, helpers, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import CryptoJS from 'crypto-js';

export default {
  methods: {
    crearLocalstorage(atractivo) {
      localStorage.setItem("savedLatitude", atractivo.g_x);
      localStorage.setItem("savedLongitude", atractivo.g_y);
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    async exportToPDF() {
      const doc = new jsPDF('landscape'); // Configura la orientación horizontal
      doc.addFont('Helvetica', 'normal'); // Agrega la fuente que desees

      // Configura el tamaño y posición del título centrado
      const title = 'Gestión de Atractivos Turísticos';
      const titleWidth = doc.getStringUnitWidth(title) * doc.internal.getFontSize() / doc.internal.scaleFactor;
      const titleX = (doc.internal.pageSize.getWidth() - titleWidth) / 2;
      doc.setFontSize(20);
      doc.text(title, titleX, 10);

      // Obtén los datos de la tabla
      const tableData = this.atractivos.map(item => [item.nombre_atractivo, item.categoria, item.tipo, item.subtipo, item.codigo, item.jerarquia]);

      // Encabezados de la tabla
      const headers = [['Atractivo Turístico', 'Categoría', 'Típo', 'Subtipo', 'Código', 'Jerarquía']];

      // Crea la tabla en el PDF
      doc.autoTable({
        head: headers,
        body: tableData,
        startY: 20,
      });

      // Guarda el PDF
      doc.save('atractivos.pdf');
    },
    getDerivadoLabel(derivado) {
      switch (derivado) {
        case 1:
          return 'Derivado';
        default:
          return derivado;
      }
    },
    getAprobadoLabel(aprobado_nacional) {
      switch (aprobado_nacional) {
        case 1:
          return 'Aprobado';
        default:
          return aprobado_nacional;
      }
    },
    getCellStyle(derivado) {
      let backgroundColor;
      switch (derivado) {
        case 1:
          backgroundColor = '#54ad07';
          break;
        default:
          backgroundColor = '#FFFFFF';
      }
      return {
        backgroundColor,
        padding: '8px',
        color: '#FFFFFF',
        borderRadius: '5px',
        display: 'inline-block',
        marginTop: '2px',
        fontWeight: 'bold',
      };
    },
    getEncryptedURL(id) {
      const encryptionKey = 'M1n1st3r10d3d3s4rr0ll0pr0duct1v0y3c0n0m14plur4l';
      const sanitizedId = id.toString().replace(/\//g, '_');
      const encryptedId = CryptoJS.AES.encrypt(sanitizedId, encryptionKey).toString();
      const hexEncodedId = Array.from(encryptedId, (char) => char.charCodeAt(0).toString(16)).join('');

      return {
        name: 'informacion',
        params: { 'id': hexEncodedId },
      };
    },

    getEncryptedURLVer(id) {
      const encryptionKey = 'M1n1st3r10d3d3s4rr0ll0pr0duct1v0y3c0n0m14plur4l';
      const sanitizedId = id.toString().replace(/\//g, '_');
      const encryptedId = CryptoJS.AES.encrypt(sanitizedId, encryptionKey).toString();
      const hexEncodedId = Array.from(encryptedId, (char) => char.charCodeAt(0).toString(16)).join('');

      return {
        name: 'ver',
        params: { 'id': hexEncodedId },
      };
    },

  },

  setup() {

    const rolss = JSON.parse(localStorage.getItem("role")) || [];
    const primerRol = ref(rolss[0] || {});
    const idPrimerRol = ref(primerRol.value.id || null);

    const atractivos = ref();
    const atractivo = ref({});
    const displayModal = ref(false);
    const submitted = ref(false);
    const toast = useToast();
    const errores = ref({});
    const departamentos = ref([]);
    const entidades = ref([]);
    const roles = ref([]);

    onMounted(() => {
      listarAtractivos();
      getDepartamentos();
      getEntidad();
      getRol();
    });

    const getDepartamentos = async () => {
      const { data } = await departamentoSevice.index('', '', '');
      departamentos.value = data.departamentos.data;
    }

    const getEntidad = async () => {
      const { data } = await entidadSevice.index('', '', '');
      entidades.value = data.entidades.data;
    }

    const getRol = async () => {
      const { data } = await rolSevice.index();
      roles.value = data.rol.data;
    }

    const listaSexos = ref([
      { sexo: 'MASCULINO', code_s: 'M' },
      { sexo: 'FEMENINO', code_s: 'F' }
    ]);

    const listaNacionalidades = ref([
      { nacionalidad: 'BOLIVIANA', code_n: '0' },
      { nacionalidad: 'EXTRANJERO', code_n: '1' }
    ]);

    // Definimos los variables a validar
    const state = reactive({
      email: "",
    });

    // Tipo de validacion a realizar del dato
    const rules = {
      email: { required, email },
    };
    // Seccion de la validacion
    const v$ = useVuelidate(rules, state);

    const dt = ref();
    const deleteProductDialog = ref(false);
    const derivarDialog = ref(false);
    const aprobarDialog = ref(false);
    const deleteProductsDialog = ref(false);
    const loading = ref(false);
    const totalRecords = ref(0);
    const selectedProducts = ref();
    const lazyParams = ref({});

    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    // METODOS
    const onPage = (event) => {
      lazyParams.value = event;
      listarAtractivos();
    };

    const onFilter = () => {
      lazyParams.value.filters = filters.value;
      listarAtractivos();
    };

    const buscar = () => {
      // console.log(filters.value.global.value);
      listarAtractivos();
    };

    // Listado de las entidades creadas
    const listarAtractivos = async () => {
      try {
        loading.value = true;
        let rows = lazyParams.value.rows;
        let q = filters.value.global.value == null ? '' : filters.value.global.value;
        let page = (lazyParams.value.page == null) ? 0 : lazyParams.value.page;

        const { data } = await plantillaSevice.index(page + 1, rows, q);
        atractivos.value = data.ppirta.data;
        totalRecords.value = data.ppirta.total;
        loading.value = false;
        console.log(atractivos.value)

      } catch (error) {
        // console.log(error.response.data);
      }
    };

    // METODOS DE LA PLANTILLA
    // Abrir un modal
    const openModal = () => {
      atractivo.value = {};
      displayModal.value = true;
      submitted.value = false;
    };
    // Abrir un modal
    const editarDialogModal = (datos) => {
      atractivo.value = datos;
      displayModal.value = true;
    };

    // Eliminar un producto
    const confirmDeleteProduct = (datos) => {
      atractivo.value = datos;
      deleteProductDialog.value = true;
    };

    //Mensaje de derivado
    const mostrarMensajeDerivado = () => {
      toast.add({ severity: 'info', summary: 'Este registro ya fue Derivado', life: 5000 });
    };

    //Mensaje de aprobado
    const mostrarMensajeAprobado = () => {
      toast.add({ severity: 'info', summary: 'Este registro ya fue Aprobado para el Catálogo Nacional', life: 5000 });
    };

    const deleteProduct = async () => {
      try {
        await plantillaSevice.destroy(atractivo.value.id);
        listarAtractivos();
        toast.add({
          severity: "error",
          summary: "REGISTRO",
          detail: "Atractivo turístico eliminado con éxito",
          life: 5000,
        });
        deleteProductDialog.value = false;
        atractivo.value = {};
      } catch (error) {
        //console.log(error.response.data);
        errores.value = error.response.data.errores;
        toast.add({
          severity: "error",
          summary: "REGISTRO",
          detail: "No se eliminó el Atractivo turístico",
          life: 5000,
        });
      }

    };

    // Derivar
    const confirmDerivar = (datos) => {
      atractivo.value = datos;
      derivarDialog.value = true;
    };

    const derivarRegistro = async () => {
      try {

        const estadoDerivado = await plantillaSevice.derivarPpirta(atractivo.value.id);

        //console.log(atractivo.value.id)
        listarAtractivos();
        toast.add({
          severity: "success",
          summary: "REGISTRO",
          detail: "Se Derivó a la Gobernación con éxito",
          life: 5000,
        });
        derivarDialog.value = false;
        atractivo.value = {};
      } catch (error) {
        //console.log(error.response.data);
        errores.value = error.response.data.errores;
        toast.add({
          severity: "error",
          summary: "REGISTRO",
          detail: "No se pudo derivar a la gobernación",
          life: 5000,
        });
      }

    };

    // Aprobar
    const confirmAprobar = (datos) => {
      atractivo.value = datos;
      aprobarDialog.value = true;
    };

    const aprobarRegistro = async () => {
      try {
        
        await plantillaSevice.aprobarPpirta(atractivo.value.id);

        console.log(atractivo.value.id)
        listarAtractivos();
        toast.add({
          severity: "success",
          summary: "REGISTRO",
          detail: "Se Aprobó para el catálogo Nacional",
          life: 5000,
        });
        aprobarDialog.value = false;
        atractivo.value = {};
      } catch (error) {
        //console.log(error.response.data);
        errores.value = error.response.data.errores;
        toast.add({
          severity: "error",
          summary: "REGISTRO",
          detail: "No se aprobó el registro",
          life: 5000,
        });
      }

    };

    const confirmDeleteSelected = () => {
      deleteProductsDialog.value = true;
    };
    const deleteSelectedProducts = () => {
      products.value = products.value.filter(val => !selectedProducts.value.includes(val));
      deleteProductsDialog.value = false;
      selectedProducts.value = null;
      toast.add({ severity: 'success', summary: 'Successful', detail: 'Categorias Eliminadas', life: 3000 });
    };

    const pdff = ref({});
      const pdffs = ref();

      const pdfDialogVisible = ref(false);

      // Implementación de la función para obtener la fuente del PDF
      const getPdfSource = () => {
        // Asegúrate de que pdffs contenga los datos en formato base64 del PDF
        return `data:application/pdf;base64,${pdffs.value}`;
      };

      // Función para mostrar el diálogo del PDF
      const confirmarVerPDF = (datos) => {
        pdff.value = datos;
        pdfDialogVisible.value = true;
      };

      // Función para ver el PDF
      const verPDF = async (datos) => {
        pdff.value = datos;
        loading.value = true;
        const startTime = performance.now(); // Registro del tiempo de inicio

        try {
          const { data } = await pdfService.ppirtaseguimientoPDF(pdff.value.id);
         
          pdffs.value = data.pdf;
          pdfDialogVisible.value = true;
        } catch (error) {
          pdfDialogVisible.value = false;
          loading.value = false;
          toast.add({
          severity: "error",
          summary: "PDF",
          detail: "No se pudo generar el PDF",
          life: 5000,
        });
        } finally {
        const endTime = performance.now(); // Registro del tiempo de finalización
        const elapsedTime = endTime - startTime; // Cálculo del tiempo transcurrido en milisegundos

        // Establecer un límite de tiempo
        const timeoutDuration = 20000;
        const remainingTime = timeoutDuration - elapsedTime;

        if (remainingTime > 0) {

          setTimeout(() => {
            loading.value = false;
          }, remainingTime);
        } else {
          loading.value = false;
        }
      }
      };

      // Cerrar un modal
      const closeModalpdf = () => {
        loading.value = false;
        pdfDialogVisible.value = false;
        pdffs.value = null;
        
      };

    // Cerrar un modal
    const closeModal = () => {
      displayModal.value = false;
      atractivo.value = {};
      submitted.value = false;
      listarAtractivos();
      state.codigo = '';
      state.nombre = '';
      state.id_macro_region = '';
    };

    const formatCurrency = (value) => {
      if (value)
        return value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      return;
    };

    // DEVOLVER REGISTROS
    return {
      getPdfSource,
      confirmarVerPDF,
      pdfDialogVisible,
      verPDF,
      pdff,
      pdffs,
      rolss,
      idPrimerRol,
      v$,
      listaSexos,
      listaNacionalidades,
      departamentos,
      entidades,
      roles,
      state,
      rules,
      buscar,
      onFilter,
      onPage,
      loading,
      totalRecords,
      formatCurrency,
      dt,
      deleteProductDialog,
      derivarDialog,
      aprobarDialog,
      deleteProductsDialog,
      confirmDeleteProduct,
      confirmDerivar,
      confirmAprobar,
      deleteProduct,
      derivarRegistro,
      aprobarRegistro,
      confirmDeleteSelected,
      deleteSelectedProducts,
      selectedProducts,
      filters,
      atractivos,
      atractivo,
      submitted,
      displayModal,
      openModal,
      closeModal,
      closeModalpdf,
      editarDialogModal,
      errores,
      mostrarMensajeDerivado,
      mostrarMensajeAprobado,
    };
  },
  computed:{
    getNombreDepartamento() {
      return (id) => {
        switch (id) {
          case 1:
            return 'CHUQUISACA';
          case 2:
            return 'LA PAZ';
          case 3:
            return 'COCHABAMBA';
          case 4:
            return 'ORURO';
          case 5:
            return 'POTOSÍ';
          case 6:
            return 'TARIJA';
          case 7:
            return 'SANTA CRUZ';
          case 8:
            return 'BENI';
          case 9:
            return 'PANDO';
          default:
            return 'N/A';
        }
      };
    }
  },
  components: { InputMask },
};
</script>
<style></style>