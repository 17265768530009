import {http, urlBase} from "./Http" 

//LISTAR
export const index = (page=1, rows=10, q=null) => {
  return http().get(`${urlBase}/proyeccion?page=${page}&rows=${rows}&q=${q}`);
}

export const list = () => {
  return http().get(`${urlBase}/listproyeccion`)
    .catch(error => {
      console.error('Error al cargar la lista de proyecciones:', error);
      throw error; // Re-lanza el error para que pueda ser manejado en el componente
    });
}


// GUARDAR
export const store = (datos) => {
  return http().post(`${urlBase}/proyeccion`,datos);
}

// MOSTRAR
export const show = (id) => {
  return http().get(`${urlBase}/proyeccion/${id}`);
}

// MODIFICAR
export const update = (datos, id) => {
  return http().put(`${urlBase}/proyeccion/${id}`, datos);
}

// ELIMINAR
export const destroy = (id) => {
  return http().delete(`${urlBase}/proyeccion/${id}`);
}