<template>
    <div class="card text-center">
        <h4 style="color: #1565C0;margin-top: 15px; margin-bottom: 15px;">GRÁFICOS INFORMATIVOS</h4>
        <div v-if="idPrimerRol ===1" class="col-12 md:col-12">
                <div class="card height-100">
                    <div class="card-header">
                        <h5>Gráfico Inventario de Recursos Turísticos Atractivos y Recursos Turísticos Facilidades se visualizan para los usuarios Rol: Gobernacion y Rol: Nacional </h5><br>
                    </div>
                </div>
            </div>
        <div class="grid dashboard">

            <div v-if="idPrimerRol ===2" class="col-12 md:col-8">
                <div class="card height-100">
                    <div class="card-header">
                        <h5>Gráfico Inventario de Recursos Turísticos Atractivos</h5><br>
                        <!-- <Button label="Datos Verticales/Apilados" class="p-button-text" :class="{'ml-auto': !isRTL, 'mr-auto': isRTL}" @click="changeMonthlyDataView()"></Button> -->
                    </div>

                    <Chart ref="monthly" type="bar" :data="getChartMonthlyData()" :options="chartMonthlyOptions" responsive="true" :height="400"></Chart>
                    <!--{{ graficoN }}-->
                    
                </div>
            </div>
            <!--gobernacion-->
            <div v-if="idPrimerRol ===3" class="col-12 md:col-8">
                <div class="card height-100">
                    <div class="card-header">
                        <h5>Gráfico Inventario de Recursos Turísticos Atractivos</h5><br>
                        <!-- <Button label="Datos Verticales/Apilados" class="p-button-text" :class="{'ml-auto': !isRTL, 'mr-auto': isRTL}" @click="changeMonthlyDataView()"></Button> -->
                    </div>

                    <Chart ref="monthly" type="bar" :data="getChartMonthlyDataGobernacion()" :options="chartMonthlyOptions" responsive="true" :height="400"></Chart>
                    <!--{{ graficoG }}-->
                    
                </div>
            </div>
            
            <div v-if="idPrimerRol ===2" class="col-12 md:col-4">
                <div class="card widget-insights height-100">
                    <div class="card-header mb-2" style="height: 40px;">
                        <h5>Lugares Turísticos por Macro Región de Recursos Turísticos Atractivos</h5>
                    </div>
                    <div class="card-subheader mb-2 flex align-items-center">
                        <!-- <span>La Paz, 09 de Enero 2022</span> -->
                        <!--Button label="Datos Parciales/Completos" class="p-button-text" :class="{'ml-auto': !isRTL, 'mr-auto': isRTL}" @click="changeDoughnutDataView()"></Button-->
                    </div>
                    <Chart ref="doughnut" type="doughnut" :data="getDoughnutData()" :options="doughnutOptions" responsive="true"></Chart>
                    <div class="flex flex-column justify-content-center">
                        <div class="flex flex-row align-items-center mt-4 px-3">
                            <i class="pi pi-thumbs-up p-3 rounded-circle lightgreen-bgcolor solid-surface-text-color"></i>
                            <div class="flex flex-column" :class="{'ml-3': !isRTL, 'mr-3': isRTL}">
                                <span>Macro Región con más registrados</span>
                            </div>
                            <span class="indigo-color" :class="{'ml-auto': !isRTL, 'mr-auto': isRTL}">{{ mayorA }}</span>
                        </div>
                        <div class="flex flex-row align-items-center my-4 px-3">
                            <i class="pi pi-thumbs-down rounded-circle p-3 orange-bgcolor solid-surface-text-color"></i>
                            <div class="flex flex-column" :class="{'ml-3': !isRTL, 'mr-3': isRTL}">
                                <span>Macro Región con menos registrados</span>
                            </div>
                            <span class="indigo-color" :class="{'ml-auto': !isRTL, 'mr-auto': isRTL}">{{ menorA }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <!--gobernacion-->
            <div v-if="idPrimerRol ===3" class="col-12 md:col-4">
                <div class="card widget-insights height-100">
                    <div class="card-header mb-2" style="height: 40px;">
                        <h5>Lugares Turísticos por Macro Región de Recursos Turísticos Atractivos</h5>
                    </div>
                    <div class="card-subheader mb-2 flex align-items-center">
                        <!-- <span>La Paz, 09 de Enero 2022</span> -->
                        <!--Button label="Datos Parciales/Completos" class="p-button-text" :class="{'ml-auto': !isRTL, 'mr-auto': isRTL}" @click="changeDoughnutDataView()"></Button-->
                    </div>
                    <Chart ref="doughnut" type="doughnut" :data="getDoughnutDataGobernacion()" :options="doughnutOptions" responsive="true"></Chart>
                    <div class="flex flex-column justify-content-center">
                        <div class="flex flex-row align-items-center mt-4 px-3">
                            <i class="pi pi-thumbs-up p-3 rounded-circle lightgreen-bgcolor solid-surface-text-color"></i>
                            <div class="flex flex-column" :class="{'ml-3': !isRTL, 'mr-3': isRTL}">
                                <span>Macro Región con más registrados</span>
                            </div>
                            <span class="indigo-color" :class="{'ml-auto': !isRTL, 'mr-auto': isRTL}">{{ mayorAG }}</span>
                        </div>
                        <div class="flex flex-row align-items-center my-4 px-3">
                            <i class="pi pi-thumbs-down rounded-circle p-3 orange-bgcolor solid-surface-text-color"></i>
                            <div class="flex flex-column" :class="{'ml-3': !isRTL, 'mr-3': isRTL}">
                                <span>Macro Región con menos registrados</span>
                            </div>
                            <span class="indigo-color" :class="{'ml-auto': !isRTL, 'mr-auto': isRTL}">{{ menorAG }}</span>
                        </div>
                    </div>
                </div>
            </div>
            
            <div v-if="idPrimerRol ===2" class="col-12 md:col-8">
                <div class="card height-100">
                    <div class="card-header">
                        <h5>Gráfico Inventario de Recursos Turísticos Facilidades</h5><br>
                        <!-- <Button label="Datos Verticales/Apilados" class="p-button-text" :class="{'ml-auto': !isRTL, 'mr-auto': isRTL}" @click="changeMonthlyDataView()"></Button> -->
                    </div>

                    <Chart ref="monthly" type="bar" :data="getChartMonthlyDataCP()" :options="chartMonthlyOptions" responsive="true" :height="400"></Chart>
                    <!--{{ graficoN }}-->
                    
                </div>
            </div>

            <!--gobernacion-->
            <div v-if="idPrimerRol ===3" class="col-12 md:col-8">
                <div class="card height-100">
                    <div class="card-header">
                        <h5>Gráfico Inventario de Recursos Turísticos Facilidades</h5><br>
                        <!-- <Button label="Datos Verticales/Apilados" class="p-button-text" :class="{'ml-auto': !isRTL, 'mr-auto': isRTL}" @click="changeMonthlyDataView()"></Button> -->
                    </div>

                    <Chart ref="monthly" type="bar" :data="getChartMonthlyDataCPGobernacion()" :options="chartMonthlyOptions" responsive="true" :height="400"></Chart>
                    <!--{{ graficoN }}-->
                    
                </div>
            </div>

            <div v-if="idPrimerRol ===2" class="col-12 md:col-4">
                <div class="card widget-insights height-100">
                    <div class="card-header mb-2" style="height: 40px;">
                        <h5>Lugares Turísticos por Macro Región de Recursos Turísticos Facilidades</h5>
                    </div>
                    <div class="card-subheader mb-2 flex align-items-center">
                        <!-- <span>La Paz, 09 de Enero 2022</span> -->
                        <!--Button label="Datos Parciales/Completos" class="p-button-text" :class="{'ml-auto': !isRTL, 'mr-auto': isRTL}" @click="changeDoughnutDataView()"></Button-->
                    </div>
                    <Chart ref="doughnut" type="doughnut" :data="getDoughnutData2()" :options="doughnutOptions" responsive="true"></Chart>
                    <div class="flex flex-column justify-content-center">
                        <div class="flex flex-row align-items-center mt-4 px-3">
                            <i class="pi pi-thumbs-up p-3 rounded-circle lightgreen-bgcolor solid-surface-text-color"></i>
                            <div class="flex flex-column" :class="{'ml-3': !isRTL, 'mr-3': isRTL}">
                                <span>Macro Región con más registrados</span>
                            </div>
                            <span class="indigo-color" :class="{'ml-auto': !isRTL, 'mr-auto': isRTL}">{{ mayorC }}</span>
                        </div>
                        <div class="flex flex-row align-items-center my-4 px-3">
                            <i class="pi pi-thumbs-down rounded-circle p-3 orange-bgcolor solid-surface-text-color"></i>
                            <div class="flex flex-column" :class="{'ml-3': !isRTL, 'mr-3': isRTL}">
                                <span>Macro Región con menos registrados</span>
                            </div>
                            <span class="indigo-color" :class="{'ml-auto': !isRTL, 'mr-auto': isRTL}">{{ menorC }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <!--Gobernacion-->
            <div v-if="idPrimerRol ===3" class="col-12 md:col-4">
                <div class="card widget-insights height-100">
                    <div class="card-header mb-2" style="height: 40px;">
                        <h5>Lugares Turísticos por Macro Región de Recursos Turísticos Facilidades</h5>
                    </div>
                    <div class="card-subheader mb-2 flex align-items-center">
                        <!-- <span>La Paz, 09 de Enero 2022</span> -->
                        <!--Button label="Datos Parciales/Completos" class="p-button-text" :class="{'ml-auto': !isRTL, 'mr-auto': isRTL}" @click="changeDoughnutDataView()"></Button-->
                    </div>
                    <Chart ref="doughnut" type="doughnut" :data="getDoughnutData2Gobernacion()" :options="doughnutOptions" responsive="true"></Chart>
                    <div class="flex flex-column justify-content-center">
                        <div class="flex flex-row align-items-center mt-4 px-3">
                            <i class="pi pi-thumbs-up p-3 rounded-circle lightgreen-bgcolor solid-surface-text-color"></i>
                            <div class="flex flex-column" :class="{'ml-3': !isRTL, 'mr-3': isRTL}">
                                <span>Macro Región con más registrados</span>
                            </div>
                            <span class="indigo-color" :class="{'ml-auto': !isRTL, 'mr-auto': isRTL}">{{ mayorCG }}</span>
                        </div>
                        <div class="flex flex-row align-items-center my-4 px-3">
                            <i class="pi pi-thumbs-down rounded-circle p-3 orange-bgcolor solid-surface-text-color"></i>
                            <div class="flex flex-column" :class="{'ml-3': !isRTL, 'mr-3': isRTL}">
                                <span>Macro Región con menos registrados</span>
                            </div>
                            <span class="indigo-color" :class="{'ml-auto': !isRTL, 'mr-auto': isRTL}">{{ menorCG }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="idPrimerRol ===2" class="text-center">

            <h4 style="color: #1565C0;margin-top: 20px; margin-bottom: 18px;">RECURSOS ATRACTIVOS REGISTRADOS POR MACRO REGIÓN</h4>
            <div class="grid dashboard">

                <div class="col-12 md:col-4" v-for="(item, index) in jsonData" :key="index">
                    <div class="card widget-social">
                        <div class="flex justify-content-center align-items-center p-3">
                            <div class="social-icon">
                                <img src="layout/images/mdpyep/img/5.jpg" width="100" style="border-radius: 15px;">
                            </div>
                        
                            <div class="left flex flex-column">
                                <span class="title">{{ item.nombre }}</span>
                                <span class="value mb-2">{{ item.total }}</span>
                                <ProgressBar :value="62" :showValue="false"></ProgressBar>
                            </div>
                        
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--gobernacion-->
        <div v-if="idPrimerRol ===3" class="text-center">

            <h4 style="color: #1565C0;margin-top: 20px; margin-bottom: 18px;">CANTIDAD REGISTRADO POR MACRO REGIÓN</h4>
            <div class="grid dashboard">

                <div class="col-12 md:col-4" v-for="(item, index) in jsonDataG" :key="index">
                    <div class="card widget-social">
                        <div class="flex justify-content-center align-items-center p-3">
                            <div class="social-icon">
                                <img src="layout/images/mdpyep/img/11.jpg" width="100" style="border-radius: 15px;">
                            </div>
                        
                            <div class="left flex flex-column">
                                <span class="title">{{ item.nombre }}</span>
                                <span class="value mb-2">{{ item.total }}</span>
                                <ProgressBar :value="62" :showValue="false"></ProgressBar>
                            </div>
                        
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { onMounted, ref } from "vue";

import ProductService from '../service/ProductService';
import * as graficoNacional from "../service/GraficosService.js";
import * as graficoGobernacion from "../service/GraficosGService.js";

//nacional
const graficoN = ref();
const graficoNCirculo = ref();
const graficoNacionall = ref();
const graficoNCP = ref();
const graficoNCPCirculo = ref();
const mayorA = ref(0);
const menorA = ref(0);
const mayorC = ref(0);
const menorC = ref(0);

//gobernacion
const graficoG = ref();
const graficoGCirculo = ref();
const graficoGobernacionn = ref();
const graficoGCP = ref();
const graficoGCPCirculo = ref();
const mayorAG = ref(0);
const menorAG = ref(0);
const mayorCG = ref(0);
const menorCG = ref(0);

export default {

    setup() {

        const rolss = JSON.parse(localStorage.getItem("role")) || [];
        const primerRol = ref(rolss[0] || {});
        const idPrimerRol = ref(primerRol.value.id || null);

        const store = useStore();
        // Puedes acceder a los datos del usuario logeado desde el estado de Vuex
        const usuario = computed(() => store.state.auth.user); // Aquí se accede a user
        const rol = computed(() => store.state.auth.rol); // Aquí se accede a rol
        const menu = computed(() => store.state.auth.menu); // Aquí se accede a menu
    
        onMounted(() => {
            listarGraficos();
            listarGraficosCirculo();
            listarGraficosNacional();
            listarGraficosCP();
            listarGraficosCPCirculo();
            //gobernacion
            listarGraficosG();
            listarGraficosCirculoG();
            listarGraficosGobernacion();
            listarGraficosCPG();
            listarGraficosCPCirculoG();
        });

        //nacional
        //Atractivos turisticos
        const listarGraficos = async () => {
            try {
                const { data } = await graficoNacional.showGrafico();
                //console.log(data.grafico)
                graficoN.value = data.grafico

            } catch (error) {
                console.log('Error al mostrar gráfico')
            }
        };

        const listarGraficosCirculo = async () => {
            try {
                const { data } = await graficoNacional.showGrafico2();
                //console.log(data.grafico)
                graficoNCirculo.value = data.grafico
                
                //Sacar el mayor y menor
                const jsonData = graficoNCirculo.value;
                const dataa = jsonData.datasets[0].data;

                mayorA.value = Math.max(...dataa);
                menorA.value = Math.min(...dataa);
    
            } catch (error) {
                console.log('Error al mostrar gráfico')
            }
        };

        const listarGraficosNacional = async () => {
            try {
                const { data } = await graficoNacional.graficoNacional();
                //console.log(data.grafico)
                graficoNacionall.value = data.grafico
                
            } catch (error) {
                console.log('Error al mostrar gráfico')
            }
        };


        //Centro poblado
        const listarGraficosCP = async () => {
            try {
                const { data } = await graficoNacional.showGraficoCP();
                //console.log(data.grafico)
                graficoNCP.value = data.grafico
    
            } catch (error) {
                console.log('Error al mostrar gráfico')
            }
        };

        const listarGraficosCPCirculo = async () => {
            try {
                const { data } = await graficoNacional.showGraficoCP2();
                //console.log(data.grafico)
                graficoNCPCirculo.value = data.grafico

                //Sacar el mayor y menor
                const jsonData = graficoNCPCirculo.value;
                const datac = jsonData.datasets[0].data;

                mayorC.value = Math.max(...datac);
                menorC.value = Math.min(...datac);
    
            } catch (error) {
                console.log('Error al mostrar gráfico')
            }
        };

        //gobernacion
        //Atractivos turisticos
        const listarGraficosG = async () => {
            try {
                const { data } = await graficoGobernacion.showGraficoG();
                //console.log(data.grafico)
                graficoG.value = data.grafico

            } catch (error) {
                console.log('Error al mostrar gráfico')
            }
        };

        const listarGraficosCirculoG = async () => {
            try {
                const { data } = await graficoGobernacion.showGrafico2G();
                //console.log(data.grafico)
                graficoGCirculo.value = data.grafico
                
                //Sacar el mayor y menor
                const jsonDataG = graficoGCirculo.value;
                const dataa = jsonDataG.datasets[0].data;

                mayorAG.value = Math.max(...dataa);
                menorAG.value = Math.min(...dataa);
    
            } catch (error) {
                console.log('Error al mostrar gráfico')
            }
        };

        const listarGraficosGobernacion = async () => {
            try {
                const { data } = await graficoGobernacion.graficoGobernacion();
                //console.log(data.grafico)
                graficoGobernacionn.value = data.grafico
                
            } catch (error) {
                console.log('Error al mostrar gráfico')
            }
        };


        //Centro poblado
        const listarGraficosCPG = async () => {
            try {
                const { data } = await graficoGobernacion.showGraficoCPG();
                //console.log(data.grafico)
                graficoGCP.value = data.grafico
    
            } catch (error) {
                console.log('Error al mostrar gráfico')
            }
        };

        const listarGraficosCPCirculoG = async () => {
            try {
                const { data } = await graficoGobernacion.showGraficoCP2G();
                //console.log(data.grafico)
                graficoGCPCirculo.value = data.grafico

                //Sacar el mayor y menor
                const jsonDataG = graficoGCPCirculo.value;
                const datac = jsonDataG.datasets[0].data;

                mayorCG.value = Math.max(...datac);
                menorCG.value = Math.min(...datac);
    
            } catch (error) {
                console.log('Error al mostrar gráfico')
            }
        };

        return {
            rolss,
            idPrimerRol,
            usuario,
            rol,
            menu,
            
            graficoNCirculo,
            mayorA,
            menorA,
            mayorC,
            menorC,
            graficoNacionall,

            graficoGCirculo,
            mayorAG,
            menorAG,
            mayorCG,
            menorCG,
            graficoGobernacionn,
            
        };
      
    },
    data() {
        return {
            jsonData: graficoNacionall,
            jsonDataG: graficoGobernacionn,
            products: null,
            items: [
                {label: 'Update', icon: 'pi pi-fw pi-refresh'},
                {label: 'Edit', icon: 'pi pi-fw pi-pencil'}
            ],
            storeADiff: 0,
            storeAStatus: 0,
            storeBDiff: 0,
            storeBStatus: 0,
            storeCDiff: 0,
            storeCStatus: 0,
            storeDDiff: 0,
            storeDStatus: 0,
            chartMonthlyData: null,
            chartMonthlyOptions: null,
            doughnutData: null,
            doughnutOptions: null,
            pieData: null,
            pieOptions: null,
        }
    },
    productService: null,
	created() {
		this.productService = new ProductService();
	},
	mounted() {
		this.productService.getProducts().then(data => this.products = data);
        this.setStoreInterval();
        this.refreshChart();
	},
    watch: {
		'$appState.isNewThemeLoaded'(isLoaded) {
			if (isLoaded) {
				this.refreshChart();
				this.$appState.isNewThemeLoaded = false;
			}
		}
	},
	methods: {
		formatCurrency(value) {
			return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
		},
        toggle(event) {
            this.$refs.menu.toggle(event);
        },
        calculateStore(storeData, totalValue) {
            let randomNumber = +((Math.random() * 500).toFixed(2));
            let data = []
            let length = data.length;
            data.push(randomNumber);
            data.shift();
            //storeData.datasets[0].data = data;

            let diff = +((data[length - 1] - data[length - 2]).toFixed(2));
            let status = diff === 0 ? 0 : (diff > 0 ? 1 : -1);
            totalValue = +((totalValue + diff).toFixed(2));

            return { diff, totalValue, status };
        },
        setStoreInterval () {
            this.storeInterval = setInterval(() => {
                requestAnimationFrame(() => {
                    let { diff: storeADiff, totalValue: storeATotalValue, status: storeAStatus } = this.calculateStore(this.storeAData, this.storeATotalValue);
                    this.storeADiff = storeADiff;
                    this.storeATotalValue = storeATotalValue;
                    this.storeAStatus = storeAStatus;
                    //this.$refs.storeA.chart.update();

                    let { diff: storeBDiff, totalValue: storeBTotalValue, status: storeBStatus } = this.calculateStore(this.storeBData, this.storeBTotalValue);
                    this.storeBDiff = storeBDiff;
                    this.storeBTotalValue = storeBTotalValue;
                    this.storeBStatus = storeBStatus;
                    //this.$refs.storeB.chart.update();

                    let { diff: storeCDiff, totalValue: storeCTotalValue, status: storeCStatus } = this.calculateStore(this.storeCData, this.storeCTotalValue);
                    this.storeCDiff = storeCDiff;
                    this.storeCTotalValue = storeCTotalValue;
                    this.storeCStatus = storeCStatus;
                    //this.$refs.storeC.chart.update();

                    let { diff: storeDDiff, totalValue: storeDTotalValue, status: storeDStatus } = this.calculateStore(this.storeDData, this.storeDTotalValue);
                    this.storeDDiff = storeDDiff;
                    this.storeDTotalValue = storeDTotalValue;
                    this.storeDStatus = storeDStatus;
                    //this.$refs.storeD.chart.update();
                })
            }, 2000);
        },
        changeMonthlyDataView() {
            if (this.$refs.monthly.chart.config.options.scales.x.stacked) {
                this.$refs.monthly.chart.config.options.scales.x.stacked = false;
                this.$refs.monthly.chart.config.options.scales.y.stacked = false;
            }
            else {
                this.$refs.monthly.chart.config.options.scales.x.stacked = true;
                this.$refs.monthly.chart.config.options.scales.y.stacked = true;
            }

            this.$refs.monthly.chart.update();
        },
        changeDoughnutDataView() {
            if (this.$refs.doughnut.chart.config.options.circumference === 360) {
                this.$refs.doughnut.chart.config.options.circumference = 180;
                this.$refs.doughnut.chart.config.options.rotation = -1 * 90;
            } else {
                this.$refs.doughnut.chart.config.options.circumference = 360;
                this.$refs.doughnut.chart.config.options.rotation = 0;
            }

            this.$refs.doughnut.chart.update();
        },
        togglePieDoughnut() {
            this.$refs.pie.chart.config.options.cutout = this.$refs.pie.chart.config.options.cutout ? 0 : 50;
            this.$refs.pie.chart.update();
        },
        changePieDoughnutDataView() {
            if (this.$refs.pie.chart.config.options.circumference === 180) {
                this.$refs.pie.chart.config.options.circumference = 360;
                this.$refs.pie.chart.config.options.rotation = 0;
            } else {
                this.$refs.pie.chart.config.options.circumference = 180;
                this.$refs.pie.chart.config.options.rotation = -1 * 90;
            }

            this.$refs.pie.chart.update();
        },
        refreshChart() {
            this.chartMonthlyData = this.getChartMonthlyData();
            this.chartMonthlyOptions = this.getMonthlyChartOptions();
            this.doughnutData = this.getDoughnutData();
            this.doughnutOptions = this.getDoughnutOptions();
            this.pieData = this.getPieData();
            this.pieOptions = this.getPieOptions();
        },
        getChartMonthlyData() {
            const { limeColor, blueColor, deeporangeColor, cyanColor, tealColor, greenColor, lightgreenColor, purpleColor, brownColor } = this.getColors();
            const graficoData = graficoN.value;

            if (graficoData && graficoData.datasets) {
                const datasets = graficoData.datasets;

                // Configura los colores de fondo para cada dataset
                datasets.forEach((dataset, index) => {
                    switch (index) {
                        case 0:
                            dataset.backgroundColor = purpleColor;
                            dataset.borderColor = purpleColor;
                            dataset.borderWidth = 2;
                            dataset.fill = true;
                            break;
                        case 1:
                            dataset.backgroundColor = blueColor;
                            dataset.borderColor = blueColor;
                            dataset.borderWidth = 2;
                            dataset.fill = true;
                            break;
                        case 2:
                            dataset.backgroundColor = deeporangeColor;
                            dataset.borderColor = deeporangeColor;
                            dataset.borderWidth = 2;
                            dataset.fill = true;
                            break;
                        case 3:
                            dataset.backgroundColor = cyanColor;
                            dataset.borderColor = cyanColor;
                            dataset.borderWidth = 2;
                            dataset.fill = true;
                            break;
                        case 4:
                            dataset.backgroundColor = tealColor;
                            dataset.borderColor = tealColor;
                            dataset.borderWidth = 2;
                            dataset.fill = true;
                            break;
                        case 5:
                            dataset.backgroundColor = greenColor;
                            dataset.borderColor = greenColor;
                            dataset.borderWidth = 2;
                            dataset.fill = true;
                            break;
                        case 6:
                            dataset.backgroundColor = lightgreenColor;
                            dataset.borderColor = lightgreenColor;
                            dataset.borderWidth = 2;
                            dataset.fill = true;
                            break;
                        case 7:
                            dataset.backgroundColor = limeColor;
                            dataset.borderColor = limeColor;
                            dataset.borderWidth = 2;
                            dataset.fill = true;
                            break;
                        case 8:
                            dataset.backgroundColor = brownColor;
                            dataset.borderColor = brownColor;
                            dataset.borderWidth = 2;
                            dataset.fill = true;
                            break;
                        
                    }
                });
            }

            return graficoData;
        },
        getChartMonthlyDataGobernacion() {
            const { limeColor, blueColor, deeporangeColor, cyanColor, tealColor, greenColor, lightgreenColor, purpleColor, brownColor } = this.getColors();
            const graficoDataGobernacion = graficoG.value;

            if (graficoDataGobernacion && graficoDataGobernacion.datasets) {
                const datasets = graficoDataGobernacion.datasets;

                // Configura los colores de fondo para cada dataset
                datasets.forEach((dataset, index) => {
                    switch (index) {
                        case 0:
                            dataset.backgroundColor = purpleColor;
                            dataset.borderColor = purpleColor;
                            dataset.borderWidth = 2;
                            dataset.fill = true;
                            break;
                        case 1:
                            dataset.backgroundColor = blueColor;
                            dataset.borderColor = blueColor;
                            dataset.borderWidth = 2;
                            dataset.fill = true;
                            break;
                        case 2:
                            dataset.backgroundColor = deeporangeColor;
                            dataset.borderColor = deeporangeColor;
                            dataset.borderWidth = 2;
                            dataset.fill = true;
                            break;
                        case 3:
                            dataset.backgroundColor = cyanColor;
                            dataset.borderColor = cyanColor;
                            dataset.borderWidth = 2;
                            dataset.fill = true;
                            break;
                        case 4:
                            dataset.backgroundColor = tealColor;
                            dataset.borderColor = tealColor;
                            dataset.borderWidth = 2;
                            dataset.fill = true;
                            break;
                        case 5:
                            dataset.backgroundColor = greenColor;
                            dataset.borderColor = greenColor;
                            dataset.borderWidth = 2;
                            dataset.fill = true;
                            break;
                        case 6:
                            dataset.backgroundColor = lightgreenColor;
                            dataset.borderColor = lightgreenColor;
                            dataset.borderWidth = 2;
                            dataset.fill = true;
                            break;
                        case 7:
                            dataset.backgroundColor = limeColor;
                            dataset.borderColor = limeColor;
                            dataset.borderWidth = 2;
                            dataset.fill = true;
                            break;
                        case 8:
                            dataset.backgroundColor = brownColor;
                            dataset.borderColor = brownColor;
                            dataset.borderWidth = 2;
                            dataset.fill = true;
                            break;
                        
                    }
                });
            }

            return graficoDataGobernacion;
        },
        getChartMonthlyDataCP() {
            const { limeColor, blueColor,deeporangeColor, cyanColor, tealColor, greenColor, lightgreenColor, purpleColor, brownColor } = this.getColors();
            const graficoData = graficoNCP.value;

            if (graficoData && graficoData.datasets) {
                const datasets = graficoData.datasets;

                // Configura los colores de fondo para cada dataset
                datasets.forEach((dataset, index) => {
                    switch (index) {
                        case 0:
                            dataset.backgroundColor = purpleColor;
                            dataset.borderColor = purpleColor;
                            dataset.borderWidth = 2;
                            dataset.fill = true;
                            break;
                        case 1:
                            dataset.backgroundColor = blueColor;
                            dataset.borderColor = blueColor;
                            dataset.borderWidth = 2;
                            dataset.fill = true;
                            break;
                        case 2:
                            dataset.backgroundColor = deeporangeColor;
                            dataset.borderColor = deeporangeColor;
                            dataset.borderWidth = 2;
                            dataset.fill = true;
                            break;
                        case 3:
                            dataset.backgroundColor = cyanColor;
                            dataset.borderColor = cyanColor;
                            dataset.borderWidth = 2;
                            dataset.fill = true;
                            break;
                        case 4:
                            dataset.backgroundColor = tealColor;
                            dataset.borderColor = tealColor;
                            dataset.borderWidth = 2;
                            dataset.fill = true;
                            break;
                        case 5:
                            dataset.backgroundColor = greenColor;
                            dataset.borderColor = greenColor;
                            dataset.borderWidth = 2;
                            dataset.fill = true;
                            break;
                        case 6:
                            dataset.backgroundColor = lightgreenColor;
                            dataset.borderColor = lightgreenColor;
                            dataset.borderWidth = 2;
                            dataset.fill = true;
                            break;
                        case 7:
                            dataset.backgroundColor = limeColor;
                            dataset.borderColor = limeColor;
                            dataset.borderWidth = 2;
                            dataset.fill = true;
                            break;
                        case 8:
                            dataset.backgroundColor = brownColor;
                            dataset.borderColor = brownColor;
                            dataset.borderWidth = 2;
                            dataset.fill = true;
                            break;
                        
                    }
                });
            }

            return graficoData;

        },
        getChartMonthlyDataCPGobernacion() {
            const { limeColor, blueColor,deeporangeColor, cyanColor, tealColor, greenColor, lightgreenColor, purpleColor, brownColor } = this.getColors();
            const graficoDataG = graficoGCP.value;

            if (graficoDataG && graficoDataG.datasets) {
                const datasets = graficoDataG.datasets;

                // Configura los colores de fondo para cada dataset
                datasets.forEach((dataset, index) => {
                    switch (index) {
                        case 0:
                            dataset.backgroundColor = blueColor;
                            dataset.borderColor = blueColor;
                            dataset.borderWidth = 2;
                            dataset.fill = true;
                            break;
                        case 1:
                            dataset.backgroundColor = purpleColor;
                            dataset.borderColor = purpleColor;
                            dataset.borderWidth = 2;
                            dataset.fill = true;
                            break;
                        case 2:
                            dataset.backgroundColor = deeporangeColor;
                            dataset.borderColor = deeporangeColor;
                            dataset.borderWidth = 2;
                            dataset.fill = true;
                            break;
                        case 3:
                            dataset.backgroundColor = cyanColor;
                            dataset.borderColor = cyanColor;
                            dataset.borderWidth = 2;
                            dataset.fill = true;
                            break;
                        case 4:
                            dataset.backgroundColor = tealColor;
                            dataset.borderColor = tealColor;
                            dataset.borderWidth = 2;
                            dataset.fill = true;
                            break;
                        case 5:
                            dataset.backgroundColor = greenColor;
                            dataset.borderColor = greenColor;
                            dataset.borderWidth = 2;
                            dataset.fill = true;
                            break;
                        case 6:
                            dataset.backgroundColor = lightgreenColor;
                            dataset.borderColor = lightgreenColor;
                            dataset.borderWidth = 2;
                            dataset.fill = true;
                            break;
                        case 7:
                            dataset.backgroundColor = limeColor;
                            dataset.borderColor = limeColor;
                            dataset.borderWidth = 2;
                            dataset.fill = true;
                            break;
                        case 8:
                            dataset.backgroundColor = brownColor;
                            dataset.borderColor = brownColor;
                            dataset.borderWidth = 2;
                            dataset.fill = true;
                            break;
                        
                    }
                });
            }

            return graficoDataG;

        },
        getMonthlyChartOptions() {
            const textColor = getComputedStyle(document.body).getPropertyValue('--text-color') || 'rgba(0, 0, 0, 0.87)';
            const gridLinesColor = getComputedStyle(document.body).getPropertyValue('--divider-color') || 'rgba(160, 167, 181, .3)';
            const fontFamily = getComputedStyle(document.body).getPropertyValue('--font-family');
            return {
                plugins: {
                    legend: {
                        display: true,
                        labels: {
                            font: {
                                family: fontFamily
                            },
                            color: textColor
                        }
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
                interaction: {
					mode: 'x'
				},
                scales: {
                    y: {
                        ticks: {
                            font: {
                                family: fontFamily
                            },
                            color: textColor
                        },
                        grid: {
                            color: gridLinesColor
                        }
                    },
                    x: {
                        categoryPercentage: .9,
                        barPercentage: .8,
                        ticks: {
                            font: {
                                family: fontFamily
                            },
                            color: textColor
                        },
                        grid: {
                            color: gridLinesColor
                        }
                    }
                },
                animation: {
                    animateScale: true,
                    animateRotate: true
                },

            }
        },
        getPieData() {
            const { limeColor, blueColor, tealColor } = this.getColors();
            const borderColor = getComputedStyle(document.body).getPropertyValue('--divider-color') || 'rgba(160, 167, 181, .3)';
            return {
                labels: ['O', 'D', 'R'],
                datasets: [
                    {
                        data: [300, 50, 100],
                        backgroundColor: [
                            blueColor,
                            tealColor,
                            limeColor
                        ],
                        borderColor
                    }
                ]
            }
        },
        getPieOptions() {
            const textColor = getComputedStyle(document.body).getPropertyValue('--text-color') || 'rgba(0, 0, 0, 0.87)';
            const fontFamily = getComputedStyle(document.body).getPropertyValue('--font-family');
            return {
                responsive: true,
                aspectRatio: 1,
                plugins: {
                    legend: {
                        position: 'top',
                        labels: {
                            font: {
                                family: fontFamily
                            },
                            color: textColor
                        }
                    }
                },
                animation: {
                    animateScale: true,
                    animateRotate: true
                }
            };
        },
        getDoughnutData() {

            const { orangeColor, blueColor, lightblueColor,cyanColor, tealColor, greenColor, lightgreenColor} = this.getColors();
            const graficoData = graficoNCirculo.value;

            if (graficoData && graficoData.datasets) {
                const datasets = graficoData.datasets;

                // Configura los colores de fondo para cada dataset
                datasets.forEach((dataset, index) => {
                    dataset.backgroundColor = [blueColor, lightblueColor, cyanColor, tealColor, greenColor, lightgreenColor, orangeColor];
                    dataset.borderWidth = 2; // Grosor del borde (puedes ajustarlo)
                    dataset.fill = true; // Rellenar el área interior
                });
            }

            return graficoData;
        },

        getDoughnutDataGobernacion() {

            const { orangeColor, blueColor, lightblueColor,cyanColor, tealColor, greenColor, lightgreenColor} = this.getColors();
            const graficoDataG = graficoGCirculo.value;

            if (graficoDataG && graficoDataG.datasets) {
                const datasets = graficoDataG.datasets;

                // Configura los colores de fondo para cada dataset
                datasets.forEach((dataset, index) => {
                    dataset.backgroundColor = [blueColor, lightblueColor, cyanColor, tealColor, greenColor, lightgreenColor, orangeColor];
                    dataset.borderWidth = 2; // Grosor del borde (puedes ajustarlo)
                    dataset.fill = true; // Rellenar el área interior
                });
            }

            return graficoDataG;
            },

        getDoughnutData2() {

            const { orangeColor, blueColor, lightblueColor,cyanColor, tealColor, greenColor, lightgreenColor} = this.getColors();
            const graficoData = graficoNCPCirculo.value;

            if (graficoData && graficoData.datasets) {
                const datasets = graficoData.datasets;

                // Configura los colores de fondo para cada dataset
                datasets.forEach((dataset, index) => {
                    dataset.backgroundColor = [blueColor, lightblueColor, cyanColor, tealColor, greenColor, lightgreenColor, orangeColor];
                    dataset.borderWidth = 2; // Grosor del borde (puedes ajustarlo)
                    dataset.fill = true; // Rellenar el área interior
                });
            }

            return graficoData;
        },
        getDoughnutData2Gobernacion() {

            const { orangeColor, blueColor, lightblueColor,cyanColor, tealColor, greenColor, lightgreenColor} = this.getColors();
            const graficoDataG = graficoGCPCirculo.value;

            if (graficoDataG && graficoDataG.datasets) {
                const datasets = graficoDataG.datasets;

                // Configura los colores de fondo para cada dataset
                datasets.forEach((dataset, index) => {
                    dataset.backgroundColor = [blueColor, lightblueColor, cyanColor, tealColor, greenColor, lightgreenColor, orangeColor];
                    dataset.borderWidth = 2; // Grosor del borde (puedes ajustarlo)
                    dataset.fill = true; // Rellenar el área interior
                });
            }

            return graficoDataG;
            },

        getDoughnutOptions() {
            const textColor = getComputedStyle(document.body).getPropertyValue('--text-color') || 'rgba(0, 0, 0, 0.87)';
            const fontFamily = getComputedStyle(document.body).getPropertyValue('--font-family');
            return {
                responsive: true,
                aspectRatio: 2,
                plugins: {
                    legend: {
                        position: 'top',
                        labels: {
                            font: {
                                family: fontFamily
                            },
                            color: textColor
                        }
                    }
                },
                circumference: 180,
                rotation: -90,
                animation: {
                    animateScale: true,
                    animateRotate: true
                }
            };
        },
        getColors() {
            const isLight = this.$appState.layoutMode === 'light';
            return {
                pinkColor: isLight ? '#EC407A' : '#F48FB1',
                purpleColor: isLight ? '#AB47BC' : '#CE93D8',
                deeppurpleColor: isLight ? '#7E57C2' : '#B39DDB',
                indigoColor: isLight ? '#5C6BC0' : '#9FA8DA',
                blueColor: isLight ? '#42A5F5' : '#90CAF9',
                lightblueColor: isLight ? '#29B6F6' : '#81D4FA',
                cyanColor: isLight ? '#00ACC1' : '#4DD0E1',
                tealColor: isLight ? '#26A69A' : '#80CBC4',
                greenColor: isLight ? '#66BB6A' : '#A5D6A7',
                lightgreenColor: isLight ? '#9CCC65' : '#C5E1A5',
                limeColor: isLight ? '#D4E157' : '#E6EE9C',
                yellowColor: isLight ? 'FFEE58' : '#FFF59D',
                amberColor: isLight ? '#FFCA28' : '#FFE082',
                orangeColor: isLight ? '#FFA726' : '#FFCC80',
                deeporangeColor: isLight ? '#FF7043' : '#FFAB91',
                brownColor: isLight ? '#8D6E63' : '#BCAAA4'
            }
        },
    },
    computed: {
        isRTL() {
			return this.$appState.RTL;
		}
    },
    beforeUnmount() {
        if (this.storeInterval) {
            clearInterval(this.storeInterval);
        }
    }
}
</script>

<style lang="scss" scoped>
</style>