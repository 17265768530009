import {http, urlBase} from "./Http" 

// LISTAR
export const index = (page=1, rows=10, q=null) => {
  return http().get(`${urlBase}/catsubtipo?page=${page}&rows=${rows}&q=${q}`);
}

// LISTAR
export const buscar = (id) => {
  return http().get(`${urlBase}/cattiposub/${id}`);
}

// GUARDAR
export const store = (datos) => {
  return http().post(`${urlBase}/subtipocat`,datos);
}

// MOSTRAR CAT SUBTIPO
export const show = (id) => {
  return http().get(`${urlBase}/subtipocat/${id}`);
}

// GUARDAR CAT SUBTIPO
export const storeCat = (datos) => {
  return http().post(`${urlBase}/catsubtipo`,datos);
}

// MODIFICAR CAT SUBTIPO
export const updateCat = (datos, id) => {
  return http().put(`${urlBase}/catsubtipo/${id}`, datos);
}

// ELIMINAR CAT SUBTIPO
export const destroyCat = (id) => {
  return http().delete(`${urlBase}/catsubtipo/${id}`);
}