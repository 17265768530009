import {http, urlBase} from "./Http" 

//LISTAR
export const index = (ppirtf = ppirtf, page=1, rows=10, q=null) => {
  return http().get(`${urlBase}/pernoctacionppirtf?id_ppirtf=${ppirtf}&page=${page}&rows=${rows}&q=${q}`);
}


export const list = () => {
  return http().get(`${urlBase}/listpernoctacionppirtf`)
    .catch(error => {
      console.error('Error al cargar la lista de proyecciones:', error);
      throw error; // Re-lanza el error para que pueda ser manejado en el componente
    });
}


// GUARDAR
export const store = (datos) => {
  return http().post(`${urlBase}/pernoctacionppirtf`,datos);
}

// MOSTRAR
export const show = (id) => {
  return http().get(`${urlBase}/pernoctacionppirtf/${id}`);
}

// MODIFICAR
export const update = (datos, id) => {
  return http().put(`${urlBase}/pernoctacionppirtf/${id}`, datos);
}

// ELIMINAR
export const destroy = (id) => {
  return http().delete(`${urlBase}/pernoctacionppirtf/${id}`);
}