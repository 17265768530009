import {http, urlBase} from "./Http" 

//Gobernación
// MOSTRAR
export const index = (page=1, rows=10, q=null) => {
  return http().get(`${urlBase}/ppirtffinalizado?page=${page}&rows=${rows}&q=${q}`);
}

// RECIBIR
export const recibirPpirta = (id) => {
  return http().post(`${urlBase}/ppirtarecibir/${id}`);
}

// REVISAR
export const revisarPpirta = (datos) => {
  return http().post(`${urlBase}/ppirtarevisargobernacion`,datos);
}

//Nacional
// MOSTRAR
export const indexN = (page=1, rows=10, q=null) => {
  return http().get(`${urlBase}/ppirtfnacionalfinalizado?page=${page}&rows=${rows}&q=${q}`);
}

