import {http, urlBase} from "./Http" 

//Gobernacion
// MOSTRAR
export const index = (page=1, rows=10, q=null) => {
  return http().get(`${urlBase}/ppirtfpendiente?page=${page}&rows=${rows}&q=${q}`);
}

// RECIBIR
export const recibirPpirta = (id) => {
  return http().post(`${urlBase}/ppirtfrecibir/${id}`);
}

// REVISAR
export const revisarPpirta = (datos) => {
  return http().post(`${urlBase}/ppirtfrevisargobernacion`,datos);
}


//Nacional
// MOSTRAR
export const indexN = (page=1, rows=10, q=null) => {
  return http().get(`${urlBase}/ppirtfnacionalpendiente?page=${page}&rows=${rows}&q=${q}`);
}

// REVISAR
export const revisarPpirtaN = (datos) => {
  return http().post(`${urlBase}/ppirtfrevisarnacional`,datos);
}

