<template>
  <div class="card">
    <h2>GESTIÒN ATRACTIVOS TURISTICOS</h2>

    <Button label="Nuevo" icon="pi pi-external-link" @click="openModal" />

    <router-link to="/detalle"><b>DETALLE</b></router-link>
    
      <div class="floatlabel-demo col-12 md:col-12">
        <Dialog header="REGISTRO TURISTICO" v-model:visible="displayModal" :breakpoints="{'850px': '450px'}" class="p-fluid" :style="{width: '850px'}" :modal="true">
          <InlineMessage :style="{  border: 'solid #696cff', borderWidth: '0 0 0 6px', color: '#000000' }"
                    severity="warn" class="border-primary w-full justify-content-start">
          <div class="flex align-items-center">
              <!-- <img alt="logo" src="https://primefaces.org/cdn/primevue/images/logo.svg" width="32" /> -->
              <div class="ml-2">Debe verificar que los datos seleccionados e ingresados sean correctos para registrar.<br>De lo contrario puede <span style="color:#FF0000"> X Cancelar</span> el registro.</div>
          </div>
        </InlineMessage>
        <br>
        <div class="grid formgrid">
					<div class="field col-12 mb-3 lg:col-4 lg:col-3">
            <span class="p-float-label"> 
						<InputText id="Codigo" :readonly="true"/>
            <label for="inputtext">Codigo automatico</label>
          </span>
					</div>
					<div class="field col-12 mb-3 lg:col-8 lg:col-3">
            <span class="p-float-label">
            <InputText type="text" id="name" v-model.trim="categoria.nombre" autofocus/>
            <label for="inputtext">Registro Turistico</label>
          </span>
					</div>
				</div>
        <div class="grid formgrid">
          <div class="field col-12 mb-3 lg:col-4 lg:col-3">
              <span class="p-float-label">
                <Dropdown id="categoria" :options="combo1" v-model="valor1" optionLabel="nombre1"></Dropdown>
                <label for="categoria">Categoria</label>
              </span>
          </div>
          <div class="field col-12 mb-3 lg:col-4 lg:col-3">
              <span class="p-float-label">
                <Dropdown id="tipo_c" :options="combo2" v-model="valor2" optionLabel="name"></Dropdown>
                <label for="categoria">Tipo de Categoria</label>
              </span>
          </div>
          <div class="field col-12 mb-3 lg:col-4 lg:col-3">
              <span class="p-float-label">
                <Dropdown id="s_tipo_c" :options="combo3" v-model="valor3" optionLabel="name"></Dropdown>
                <label for="categoria">Sub tipo de categoria</label>
              </span>
          </div>
        </div>  
      
        <table style="width: 100%; background-color:#F8F8F8; height: 35px;">
          <tr >
            <td>
              <B>&nbsp;&nbsp;GEOLOCALIZACIÓN</B>
            </td>
          </tr>
        </table><br>
        <div class="grid formgrid">
          <div class="field col-12 mb-3 lg:col-2 lg:col-2">
            <span class="p-float-label">
              <InputText type="text" id="g_x" v-model.trim="categoria.g_x" autofocus/>
              <label for="inputtext">X</label>
            </span>
					</div>
          <div class="field col-12 mb-3 lg:col-2 lg:col-2">
            <span class="p-float-label">
              <InputText type="text" id="g_y" v-model.trim="categoria.g_y" autofocus/>
              <label for="inputtext">X</label>
            </span>
					</div>
          <div class="field col-12 mb-3 lg:col-2 lg:col-2">
            <span class="p-float-label">
              <InputText type="text" id="g_z" v-model.trim="categoria.g_z" autofocus/>
              <label for="inputtext">Z</label>
            </span>
					</div>

          <div class="field col-12 mb-3 lg:col-3 lg:col-2">
            <span class="p-float-label">
            <InputText type="text" id="g_proyeccion" v-model.trim="categoria.g_proyeccion" autofocus/>
            <label for="inputtext">Proyeccion</label>
          </span>
					</div>
          <div class="field col-12 mb-3 lg:col-3 lg:col-2">
            <span class="p-float-label">
            <InputText type="text" id="g_datum" v-model.trim="categoria.g_datum" autofocus/>
            <label for="inputtext">Datum</label>
          </span>
					</div>
				</div>

        <div class="grid formgrid">
            <div class="field col-12">
              <span class="p-float-label">
  							<Textarea inputId="textarea" rows="3" cols="30" v-model="categoria.descripcion"></Textarea>
	  						<label for="textarea">Descripcion del recurso turistico</label>
		  				</span>
            </div>
        </div>
        <div class="grid formgrid">
            <div class="field col-12">
              <FileUpload mode="basic" name="demo[]" url="./upload.php" accept="image/*" :maxFileSize="1000000" @upload="onUpload"  label="Guardar" />
            </div> 
        </div>
      <table style="width: 100%; background-color:#F8F8F8; height: 35px;">
          <tr >
            <td>
              <B>&nbsp;&nbsp;UBICACÓN TERRITORIAL</B>
            </td>
          </tr>
        </table>
        <br>
        <div class="grid formgrid">
          <div class="field col-12 mb-3 lg:col-3 lg:col-2">
            <span class="p-float-label">
                <Dropdown id="macroregion" :options="combo4" v-model="categoria.valor4" optionLabel="nombre4"></Dropdown>
                <label for="macroregion">Macro region</label>
            </span>
					</div>
          <div class="field col-12 mb-3 lg:col-3 lg:col-2">
            <span class="p-float-label">
                <Dropdown id="region" :options="combo5" v-model="categoria.valor5" optionLabel="nombre5"></Dropdown>
                <label for="region">Region</label>
            </span>
					</div>
          <div class="field col-12 mb-3 lg:col-3 lg:col-2">
            <span class="p-float-label">
                <Dropdown id="departamento" :options="combo6" v-model="categoria.valor6" optionLabel="nombre6"></Dropdown>
                <label for="departamento">Departamento</label>
            </span>
					</div>
          <div class="field col-12 mb-3 lg:col-3 lg:col-2">
            <span class="p-float-label">
                <Dropdown id="provincia" :options="combo7" v-model="categoria.valor7" optionLabel="nombre7"></Dropdown>
                <label for="provincia">Provincia</label>
            </span>
					</div>
				</div>

        <div class="grid formgrid">
          <div class="field col-12 mb-3 lg:col-3 lg:col-2">
            <span class="p-float-label">
                <Dropdown id="municipio" :options="combo8" v-model="categoria.valor8" optionLabel="nombre8"></Dropdown>
                <label for="municipio">Municipio</label>
            </span>
					</div>
          <div class="field col-12 mb-3 lg:col-3 lg:col-2">
            <span class="p-float-label">
              <InputText type="text" id="distrito" v-model.trim="categoria.distrito" autofocus/>
              <label for="inputtext">Distrito/Seccion</label>
            </span>
					</div>
          <div class="field col-12 mb-3 lg:col-3 lg:col-2">
            <span class="p-float-label">
              <InputText type="text" id="gaioc" v-model.trim="categoria.gaioc" autofocus/>
              <label for="inputtext">GAIOC/TIOC</label>
            </span>
					</div>
          <div class="field col-12 mb-3 lg:col-3 lg:col-2">
            <span class="p-float-label">
                <Dropdown id="comunidad" :options="combo9" v-model="categoria.valor9" optionLabel="nombre9"></Dropdown>
                <label for="comunidad">Comunidad</label>
            </span>
					</div>
				</div>
        <table style="width: 100%; background-color:#F8F8F8; height: 35px;">
          <tr >
            <td>
              <B>&nbsp;&nbsp;CONDICIONES CLIMÁTICAS</B>
            </td>
          </tr>
        </table>
        <br>
        <div class="grid formgrid">
          <div class="field col-12 mb-3 lg:col-4 lg:col-2">
            <span class="p-float-label">
              <InputText type="text" id="bioclima" v-model.trim="categoria.bioclima" autofocus/>
              <label for="inputtext">Bioclima</label>
            </span>
					</div>
          <div class="field col-12 mb-3 lg:col-4 lg:col-2">
            <span class="p-float-label">
              <InputText type="text" id="temperatura" v-model.trim="categoria.temperatura" autofocus/>
              <label for="inputtext">Temperatura %</label>
            </span>
					</div>
          <div class="field col-12 mb-3 lg:col-4 lg:col-2">
            <span class="p-float-label">
              <InputText type="text" id="humedad" v-model.trim="categoria.humedad" autofocus/>
              <label for="inputtext">Humedad R.</label>
            </span>
					</div>
				</div>
        
  
             {{ categoria }}
            <template #footer>
                <Button label="Cancelar" icon="pi pi-times" @click="closeModal" class="p-button-text"/>
                <Button label="Guardar" icon="pi pi-check" @click="guardarCategoria" autofocus />
            </template>
        </Dialog>
</div>
    <DataTable :value="categorias" reponsiveLayout="scroll" >
      <Column field="id" header="ID"></Column>
      <Column field="nombre" header="CODIGO"></Column>
      <Column field="detalle" header="MACRO REGION"></Column>
      
      <Column :exportable="false" style="min-width:8rem" header="ACCION">
          <template #body="slotProps">
              <!--{{ slotProps.data}}-->
              <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editarDialogModal(slotProps.data)"/>
              <Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="confirmDeleteProduct(slotProps.data)" />
          </template>
      </Column>
    </DataTable>  
<Toast/>
  </div>
  <img src="" alt="">
</template>

<script>
import { onMounted, ref } from 'vue'
import * as categoriaService from '@/service/CategoriaService.js'
import { useToast } from "primevue/usetoast";

export default {
  props:{
  src: {
    type: Array,
    required: true
  }
},

  setup(){

    const categorias = ref([]);
    const categoria = ref({});
    const displayModal = ref(false);
    const submitted = ref(false);
    const toast = useToast();
    const errores = ref({});
    
    onMounted( () => {
      listarCategorias()
    })

    // METODOS
    const listarCategorias =  async () => {
     const {data} = await categoriaService.index()
      categorias.value = data
    }

    const guardarCategoria = async () => {
      try {
        if(categoria.value.id){
          await categoriaService.update(categoria.value, categoria.value.id)
          listarCategorias()
          closeModal() 
          toast.add({severity:'success', summary: 'REGISTRO', detail:'Macro region Actualizada', life: 3000});
        }
        else{
        await categoriaService.store(categoria.value)
        listarCategorias()
        //categorias.value.push(categoria.value)
        closeModal()     
        toast.add({severity:'success', summary: 'REGISTRO', detail:'Macro region Registrada', life: 3000}); 
        }
      } catch (error) {
        console.log(error.response.data)
        errores.value = error.response.data.errores
        toast.add({severity:'error', summary: 'ERROR DE REGISTRO', detail:'Error de registro de Macro region', life: 3000});
      }



    
    }

    // METODOS DE LA PLANTILLA
    const valor1=null;
    const combo1=([
				{ nombre1: 'New York', code: 'NY' },
				{ nombre1: 'Rome', code: 'RM' },
				{ nombre1: 'London', code: 'LDN' },
				{ nombre1: 'Istanbul', code: 'IST' },
				{ nombre1: 'Paris', code: 'PRS' },
			]);
      
      

    const selectButtonValues1= ([
					{name: 'SI', code: 'O1'},
					{name: 'NO', code: 'O2'},
				]);

     const selectButtonValue1 = null;
    
    // Abrir un modal
      const openModal = () => {
        categoria.value={}
        displayModal.value= true
      }

    // Abrir un modal
    const editarDialogModal = (datos) => {
        categoria.value = datos
        displayModal.value= true
      }

    // Cerrar un modal
      const closeModal = () => {
        displayModal.value= false
        categoria.value={}
      }
      // DEVOLVER REGISTROS
      return {combo1, valor1, selectButtonValues1, selectButtonValue1, categoria, submitted, displayModal, openModal, closeModal, guardarCategoria, editarDialogModal, errores}

  }

}
</script>

<style lang="scss" scoped>
.floatlabel-demo {
	.field {
		margin-top: 2rem;
	}
}

::v-deep(.p-chips .p-chips-token) {
	background-color: var(--primary-color);
	color: var(--primary-color-text);
}
</style>






































