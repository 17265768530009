<!-- SpecialPage.vue -->
<template>
    <div class="privacy-policy">
    <h3>Política de Privacidad</h3>
    <p>
        Lea detenidamente esta política de privacidad para usuarios que utilizan la aplicación móvil desarrollada por el Ministerio de Desarrollo Productivo y Economía Plural. Aquí encontrará los términos sobre el uso y la finalidad de los datos recopilados.
    </p>
    <section>
      <h2>1. Tratamiento de Datos</h2>
      <p>
        La aplicación móvil se encuentra alojada en los servidores del Ministerio de Desarrollo Productivo y Economía Plural, La Paz, Bolivia.
      </p>
    </section>

    <section>
      <h2>2. Datos Recolectados</h2>
      <p>
        La aplicación recopila y utiliza la siguiente información personal para cumplir con su objetivo de desarrollar y mantener un catálogo nacional de recursos turísticos:
      </p>
      <ul>
        <li>
          <strong>Datos de ubicación:</strong>  La aplicación utiliza el GPS del dispositivo para recopilar datos de ubicación precisos sobre los recursos turísticos. Esta información se usa para enriquecer y actualizar el catálogo nacional de recursos turísticos.
        </li>
        <li>
          <strong>Imágenes:</strong> Accedemos a la cámara del dispositivo para cargar imágenes de recursos turísticos. Estas imágenes se utilizan para la gestión, visualización y enriquecimiento del catálogo nacional.
        </li>
      </ul>
      <p>
        Toda la información recolectada se la utilizará con fines estrictamente de interés público en el ámbito de desarrollar y elaborar el Catalogo Nacional de Recursos Turisticos en cumplimiento de la Guía Técnica de Inventariación de Recursos Turísticos.
      </p>
    </section>

    <section>
      <h2>3. Finalidad de los datos recolectados, guardados y su uso</h2>
      <p>
        La información y los datos recopilados a través de la aplicación se utilizarán exclusivamente para los siguientes fines:
    </p><ul>
        <li>
          <strong>Desarrollo del Catálogo Nacional:</strong>  Enriquecer y mantener el catálogo nacional de recursos turísticos, contribuyendo a una base de datos precisa y completa.
        </li></ul>
    </section>

    <section>
      <h2>4. Tiempo de Conservación de Datos</h2>
      <p>
        Los datos serán conservados y tratados mientras sean necesarios para cumplir con las finalidades indicadas en el punto 3.
      </p>
    </section>

    <section>
      <h2>5. Acceso a Datos</h2>
      <p>
        El acceso a los datos recopilados está restringido al personal autorizado del Ministerio de Desarrollo Productivo y Economía Plural, así como a las entidades responsables de la gestión de usuarios, como las gobernaciones y el nivel nacional. Estos datos pueden ser utilizados para mejorar la prestación de los servicios y garantizar el correcto funcionamiento de la aplicación.
      </p>
    </section>
  </div>
  </template>
  <style scoped>
  .privacy-policy {
    text-align: justify;
    max-width: 80%;
    margin: 0 auto;
    padding: 40px;
    font-family: Arial, sans-serif;
  }
  
  .privacy-policy h1 {
    font-size: 24px;
    margin-bottom: 16px;
  }
  
  .privacy-policy h2 {
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .privacy-policy p {
    line-height: 1.6;
    margin-bottom: 10px;
  }
  
  .privacy-policy ul {
    list-style: disc;
    margin-left: 20px;
  }
  
  .privacy-policy ul li {
    margin-bottom: 10px;
  }
  </style>