import {http, urlBase} from "./Http"

//Gobernacion
// MOSTRAR
export const index = (page=1, rows=10, q=null) => {
  return http().get(`${urlBase}/ppirtaentrada?page=${page}&rows=${rows}&q=${q}`);
}

// RECIBIR
export const recibirPpirta = (id) => {
  return http().post(`${urlBase}/ppirtarecibir/${id}`);
}


//Nacional
// MOSTRAR
export const indexN = (page=1, rows=10, q=null) => {
  return http().get(`${urlBase}/ppirtanacionalentrada?page=${page}&rows=${rows}&q=${q}`);
}

// RECIBIR
export const recibirPpirtaN = (id) => {
  return http().post(`${urlBase}/ppirtanacionalrecibir/${id}`);
}