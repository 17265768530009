import {http, urlBase} from "./Http" 

// LISTAR
export const index = () => {
  return http().get(`${urlBase}/rol`);
}

// GUARDAR
export const store = (datos) => {
  return http().post(`${urlBase}/rol`,datos);
}

// MOSTRAR
export const show = (id) => {
  return http().get(`${urlBase}/rol/${id}`, datos);
}

// MODIFICAR
export const update = (datos, id) => {
  return http().put(`${urlBase}/rol/${id}`);
}

// ELIMINAR
export const destroy = (id) => {
  return http().delete(`${urlBase}/rol/${id}`);
}
