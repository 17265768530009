import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Crud from '../pages/CrudDemo.vue'
import Dashboard from '../components/Dashboard.vue'

import Privacidad from '../pages/PoliticaDePrivacidad.vue'

// Inicio 
import DashboardE from '../components/DashboardAnalytics.vue'
import Login from '../pages/Login.vue'
import Perfil from '../pages/admin/Perfil.vue'
// import Categoria from "../pages/admin/categoria/CategoriaView.vue"

// Gestion regional 
import region from "../pages/admin/region/RegionView.vue"
import macroregion from "../pages/admin/region/MacroRegionView.vue"

// Gestion Geográfica 
import departamento from "../pages/admin/geografico/DepartamentoView.vue"
import provincia from "../pages/admin/geografico/ProvinciaView.vue"
import municipio from "../pages/admin/geografico/MunicipioView.vue"
import proyeccion from "../pages/admin/geografico/ProyeccionView.vue"
import datum from "../pages/admin/geografico/DatumView.vue"

// Gestion Turística 
import seccion from "../pages/admin/clasificador/SeccionView.vue"
import categorias from "../pages/admin/clasificador/CategoriasView.vue"
import tipo from "../pages/admin/clasificador/TipoView.vue"
import subtipo from "../pages/admin/clasificador/SubTipoView.vue"
import catsubtipo from "../pages/admin/clasificador/CatSubTipoView.vue"

// Gestion de usuarios
import entidad from "../pages/admin/entidad/EntidadView.vue"
import usuario from "../pages/admin/usuario/UsuarioView.vue"
import permisos from "../pages/admin/usuario/PermisoView.vue"

//Centro Poblado
import poblado from "../pages/admin/turistico/PobladoView.vue"
import nuevop from "../pages/admin/turistico/NuevoPobladoView.vue"
import detallep from "../pages/admin/turistico/DetallePobladoView.vue"
import informacionp from "../pages/admin/turistico/InformacionPoblacionView.vue"
import medico from "../pages/admin/turistico/ServicioMedicoPobladoView.vue"
import pernoctacion from "../pages/admin/turistico/PernoctacionPobladoView.vue"
import restauracion from "../pages/admin/turistico/RestauracionPobladoView.vue"
import recreacion from "../pages/admin/turistico/RecreacionPobladoView.vue"
import infoguiaje from "../pages/admin/turistico/InfoGuiajePobladoView.vue"
import transporte from "../pages/admin/turistico/TransportePobladoView.vue"
import infraestructura from "../pages/admin/turistico/InfraestructuraPobladoView.vue"

//Atractivo Turístico
import turistico from "../pages/admin/turistico/TuristicoView.vue"
import nuevo from "../pages/admin/turistico/NuevoAtractivoView.vue"
import detalle from "../pages/admin/turistico/DetalleTuristicoView.vue"
import informacion from "../pages/admin/turistico/InformacionTuristicaView.vue"
import rutasacceso from "../pages/admin/turistico/RutasAccesoView.vue"
import temporalidad from "../pages/admin/turistico/TemporalidadView.vue"
import visitas from "../pages/admin/turistico/AbiertoTurismoView.vue"
import integridad from "../pages/admin/turistico/IntegridadView.vue"
import capacidad from "../pages/admin/turistico/CapacidadView.vue"
import actividadturistica from "../pages/admin/turistico/ActividadTuristicaView.vue"
import fotograma from "../pages/admin/turistico/FotogramaView.vue"

//Ver Atractivos
import verdetalle from "../pages/admin/turistico/DetalleVerTuristicoView.vue"
import ver from "../pages/admin/turistico/InformacionVerTuristicaView.vue"
import verrutas from "../pages/admin/turistico/ver/VerRutas.vue"
import vertemporalidad from "../pages/admin/turistico/ver/VerTemporalidad.vue"
import vervisitas from "../pages/admin/turistico/ver/VerAbiertoTurismo.vue"
import verintegridad from "../pages/admin/turistico/ver/VerIntegridad.vue"
import vercapacidad from "../pages/admin/turistico/ver/VerCapacidad.vue"
import veractividad from "../pages/admin/turistico/ver/VerActividad.vue"
import verfotograma from "../pages/admin/turistico/ver/VerFotograma.vue"

//Ver Facilidades
import verdetallef from "../pages/admin/turistico/DetalleVerPobladoView.vue"
import verf from "../pages/admin/turistico/InformacionVerPobladoView.vue"
import vermedico from "../pages/admin/turistico/ver/VerMedico.vue"
import verpernoctacion from "../pages/admin/turistico/ver/VerPernoctacion.vue"
import verrestauracion from "../pages/admin/turistico/ver/VerRestauracion.vue"
import verrecreacion from "../pages/admin/turistico/ver/VerRecreacion.vue"
import verinfoguiaje from "../pages/admin/turistico/ver/VerInfoGuiaje.vue"
import vertransporte from "../pages/admin/turistico/ver/VerTransporte.vue"
import verinfraestructura from "../pages/admin/turistico/ver/VerInfraestructura.vue"


//Bandeja ppirta gobernacion
import ppirtaentrante from "../pages/admin/turistico/bandeja/PpirtaEntranteView.vue"
import ppirtarevision from "../pages/admin/turistico/bandeja/PpirtaPendienteView.vue"
import ppirtafinalizado from "../pages/admin/turistico/bandeja/PpirtaFinalizadoView.vue"

//Bandeja ppirta nacional
import ppirtaentrantenacional from "../pages/admin/turistico/bandeja/PpirtaEntranteNacionalView.vue"
import ppirtarevisionnacional from "../pages/admin/turistico/bandeja/PpirtaPendienteNacionalView.vue"
import ppirtafinalizadonacional from "../pages/admin/turistico/bandeja/PpirtaFinalizadoNacionalView.vue"

//Bandeja centro poblado gobernacion
import cpentrante from "../pages/admin/turistico/bandeja/PpirtfEntranteView.vue"
import cprevision from "../pages/admin/turistico/bandeja/PpirtfPendienteView.vue"
import cpfinalizado from "../pages/admin/turistico/bandeja/PpirtfFinalizadoView.vue"

//Bandeja ppirtf nacional
import cpentrantenacional from "../pages/admin/turistico/bandeja/PpirtfEntranteNacionalView.vue"
import cprevisionnacional from "../pages/admin/turistico/bandeja/PpirtfPendienteNacionalView.vue"
import cpfinalizadonacional from "../pages/admin/turistico/bandeja/PpirtfFinalizadoNacionalView.vue"


import prueba from "../pages/admin/turistico/PruevaView.vue"

import { Buffer } from "buffer"

const routes = [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {requireAuth: true}
  },
  {
    path: '/inicio',
    name: 'dashboarde',
    component: DashboardE,
    meta: { requireAuth: true }
  },
  {
    path: '/',
    redirect: '/inicio' // Redirige / a /inicio
  },
  // {
  //   path: '/categoria',
  //   name: 'categoria',
  //   component: Categoria,
  //   meta: {requireAuth: true}
  // },
  {
    path: '/macroregion',
    name: 'macroregion',
    component: macroregion,
    meta: {requireAuth: true}
  },
  {
    path: '/region',
    name: 'region',
    component: region,
    meta: {requireAuth: true}
  },
  {
    path: '/departamento',
    name: 'departamento',
    component: departamento,
    meta: {requireAuth: true}
  },
  {
    path: '/provincia',
    name: 'provincia',
    component: provincia,
    meta: {requireAuth: true}
  },
  {
    path: '/municipio',
    name: 'municipio',
    component: municipio,
    meta: {requireAuth: true}
  }, 
  {
    path: '/proyeccion',
    name: 'proyeccion',
    component: proyeccion,
    meta: {requireAuth: true}
  },
  {
    path: '/datum',
    name: 'datum',
    component: datum,
    meta: {requireAuth: true}
  },
  {
    path: '/seccion',
    name: 'seccion',
    component: seccion,
    meta: {requireAuth: true}
  },
 {
    path: '/categorias',
    name: 'categorias',
    component: categorias,
    meta: {requireAuth: true}
  },
  {
    path: '/tipo',
    name: 'tipo',
    component: tipo,
    meta: {requireAuth: true}
  },
  {
    path: '/subtipo',
    name: 'subtipo',
    component: subtipo,
    meta: {requireAuth: true}
  },

  {
    path: '/catsubtipo',
    name: 'catsubtipo',
    component: catsubtipo,
    meta: {requireAuth: true}
  },

  {
    path: '/turistico',
    name: 'turistico',
    component: turistico,
    meta: {requireAuth: true}
  },
  {
    path: '/nuevo',
    name: 'nuevo',
    component: nuevo,
    meta: {requireAuth: true}
  },
  {
    path: '/detalle',
    name: 'detalle',
    component: detalle,
    meta: {requireAuth: true},

    children: [
      { path: '/rutasacceso/:id',
        name: 'rutasacceso',
        component: rutasacceso,
        meta: {requireAuth: true}, 
      },
      {
        path: '/rutasacceso',
        redirect: { name: 'turistico' },
      },
      { path: '/detalle/:id',
        name: 'actividadturistica',
        component: actividadturistica,
        meta: {requireAuth: true}, 
      },   
      {
        path: '/detalle',
        redirect: { name: 'turistico' },
      }, 
      { path: '/visitas/:id',
        name: 'visitas',
        component: visitas,
        meta: {requireAuth: true}, 
      },
      {
        path: '/visitas',
        redirect: { name: 'turistico' },
      }, 
      { path: '/fotograma/:id',
        name: 'fotograma',
        component: fotograma,
        meta: {requireAuth: true}, 
      },
      {
        path: '/fotograma',
        redirect: { name: 'turistico' },
      }, 
      { path: '/informacion/:id',
        name: 'informacion',
        component: informacion,
        meta: {requireAuth: true},
      },
      {
        path: '/informacion',
        redirect: { name: 'turistico' },
      },

      { path: '/temporalidad/:id',
        name: 'temporalidad',
        component: temporalidad,
        meta: {requireAuth: true}, 
      },
      {
        path: '/temporalidad',
        redirect: { name: 'turistico' },
      },
      { path: '/capacidad/:id',
        name: 'capacidad',
        component: capacidad,
        meta: {requireAuth: true}, 
      },
      {
        path: '/capacidad',
        redirect: { name: 'turistico' },
      },
      { path: '/integridad/:id',
        name: 'integridad',
        component: integridad,
        meta: {requireAuth: true}, 
      },
      {
        path: '/integridad',
        redirect: { name: 'turistico' },
      },

    ],

  },

  //ver atractivo turístico
  {
    path: '/verdetalle',
    name: 'verdetalle',
    component: verdetalle,
    meta: {requireAuth: true},

    children: [
      { path: '/verrutas/:id',
        name: 'verrutas',
        component: verrutas,
        meta: {requireAuth: true}, 
      },
      { path: '/vertemporalidad/:id',
        name: 'vertemporalidad',
        component: vertemporalidad,
        meta: {requireAuth: true}, 
      },
      { path: '/veractividad/:id',
        name: 'veractividad',
        component: veractividad,
        meta: {requireAuth: true}, 
      },    
      { path: '/vervisitas/:id',
        name: 'vervisitas',
        component: vervisitas,
        meta: {requireAuth: true}, 
      },
      { path: '/verfotograma/:id',
        name: 'verfotograma',
        component: verfotograma,
        meta: {requireAuth: true}, 
      },
      { path: '/ver/:id',
        name: 'ver',
        component: ver,
        meta: {requireAuth: true}, 
      },
      
      { path: '/vercapacidad/:id',
        name: 'vercapacidad',
        component: vercapacidad,
        meta: {requireAuth: true}, 
      },
      { path: '/verintegridad/:id',
        name: 'verintegridad',
        component: verintegridad,
        meta: {requireAuth: true}, 
      },

    ],

  },

  //ver facilidades

  {
    path: '/verdetallef',
    name: 'verdetallef',
    component: verdetallef,
    meta: {requireAuth: true},

    children: [
    
      { path: '/verf/:id',
        name: 'verf',
        component: verf,
        meta: {requireAuth: true}, 
      },
      { path: '/vermedico/:id',
        name: 'vermedico',
        component: vermedico,
        meta: {requireAuth: true}, 
      },
      { path: '/verpernoctacion/:id',
        name: 'verpernoctacion',
        component: verpernoctacion,
        meta: {requireAuth: true}, 
      },
      { path: '/verrestauracion/:id',
        name: 'verrestauracion',
        component: verrestauracion,
        meta: {requireAuth: true}, 
      },
      { path: '/verrecreacion/:id',
        name: 'verrecreacion',
        component: verrecreacion,
        meta: {requireAuth: true}, 
      },
      { path: '/verinfoguiaje/:id',
        name: 'verinfoguiaje',
        component: verinfoguiaje,
        meta: {requireAuth: true}, 
      },
      { path: '/vertransporte/:id',
        name: 'vertransporte',
        component: vertransporte,
        meta: {requireAuth: true}, 
      },
      { path: '/verinfraestructura/:id',
        name: 'verinfraestructura',
        component: verinfraestructura,
        meta: {requireAuth: true}, 
      },
    

    ],

  },
  

  //bandeja ppirta gobernacion
  
  { path: '/ppirtaentrante/',
    name: 'ppirtaentrante',
    component: ppirtaentrante,
    meta: {requireAuth: true}, 
  },
  {
    path: '/ppirtarevision/',
    name: 'ppirtarevision',
    component: ppirtarevision,
    meta: {requireAuth: true}, 
  },
  {
    path: '/ppirtafinalizado/',
    name: 'ppirtafinalizado',
    component: ppirtafinalizado,
    meta: {requireAuth: true}, 
  },

  //Bandeja ppirta nacional

  { path: '/ppirtaentrantenacional/',
    name: 'ppirtaentrantenacional',
    component: ppirtaentrantenacional,
    meta: {requireAuth: true}, 
  },
  {
    path: '/ppirtarevisionnacional/',
    name: 'ppirtarevisionnacional',
    component: ppirtarevisionnacional,
    meta: {requireAuth: true}, 
  },
  {
    path: '/ppirtafinalizadonacional/',
    name: 'ppirtafinalizadonacional',
    component: ppirtafinalizadonacional,
    meta: {requireAuth: true}, 
  },

  //bandeja centro poblado
  
  { path: '/cpentrante/',
    name: 'cpentrante',
    component: cpentrante,
    meta: {requireAuth: true}, 
  },
  {
    path: '/cprevision/',
    name: 'cprevision',
    component: cprevision,
    meta: {requireAuth: true}, 
  },
  {
    path: '/cpfinalizado/',
    name: 'cpfinalizado',
    component: cpfinalizado,
    meta: {requireAuth: true}, 
  },

  //Bandeja ppirta nacional

  { path: '/cpentrantenacional/',
    name: 'cpentrantenacional',
    component: cpentrantenacional,
    meta: {requireAuth: true}, 
  },
  {
    path: '/cprevisionnacional/',
    name: 'cprevisionnacional',
    component: cprevisionnacional,
    meta: {requireAuth: true}, 
  },
  {
    path: '/cpfinalizadonacional/',
    name: 'cpfinalizadonacional',
    component: cpfinalizadonacional,
    meta: {requireAuth: true}, 
  },
  {
    path: '/poblado',
    name: 'poblado',
    component: poblado,
    meta: {requireAuth: true}
  },
  {
    path: '/nuevop',
    name: 'nuevop',
    component: nuevop,
    meta: {requireAuth: true}
  },
  {
    path: '/detallep',
    name: 'detallep',
    component: detallep,
    meta: {requireAuth: true},

    children: [

      { path: '/informacionp/:id',
        name: 'informacionp',
        component: informacionp,
        meta: {requireAuth: true}, 
      },
      {
        path: '/informacionp',
        redirect: { name: 'poblado' },
      },
      
      { path: '/medico/:id',
        name: 'medico',
        component: medico,
        meta: {requireAuth: true}, 
      },        
      { path: '/pernoctacion/:id',
        name: 'pernoctacion',
        component: pernoctacion,
        meta: {requireAuth: true}, 
      },
      { path: '/restauracion/:id',
        name: 'restauracion',
        component: restauracion,
        meta: {requireAuth: true}, 
      },
      { path: '/recreacion/:id',
        name: 'recreacion',
        component: recreacion,
        meta: {requireAuth: true}, 
      },
      { path: '/infoguiaje/:id',
        name: 'infoguiaje',
        component: infoguiaje,
        meta: {requireAuth: true}, 
      },
      { path: '/transporte/:id',
        name: 'transporte',
        component: transporte,
        meta: {requireAuth: true}, 
      },
      { path: '/infraestructura/:id',
        name: 'infraestructura',
        component: infraestructura,
        meta: {requireAuth: true}, 
      },


    ],

  },

 
  {
    path: '/prueba',
    name: 'prueba',
    component: prueba,
    meta: {requireAuth: true}
  },
  {
    path: '/entidad',
    name: 'entidad',
    component: entidad,
    meta: {requireAuth: true}
  },
  {
    path: '/usuario',
    name: 'usuario',
    component: usuario,
    meta: {requireAuth: true}
  },   
  {
    path: '/permisos',
    name: 'permisos',
    component: permisos,
    meta: {requireAuth: true}
  },   
  {
    path: '/perfil',
    name: 'Perfil',
    component: Perfil,
    meta: {requireAuth: true}
  },  
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/privacidad',
    name: 'Privacidad',
    component: Privacidad,
    meta: { noLayout: true }
  },
  //{
    //path: '/logout',
    //name: 'logout',
    //component: Logout,
    //meta: {requireAuth: true}
    
  //},
  {
    path: '/crud',
    name: 'crud',
    component: Crud
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  let token = "";
  
  // Si la ruta requiere autenticación
  if (to.meta.requireAuth) {
    // Obtén el token del almacenamiento local
    if (localStorage.getItem("token")) {
      token = Buffer.from(localStorage.getItem("token"), 'base64').toString('ascii');
    }

    // Si el token es válido, permite el acceso
    if (token) {
      next();
    } else {
      next("/login"); // Redirige a la página de inicio de sesión
    }
  } else {
    // Si la ruta no requiere autenticación, permite el acceso
    next();
  }
});

export default router


