import axios from "axios"
import { Buffer } from "buffer"
export const urlBase ="https://api-irt.produccion.gob.bo/api";

/**
 * Configuracion de axios (Interceptor)
 * @returns Axios
 */
export function http(){

    let token= "";
    if(localStorage.getItem("token")){
      token = Buffer.from(localStorage.getItem("token"),'base64').toString('ascii');
    }
  const interceptor = axios.create({
    baseURL: urlBase, 
    headers:{
      'Accept': 'application/json',
      'Content-Type': 'application/json', 
      'Authorization': `Bearer ${token}`
    },
    timeout:180000
  });

  //interceptar errores (401, 403)
  interceptor.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      //alert(error.response.status)
      if(error.response.status === 401){
        localStorage.removeItem("token")
        window.location.href = "/login"
      }      
      return Promise.reject(error);
    }
  );

  return interceptor;


}

