<template>
    <div class="card">
      <table cellpadding="5px" style="width: 100%; background-color: #f8f8f8; height: 45px">
        <tr>
          <td style="font-size: 20px">
            <B>FOTOGRAMAS</B>
          </td>
        </tr>
      </table>
      <br />

      <!--Tabla de displiegue de la informacion-->
      <DataTable ref="dt" :value="fotogramas" v-model:selection="selectedProducts" dataKey="id" responsiveLayout="scroll">
        <Column></Column>
        <Column></Column>
        <Column></Column>
        <Column></Column>
        <Column field="ruta_fotograma" header="FOTOGRAMA">
          <template #body="slotProps">
            <div v-if="slotProps.data.favorito==='1'">
              <h6 style="color:#1565C0">⬇ Fotograma favorito ⬇️</h6>
              <Image :src="'data:image/jpeg;base64,' + slotProps.data.ruta_fotograma" alt="Image" width="150" preview />
            </div>
            <div v-else>
              <Image :src="'data:image/jpeg;base64,' + slotProps.data.ruta_fotograma" alt="Image" width="150" preview />
            </div>
          </template>
        </Column>
      
      </DataTable>
  
      <!--Para el despliegue del mensaje de salida-->
      <Toast />
    </div>
  </template>
  
  <script>
  import { onMounted, ref } from "vue";
  import { useToast } from "primevue/usetoast";
  import * as fotogramaService from '@/service/PlantillaService.js'
  
  // Importaciones
  import { useVuelidate } from "@vuelidate/core";
  import { useRoute } from 'vue-router'

  import CryptoJS from 'crypto-js';
  
  export default {
    data() {
      return {
        showImageInfo: true
      };
    },
    methods: {
      hideImageInfo() {
        this.showImageInfo = false;
      }
    },
    setup() {
      const rolss = JSON.parse(localStorage.getItem("role")) || [];
      const primerRol = ref(rolss[0] || {});
      const idPrimerRol = ref(primerRol.value.id || null);
  
      const route = useRoute();
      const fotogramaId = route.params.id;
  
      const fotogramas = ref();
      const fotograma = ref({});
      const displayAddModal = ref(false);
      const displayEditModal = ref(false);
      const submitted = ref(false);
      const toast = useToast();
      const errores = ref({});
      
      onMounted(() => {
        listarFotogramas();
        
      });
  
      const dt = ref();
      const deleteProductDialog = ref(false);
      const deleteProductsDialog = ref(false);
      const loading = ref(false);
      const selectedProducts = ref();
      const lazyParams = ref({});
  
      // METODOS
      const onPage = (event) => {
        lazyParams.value = event;
        listarFotogramas();
      };
  
      // Listado de las entidades creadas
      const listarFotogramas = async () => {
        try {
          const encryptionKey = 'M1n1st3r10d3d3s4rr0ll0pr0duct1v0y3c0n0m14plur4l';
          const encryptedId = route.params.id.match(/.{1,2}/g).map(byte => String.fromCharCode(parseInt(byte, 16))).join('');
          const sanitizedId = CryptoJS.AES.decrypt(encryptedId, encryptionKey).toString(CryptoJS.enc.Utf8);
          const originalId = sanitizedId.replace(/_/g, '/');

          const { data } = await fotogramaService.listF(originalId);
          // console.log(data);
  
          fotogramas.value = data.fotograma;
          loading.value = false;
  
          // Mostrar los datos de los fotogramas
          data.Fotograma.data.forEach((fotograma, index) => {
            // console.log(`Fotograma ${index + 1}:`, fotograma);
          });
  
        } catch (error) {
          // console.log(error.response);
        }
      };
  
      // Guardar fotograma 
      const guardarFotograma = async (event) => {
  
        const promesas = [];
        for (const imageFile of event.files) {
          const promesa = new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(imageFile);
  
            reader.onload = async () => {
              const base64Image = reader.result.split(',')[1];
  
              // Asignar el base64 a la propiedad ruta_fotograma del objeto fotograma
              fotograma.value.ruta_fotograma = base64Image;
  
              try {
                await fotogramaService.subirFotogramas(fotograma.value);
                resolve(true);
              } catch (error) {
                console.error(error);
                resolve(false);
              }
            };
          });
  
          promesas.push(promesa);
        }
  
        // Espere a que se resuelvan todas las promesas.
        const results = await Promise.all(promesas);
        // Compruebe si alguna promesa tenía errores.
        const hasError = results.some((result) => result === false);
  
        if (!hasError) {
          listarFotogramas();
          closeModal();
          toast.add({
            severity: "success",
            summary: "REGISTRO",
            detail: "Fotogramas Registrado con éxito",
            life: 5000,
          });
        } else {
          toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Hubo un problema al subir una o más imágenes',
            life: 5000,
          });
        }
      };
  
      // editar fotograma
      const editarFotograma = async (event) => {
  
        const promesas = [];
        for (const imageFile of event.files) {
          const promesa = new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(imageFile);
  
            reader.onload = async () => {
              const base64Image = reader.result.split(',')[1];
  
              // Asignar el base64 a la propiedad ruta_fotograma del objeto fotograma
              fotograma.value.ruta_fotograma = base64Image;
  
              try {
                await fotogramaService.updateF(fotograma.value, fotograma.value.id);
                resolve(true);
              } catch (error) {
                resolve(false);
              }
            };
          });
  
          promesas.push(promesa);
        }
  
        // Espere a que se resuelvan todas las promesas.
        const results = await Promise.all(promesas);
        // Compruebe si alguna promesa tenía errores.
        const hasError = results.some((result) => result === false);
  
        if (!hasError) {
          listarFotogramas();
          closeModalEdit();
          toast.add({
            severity: "success",
            summary: "REGISTRO",
            detail: "Fotograma Actualizada con éxito",
            life: 5000,
          });
        } else {
          toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Hubo un problema al subir una o más imágenes',
            life: 5000,
          });
        }
  
      };
      // METODOS DE LA PLANTILLA
      // Abrir modal
      const openAddModal = () => {
  
        if (fotogramas.value && fotogramas.value.length > 0) {
          const idPpirta = fotogramas.value[0].id_ppirta;
          
          fotograma.value = {
            id_ppirta: idPpirta,
            
          };
          displayAddModal.value = true;
          submitted.value = false;
        }
        fotograma.value = {
            id_ppirta: fotogramaId,
            ruta_fotograma: '',
  
          };
          displayAddModal.value = true;
          submitted.value = false;
      };
  
      // Editar modal
      const openEditModal = (datos) => {
        fotograma.value = datos;
        displayEditModal.value = true;
      };
  
      // Eliminar un producto
      const confirmDeleteProduct = (datos) => {
        fotograma.value = datos;
        deleteProductDialog.value = true;
      };
  
      const deleteProduct = async () => {
        try {
          await fotogramaService.destroyF(fotograma.value.id);
          listarFotogramas();
          toast.add({
            severity: "error",
            summary: "REGISTRO",
            detail: "Fotograma Eliminado",
            life: 5000,
          });
          deleteProductDialog.value = false;
          fotograma.value = {}; 
        } catch (error) {
          //console.log(error.response.data);
          errores.value = error.response.data.errores;
          toast.add({
            severity: "error",
            summary: "REGISTRO",
            detail: "No se eliminó el registro",
            life: 5000,
          });
        }
                
      };
  
      const confirmDeleteSelected = () => {
        deleteProductsDialog.value = true;
      }; 
         
      // Cerrar un modal
      const closeModal = () => {
        displayAddModal.value = false;
        fotograma.value = {};
        submitted.value = false;
        listarFotogramas();
      };
  
      const closeModalEdit = () => {
        displayEditModal.value = false;
        fotograma.value = {};
        submitted.value = false;
        listarFotogramas();
      };
  
      const onUpload = () =>{
        toast.add({severity: 'info', summary: 'Success', detail: 'File Uploaded', life: 3000});
      }
  
      // DEVOLVER REGISTROS
      return {
        rolss,
        idPrimerRol,
        onUpload,
        guardarFotograma,
        onPage,
        loading,
        dt,
        deleteProductDialog,
        deleteProductsDialog,
        confirmDeleteProduct,
        deleteProduct,
        confirmDeleteSelected,
        selectedProducts,
        fotogramas,
        fotograma,
        submitted,
        openAddModal,
        openEditModal,
        displayAddModal,
        displayEditModal,
        closeModal,
        closeModalEdit,
        editarFotograma,
        errores,
      };
    },
  };
  </script>
  <style></style>