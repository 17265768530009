<template>
  <div class="card">
    <table
      cellpadding="5px"
      style="width: 100%; background-color: #f8f8f8; height: 45px">
      <tr>
        <td style="font-size: 20px">
          <B>GESTIÓN DE ENTIDADES</B>
        </td>
      </tr>
    </table>
    <br />
    <!--Cabecera de menu principal-->
    <Toolbar class="mb-4">
      <template #start>
        <Button label="Nuevo" icon="pi pi-plus" class="p-button-success mr-2" @click="openModal" />
        <!-- <Button label="Eliminar" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected"
          :disabled="!selectedProducts || !selectedProducts.length" /> -->
      </template>
      <template #end>
        <Button label="PDF" icon="pi pi-file-pdf" class="p-button-help" @click="exportToPDF" />
      </template>
    </Toolbar>

    <!--Tabla de displiegue de la informacion-->
    <DataTable ref="dt" :lazy="true" :value="entidades" v-model:selection="selectedProducts" dataKey="id" :paginator="true"
      :rows="10" :filters="filters" :totalRecords="totalRecords" :loading="loading" @page="onPage($event)"  @filter="onFilter($event)" 
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros" responsiveLayout="scroll">
      <template #header>
        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
          <h5 class="m-0">ENTIDADES</h5>
          <span class="block mt-2 md:mt-0 p-input-icon-left">
            <i class="pi pi-search" />
            <InputText v-model="filters['global'].value" @input="buscar()" placeholder="Buscar..." />
          </span>
        </div>
      </template>
      <!-- <Column selectionMode="multiple" style="width: 3rem" :exportable="false"></Column>
      <Column field="id" header="ID" :sortable="true" style="min-width: 6rem"></Column> -->
      <Column field="sigla" header="SÍGLA" :sortable="false" style="min-width: 8rem"></Column>
      <Column field="nombre" header="ENTIDAD" :sortable="false" style="min-width: 16rem"></Column>
      <Column field="direccion" header="DIRECCIÓN" :sortable="false" style="min-width: 10rem"></Column>
      <Column field="email" header="E-MAIL" :sortable="false" style="min-width: 12rem"></Column>
      <Column field="telefono" header="TELÉFONO" :sortable="false" style="min-width: 8rem"></Column>
      <Column field="id_provincia" header="PROVINCIA" :sortable="false" style="min-width: 8rem"></Column>
      <Column field="id_municipio" header="MUNICIPIO" :sortable="false" style="min-width: 8rem"></Column>
      <Column :exportable="false" style="min-width: 8rem" header="ACCIONES">
        <template #body="slotProps">
          <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
            @click="editarDialogModal(slotProps.data)" />
          <Button v-if="idPrimerRol === 1" icon="pi pi-trash" class="p-button-rounded p-button-warning"
            @click="confirmDeleteProduct(slotProps.data)" />
        </template>
      </Column>
    </DataTable>

    <!-- Formulario de registro de información -->
    <div class="floatlabel-demo col-12 md:col-12">
      <Dialog header="REGISTRO DE ENTIDAD" v-model:visible="displayModal" :style="{ width: '35%' }" maximizable :modal="true"
        class="p-fluid">
        <InlineMessage :style="{  border: 'solid #696cff', borderWidth: '0 0 0 6px', color: '#000000' }"
                    severity="info" class="border-primary w-full justify-content-start">
          <div class="flex align-items-center">
              <!-- <img alt="logo" src="https://primefaces.org/cdn/primevue/images/logo.svg" width="32" /> -->
              <div class="ml-2">Debe verificar que los datos ingresados sean correctos para registrar.<br>De lo contrario puede <span style="color:#4E5FBB"> X Cancelar</span> el registro.</div>
          </div>
        </InlineMessage>
        <br />
        <div class="grid formgrid">
          <div class="field col-12 mb-3 lg:col-2 lg:col-4">

            <label for="name">Sígla</label>
            <InputText id="sigla" v-model.trim="entidad.sigla" v-model="v$.sigla.$model" 
            :class="{'p-invalid':v$.sigla.$invalid && submitted}" @input="v$.sigla.$model"/>
              <small  v-if="(v$.sigla.$invalid && submitted) || v$.sigla.$pending.$response" class="p-error">
              La sígla es requerida</small>
          </div>
          <div class="field col-12 mb-3 lg:col-2 lg:col-8">
            <label for="name">Entidad</label>
            <InputText id="nombre" v-model.trim="entidad.nombre" v-model="v$.nombre.$model" 
            :class="{'p-invalid':v$.nombre.$invalid && submitted}" @change="v$.nombre.$model"/>
            <small v-if="(v$.nombre.$invalid && submitted) || v$.nombre.$pending.$response" class="p-error">
              La entidad es requerida</small>

          </div>
        </div>
        <div class="grid formgrid">
          <div class="field col-12 mb-3 lg:col-2 lg:col-12">
            <label for="name">Descripción de la entidad</label>
            <Textarea v-model="entidad.descripcion" rows="4" cols="20" />
          </div>
        </div>
        <div class="grid formgrid">
          <div class="field col-12 mb-3 lg:col-2 lg:col-6">
            <label for="name">Dirección de la entidad</label>
            <InputText id="direccion" v-model.trim="entidad.direccion" required="true" />
          </div>

          <div class="field col-12 mb-3 lg:col-2 lg:col-6">
            <label for="name">Correo electrónico de la entidad</label>
                
                <InputText id="email" v-model.trim="entidad.email" v-model="v$.email.$model" :class="{'p-invalid':v$.email.$invalid && submitted}" :key="email" aria-describedby="email-error"/>
                
              <span v-if="v$.email.$error && submitted">
                  <span id="email-error" v-for="(error, index) of v$.email.$errors" :key="index">
                  <small class="p-error">No es un correo electrónico válido</small>
                  </span>
              </span>
              
          </div>
        </div>

        <div class="grid formgrid">
          <div class="field col-12 mb-3 lg:col-2 lg:col-8">
            <label for="name">Página web de la entidad</label>
            <InputText id="web" v-model.trim="entidad.web" required="true" />
          </div>
          <div class="field col-12 mb-3 lg:col-2 lg:col-4">
            <label for="name">Teléfono de la entidad</label>
            
            <InputText type="number" id="telefono" v-model.trim="entidad.telefono" v-model="v$.telefono.$model" 
              :class="{'p-invalid':v$.telefono.$invalid && submitted}" @change="v$.telefono.$model"/>
              <small v-if="(v$.telefono.$invalid && submitted) || v$.telefono.$pending.$response" class="p-error">
                El teléfono es requerida</small>
          </div>
        </div>

        <table style="width: 100%; background-color: #f8f8f8; height: 35px">
          <tr>
            <td>
              <B>&nbsp;&nbsp;DATOS DEL DEPARTAMENTO, PROVINCIA Y MUNICIPIO</B>
            </td>
          </tr>
        </table>
        <br />

        <div class="grid formgrid">
          <div class="field col-12 mb-3 lg:col-2 lg:col-4">
            <label for="name">Departamento</label>
            <Dropdown 
              id="id" 
              v-model.trim="entidad.id_departamento"
              v-model="v$.id_departamento.$model" 
              :key="id_departamento" 
              @change="listarProvincias()" 
              :options="departamentos" 
              optionLabel="nombre" 
              optionValue="id"  
              placeholder="Elegir..." 
              :class="{'p-invalid':v$.id_departamento.$invalid && submitted}"
            />
            <small v-if="(v$.id_departamento.$invalid && submitted) || v$.id_departamento.$pending.$response" class="p-error">
              El Departamento es requerida</small>

          </div>
        
          <div class="field col-12 mb-3 lg:col-2 lg:col-4">
            <label for="name">Provincia</label>
              <Dropdown 
                id="id_provincia" 
                v-model.trim="entidad.id_provincia"
                v-model="v$.id_provincia.$model" 
                :key="id_provincia" 
                @change="listarMunicipios()" 
                :options="provincias" 
                optionLabel="nombre" 
                optionValue="id" 
                placeholder="Elegir..." 
                :class="{'p-invalid':v$.id_provincia.$invalid && submitted}"
              />
              <small v-if="(v$.id_provincia.$invalid && submitted) || v$.id_provincia.$pending.$response" class="p-error">
              La provincia es requerida</small>
          </div>

          <div class="field col-12 mb-3 lg:col-2 lg:col-4">
            <label for="name">Municipio</label>
              <Dropdown 
                id="id_municipio" 
                v-model.trim="entidad.id_municipio"
                v-model="v$.id_municipio.$model" 
                :options="municipios" 
                optionLabel="nombre" 
                optionValue="id" 
                placeholder="Elegir..." 
                :class="{'p-invalid':v$.id_municipio.$invalid && submitted}"
              />
              <small v-if="(v$.id_municipio.$invalid && submitted) || v$.id_municipio.$pending.$response" class="p-error">
              El municipio es requerida</small>
          </div>

        </div>

      <!-- {{ entidad }} -->
      <template #footer>
        <Button label="Cancelar" icon="pi pi-times" @click="closeModal" class="p-button-text" />
        <Button label="Guardar" icon="pi pi-check" @click="guardarEntidad(!v$.$invalid)" />
      </template>
        
      </Dialog>
   
      <!-- Eliminar un registro-->
      <Dialog v-model:visible="deleteProductDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
        <div class="confirmation-content">
          <h4><i class="pi pi-exclamation-triangle mr-2" style="font-size: 2rem" /><b>¿Está seguro de borrar el registro?</b></h4><br>
            <span v-if="entidad"><b style="color: #4E5FBB;">{{entidad.nombre}}</b></span><br>
            <span>¡Si no lo está puede cancelar la accíón!</span>
        </div>
        <template #footer>
          <Button label="Cancelar" icon="pi pi-times" class="p-button-text"   @click="deleteProductDialog = false" />
          <Button label="Si, borrar registro" icon="pi pi-check" @click="deleteProduct"/>
        </template>
      </Dialog>
      <!-- Eliminar varios registro-->
        <Dialog v-model:visible="deleteProductsDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
            <div class="confirmation-content">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                <span v-if="product">Are you sure you want to delete the selected products?</span>
            </div>
            <template #footer>
                <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductsDialog = false"/>
                <Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteSelectedProducts" />
            </template>
        </Dialog>

    </div>

    <!--Para el despliegue del mensaje de salida-->
    <Toast />
  </div>
</template>

<script>

import { reactive,onMounted, ref } from "vue";
import { useToast } from "primevue/usetoast";
import InputMask from "primevue/inputmask";
import { FilterMatchMode } from "primevue/api";

import * as entidadService from "@/service/EntidadService.js";
import * as departamentoService from "@/service/DepartamentoService.js";
import * as provinciaService from "@/service/ProvinciaService.js";
import * as municipioService from "@/service/MunicipioService.js";

// Importacones para realizar las validaciones
import { email, helpers, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import jsPDF from 'jspdf';
import 'jspdf-autotable';

export default {
  setup() {
    const rolss = JSON.parse(localStorage.getItem("role")) || [];
    const primerRol = ref(rolss[0] || {});
    const idPrimerRol = ref(primerRol.value.id || null);

    const entidades = ref();
    const entidad = ref({});
    const departamento = ref({});
    const departamentos = ref([]);
    const provincias = ref([]);
    const municipios = ref([]);
    const displayModal = ref(false);
    const submitted = ref(false);
    const toast = useToast();
    const errores = ref({});
    onMounted(() => {
      listarEntidades();
      listarDepartamentos();
    });

    const listarDepartamentos = async () => {
      const {data} = await departamentoService.index('','','');
      departamentos.value=data.departamentos.data;
    }

    const listarProvincias = async () => {
      const {data} = await provinciaService.buscar(entidad.value.id_departamento);
      provincias.value=data.provincias;
    }

    const listarMunicipios = async () => {
      const {data} = await municipioService.showPromun(entidad.value.id_provincia);
      municipios.value=data.municipios;
    }

    const dt = ref();
    const deleteProductDialog = ref(false);
    const deleteProductsDialog = ref(false);
    const loading = ref(false);
    const totalRecords = ref(0);
    const selectedProducts = ref();
    const lazyParams = ref({});
   
    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    // METODOS
    const onPage = (event) => {
      lazyParams.value = event;
      listarEntidades();
    };

    const onFilter = () => {
      lazyParams.value.filters = filters.value;
      listarEntidades();
    };

    const buscar = () => {
      listarEntidades();
    };

    // Validaion del formulario
    // Definimos los variables a validar
    const state = reactive( {
      
      sigla: "",
      nombre: "",
      email: "",
      id_departamento:"",
      id_provincia:"",
      id_municipio:"",
    
    });

    // Tipo de validacion a realizar del dato
    const rules = {
      
      sigla: { required },
      nombre: { required },
      email:{ email },
      telefono:{ required },
      id_departamento:{ required },
      id_provincia:{ required },
      id_municipio:{ required },
    
    };
    // Seccion de la validacion
    const v$ = useVuelidate(rules, state);

    // Listado de las entidades creadas
    const listarEntidades = async () => {
      try {
        loading.value = true;
        let rows = lazyParams.value.rows;
        let q = filters.value.global.value == null?'':filters.value.global.value;
        let page = (lazyParams.value.page == null)?0:lazyParams.value.page;

        const { data } = await entidadService.index(page + 1, rows, q);
        entidades.value = data.entidades.data;
        totalRecords.value = data.entidades.total;
        loading.value = false;

      } catch (error) {
        // console.log(error.response.data);
      }
    };

    // Guardar la entidad 
    const guardarEntidad = async (isFormValid) => {

     
      //if(!entidad.value.sigla || !entidad.value.nombre){
        submitted.value = true;
        if (!isFormValid) { 
          return;
        }
      //}

      try {
        if (entidad.value.id) {
          await entidadService.update(entidad.value, entidad.value.id);
          listarEntidades();
          closeModal();
          toast.add({
            severity: "success",
            summary: "REGISTRO",
            detail: "Entidad actualizada con éxito",
            life: 5000,
          });
        } else {
          await entidadService.store(entidad.value);
          listarEntidades();
          //categorias.value.push(categoria.value)
          closeModal();
          toast.add({
            severity: "success",
            summary: "REGISTRO",
            detail: "Entidad Registrada con éxito",
            life: 5000,
          });
        }
      } catch (error) {
        // console.log(error.response.data);
        errores.value = error.response.data.errores;
        toast.add({
          severity: "error",
          summary: "ERROR DE REGISTRO",
          detail: "Error de registro de la entidad",
          life: 5000,
        });
      }
    };
    // METODOS DE LA PLANTILLA
    // Abrir un modal
    const openModal = () => {
      entidad.value = {};
      displayModal.value = true;
      submitted.value = false;
    };
    // Abrir un modal
    const editarDialogModal = (datos) => {
      entidad.value = datos;
      displayModal.value = true;
    };

    // Eliminar un producto
    const confirmDeleteProduct = (datos) => {
      entidad.value = datos;
      deleteProductDialog.value = true;
    };

    const deleteProduct = async () => {
      try {
        await entidadService.destroy(entidad.value.id);
        listarEntidades();
        toast.add({
          severity: "error",
          summary: "REGISTRO",
          detail: "Entidad Eliminada",
          life: 5000,
        });
        deleteProductDialog.value = false;
        entidad.value = {}; 
      } catch (error) {
        //console.log(error.response.data);
        errores.value = error.response.data.errores;
        toast.add({
          severity: "error",
          summary: "REGISTRO",
          detail: "No se eleimino el registro",
          life: 5000,
        });
      }
              
    };

    const confirmDeleteSelected = () => {
      deleteProductsDialog.value = true;
    }; 
    const deleteSelectedProducts = () => {
      products.value = products.value.filter(val => !selectedProducts.value.includes(val));
      deleteProductsDialog.value = false;
      selectedProducts.value = null;
      toast.add({severity:'success', summary: 'Successful', detail: 'Entidades Eliminadas', life: 3000});
    };  
        
    // Cerrar un modal
    const closeModal = () => {
      displayModal.value = false;
      entidad.value = {};
      submitted.value = false;
      listarEntidades();
      state.sigla = '';
      state.nombre = '';
      state.correo = '';
    };

    // DEVOLVER REGISTROS
    return {
      rolss,
      idPrimerRol,
      v$,
      state,
      rules,
      buscar,
      onFilter,
      onPage,
      loading,
      totalRecords,
      dt,
      deleteProductDialog,
      deleteProductsDialog,
      confirmDeleteProduct,
      deleteProduct,
      confirmDeleteSelected,
      deleteSelectedProducts,
      selectedProducts,
      filters,
      entidades,
      entidad,
      departamento,
      departamentos,
      provincias,
      listarProvincias,
      municipios,
      listarMunicipios,
      submitted,
      displayModal,
      openModal,
      closeModal,
      guardarEntidad,
      editarDialogModal,
      errores,
    };
  },
  methods: {
		exportCSV() {
			this.$refs.dt.exportCSV();
		},
    async exportToPDF() {
      const doc = new jsPDF();

      // Título del PDF
      doc.setFontSize(20);
      doc.text('Gestión de Entidades', 10, 10);

      // Obtén los datos de la tabla
      const tableData = this.entidades.map(item => [item.sigla, item.nombre, item.direccion, item.email, item.telefono]);

      // Encabezados de la tabla
      const headers = [['Sigla', 'Entidad', 'Dirección', 'E-mail', 'Teléfono']];

      // Crea la tabla en el PDF
      doc.autoTable({
        head: headers,
        body: tableData,
        startY: 20,
      });

      // Guarda el PDF
      doc.save('entidades.pdf');
    },
	},
  components: { InputMask },
};
</script>
<style></style>