import {http, urlBase} from "./Http"

// Listar Declaratoria
export const index = (ppirta = ppirta, page=1, rows=10, q=null) => {
  return http().get(`${urlBase}/decppirta?ppirta=${ppirta}&page=${page}&rows=${rows}&q=${q}`);
}

// Guardar Declaratoria
export const save = (datos) => {
  return http().post(`${urlBase}/decppirta`, datos);
}

// Editar Declaratoria

export const update = (datos, id) => {
  return http().put(`${urlBase}/decppirta/${id}`, datos);
}
// eliminar Declaratoria
export const destroy = (id) => {
  return http().delete(`${urlBase}/decppirta/${id}`);
}
// MOSTRAR
export const show = () => {
  return http().get(`${urlBase}/listvia`);
}
// Estado Declaratoria
export const listest = () => {
  return http().get(`${urlBase}/listestado`);
}
// Listado Declaratoria
export const listdeclaratoria = () => {
  return http().get(`${urlBase}/listpatri`);
}