<template>
    <div class="card">
      <table cellpadding="5px" style="width: 100%; background-color: #f8f8f8; height: 45px">
        <tr>
          <td style="font-size: 20px">
            <B>DATOS DE ENTRANTES</B>
          </td>
        </tr>
      </table>
      <br />

      <Toolbar class="mb-4">
        <template #start>
          <router-link to="/ppirtarevisionnacional">
            <Button label="Ir a bandeja revisión" icon="pi pi-eye" class="p-button-info" />
          </router-link>
          
        </template>
        
      </Toolbar>
      
      <DataTable ref="dt" :lazy="true" :value="entrantes" v-model:selection="selectedProducts" dataKey="id"
      :paginator="true" :rows="10" :filters="filters" :totalRecords="totalRecords" :loading="loading"
      @page="onPage($event)" @filter="onFilter($event)"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10]"
      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros" responsiveLayout="scroll">

        <template #header>
        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
          <h5 class="m-0">ENTRANTES</h5>
          <span class="block mt-2 md:mt-0 p-input-icon-left">
            <i class="pi pi-search" />
            <InputText v-model="filters['global'].value" @input="buscar()" placeholder="Buscar..." />
          </span>
        </div>
      </template>
  
        <Column field="codigo" header="CÓDIGO" :sortable="false" style="min-width: 8rem"></Column>
        <Column field="nombre_emisor" header="NOMBRE DEL EMISOR" :sortable="false" style="min-width: 8rem"></Column>
        <Column field="de_rol" header="NOMBRE DE ROL" :sortable="false" style="min-width: 8rem"></Column>
        <Column field="fecha_emision" header="FECHA DE EMISIÓN" :sortable="false" style="min-width: 8rem"></Column>
        <Column :exportable="false" style="min-width: 12rem" header="ESTADO">
            <template #body="slotProps">
                <span style="background: orange;color: white; padding: 6px;">
                    {{ slotProps.data.seg_estado }}
                </span>
            </template>
        </Column>

        <Column :exportable="false" style="min-width: 12rem" header="ACCIONES">

        <template #body="slotProps">
          <span :style="getCellStyle(slotProps.data.aprobado_gobernacion)" class="mr-1">
            {{ getEntranteLabel(slotProps.data.aprobado_gobernacion) }}
          </span>

          <Button v-if="idPrimerRol === 2 && slotProps.data.aprobado_gobernacion !== 1" class="p-button-primary mr-1"
          @click="confirmRecibir(slotProps.data)" title="Recibir">
            Recibir
          </Button>
        </template>
      </Column>
      </DataTable>

      <!--Formulario de recibir registro -->
    <div class="floatlabel-demo col-12 md:col-12">
  
      <Dialog v-model:visible="recibirDialog" :style="{ width: '450px' }" header="" :modal="true" :closable="false">
        <div class="confirmation-content">
          <h4><b>¿Está seguro de Recibir de la gobernación de  {{ getNombreDepartamento(entrante.id_departamento) }} ?</b></h4><br>
          <span v-if="entrante">
            Código : <b style="color: #4E5FBB;"> {{ entrante.codigo }}</b><br><br>
            Nombre de Emisor : <b style="color: #4E5FBB;"> {{ entrante.nombre_emisor }}</b><br><br>
            Nombre de Rol : <b style="color: #4E5FBB;"> {{ entrante.de_rol }}</b><br><br>
          </span><br>

        </div>
        <template #footer>
          <Button label="Cancelar" icon="pi pi-times" class="p-button-danger" @click="recibirDialog = false" />
          <Button label="RECIBIR" icon="pi pi-check" @click="recibirRegistro" />
        </template>
      </Dialog>

    </div>
      
      <!--Para el despliegue del mensaje de salida-->
      <Toast />
    </div>
  </template>
  
  <script>
  import { reactive,onMounted, ref } from "vue";
  
  import * as ppentranteNService from '@/service/PpirtaEntranteService.js';
  
  import { useToast } from "primevue/usetoast";
  import InputMask from "primevue/inputmask";
  
  import { FilterMatchMode } from "primevue/api";
  
  // Importacones para realizar las validaciones
  import { required } from "@vuelidate/validators";
  import { useVuelidate } from "@vuelidate/core";
  import { useRoute } from 'vue-router'
  
  
  export default {
    setup() {
      const rolss = JSON.parse(localStorage.getItem("role")) || [];
      const primerRol = ref(rolss[0] || {});
      const idPrimerRol = ref(primerRol.value.id || null);
  
      const route = useRoute();
      const entranteId = route.params.id;
  
      const entrantes = ref();
      const entrante = ref({});
      const displayModal = ref(false);
      const submitted = ref(false);
      const toast = useToast();
      const errores = ref({});
      
      onMounted(() => {
        listarEntrantes();
        
      });
  
      const dt = ref();
      const recibirDialog = ref(false);
      const loading = ref(false);
      const totalRecords = ref(0);
      const selectedProducts = ref();
      const lazyParams = ref({});
      
      const filters = ref({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      });
  
      // METODOS
      const onPage = (event) => {
        lazyParams.value = event;
        listarEntrantes();
      };
  
      const onFilter = () => {
        lazyParams.value.filters = filters.value;
        listarEntrantes();
      };
  
      const buscar = () => {
        listarEntrantes();
      };
  
      // Validaion del formulario
      // Definimos los variables a validar
  
      const state = reactive( {
        entrante: {
          id:"",
        }
      });
  
      // Tipo de validacion a realizar del dato
      const rules = {
        entrante: {
          id: { required },
            
        }
      };
      // Seccion de la validacion
      const v$ = useVuelidate(rules, state);
  
      // Listado de las entidades creadas
      const listarEntrantes = async () => {
        try {
          loading.value = true;
          let ppirta = route.params.id
          let rows = lazyParams.value.rows;
          let q = filters.value.global.value == null?'':filters.value.global.value;
          let page = (lazyParams.value.page == null)?0:lazyParams.value.page;
  
          const { data } = await ppentranteNService.indexN(page + 1, rows, q);
      
          entrantes.value = data.entradas.data;
          totalRecords.value = data.entradas.total;
          loading.value = false;
  
        } catch (error) {
          console.log(error.response.data);
        }
      };
  
      // Recibir
      const confirmRecibir = (datos) => {
        entrante.value = datos;
        recibirDialog.value = true;
      };

      const recibirRegistro = async () => {
        try {

          await ppentranteNService.recibirPpirtaN(entrante.value.id);

          listarEntrantes();
          toast.add({
            severity: "success",
            summary: "EL REGISTRO",
            detail: "Se Recibió con éxito, por favor vaya a bandeja de Revisión",
            life: 5000,
          });
          recibirDialog.value = false;
          entrante.value = {};
        } catch (error) {
          //console.log(error.response.data);
          errores.value = error.response.data.errores;
          toast.add({
            severity: "error",
            summary: "EL REGISTRO",
            detail: "No se recibió el registro",
            life: 5000,
          });
        }

      };
   
      // Cerrar un modal
      const closeModal = () => {
        displayModal.value = false;
        entrante.value = {};
        submitted.value = false;
        listarEntrantes();
        state.id = '';
      };
  
      // DEVOLVER REGISTROS
      return {
        rolss,
        idPrimerRol,
        v$,
        state,
        rules,
        buscar,
        onFilter,
        onPage,
        loading,
        totalRecords,
        dt,
        recibirDialog,
        recibirRegistro,
        confirmRecibir,
        selectedProducts,
        filters,
        entrantes,
        entrante,
        submitted,
        displayModal,
        closeModal,
        errores,
      };
    },
    methods: {
      exportCSV() {
        this.$refs.dt.exportCSV();
      },
      getEntranteLabel(aprobado_gobernacion) {
      switch (aprobado_gobernacion) {
        case 1:
          return 'Recibido';
        default:
          return aprobado_gobernacion;
      }
    },
    getCellStyle(aprobado_gobernacion) {
      let backgroundColor;
      switch (aprobado_gobernacion) {
        case 1:
          backgroundColor = '#54ad07';
          break;
        default:
          backgroundColor = '#FFFFFF';
      }
      return {
        backgroundColor,
        padding: '8px',
        color: '#FFFFFF',
        borderRadius: '5px',
        display: 'inline-block',
        marginTop: '2px',
        fontWeight: 'bold',
      };
    },
      
    },
    computed:{
      getNombreDepartamento() {
        return (id) => {
          switch (id) {
            case 1:
              return 'CHUQUISACA';
            case 2:
              return 'LA PAZ';
            case 3:
              return 'COCHABAMBA';
            case 4:
              return 'ORURO';
            case 5:
              return 'POTOSÍ';
            case 6:
              return 'TARIJA';
            case 7:
              return 'SANTA CRUZ';
            case 8:
              return 'BENI';
            case 9:
              return 'PANDO';
            default:
              return 'N/A';
          }
        };
      }
    },
    components: { InputMask },
  };
  </script>
  
  <style></style>