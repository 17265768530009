import {http, urlBase} from "./Http" 

// LISTAR
export const index = (page=1, rows=10, q=null) => {
  return http().get(`${urlBase}/provincia?page=${page}&rows=${rows}&q=${q}`);
}

// LISTAR
export const buscar = (id) => {
  return http().get(`${urlBase}/deppro/${id}`);
}

// GUARDAR
export const store = (datos) => {
  return http().post(`${urlBase}/provincia`,datos);
}

// MOSTRAR
export const show = (id) => {
  return http().get(`${urlBase}/provincia/${id}`);
}

// MODIFICAR
export const update = (datos, id) => {
  return http().put(`${urlBase}/provincia/${id}`, datos);
}

// ELIMINAR
export const destroy = (id) => {
  return http().delete(`${urlBase}/provincia/${id}`);
}