import {http, urlBase} from "./Http" 

// MOSTRAR
export const index = (page=1, rows=10, q=null) => {
  return http().get(`${urlBase}/ppirtfentrada?page=${page}&rows=${rows}&q=${q}`);
}

// RECIBIR
export const recibirPpirta = (id) => {
  return http().post(`${urlBase}/ppirtarecibir/${id}`);
}

//Nacional
// MOSTRAR
export const indexN = (page=1, rows=10, q=null) => {
  return http().get(`${urlBase}/ppirtfnacionalentrada?page=${page}&rows=${rows}&q=${q}`);
}

// RECIBIR
export const recibirPpirtaN = (id) => {
  return http().post(`${urlBase}/ppirtfnacionalrecibir/${id}`);
}