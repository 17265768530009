<template>
    <div class="pages-body login-page flex flex-column">

        <div class="align-self-center mt-auto mb-auto">
            <div class="pages-panel card flex flex-column">

                <div>
                    <img style="height: 230px; width: 200px; padding-bottom: 0px;margin-bottom: 0px;" src="layout/images/mdpyep/logo_viceministerio_turismo.png" alt="logo viceministerio turismo" />
                </div>

                <div style="text-align: center; padding-bottom: 0px;margin-bottom: 0px;">
                    <img style="height: 160px; width: 200px;" src="layout/images/mdpyep/logo-IRTs.png" alt="logo IRT" />
                </div>
                
                <b>Inventariación de Recursos Turísticos</b>
                <br><br>
                
                <div style="width: 350px; text-align: left; color: dimgray;">
                    <label for="email">Correo Electrónico</label>
                    <InputText type="email" name="email" id="email" v-model="credenciales.email" class="w-full mb-4" />

                    <label for="email">Contraseña</label>
                    <div class="input-group">
                        <InputText
                        id="password"
                        :type="mostrarContrasena ? 'text' : 'password'"
                        v-model="credenciales.password"
                        class="w-full mb-2"
                        />
                        <button @click="mostrarContrasena = !mostrarContrasena" class="eye-button">
                        <i :class="['pi', mostrarContrasena ? 'pi-eye-slash' : 'pi-eye']" title="Mostrar contraseña"></i>
                        </button>
                    </div>

                    <div class="flex align-items-center justify-content-between mb-3">
                        <div class="flex align-items-center">
                            <Checkbox id="rememberme" v-model="rememberMe" :binary="true" class="mr-2"></Checkbox>
                            <label for="rememberme">Recordar</label>
                        </div>
                        <!-- <a class="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer">¿Olvido tu contraseña?</a> -->
                    </div>
                    <Button type="button" :loading="loading" @click="login()" label="Iniciar Sesion" icon="pi pi-user" class="w-full my-2"></Button>
                </div>
                    <a href="layout/apk/app-release.apk" class="p-button font-bold w-full flex align-items-center justify-content-center">Descargar aplicación movil</a>
            </div>
        </div>
        <Toast />
    </div>
</template>

<script>

function validarCorreoElectronico(email) {
  const expresionRegularCorreo = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return expresionRegularCorreo.test(email);
}

import { ref } from "vue"
import * as authService from "@/service/AuthService.js"
import { useRouter, useRoute } from "vue-router"
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex"
import { Buffer } from "buffer"
import { onMounted } from "vue";

export default {
    data() {
        return {
        mostrarContrasena: false,
        // ... otros datos y métodos ...
        };
    },

    setup() {
        const loading = ref(false);
        const checked = ref(false);
        const toast = useToast();
        const router = useRouter();
        const store = useStore();
        const credenciales = ref({ email: '', password: '' });
        const rememberMe = ref(false);

        const login = async () => {

            loading.value = true;
            const startTime = performance.now();

            if (!validarCorreoElectronico(credenciales.value.email)) {
                toast.add({
                severity: "error",
                summary: "ERROR",
                detail: "Por favor, ingrese un correo electrónico válido.",
                life: 5000,
                });
                return; // Salir de la función si el correo no es válido
            }
            try {
                const res = await authService.loginConLaravel(credenciales.value)
                store.dispatch("loginVuex", res.data)

                const base64Token = Buffer.from(res.data.access_token).toString('base64');

                localStorage.setItem("token", base64Token);
                localStorage.setItem("user", JSON.stringify(res.data.user));
                localStorage.setItem("role", JSON.stringify(res.data.rol));
                localStorage.setItem("menu", JSON.stringify(res.data.menu));

                if (rememberMe.value) {
                    localStorage.setItem("rememberMe", "true");
                    localStorage.setItem("rememberedEmail", credenciales.value.email);
                } else {
                    localStorage.setItem("rememberMe", "false");
                    localStorage.removeItem("rememberedEmail");
                }

                // router.push({ name: "dashboarde" });
                router.push("/inicio");

            } catch (error) {
                loading.value = false;
                toast.add({
                    severity: "error",
                    summary: "CORREGIR",
                    detail: "Los Credenciales del usuario son Incorrectos.",
                    life: 5000,
                });

            } finally {
                const endTime = performance.now(); // Registro del tiempo de finalización
                const elapsedTime = endTime - startTime; // Cálculo del tiempo transcurrido en milisegundos

                // Establecer un límite de tiempo
                const timeoutDuration = 3000;
                const remainingTime = timeoutDuration - elapsedTime;

                if (remainingTime > 0) {

                setTimeout(() => {
                    loading.value = false;
                }, remainingTime);
                } else {
                loading.value = false;
                }
            }
        }

        onMounted(() => {
            const rememberedEmail = localStorage.getItem("rememberedEmail");
            if (rememberedEmail) {
                credenciales.value.email = rememberedEmail;
            }

            const rememberedCheckbox = localStorage.getItem("rememberMe");
            if (rememberedCheckbox === "true") {
                rememberMe.value = true;
            } else if (rememberedCheckbox === "false") {
                rememberMe.value = false;
            }
        });

        return {
            isAuthenticated: store.getters.isAuthenticated, // Add this line
            auth: store.state.auth, // Add this line
            credenciales,
            login,
            rememberMe,
            loading,
        };

    }
}
</script>
<style scoped>
/* Agrega estilos al botón del ojo */
.input-group button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  outline: none;
}

/* Estilos para el ícono del ojo abierto */
.pi-eye {
  font-size: 16px; /* Ajusta el tamaño según sea necesario */
  color: #333; /* Cambia el color del ícono según sea necesario */
}

/* Estilos para el ícono del ojo tachado (contraseña oculta) */
.pi-eye-slash {
  font-size: 16px; /* Ajusta el tamaño según sea necesario */
  color: #ccc; /* Cambia el color del ícono según sea necesario */
}

/* Estilos para el campo de contraseña */
#password {
  padding-right: 30px; /* Espacio para el botón del ojo */
}

/* Establece la contraseña como visible cuando mostrarContrasena es true */
#password[type="text"] {
  -webkit-text-security: none;
  text-security: none;
}

/* Establece la contraseña como oculta cuando mostrarContrasena es false */
#password[type="password"] {
  -webkit-text-security: disc;
  text-security: disc;
}
.input-group {
  display: flex;
  align-items: center; /* Centra verticalmente los elementos */
}

/* Estilos para el botón del ojo */
.eye-button {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 10px; /* Ajusta el espacio entre el campo y el botón */
}
</style>